// @flow
import React, {PureComponent, type ElementConfig} from 'react';
import {debounce} from 'lodash';

import GoogleMap, {
  type MapRef,
  type LatLng,
  type LatLngLiteral,
} from 'google/maps/GoogleMap';

const MAP_ZOOM = 18;

const MAP_OPTIONS = {
  gestureHandling: 'none',
  draggableCursor: 'default',
  fullscreenControl: false,
  rotateControl: false,
  streetViewControl: false,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
};

type Props = {|
  ...ElementConfig<typeof GoogleMap>,
  center: LatLng | LatLngLiteral,
  zoom: number,
  options: Object,
|};

class GoogleMapStatic extends PureComponent<Props> {
  static defaultProps = {
    zoom: MAP_ZOOM,
    options: MAP_OPTIONS,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeDebounced);
  }

  handleResize = () => {
    const {center} = this.props;

    if (this.map) {
      this.map.panTo(center);
    }
  };

  handleResizeDebounced = debounce(this.handleResize, 100);

  map: ?MapRef;
  handleMapMount = (mapRef: MapRef) => (this.map = mapRef);

  render() {
    const {center, zoom, options, ...props} = this.props;

    return (
      <GoogleMap
        onMount={this.handleMapMount}
        center={center}
        zoom={zoom}
        options={options}
        {...props}
      />
    );
  }
}

export default GoogleMapStatic;
