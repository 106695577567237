// @flow
import React, {PureComponent, type Node, type ComponentType} from 'react';
import PropTypes from 'prop-types';

import GoogleMapsAPI from 'google/maps/GoogleMapsAPI';

type Props = {|
  googleMapsAPI: GoogleMapsAPI,
  children: Node,
|};

export type GoogleMapsAPIContext = {
  googleMapsAPI: GoogleMapsAPI,
};

export const GoogleMapsAPIContextTypes = {
  googleMapsAPI: PropTypes.instanceOf(GoogleMapsAPI),
};

export function withGoogleMapsAPI<
  InnerProps: Object,
  OuterProps: $Diff<InnerProps, {googleMapsAPI: GoogleMapsAPI}>,
>(C: ComponentType<InnerProps>): ComponentType<OuterProps> {
  return class Wrapped extends PureComponent<OuterProps> {
    static contextTypes = GoogleMapsAPIContextTypes;
    context: GoogleMapsAPIContext;

    render() {
      const {googleMapsAPI} = this.context;
      return <C {...this.props} googleMapsAPI={googleMapsAPI} />;
    }
  };
}

export default class GoogleMapsAPIProvider extends PureComponent<Props> {
  static childContextTypes = GoogleMapsAPIContextTypes;

  getChildContext() {
    const {googleMapsAPI} = this.props;
    return {googleMapsAPI};
  }

  render() {
    return this.props.children;
  }
}
