// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/photos/models.proto

export const Photo_CategoryValues = {
  INVALID: 0,
  MISC: 1,
  PRE_INSTALLATION_CHECKLIST: 2,
  POST_INSTALLATION_CHECKLIST: 3,
  LADDER: 4,
  FRONT_OF_HOUSE: 5,
  EXISTING_DAMAGE: 6,
  CAUSED_DAMAGE: 7,
  ROOFTOP: 8,
  RADIO_MAC_ADDRESS: 9,
  FULL_MOUNT: 10,
  MOUNT_ATTACHMENT: 11,
  EXTERIOR_CABLE_RUN: 12,
  INTERIOR_EQUIPMENT: 13,
  SPEEDTEST: 14,
  GENERAL_LOS: 15,
  RADIO_LOS: 16,
  AP_MAC_ADDRESS: 17,
  SAFETY: 18,
};
type Photo_Category = $Keys<typeof Photo_CategoryValues>;
export type {Photo_Category};

export function Photo_CategoryValue(n: number): Photo_Category {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'MISC';

    case 2:
      return 'PRE_INSTALLATION_CHECKLIST';

    case 3:
      return 'POST_INSTALLATION_CHECKLIST';

    case 4:
      return 'LADDER';

    case 5:
      return 'FRONT_OF_HOUSE';

    case 6:
      return 'EXISTING_DAMAGE';

    case 7:
      return 'CAUSED_DAMAGE';

    case 8:
      return 'ROOFTOP';

    case 9:
      return 'RADIO_MAC_ADDRESS';

    case 10:
      return 'FULL_MOUNT';

    case 11:
      return 'MOUNT_ATTACHMENT';

    case 12:
      return 'EXTERIOR_CABLE_RUN';

    case 13:
      return 'INTERIOR_EQUIPMENT';

    case 14:
      return 'SPEEDTEST';

    case 15:
      return 'GENERAL_LOS';

    case 16:
      return 'RADIO_LOS';

    case 17:
      return 'AP_MAC_ADDRESS';

    case 18:
      return 'SAFETY';

    default:
      return 'INVALID';
  }
}

class Photo {
  id: string;
  url: string;
  created: ?Date;
  title: string;
  contentType: string;
  fileName: string;
  bytes: string;
  uploader: string;
  deleted: boolean;
  deleted_time: ?Date;
  category: Photo_Category;
  site_id: string;
  site_radio_link_id: string;
  work_order_id: string;
  serving_url: string;

  constructor(props: $Shape<Photo> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.url = '';
    if (props.hasOwnProperty('url')) {
      const v = props.url;
      this.url = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.title = '';
    if (props.hasOwnProperty('title')) {
      const v = props.title;
      this.title = v;
    }

    this.contentType = '';
    if (props.hasOwnProperty('contentType')) {
      const v = props.contentType;
      this.contentType = v;
    }

    this.fileName = '';
    if (props.hasOwnProperty('fileName')) {
      const v = props.fileName;
      this.fileName = v;
    }

    this.bytes = '';
    if (props.hasOwnProperty('bytes')) {
      const v = props.bytes;
      this.bytes = v;
    }

    this.uploader = '';
    if (props.hasOwnProperty('uploader')) {
      const v = props.uploader;
      this.uploader = v;
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }

    this.deleted_time = null;
    if (props.hasOwnProperty('deleted_time')) {
      const v = props.deleted_time;
      this.deleted_time = v && new Date(v);
    }

    this.category = Photo_CategoryValue(0);
    if (props.hasOwnProperty('category')) {
      const v = props.category;
      this.category = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.site_radio_link_id = '0';
    if (props.hasOwnProperty('site_radio_link_id')) {
      const v = props.site_radio_link_id;
      this.site_radio_link_id = v;
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.serving_url = '';
    if (props.hasOwnProperty('serving_url')) {
      const v = props.serving_url;
      this.serving_url = v;
    }
  }
}
export {Photo};

// END cwn/photos/models.proto
