// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/log/models.proto

export const SeverityValues = {
  NIL: 0,
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  AUDIT: 10,
};
type Severity = $Keys<typeof SeverityValues>;
export type {Severity};

export function SeverityValue(n: number): Severity {
  switch (n) {
    case 0:
      return 'NIL';

    case 1:
      return 'DEBUG';

    case 2:
      return 'INFO';

    case 3:
      return 'WARN';

    case 4:
      return 'ERROR';

    case 10:
      return 'AUDIT';

    default:
      return 'NIL';
  }
}

export const ActionValues = {
  INVALID: 0,
  BUP_CONFIG_CHANGE: 4,
  ADMIN_REBOOT_RADIO_REQUEST: 19,
  ADMIN_FACTORY_RESET_RADIO_REQUEST: 20,
  ADMIN_SPEEDTEST_REQUEST: 21,
  ADMIN_RADIO_GROUP_CHANGE: 22,
  ADMIN_ADD_NOTE: 23,
  AGENT_BOAT_CONFIG_UPLOAD: 25,
  ADMIN_SITE_RADIO_LINK_CHANGE: 26,
  ADMIN_SUBSCRIPTION_STATUS_CHANGE: 27,
  ADMIN_SITE_DETAILS_CHANGE: 28,
  ADMIN_SITE_STATUS_CHANGE: 29,
  ADMIN_SITE_DOCTOR: 30,
  ADMIN_PUT_ACTION: 32,
  ADMIN_CONFIGURE_UNIFI: 40,
  ADMIN_EDGEWEIGHT_OVERRIDE: 41,
  CUSTOMER_SERVICEABILITY_CHANGE: 43,
  ADMIN_ENABLE_MAINTENANCE_MODE: 44,
  ADMIN_DISABLE_MAINTENANCE_MODE: 45,
  ADMIN_ENABLE_RELAY_MODE: 46,
  ADMIN_DISABLE_RELAY_MODE: 47,
  ADMIN_SITE_DEVICE_CHANGE: 69,
  ADMIN_UNINSTALL_SITE: 70,
  ADMIN_CHANNEL_LOCK_CHANGE: 71,
  AGENT_MODULE_PANIC: 53,
  AGENT_UPS_AC_DOWN: 58,
  AGENT_UPS_AC_UP: 59,
  AGENT_UPS_RUNTIME_HOUR_WARNING: 60,
  AGENT_UPS_RUNTIME_CRITICAL: 61,
  VAR_CHANGE: 67,
};
type Action = $Keys<typeof ActionValues>;
export type {Action};

export function ActionValue(n: number): Action {
  switch (n) {
    case 0:
      return 'INVALID';

    case 4:
      return 'BUP_CONFIG_CHANGE';

    case 19:
      return 'ADMIN_REBOOT_RADIO_REQUEST';

    case 20:
      return 'ADMIN_FACTORY_RESET_RADIO_REQUEST';

    case 21:
      return 'ADMIN_SPEEDTEST_REQUEST';

    case 22:
      return 'ADMIN_RADIO_GROUP_CHANGE';

    case 23:
      return 'ADMIN_ADD_NOTE';

    case 25:
      return 'AGENT_BOAT_CONFIG_UPLOAD';

    case 26:
      return 'ADMIN_SITE_RADIO_LINK_CHANGE';

    case 27:
      return 'ADMIN_SUBSCRIPTION_STATUS_CHANGE';

    case 28:
      return 'ADMIN_SITE_DETAILS_CHANGE';

    case 29:
      return 'ADMIN_SITE_STATUS_CHANGE';

    case 30:
      return 'ADMIN_SITE_DOCTOR';

    case 32:
      return 'ADMIN_PUT_ACTION';

    case 40:
      return 'ADMIN_CONFIGURE_UNIFI';

    case 41:
      return 'ADMIN_EDGEWEIGHT_OVERRIDE';

    case 43:
      return 'CUSTOMER_SERVICEABILITY_CHANGE';

    case 44:
      return 'ADMIN_ENABLE_MAINTENANCE_MODE';

    case 45:
      return 'ADMIN_DISABLE_MAINTENANCE_MODE';

    case 46:
      return 'ADMIN_ENABLE_RELAY_MODE';

    case 47:
      return 'ADMIN_DISABLE_RELAY_MODE';

    case 69:
      return 'ADMIN_SITE_DEVICE_CHANGE';

    case 70:
      return 'ADMIN_UNINSTALL_SITE';

    case 71:
      return 'ADMIN_CHANNEL_LOCK_CHANGE';

    case 53:
      return 'AGENT_MODULE_PANIC';

    case 58:
      return 'AGENT_UPS_AC_DOWN';

    case 59:
      return 'AGENT_UPS_AC_UP';

    case 60:
      return 'AGENT_UPS_RUNTIME_HOUR_WARNING';

    case 61:
      return 'AGENT_UPS_RUNTIME_CRITICAL';

    case 67:
      return 'VAR_CHANGE';

    default:
      return 'INVALID';
  }
}

class Metadata {
  blob: ?google_protobuf.Any;
  site_id: string;
  device_id: string;
  initiator: string;
  blob_string: string;
  lead_id: string;

  constructor(props: $Shape<Metadata> = {}): void {
    if (!props) {
      props = {};
    }

    this.blob = null;
    if (props.hasOwnProperty('blob')) {
      const v = props.blob;
      this.blob = v && new google_protobuf.Any(v);
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }

    this.blob_string = '';
    if (props.hasOwnProperty('blob_string')) {
      const v = props.blob_string;
      this.blob_string = v;
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }

  getBlob(): google_protobuf.Any {
    if (this.blob) {
      return this.blob;
    }
    return new google_protobuf.Any();
  }
}
export {Metadata};

class Line {
  number: string;
  severity: Severity;
  created: ?Date;
  message: string;
  scopes: Array<string>;
  action: Action;
  metadata: ?Metadata;

  constructor(props: $Shape<Line> = {}): void {
    if (!props) {
      props = {};
    }

    this.number = '0';
    if (props.hasOwnProperty('number')) {
      const v = props.number;
      this.number = v;
    }

    this.severity = SeverityValue(0);
    if (props.hasOwnProperty('severity')) {
      const v = props.severity;
      this.severity = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.message = '';
    if (props.hasOwnProperty('message')) {
      const v = props.message;
      this.message = v;
    }

    this.scopes = [];
    if (props.hasOwnProperty('scopes')) {
      const v = props.scopes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field scopes should be array');
      }
      this.scopes = v.map(function(v) {
        return v;
      });
    }

    this.action = ActionValue(0);
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v;
    }

    this.metadata = null;
    if (props.hasOwnProperty('metadata')) {
      const v = props.metadata;
      this.metadata = v && new Metadata(v);
    }
  }

  getMetadata(): Metadata {
    if (this.metadata) {
      return this.metadata;
    }
    return new Metadata();
  }
}
export {Line};

class BqLine {
  number: string;
  severity: Severity;
  created: ?Date;
  message: string;
  scopes: Array<string>;
  action: Action;
  metadata: ?BqMetadata;

  constructor(props: $Shape<BqLine> = {}): void {
    if (!props) {
      props = {};
    }

    this.number = '0';
    if (props.hasOwnProperty('number')) {
      const v = props.number;
      this.number = v;
    }

    this.severity = SeverityValue(0);
    if (props.hasOwnProperty('severity')) {
      const v = props.severity;
      this.severity = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.message = '';
    if (props.hasOwnProperty('message')) {
      const v = props.message;
      this.message = v;
    }

    this.scopes = [];
    if (props.hasOwnProperty('scopes')) {
      const v = props.scopes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field scopes should be array');
      }
      this.scopes = v.map(function(v) {
        return v;
      });
    }

    this.action = ActionValue(0);
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v;
    }

    this.metadata = null;
    if (props.hasOwnProperty('metadata')) {
      const v = props.metadata;
      this.metadata = v && new BqMetadata(v);
    }
  }

  getMetadata(): BqMetadata {
    if (this.metadata) {
      return this.metadata;
    }
    return new BqMetadata();
  }
}
export {BqLine};

class BqMetadata {
  site_id: string;
  device_id: string;
  initiator: string;
  lead_id: string;

  constructor(props: $Shape<BqMetadata> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {BqMetadata};

// END cwn/log/models.proto
