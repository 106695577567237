// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/asset/models.proto

export const VendorValues = {
  VENDOR_UNKNOWN: 0,
  UBNT: 1,
  IGNITENET: 2,
  NETGEAR: 3,
  COMMONNETWORKS: 4,
  BFGT: 5,
  SIKLU: 6,
  RFELEMENTS: 7,
  QUALCOMM: 8,
  MIKROTIK: 9,
  NETONIX: 10,
  ACTIONTEC: 11,
  POSITRON: 12,
  HITRON: 13,
};
type Vendor = $Keys<typeof VendorValues>;
export type {Vendor};

export function VendorValue(n: number): Vendor {
  switch (n) {
    case 0:
      return 'VENDOR_UNKNOWN';

    case 1:
      return 'UBNT';

    case 2:
      return 'IGNITENET';

    case 3:
      return 'NETGEAR';

    case 4:
      return 'COMMONNETWORKS';

    case 5:
      return 'BFGT';

    case 6:
      return 'SIKLU';

    case 7:
      return 'RFELEMENTS';

    case 8:
      return 'QUALCOMM';

    case 9:
      return 'MIKROTIK';

    case 10:
      return 'NETONIX';

    case 11:
      return 'ACTIONTEC';

    case 12:
      return 'POSITRON';

    case 13:
      return 'HITRON';

    default:
      return 'VENDOR_UNKNOWN';
  }
}

export const TypeValues = {
  TYPE_UNKNOWN: 0,
  RADIO: 1,
  SWITCH: 2,
  AP: 3,
  ANTENNA: 4,
  MODEM: 5,
};
type Type = $Keys<typeof TypeValues>;
export type {Type};

export function TypeValue(n: number): Type {
  switch (n) {
    case 0:
      return 'TYPE_UNKNOWN';

    case 1:
      return 'RADIO';

    case 2:
      return 'SWITCH';

    case 3:
      return 'AP';

    case 4:
      return 'ANTENNA';

    case 5:
      return 'MODEM';

    default:
      return 'TYPE_UNKNOWN';
  }
}

export const RadioFrequencyValues = {
  RF_NONE: 0,
  RF_UNDEFINED: 1,
  RF_5GHZ: 2,
  RF_24GHZ: 3,
  RF_60GHZ: 4,
  RF_70PLUSGHZ: 5,
};
type RadioFrequency = $Keys<typeof RadioFrequencyValues>;
export type {RadioFrequency};

export function RadioFrequencyValue(n: number): RadioFrequency {
  switch (n) {
    case 0:
      return 'RF_NONE';

    case 1:
      return 'RF_UNDEFINED';

    case 2:
      return 'RF_5GHZ';

    case 3:
      return 'RF_24GHZ';

    case 4:
      return 'RF_60GHZ';

    case 5:
      return 'RF_70PLUSGHZ';

    default:
      return 'RF_NONE';
  }
}

export const ModelValues = {
  MODEL_UNKNOWN: 0,
  UBNT_RADIO_IS5AC: 1,
  UBNT_RADIO_LBE5AC23: 2,
  UBNT_RADIO_LBE5ACGEN2: 3,
  UBNT_RADIO_LBE5AC16120: 4,
  UBNT_RADIO_NBE5AC16: 5,
  UBNT_RADIO_NBE5AC19: 6,
  UBNT_RADIO_NBE5ACGEN2: 7,
  UBNT_RADIO_NS5ACL: 8,
  UBNT_RADIO_PBE5ACGEN2: 9,
  UBNT_RADIO_PS5AC: 10,
  UBNT_RADIO_R5ACPRISM: 11,
  UBNT_RADIO_RP5ACGEN2: 12,
  UBNT_SWITCH_EPR6: 101,
  UBNT_SWITCH_EPS16: 102,
  UBNT_SWITCH_TS5POE: 103,
  UBNT_SWITCH_TS8PRO: 104,
  UBNT_SWITCH_ES24: 105,
  UBNT_SWITCH_ER4: 106,
  UBNT_SWITCH_ES10X: 107,
  UBNT_SWITCH_ES5XP: 108,
  UBNT_SWITCH_ES18X: 109,
  UBNT_AP_UAPACPROGEN2: 207,
  UBNT_AP_UAPACIWPRO: 208,
  UBNT_AP_UAPACHD: 209,
  UBNT_AP_UAPACMESH: 210,
  UBNT_AP_UAPACMESHPRO: 211,
  UBNT_AP_UAPACLITE: 212,
  IGNITENET_RADIO_UNKNOWN: 300,
  IGNITENET_RADIO_ML256035: 301,
  IGNITENET_RADIO_ML60LW: 302,
  IGNITENET_RADIO_ML256019: 303,
  IGNITENET_RADIO_ML1_60_35: 304,
  IGNITENET_RADIO_ML_25_60_BF_18: 305,
  IGNITENET_RADIO_ML1_60_19: 306,
  UBNT_RADIO_AF24: 401,
  UBNT_RADIO_AF24HD: 402,
  COMMONNETWORKS_SWITCH_CORV1: 501,
  COMMONNETWORKS_SWITCH_PUMA: 502,
  BFGT_RADIO_REV_5: 601,
  SIKLU_RADIO_E5500FD: 701,
  SIKLU_RADIO_EH1200FX: 702,
  SIKLU_RADIO_EH8010FX: 703,
  UBNT_ANTENNA_PRISMAP_5_90: 801,
  UBNT_ANTENNA_PRISMAP_5_60: 802,
  UBNT_ANTENNA_PRISMAP_5_45: 803,
  UBNT_ANTENNA_AMO_5G13: 804,
  RFELEMENTS_ANTENNA_SH_TP_5_30: 901,
  RFELEMENTS_ANTENNA_SH_TP_5_60: 902,
  RFELEMENTS_ANTENNA_SH_TP_5_90: 903,
  QUALCOMM_RADIO_TALYN: 1001,
  MIKROTIK_RADIO_WAP_60G: 1101,
  MIKROTIK_RADIO_WAP_60G_AP: 1102,
  MIKROTIK_RADIO_LHG_60G: 1103,
  MIKROTIK_RADIO_WAP_60GX3_AP: 1104,
  NETONIX_SWITCH_WS_6_MINI: 1201,
  ACTIONTEC_MODEM_ECB6200: 1301,
  ACTIONTEC_MODEM_ECB6250: 1302,
  POSITRON_SWITCH_GAM_12_M: 1401,
  POSITRON_SWITCH_GAM_24_M: 1402,
  HITRON_MODEM_EM4: 1501,
};
type Model = $Keys<typeof ModelValues>;
export type {Model};

export function ModelValue(n: number): Model {
  switch (n) {
    case 0:
      return 'MODEL_UNKNOWN';

    case 1:
      return 'UBNT_RADIO_IS5AC';

    case 2:
      return 'UBNT_RADIO_LBE5AC23';

    case 3:
      return 'UBNT_RADIO_LBE5ACGEN2';

    case 4:
      return 'UBNT_RADIO_LBE5AC16120';

    case 5:
      return 'UBNT_RADIO_NBE5AC16';

    case 6:
      return 'UBNT_RADIO_NBE5AC19';

    case 7:
      return 'UBNT_RADIO_NBE5ACGEN2';

    case 8:
      return 'UBNT_RADIO_NS5ACL';

    case 9:
      return 'UBNT_RADIO_PBE5ACGEN2';

    case 10:
      return 'UBNT_RADIO_PS5AC';

    case 11:
      return 'UBNT_RADIO_R5ACPRISM';

    case 12:
      return 'UBNT_RADIO_RP5ACGEN2';

    case 101:
      return 'UBNT_SWITCH_EPR6';

    case 102:
      return 'UBNT_SWITCH_EPS16';

    case 103:
      return 'UBNT_SWITCH_TS5POE';

    case 104:
      return 'UBNT_SWITCH_TS8PRO';

    case 105:
      return 'UBNT_SWITCH_ES24';

    case 106:
      return 'UBNT_SWITCH_ER4';

    case 107:
      return 'UBNT_SWITCH_ES10X';

    case 108:
      return 'UBNT_SWITCH_ES5XP';

    case 109:
      return 'UBNT_SWITCH_ES18X';

    case 207:
      return 'UBNT_AP_UAPACPROGEN2';

    case 208:
      return 'UBNT_AP_UAPACIWPRO';

    case 209:
      return 'UBNT_AP_UAPACHD';

    case 210:
      return 'UBNT_AP_UAPACMESH';

    case 211:
      return 'UBNT_AP_UAPACMESHPRO';

    case 212:
      return 'UBNT_AP_UAPACLITE';

    case 300:
      return 'IGNITENET_RADIO_UNKNOWN';

    case 301:
      return 'IGNITENET_RADIO_ML256035';

    case 302:
      return 'IGNITENET_RADIO_ML60LW';

    case 303:
      return 'IGNITENET_RADIO_ML256019';

    case 304:
      return 'IGNITENET_RADIO_ML1_60_35';

    case 305:
      return 'IGNITENET_RADIO_ML_25_60_BF_18';

    case 306:
      return 'IGNITENET_RADIO_ML1_60_19';

    case 401:
      return 'UBNT_RADIO_AF24';

    case 402:
      return 'UBNT_RADIO_AF24HD';

    case 501:
      return 'COMMONNETWORKS_SWITCH_CORV1';

    case 502:
      return 'COMMONNETWORKS_SWITCH_PUMA';

    case 601:
      return 'BFGT_RADIO_REV_5';

    case 701:
      return 'SIKLU_RADIO_E5500FD';

    case 702:
      return 'SIKLU_RADIO_EH1200FX';

    case 703:
      return 'SIKLU_RADIO_EH8010FX';

    case 801:
      return 'UBNT_ANTENNA_PRISMAP_5_90';

    case 802:
      return 'UBNT_ANTENNA_PRISMAP_5_60';

    case 803:
      return 'UBNT_ANTENNA_PRISMAP_5_45';

    case 804:
      return 'UBNT_ANTENNA_AMO_5G13';

    case 901:
      return 'RFELEMENTS_ANTENNA_SH_TP_5_30';

    case 902:
      return 'RFELEMENTS_ANTENNA_SH_TP_5_60';

    case 903:
      return 'RFELEMENTS_ANTENNA_SH_TP_5_90';

    case 1001:
      return 'QUALCOMM_RADIO_TALYN';

    case 1101:
      return 'MIKROTIK_RADIO_WAP_60G';

    case 1102:
      return 'MIKROTIK_RADIO_WAP_60G_AP';

    case 1103:
      return 'MIKROTIK_RADIO_LHG_60G';

    case 1104:
      return 'MIKROTIK_RADIO_WAP_60GX3_AP';

    case 1201:
      return 'NETONIX_SWITCH_WS_6_MINI';

    case 1301:
      return 'ACTIONTEC_MODEM_ECB6200';

    case 1302:
      return 'ACTIONTEC_MODEM_ECB6250';

    case 1401:
      return 'POSITRON_SWITCH_GAM_12_M';

    case 1402:
      return 'POSITRON_SWITCH_GAM_24_M';

    case 1501:
      return 'HITRON_MODEM_EM4';

    default:
      return 'MODEL_UNKNOWN';
  }
}

class AssetKind {
  vendor: Vendor;
  type: Type;
  model: Model;

  constructor(props: $Shape<AssetKind> = {}): void {
    if (!props) {
      props = {};
    }

    this.vendor = VendorValue(0);
    if (props.hasOwnProperty('vendor')) {
      const v = props.vendor;
      this.vendor = v;
    }

    this.type = TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.model = ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }
  }
}
export {AssetKind};

class Asset {
  mac_address: string;
  model: ?AssetKind;

  constructor(props: $Shape<Asset> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.model = null;
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v && new AssetKind(v);
    }
  }

  getModel(): AssetKind {
    if (this.model) {
      return this.model;
    }
    return new AssetKind();
  }
}
export {Asset};

// END cwn/asset/models.proto
