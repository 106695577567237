// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_site from '../../cwn/site';
import * as cwn_signup from '../../cwn/signup';
import * as cwn_geocoder from '../../cwn/geocoder';
import * as cwn_customers from '../../cwn/customers';
import * as cwn_billing from '../../cwn/billing';

// START cwn/lead/models.proto

export const OnboardingStepValues = {
  INVALID: 0,
  SIGNUP: 1,
  CHECKING_AVAILABILITY: 2,
  ACCEPTED: 3,
  REJECTED: 4,
  MANUAL_REVIEW: 5,
  PLAN_SELECTION: 6,
  APPOINTMENT_SELECTION: 7,
  NO_AVAILABLE_SLOTS: 8,
  DONE: 11,
  ERROR: 12,
  EXPIRED_LINK: 13,
  PAUSED_MDU: 14,
};
type OnboardingStep = $Keys<typeof OnboardingStepValues>;
export type {OnboardingStep};

export function OnboardingStepValue(n: number): OnboardingStep {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'SIGNUP';

    case 2:
      return 'CHECKING_AVAILABILITY';

    case 3:
      return 'ACCEPTED';

    case 4:
      return 'REJECTED';

    case 5:
      return 'MANUAL_REVIEW';

    case 6:
      return 'PLAN_SELECTION';

    case 7:
      return 'APPOINTMENT_SELECTION';

    case 8:
      return 'NO_AVAILABLE_SLOTS';

    case 11:
      return 'DONE';

    case 12:
      return 'ERROR';

    case 13:
      return 'EXPIRED_LINK';

    case 14:
      return 'PAUSED_MDU';

    default:
      return 'INVALID';
  }
}

export const Lead_StateValues = {
  INVALID: 0,
  NEW: 1,
  ACCEPTED: 2,
  BOOKED: 3,
  REJECTED: 4,
  ARCHIVED: 5,
  CONVERTED: 6,
  NO_LONGER_INTERESTED: 7,
  MANUAL_REVIEW: 8,
};
type Lead_State = $Keys<typeof Lead_StateValues>;
export type {Lead_State};

export function Lead_StateValue(n: number): Lead_State {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'NEW';

    case 2:
      return 'ACCEPTED';

    case 3:
      return 'BOOKED';

    case 4:
      return 'REJECTED';

    case 5:
      return 'ARCHIVED';

    case 6:
      return 'CONVERTED';

    case 7:
      return 'NO_LONGER_INTERESTED';

    case 8:
      return 'MANUAL_REVIEW';

    default:
      return 'INVALID';
  }
}

export const Lead_MethodOfStateChangeValues = {
  UNKNOWN_METHOD: 0,
  AUTO: 1,
  MANUAL: 2,
};
type Lead_MethodOfStateChange = $Keys<typeof Lead_MethodOfStateChangeValues>;
export type {Lead_MethodOfStateChange};

export function Lead_MethodOfStateChangeValue(
  n: number,
): Lead_MethodOfStateChange {
  switch (n) {
    case 0:
      return 'UNKNOWN_METHOD';

    case 1:
      return 'AUTO';

    case 2:
      return 'MANUAL';

    default:
      return 'UNKNOWN_METHOD';
  }
}

export const Lead_ReasonForRejectionValues = {
  UNKNOWN_REJECTION_REASON: 0,
  MARKET_NOT_LIVE: 1,
  UNSERVICEABLE: 2,
  PERMISSION_REQUIRED_IN_PROGRESS: 3,
  PERMISSION_REQUIRED_DENIED: 4,
  SITE_DELETED: 5,
  MARKET_COMING_SOON: 6,
  BUILDING_RESTRICTION: 7,
  MARKET_PAUSED: 8,
  PLAN_OVERRIDE_NO_PLANS: 9,
};
type Lead_ReasonForRejection = $Keys<typeof Lead_ReasonForRejectionValues>;
export type {Lead_ReasonForRejection};

export function Lead_ReasonForRejectionValue(
  n: number,
): Lead_ReasonForRejection {
  switch (n) {
    case 0:
      return 'UNKNOWN_REJECTION_REASON';

    case 1:
      return 'MARKET_NOT_LIVE';

    case 2:
      return 'UNSERVICEABLE';

    case 3:
      return 'PERMISSION_REQUIRED_IN_PROGRESS';

    case 4:
      return 'PERMISSION_REQUIRED_DENIED';

    case 5:
      return 'SITE_DELETED';

    case 6:
      return 'MARKET_COMING_SOON';

    case 7:
      return 'BUILDING_RESTRICTION';

    case 8:
      return 'MARKET_PAUSED';

    case 9:
      return 'PLAN_OVERRIDE_NO_PLANS';

    default:
      return 'UNKNOWN_REJECTION_REASON';
  }
}

export const Lead_ReasonForAcceptanceValues = {
  UNKNOWN_ACCEPTANCE_REASON: 0,
  PERMISSION_NOT_REQUIRED: 1,
  PERMISSION_REQUIRED_APPROVED: 2,
  PLAN_OVERRIDE_HAS_PLANS: 3,
};
type Lead_ReasonForAcceptance = $Keys<typeof Lead_ReasonForAcceptanceValues>;
export type {Lead_ReasonForAcceptance};

export function Lead_ReasonForAcceptanceValue(
  n: number,
): Lead_ReasonForAcceptance {
  switch (n) {
    case 0:
      return 'UNKNOWN_ACCEPTANCE_REASON';

    case 1:
      return 'PERMISSION_NOT_REQUIRED';

    case 2:
      return 'PERMISSION_REQUIRED_APPROVED';

    case 3:
      return 'PLAN_OVERRIDE_HAS_PLANS';

    default:
      return 'UNKNOWN_ACCEPTANCE_REASON';
  }
}

class Lead_LandlordInformation {
  name: string;
  email: string;
  phone: string;

  constructor(props: $Shape<Lead_LandlordInformation> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.phone = '';
    if (props.hasOwnProperty('phone')) {
      const v = props.phone;
      this.phone = v;
    }
  }
}
export {Lead_LandlordInformation};
class Lead_PlansSeen {
  plans: Array<cwn_customers.Subscription_Plan>;
  seen: ?Date;

  constructor(props: $Shape<Lead_PlansSeen> = {}): void {
    if (!props) {
      props = {};
    }

    this.plans = [];
    if (props.hasOwnProperty('plans')) {
      const v = props.plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field plans should be array');
      }
      this.plans = v.map(function(v) {
        return v;
      });
    }

    this.seen = null;
    if (props.hasOwnProperty('seen')) {
      const v = props.seen;
      this.seen = v && new Date(v);
    }
  }
}
export {Lead_PlansSeen};
class Lead {
  id: string;
  state: Lead_State;
  site_id: string;
  customer_id: string;
  address: ?cwn_customers.Address;
  geocoded_location: ?cwn_geocoder.GeoPoint;
  subscription_id: string;
  created: ?Date;
  raw_address: string;
  updated: ?Date;
  signup_source: string;
  offer_override: string;
  reason_for_rejection: Lead_ReasonForRejection;
  method_of_rejection: Lead_MethodOfStateChange;
  reason_for_acceptance: Lead_ReasonForAcceptance;
  method_of_acceptance: Lead_MethodOfStateChange;
  book_source: string;
  instantly_rejected: boolean;
  instantly_accepted: boolean;
  referral_code: string;
  promo_code: string;
  promo_code_snapshot: ?cwn_billing.PromoCode;
  landlord_information: ?Lead_LandlordInformation;
  helpful_information_for_your_landlord_email_sent: boolean;
  appointment_id: string;
  skip_appointment_booking: boolean;
  instrument_id: string;
  contact_info: ?cwn_signup.ContactInfo;
  tos: ?cwn_signup.TermsOfService;
  offer: ?cwn_signup.Offer;
  referral_token: string;
  seen_referral_step: boolean;
  sign_up_flow_referrals: Array<SignUpFlowReferral>;
  plan: cwn_customers.Subscription_Plan;
  eligible_plans_snapshot: Array<cwn_customers.Subscription_Plan>;
  cached_appointment_time: ?Date;
  eligible_plans_seen: Array<Lead_PlansSeen>;
  first_accepted: ?Date;

  constructor(props: $Shape<Lead> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.state = Lead_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.geocoded_location = null;
    if (props.hasOwnProperty('geocoded_location')) {
      const v = props.geocoded_location;
      this.geocoded_location = v && new cwn_geocoder.GeoPoint(v);
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.raw_address = '';
    if (props.hasOwnProperty('raw_address')) {
      const v = props.raw_address;
      this.raw_address = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.signup_source = '';
    if (props.hasOwnProperty('signup_source')) {
      const v = props.signup_source;
      this.signup_source = v;
    }

    this.offer_override = '';
    if (props.hasOwnProperty('offer_override')) {
      const v = props.offer_override;
      this.offer_override = v;
    }

    this.reason_for_rejection = Lead_ReasonForRejectionValue(0);
    if (props.hasOwnProperty('reason_for_rejection')) {
      const v = props.reason_for_rejection;
      this.reason_for_rejection = v;
    }

    this.method_of_rejection = Lead_MethodOfStateChangeValue(0);
    if (props.hasOwnProperty('method_of_rejection')) {
      const v = props.method_of_rejection;
      this.method_of_rejection = v;
    }

    this.reason_for_acceptance = Lead_ReasonForAcceptanceValue(0);
    if (props.hasOwnProperty('reason_for_acceptance')) {
      const v = props.reason_for_acceptance;
      this.reason_for_acceptance = v;
    }

    this.method_of_acceptance = Lead_MethodOfStateChangeValue(0);
    if (props.hasOwnProperty('method_of_acceptance')) {
      const v = props.method_of_acceptance;
      this.method_of_acceptance = v;
    }

    this.book_source = '';
    if (props.hasOwnProperty('book_source')) {
      const v = props.book_source;
      this.book_source = v;
    }

    this.instantly_rejected = false;
    if (props.hasOwnProperty('instantly_rejected')) {
      const v = props.instantly_rejected;
      this.instantly_rejected = !!v;
    }

    this.instantly_accepted = false;
    if (props.hasOwnProperty('instantly_accepted')) {
      const v = props.instantly_accepted;
      this.instantly_accepted = !!v;
    }

    this.referral_code = '';
    if (props.hasOwnProperty('referral_code')) {
      const v = props.referral_code;
      this.referral_code = v;
    }

    this.promo_code = '';
    if (props.hasOwnProperty('promo_code')) {
      const v = props.promo_code;
      this.promo_code = v;
    }

    this.promo_code_snapshot = null;
    if (props.hasOwnProperty('promo_code_snapshot')) {
      const v = props.promo_code_snapshot;
      this.promo_code_snapshot = v && new cwn_billing.PromoCode(v);
    }

    this.landlord_information = null;
    if (props.hasOwnProperty('landlord_information')) {
      const v = props.landlord_information;
      this.landlord_information = v && new Lead_LandlordInformation(v);
    }

    this.helpful_information_for_your_landlord_email_sent = false;
    if (
      props.hasOwnProperty('helpful_information_for_your_landlord_email_sent')
    ) {
      const v = props.helpful_information_for_your_landlord_email_sent;
      this.helpful_information_for_your_landlord_email_sent = !!v;
    }

    this.appointment_id = '0';
    if (props.hasOwnProperty('appointment_id')) {
      const v = props.appointment_id;
      this.appointment_id = v;
    }

    this.skip_appointment_booking = false;
    if (props.hasOwnProperty('skip_appointment_booking')) {
      const v = props.skip_appointment_booking;
      this.skip_appointment_booking = !!v;
    }

    this.instrument_id = '0';
    if (props.hasOwnProperty('instrument_id')) {
      const v = props.instrument_id;
      this.instrument_id = v;
    }

    this.contact_info = null;
    if (props.hasOwnProperty('contact_info')) {
      const v = props.contact_info;
      this.contact_info = v && new cwn_signup.ContactInfo(v);
    }

    this.tos = null;
    if (props.hasOwnProperty('tos')) {
      const v = props.tos;
      this.tos = v && new cwn_signup.TermsOfService(v);
    }

    this.offer = null;
    if (props.hasOwnProperty('offer')) {
      const v = props.offer;
      this.offer = v && new cwn_signup.Offer(v);
    }

    this.referral_token = '';
    if (props.hasOwnProperty('referral_token')) {
      const v = props.referral_token;
      this.referral_token = v;
    }

    this.seen_referral_step = false;
    if (props.hasOwnProperty('seen_referral_step')) {
      const v = props.seen_referral_step;
      this.seen_referral_step = !!v;
    }

    this.sign_up_flow_referrals = [];
    if (props.hasOwnProperty('sign_up_flow_referrals')) {
      const v = props.sign_up_flow_referrals;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field sign_up_flow_referrals should be array',
        );
      }
      this.sign_up_flow_referrals = v.map(function(v) {
        return new SignUpFlowReferral(v);
      });
    }

    this.plan = cwn_customers.Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }

    this.eligible_plans_snapshot = [];
    if (props.hasOwnProperty('eligible_plans_snapshot')) {
      const v = props.eligible_plans_snapshot;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field eligible_plans_snapshot should be array',
        );
      }
      this.eligible_plans_snapshot = v.map(function(v) {
        return v;
      });
    }

    this.cached_appointment_time = null;
    if (props.hasOwnProperty('cached_appointment_time')) {
      const v = props.cached_appointment_time;
      this.cached_appointment_time = v && new Date(v);
    }

    this.eligible_plans_seen = [];
    if (props.hasOwnProperty('eligible_plans_seen')) {
      const v = props.eligible_plans_seen;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eligible_plans_seen should be array');
      }
      this.eligible_plans_seen = v.map(function(v) {
        return new Lead_PlansSeen(v);
      });
    }

    this.first_accepted = null;
    if (props.hasOwnProperty('first_accepted')) {
      const v = props.first_accepted;
      this.first_accepted = v && new Date(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getGeocodedLocation(): cwn_geocoder.GeoPoint {
    if (this.geocoded_location) {
      return this.geocoded_location;
    }
    return new cwn_geocoder.GeoPoint();
  }

  getPromoCodeSnapshot(): cwn_billing.PromoCode {
    if (this.promo_code_snapshot) {
      return this.promo_code_snapshot;
    }
    return new cwn_billing.PromoCode();
  }

  getLandlordInformation(): Lead_LandlordInformation {
    if (this.landlord_information) {
      return this.landlord_information;
    }
    return new Lead_LandlordInformation();
  }

  getContactInfo(): cwn_signup.ContactInfo {
    if (this.contact_info) {
      return this.contact_info;
    }
    return new cwn_signup.ContactInfo();
  }

  getTos(): cwn_signup.TermsOfService {
    if (this.tos) {
      return this.tos;
    }
    return new cwn_signup.TermsOfService();
  }

  getOffer(): cwn_signup.Offer {
    if (this.offer) {
      return this.offer;
    }
    return new cwn_signup.Offer();
  }
}
export {Lead};

class SignUpFlowReferral {
  email: string;
  time: ?Date;

  constructor(props: $Shape<SignUpFlowReferral> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.time = null;
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v && new Date(v);
    }
  }
}
export {SignUpFlowReferral};

class FeedEntry {
  id: string;
  lead_id: string;
  prev: ?Lead;
  next: ?Lead;
  created: ?Date;

  constructor(props: $Shape<FeedEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.prev = null;
    if (props.hasOwnProperty('prev')) {
      const v = props.prev;
      this.prev = v && new Lead(v);
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new Lead(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }

  getPrev(): Lead {
    if (this.prev) {
      return this.prev;
    }
    return new Lead();
  }

  getNext(): Lead {
    if (this.next) {
      return this.next;
    }
    return new Lead();
  }
}
export {FeedEntry};

class LeadCustomerSite {
  lead: ?Lead;
  customer: ?cwn_customers.Customer;
  site: ?cwn_site.Site;

  constructor(props: $Shape<LeadCustomerSite> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead = null;
    if (props.hasOwnProperty('lead')) {
      const v = props.lead;
      this.lead = v && new Lead(v);
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }
  }

  getLead(): Lead {
    if (this.lead) {
      return this.lead;
    }
    return new Lead();
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }
}
export {LeadCustomerSite};

class LeadCustomerSubscription {
  lead: ?Lead;
  customer: ?cwn_customers.Customer;
  subscription: ?cwn_customers.Subscription;

  constructor(props: $Shape<LeadCustomerSubscription> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead = null;
    if (props.hasOwnProperty('lead')) {
      const v = props.lead;
      this.lead = v && new Lead(v);
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new cwn_customers.Subscription(v);
    }
  }

  getLead(): Lead {
    if (this.lead) {
      return this.lead;
    }
    return new Lead();
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }

  getSubscription(): cwn_customers.Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new cwn_customers.Subscription();
  }
}
export {LeadCustomerSubscription};

class UpdateStateParams {
  id: string;
  state: Lead_State;
  reason_for_rejection: Lead_ReasonForRejection;
  method_of_rejection: Lead_MethodOfStateChange;
  reason_for_acceptance: Lead_ReasonForAcceptance;
  method_of_acceptance: Lead_MethodOfStateChange;
  instantly_rejected: boolean;
  instantly_accepted: boolean;

  constructor(props: $Shape<UpdateStateParams> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.state = Lead_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.reason_for_rejection = Lead_ReasonForRejectionValue(0);
    if (props.hasOwnProperty('reason_for_rejection')) {
      const v = props.reason_for_rejection;
      this.reason_for_rejection = v;
    }

    this.method_of_rejection = Lead_MethodOfStateChangeValue(0);
    if (props.hasOwnProperty('method_of_rejection')) {
      const v = props.method_of_rejection;
      this.method_of_rejection = v;
    }

    this.reason_for_acceptance = Lead_ReasonForAcceptanceValue(0);
    if (props.hasOwnProperty('reason_for_acceptance')) {
      const v = props.reason_for_acceptance;
      this.reason_for_acceptance = v;
    }

    this.method_of_acceptance = Lead_MethodOfStateChangeValue(0);
    if (props.hasOwnProperty('method_of_acceptance')) {
      const v = props.method_of_acceptance;
      this.method_of_acceptance = v;
    }

    this.instantly_rejected = false;
    if (props.hasOwnProperty('instantly_rejected')) {
      const v = props.instantly_rejected;
      this.instantly_rejected = !!v;
    }

    this.instantly_accepted = false;
    if (props.hasOwnProperty('instantly_accepted')) {
      const v = props.instantly_accepted;
      this.instantly_accepted = !!v;
    }
  }
}
export {UpdateStateParams};

// END cwn/lead/models.proto
