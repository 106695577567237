// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/user/models.proto

class User {
  email: string;
  name: string;

  constructor(props: $Shape<User> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }
  }
}
export {User};

// END cwn/user/models.proto
