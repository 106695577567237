// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

import * as cwn_tech from '../../../cwn/tech';
import * as cwn_customers from '../../../cwn/customers';

// START cwn/tech/viewmodels/models.proto

class WorkOrderShotViewModel {
  local_mount: string;
  target_mount: string;
  target_address: ?cwn_customers.Address;

  constructor(props: $Shape<WorkOrderShotViewModel> = {}): void {
    if (!props) {
      props = {};
    }

    this.local_mount = '0';
    if (props.hasOwnProperty('local_mount')) {
      const v = props.local_mount;
      this.local_mount = v;
    }

    this.target_mount = '0';
    if (props.hasOwnProperty('target_mount')) {
      const v = props.target_mount;
      this.target_mount = v;
    }

    this.target_address = null;
    if (props.hasOwnProperty('target_address')) {
      const v = props.target_address;
      this.target_address = v && new cwn_customers.Address(v);
    }
  }

  getTargetAddress(): cwn_customers.Address {
    if (this.target_address) {
      return this.target_address;
    }
    return new cwn_customers.Address();
  }
}
export {WorkOrderShotViewModel};

class TaskViewModel {
  task: ?cwn_tech.Task;
  index: string;
  work_unit: ?cwn_tech.WorkUnit;

  constructor(props: $Shape<TaskViewModel> = {}): void {
    if (!props) {
      props = {};
    }

    this.task = null;
    if (props.hasOwnProperty('task')) {
      const v = props.task;
      this.task = v && new cwn_tech.Task(v);
    }

    this.index = '0';
    if (props.hasOwnProperty('index')) {
      const v = props.index;
      this.index = v;
    }

    this.work_unit = null;
    if (props.hasOwnProperty('work_unit')) {
      const v = props.work_unit;
      this.work_unit = v && new cwn_tech.WorkUnit(v);
    }
  }

  getTask(): cwn_tech.Task {
    if (this.task) {
      return this.task;
    }
    return new cwn_tech.Task();
  }

  getWorkUnit(): cwn_tech.WorkUnit {
    if (this.work_unit) {
      return this.work_unit;
    }
    return new cwn_tech.WorkUnit();
  }
}
export {TaskViewModel};

// END cwn/tech/viewmodels/models.proto
