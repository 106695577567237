// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_site from '../../cwn/site';
import * as cwn_customers from '../../cwn/customers';

// START cwn/signup/models.proto

export const StepValues = {
  ERROR: 0,
  NEXT_AVAILABLE_SLOT: 1,
  CALENDAR: 2,
  CREDIT_CARD: 3,
  BILLING: 4,
  SUBMITTING: 5,
  DONE: 6,
  UNAUTHORIZED: 7,
};
type Step = $Keys<typeof StepValues>;
export type {Step};

export function StepValue(n: number): Step {
  switch (n) {
    case 0:
      return 'ERROR';

    case 1:
      return 'NEXT_AVAILABLE_SLOT';

    case 2:
      return 'CALENDAR';

    case 3:
      return 'CREDIT_CARD';

    case 4:
      return 'BILLING';

    case 5:
      return 'SUBMITTING';

    case 6:
      return 'DONE';

    case 7:
      return 'UNAUTHORIZED';

    default:
      return 'ERROR';
  }
}

class Signup {
  id: string;
  created: ?Date;
  customer_id: string;
  subscription_id: string;
  contact_info: ?ContactInfo;
  tos: ?TermsOfService;
  stripe_card: ?StripeCard;
  skip_appointment_booking: boolean;
  offer: ?Offer;
  lead_id: string;
  instrument_id: string;
  deleted: boolean;

  constructor(props: $Shape<Signup> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.contact_info = null;
    if (props.hasOwnProperty('contact_info')) {
      const v = props.contact_info;
      this.contact_info = v && new ContactInfo(v);
    }

    this.tos = null;
    if (props.hasOwnProperty('tos')) {
      const v = props.tos;
      this.tos = v && new TermsOfService(v);
    }

    this.stripe_card = null;
    if (props.hasOwnProperty('stripe_card')) {
      const v = props.stripe_card;
      this.stripe_card = v && new StripeCard(v);
    }

    this.skip_appointment_booking = false;
    if (props.hasOwnProperty('skip_appointment_booking')) {
      const v = props.skip_appointment_booking;
      this.skip_appointment_booking = !!v;
    }

    this.offer = null;
    if (props.hasOwnProperty('offer')) {
      const v = props.offer;
      this.offer = v && new Offer(v);
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.instrument_id = '0';
    if (props.hasOwnProperty('instrument_id')) {
      const v = props.instrument_id;
      this.instrument_id = v;
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }
  }

  getContactInfo(): ContactInfo {
    if (this.contact_info) {
      return this.contact_info;
    }
    return new ContactInfo();
  }

  getTos(): TermsOfService {
    if (this.tos) {
      return this.tos;
    }
    return new TermsOfService();
  }

  getStripeCard(): StripeCard {
    if (this.stripe_card) {
      return this.stripe_card;
    }
    return new StripeCard();
  }

  getOffer(): Offer {
    if (this.offer) {
      return this.offer;
    }
    return new Offer();
  }
}
export {Signup};

class ContactInfo {
  phone_number: string;
  address: ?cwn_customers.Address;
  email: string;
  name: string;

  constructor(props: $Shape<ContactInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.phone_number = '';
    if (props.hasOwnProperty('phone_number')) {
      const v = props.phone_number;
      this.phone_number = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {ContactInfo};

class StripeCard {
  id: string;
  name: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  customer_id: string;

  constructor(props: $Shape<StripeCard> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.brand = '';
    if (props.hasOwnProperty('brand')) {
      const v = props.brand;
      this.brand = v;
    }

    this.last4 = '';
    if (props.hasOwnProperty('last4')) {
      const v = props.last4;
      this.last4 = v;
    }

    this.exp_month = 0;
    if (props.hasOwnProperty('exp_month')) {
      const v = props.exp_month;
      this.exp_month = v;
    }

    this.exp_year = 0;
    if (props.hasOwnProperty('exp_year')) {
      const v = props.exp_year;
      this.exp_year = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }
  }
}
export {StripeCard};

class TermsOfService {
  agreed: boolean;
  copy: string;

  constructor(props: $Shape<TermsOfService> = {}): void {
    if (!props) {
      props = {};
    }

    this.agreed = false;
    if (props.hasOwnProperty('agreed')) {
      const v = props.agreed;
      this.agreed = !!v;
    }

    this.copy = '';
    if (props.hasOwnProperty('copy')) {
      const v = props.copy;
      this.copy = v;
    }
  }
}
export {TermsOfService};

class Offer {
  signup_source: string;
  created: ?Date;
  coupon_name: string;
  months_free: number;

  constructor(props: $Shape<Offer> = {}): void {
    if (!props) {
      props = {};
    }

    this.signup_source = '';
    if (props.hasOwnProperty('signup_source')) {
      const v = props.signup_source;
      this.signup_source = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.coupon_name = '';
    if (props.hasOwnProperty('coupon_name')) {
      const v = props.coupon_name;
      this.coupon_name = v;
    }

    this.months_free = 0;
    if (props.hasOwnProperty('months_free')) {
      const v = props.months_free;
      this.months_free = v;
    }
  }
}
export {Offer};

class FeedEntry {
  id: string;
  signup_id: string;
  signup: ?Signup;
  created: ?Date;

  constructor(props: $Shape<FeedEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.signup_id = '0';
    if (props.hasOwnProperty('signup_id')) {
      const v = props.signup_id;
      this.signup_id = v;
    }

    this.signup = null;
    if (props.hasOwnProperty('signup')) {
      const v = props.signup;
      this.signup = v && new Signup(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }

  getSignup(): Signup {
    if (this.signup) {
      return this.signup;
    }
    return new Signup();
  }
}
export {FeedEntry};

class SignupV2 {
  id: string;
  address: ?cwn_customers.Address;
  subpremise: string;
  building_type: cwn_site.Site_BuildingType;
  number_of_units: cwn_site.Site_NumberOfUnits;
  name: string;
  email: string;
  phone: string;
  created: ?Date;
  lead_id: string;
  address_check_id: string;
  updated: ?Date;

  constructor(props: $Shape<SignupV2> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.subpremise = '';
    if (props.hasOwnProperty('subpremise')) {
      const v = props.subpremise;
      this.subpremise = v;
    }

    this.building_type = cwn_site.Site_BuildingTypeValue(0);
    if (props.hasOwnProperty('building_type')) {
      const v = props.building_type;
      this.building_type = v;
    }

    this.number_of_units = cwn_site.Site_NumberOfUnitsValue(0);
    if (props.hasOwnProperty('number_of_units')) {
      const v = props.number_of_units;
      this.number_of_units = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.phone = '';
    if (props.hasOwnProperty('phone')) {
      const v = props.phone;
      this.phone = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.address_check_id = '0';
    if (props.hasOwnProperty('address_check_id')) {
      const v = props.address_check_id;
      this.address_check_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {SignupV2};

// END cwn/signup/models.proto
