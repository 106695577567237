// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_user from '../../cwn/user';
import * as cwn_site_build from '../../cwn/site/build';
import * as cwn_devices_detect from '../../cwn/devices/detect';
import * as cwn_acuity from '../../cwn/acuity';

// START cwn/tech/models.proto

export const TaskKindValues = {
  UNKNOWN: 0,
  OTHER: 1,
  OTHER_PHOTOS: 2,
  CX_WALKTHROUGH: 3,
  PRE_CHECKLIST_PHOTO: 4,
  COLLECT_BILLING: 5,
  SITE_INSPECTION_PHOTOS: 6,
  RADIO_MAC_ADDRESS: 7,
  TEST_TX_POWER: 8,
  BUILDING_INFO: 9,
  ASSESS_LOS: 10,
  ASSIGN_DEVICE: 11,
  SAFETY: 12,
  VERIFY_MOUNT_LOCATION: 13,
  POINT_RADIO: 14,
  FULL_MOUNT_PHOTOS: 15,
  EXTERIOR_CABLE_RUN: 16,
  CONFIGURE_DEVICE: 17,
  ADD_AP: 18,
  FINAL_CHECKS: 19,
  FINAL_CX_WALKTHROUGH: 20,
  POST_CHECKLIST_PHOTO: 21,
  UPDATE_BUILDING_NOTES: 22,
  PRE_TASK_PHOTOS: 23,
  INSTALL_TASK_PHOTOS: 24,
  POST_TASK_PHOTOS: 25,
  ADD_RADIO: 26,
  RADIO_ADD_PHOTOS: 27,
  DEVICE_SWAP_PHOTOS: 28,
  BRIDGE_CONFIG: 29,
  HEALTH_CHECK: 30,
  REMOVE_RADIO: 31,
};
type TaskKind = $Keys<typeof TaskKindValues>;
export type {TaskKind};

export function TaskKindValue(n: number): TaskKind {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'OTHER';

    case 2:
      return 'OTHER_PHOTOS';

    case 3:
      return 'CX_WALKTHROUGH';

    case 4:
      return 'PRE_CHECKLIST_PHOTO';

    case 5:
      return 'COLLECT_BILLING';

    case 6:
      return 'SITE_INSPECTION_PHOTOS';

    case 7:
      return 'RADIO_MAC_ADDRESS';

    case 8:
      return 'TEST_TX_POWER';

    case 9:
      return 'BUILDING_INFO';

    case 10:
      return 'ASSESS_LOS';

    case 11:
      return 'ASSIGN_DEVICE';

    case 12:
      return 'SAFETY';

    case 13:
      return 'VERIFY_MOUNT_LOCATION';

    case 14:
      return 'POINT_RADIO';

    case 15:
      return 'FULL_MOUNT_PHOTOS';

    case 16:
      return 'EXTERIOR_CABLE_RUN';

    case 17:
      return 'CONFIGURE_DEVICE';

    case 18:
      return 'ADD_AP';

    case 19:
      return 'FINAL_CHECKS';

    case 20:
      return 'FINAL_CX_WALKTHROUGH';

    case 21:
      return 'POST_CHECKLIST_PHOTO';

    case 22:
      return 'UPDATE_BUILDING_NOTES';

    case 23:
      return 'PRE_TASK_PHOTOS';

    case 24:
      return 'INSTALL_TASK_PHOTOS';

    case 25:
      return 'POST_TASK_PHOTOS';

    case 26:
      return 'ADD_RADIO';

    case 27:
      return 'RADIO_ADD_PHOTOS';

    case 28:
      return 'DEVICE_SWAP_PHOTOS';

    case 29:
      return 'BRIDGE_CONFIG';

    case 30:
      return 'HEALTH_CHECK';

    case 31:
      return 'REMOVE_RADIO';

    default:
      return 'UNKNOWN';
  }
}

export const InstallBuildPlan_TypeValues = {
  INVALID_TYPE: 0,
  SFH_INSTALL: 1,
  MDU_INSTALL: 2,
};
type InstallBuildPlan_Type = $Keys<typeof InstallBuildPlan_TypeValues>;
export type {InstallBuildPlan_Type};

export function InstallBuildPlan_TypeValue(n: number): InstallBuildPlan_Type {
  switch (n) {
    case 0:
      return 'INVALID_TYPE';

    case 1:
      return 'SFH_INSTALL';

    case 2:
      return 'MDU_INSTALL';

    default:
      return 'INVALID_TYPE';
  }
}

class InstallBuildPlan_ShotSelection {
  radio_add_work_order_id: string;
  radio_add_flexible_work_order_id: string;
  candidate_network_plan_id: string;
  target_site_id: string;
  target_mount_id: string;
  install_site_radio_link_id: string;
  target_site_radio_link_id: string;
  device_swap_flexible_work_order_id: string;

  constructor(props: $Shape<InstallBuildPlan_ShotSelection> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_add_work_order_id = '0';
    if (props.hasOwnProperty('radio_add_work_order_id')) {
      const v = props.radio_add_work_order_id;
      this.radio_add_work_order_id = v;
    }

    this.radio_add_flexible_work_order_id = '0';
    if (props.hasOwnProperty('radio_add_flexible_work_order_id')) {
      const v = props.radio_add_flexible_work_order_id;
      this.radio_add_flexible_work_order_id = v;
    }

    this.candidate_network_plan_id = '0';
    if (props.hasOwnProperty('candidate_network_plan_id')) {
      const v = props.candidate_network_plan_id;
      this.candidate_network_plan_id = v;
    }

    this.target_site_id = '';
    if (props.hasOwnProperty('target_site_id')) {
      const v = props.target_site_id;
      this.target_site_id = v;
    }

    this.target_mount_id = '0';
    if (props.hasOwnProperty('target_mount_id')) {
      const v = props.target_mount_id;
      this.target_mount_id = v;
    }

    this.install_site_radio_link_id = '0';
    if (props.hasOwnProperty('install_site_radio_link_id')) {
      const v = props.install_site_radio_link_id;
      this.install_site_radio_link_id = v;
    }

    this.target_site_radio_link_id = '0';
    if (props.hasOwnProperty('target_site_radio_link_id')) {
      const v = props.target_site_radio_link_id;
      this.target_site_radio_link_id = v;
    }

    this.device_swap_flexible_work_order_id = '0';
    if (props.hasOwnProperty('device_swap_flexible_work_order_id')) {
      const v = props.device_swap_flexible_work_order_id;
      this.device_swap_flexible_work_order_id = v;
    }
  }
}
export {InstallBuildPlan_ShotSelection};
class InstallBuildPlan {
  id: string;
  created: ?Date;
  updated: ?Date;
  finished: ?Date;
  created_by: ?cwn_user.User;
  updated_by: ?cwn_user.User;
  site_id: string;
  type: InstallBuildPlan_Type;
  install_work_order_id: string;
  install_flexible_work_order_id: string;
  shot_selections: Array<InstallBuildPlan_ShotSelection>;
  shot_selections_of_canceled_or_failed_radio_adds: Array<
    InstallBuildPlan_ShotSelection,
  >;

  constructor(props: $Shape<InstallBuildPlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.finished = null;
    if (props.hasOwnProperty('finished')) {
      const v = props.finished;
      this.finished = v && new Date(v);
    }

    this.created_by = null;
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v && new cwn_user.User(v);
    }

    this.updated_by = null;
    if (props.hasOwnProperty('updated_by')) {
      const v = props.updated_by;
      this.updated_by = v && new cwn_user.User(v);
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.type = InstallBuildPlan_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.install_work_order_id = '0';
    if (props.hasOwnProperty('install_work_order_id')) {
      const v = props.install_work_order_id;
      this.install_work_order_id = v;
    }

    this.install_flexible_work_order_id = '0';
    if (props.hasOwnProperty('install_flexible_work_order_id')) {
      const v = props.install_flexible_work_order_id;
      this.install_flexible_work_order_id = v;
    }

    this.shot_selections = [];
    if (props.hasOwnProperty('shot_selections')) {
      const v = props.shot_selections;
      if (!Array.isArray(v)) {
        throw new Error('repeated field shot_selections should be array');
      }
      this.shot_selections = v.map(function(v) {
        return new InstallBuildPlan_ShotSelection(v);
      });
    }

    this.shot_selections_of_canceled_or_failed_radio_adds = [];
    if (
      props.hasOwnProperty('shot_selections_of_canceled_or_failed_radio_adds')
    ) {
      const v = props.shot_selections_of_canceled_or_failed_radio_adds;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field shot_selections_of_canceled_or_failed_radio_adds should be array',
        );
      }
      this.shot_selections_of_canceled_or_failed_radio_adds = v.map(function(
        v,
      ) {
        return new InstallBuildPlan_ShotSelection(v);
      });
    }
  }

  getCreatedBy(): cwn_user.User {
    if (this.created_by) {
      return this.created_by;
    }
    return new cwn_user.User();
  }

  getUpdatedBy(): cwn_user.User {
    if (this.updated_by) {
      return this.updated_by;
    }
    return new cwn_user.User();
  }
}
export {InstallBuildPlan};

class InstallBuildPlanUpdate {
  id: string;
  previous: ?InstallBuildPlan;
  next: ?InstallBuildPlan;
  updated: ?Date;

  constructor(props: $Shape<InstallBuildPlanUpdate> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.previous = null;
    if (props.hasOwnProperty('previous')) {
      const v = props.previous;
      this.previous = v && new InstallBuildPlan(v);
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new InstallBuildPlan(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }

  getPrevious(): InstallBuildPlan {
    if (this.previous) {
      return this.previous;
    }
    return new InstallBuildPlan();
  }

  getNext(): InstallBuildPlan {
    if (this.next) {
      return this.next;
    }
    return new InstallBuildPlan();
  }
}
export {InstallBuildPlanUpdate};

class RadioToAdd {
  mount_id: string;
  radio: ?cwn_site_build.LocalRadio;
  radio_mac_address: string;

  constructor(props: $Shape<RadioToAdd> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.radio = null;
    if (props.hasOwnProperty('radio')) {
      const v = props.radio;
      this.radio = v && new cwn_site_build.LocalRadio(v);
    }

    this.radio_mac_address = '';
    if (props.hasOwnProperty('radio_mac_address')) {
      const v = props.radio_mac_address;
      this.radio_mac_address = v;
    }
  }

  getRadio(): cwn_site_build.LocalRadio {
    if (this.radio) {
      return this.radio;
    }
    return new cwn_site_build.LocalRadio();
  }
}
export {RadioToAdd};

class RadioToRepoint {
  radio_srl_id: string;
  heading: string;

  constructor(props: $Shape<RadioToRepoint> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_srl_id = '0';
    if (props.hasOwnProperty('radio_srl_id')) {
      const v = props.radio_srl_id;
      this.radio_srl_id = v;
    }

    this.heading = '0';
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }
  }
}
export {RadioToRepoint};

class RadioToRemove {
  radio_srl_id: string;

  constructor(props: $Shape<RadioToRemove> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_srl_id = '0';
    if (props.hasOwnProperty('radio_srl_id')) {
      const v = props.radio_srl_id;
      this.radio_srl_id = v;
    }
  }
}
export {RadioToRemove};

class MountToMove {
  mount_id: string;
  lat: number;
  lng: number;
  elevation: number;

  constructor(props: $Shape<MountToMove> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }

    this.elevation = 0;
    if (props.hasOwnProperty('elevation')) {
      const v = props.elevation;
      this.elevation = v;
    }
  }
}
export {MountToMove};

class MountWithRadiosToAdd {
  site_id: string;
  lat: number;
  lng: number;
  elevation: number;
  height: number;
  radios: Array<RadioToAdd>;
  mount_id: string;

  constructor(props: $Shape<MountWithRadiosToAdd> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }

    this.elevation = 0;
    if (props.hasOwnProperty('elevation')) {
      const v = props.elevation;
      this.elevation = v;
    }

    this.height = 0;
    if (props.hasOwnProperty('height')) {
      const v = props.height;
      this.height = v;
    }

    this.radios = [];
    if (props.hasOwnProperty('radios')) {
      const v = props.radios;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radios should be array');
      }
      this.radios = v.map(function(v) {
        return new RadioToAdd(v);
      });
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }
  }
}
export {MountWithRadiosToAdd};

class MountWithoutRadiosToAdd {
  site_id: string;
  lat: number;
  lng: number;
  elevation: number;
  height: number;
  mount_id: string;

  constructor(props: $Shape<MountWithoutRadiosToAdd> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }

    this.elevation = 0;
    if (props.hasOwnProperty('elevation')) {
      const v = props.elevation;
      this.elevation = v;
    }

    this.height = 0;
    if (props.hasOwnProperty('height')) {
      const v = props.height;
      this.height = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }
  }
}
export {MountWithoutRadiosToAdd};

class DeviceToAdd {
  platform: cwn_devices_detect.Platform;

  constructor(props: $Shape<DeviceToAdd> = {}): void {
    if (!props) {
      props = {};
    }

    this.platform = cwn_devices_detect.PlatformValue(0);
    if (props.hasOwnProperty('platform')) {
      const v = props.platform;
      this.platform = v;
    }
  }
}
export {DeviceToAdd};

class WorkUnit_RequiredWork {
  mount_to_move: ?MountToMove;
  mount_with_radios_to_add: ?MountWithRadiosToAdd;
  mount_without_radios_to_add: ?MountWithoutRadiosToAdd;
  radio_to_add: ?RadioToAdd;
  radio_to_repoint: ?RadioToRepoint;
  radio_to_remove: ?RadioToRemove;
  device_to_add: ?DeviceToAdd;

  constructor(props: $Shape<WorkUnit_RequiredWork> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_to_move = null;
    if (props.hasOwnProperty('mount_to_move')) {
      const v = props.mount_to_move;
      this.mount_to_move = v && new MountToMove(v);
    }

    this.mount_with_radios_to_add = null;
    if (props.hasOwnProperty('mount_with_radios_to_add')) {
      const v = props.mount_with_radios_to_add;
      this.mount_with_radios_to_add = v && new MountWithRadiosToAdd(v);
    }

    this.mount_without_radios_to_add = null;
    if (props.hasOwnProperty('mount_without_radios_to_add')) {
      const v = props.mount_without_radios_to_add;
      this.mount_without_radios_to_add = v && new MountWithoutRadiosToAdd(v);
    }

    this.radio_to_add = null;
    if (props.hasOwnProperty('radio_to_add')) {
      const v = props.radio_to_add;
      this.radio_to_add = v && new RadioToAdd(v);
    }

    this.radio_to_repoint = null;
    if (props.hasOwnProperty('radio_to_repoint')) {
      const v = props.radio_to_repoint;
      this.radio_to_repoint = v && new RadioToRepoint(v);
    }

    this.radio_to_remove = null;
    if (props.hasOwnProperty('radio_to_remove')) {
      const v = props.radio_to_remove;
      this.radio_to_remove = v && new RadioToRemove(v);
    }

    this.device_to_add = null;
    if (props.hasOwnProperty('device_to_add')) {
      const v = props.device_to_add;
      this.device_to_add = v && new DeviceToAdd(v);
    }
  }

  getMountToMove(): MountToMove {
    if (this.mount_to_move) {
      return this.mount_to_move;
    }
    return new MountToMove();
  }

  getMountWithRadiosToAdd(): MountWithRadiosToAdd {
    if (this.mount_with_radios_to_add) {
      return this.mount_with_radios_to_add;
    }
    return new MountWithRadiosToAdd();
  }

  getMountWithoutRadiosToAdd(): MountWithoutRadiosToAdd {
    if (this.mount_without_radios_to_add) {
      return this.mount_without_radios_to_add;
    }
    return new MountWithoutRadiosToAdd();
  }

  getRadioToAdd(): RadioToAdd {
    if (this.radio_to_add) {
      return this.radio_to_add;
    }
    return new RadioToAdd();
  }

  getRadioToRepoint(): RadioToRepoint {
    if (this.radio_to_repoint) {
      return this.radio_to_repoint;
    }
    return new RadioToRepoint();
  }

  getRadioToRemove(): RadioToRemove {
    if (this.radio_to_remove) {
      return this.radio_to_remove;
    }
    return new RadioToRemove();
  }

  getDeviceToAdd(): DeviceToAdd {
    if (this.device_to_add) {
      return this.device_to_add;
    }
    return new DeviceToAdd();
  }
}
export {WorkUnit_RequiredWork};
class WorkUnit {
  required_work: ?WorkUnit_RequiredWork;
  tasks: Array<Task>;
  completed_by: ?cwn_user.User;
  completed: ?Date;

  constructor(props: $Shape<WorkUnit> = {}): void {
    if (!props) {
      props = {};
    }

    this.required_work = null;
    if (props.hasOwnProperty('required_work')) {
      const v = props.required_work;
      this.required_work = v && new WorkUnit_RequiredWork(v);
    }

    this.tasks = [];
    if (props.hasOwnProperty('tasks')) {
      const v = props.tasks;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tasks should be array');
      }
      this.tasks = v.map(function(v) {
        return new Task(v);
      });
    }

    this.completed_by = null;
    if (props.hasOwnProperty('completed_by')) {
      const v = props.completed_by;
      this.completed_by = v && new cwn_user.User(v);
    }

    this.completed = null;
    if (props.hasOwnProperty('completed')) {
      const v = props.completed;
      this.completed = v && new Date(v);
    }
  }

  getRequiredWork(): WorkUnit_RequiredWork {
    if (this.required_work) {
      return this.required_work;
    }
    return new WorkUnit_RequiredWork();
  }

  getCompletedBy(): cwn_user.User {
    if (this.completed_by) {
      return this.completed_by;
    }
    return new cwn_user.User();
  }
}
export {WorkUnit};

export const FlexibleWorkOrder_WorkOrderTypeValues = {
  UNKNOWN_TYPE: 0,
  OTHER_TYPE: 1,
  SFH_INSTALL: 2,
  MDU_INSTALL: 3,
  MDU_ADD: 4,
  INDOOR_SERVICE_CALL: 5,
  OUTDOOR_SERVICE_CALL: 6,
  RADIO_ADD: 7,
  SITE_SURVEY: 8,
  RELAY: 9,
  DEVICE_SWAP: 10,
};
type FlexibleWorkOrder_WorkOrderType = $Keys<
  typeof FlexibleWorkOrder_WorkOrderTypeValues,
>;
export type {FlexibleWorkOrder_WorkOrderType};

export function FlexibleWorkOrder_WorkOrderTypeValue(
  n: number,
): FlexibleWorkOrder_WorkOrderType {
  switch (n) {
    case 0:
      return 'UNKNOWN_TYPE';

    case 1:
      return 'OTHER_TYPE';

    case 2:
      return 'SFH_INSTALL';

    case 3:
      return 'MDU_INSTALL';

    case 4:
      return 'MDU_ADD';

    case 5:
      return 'INDOOR_SERVICE_CALL';

    case 6:
      return 'OUTDOOR_SERVICE_CALL';

    case 7:
      return 'RADIO_ADD';

    case 8:
      return 'SITE_SURVEY';

    case 9:
      return 'RELAY';

    case 10:
      return 'DEVICE_SWAP';

    default:
      return 'UNKNOWN_TYPE';
  }
}

export const FlexibleWorkOrder_StateValues = {
  UNKNOWN_STATE: 0,
  OPENED: 1,
  STARTED: 2,
  CANCELLED: 3,
  FINISHED: 4,
  FAILED: 5,
};
type FlexibleWorkOrder_State = $Keys<typeof FlexibleWorkOrder_StateValues>;
export type {FlexibleWorkOrder_State};

export function FlexibleWorkOrder_StateValue(
  n: number,
): FlexibleWorkOrder_State {
  switch (n) {
    case 0:
      return 'UNKNOWN_STATE';

    case 1:
      return 'OPENED';

    case 2:
      return 'STARTED';

    case 3:
      return 'CANCELLED';

    case 4:
      return 'FINISHED';

    case 5:
      return 'FAILED';

    default:
      return 'UNKNOWN_STATE';
  }
}

export const FlexibleWorkOrder_ClosedReasonValues = {
  INVALID_CLOSED_REASON: 0,
  UNSERVICEABLE_NO_LOS: 1,
  LL_OWNER_PERMISSION_DENIED: 2,
  LL_OWNER_PERMISSION_REQUIRED_IN_PROGRESS: 3,
  AESTHETICS: 4,
  BUILDING_RESTRICTION_TOO_COMPLEX: 5,
  BUILDING_RESTRICTION_ROOF_UNSAFE: 6,
  NO_TECHS_AVAILABLE: 7,
  CUSTOMER_NO_SHOW: 8,
  RAN_OUT_OF_TIME: 9,
  INVENTORY_ISSUE: 10,
  MISSED_INSTALL_WINDOW: 11,
  HIGH_TX: 12,
  HST_UNAVAILABLE: 13,
  CX_REBOOK_LATER: 14,
  RESCHEDULED: 15,
  BOOKED_BY_ACCIDENT: 16,
  STILL_UNDER_CONTRACT: 17,
  OTHER_CLOSED_REASON: 18,
  SECOND_TECH_NEEDED: 19,
  RELATED_WORK_ORDER_CLOSED: 20,
  DEVICE_UNREACHABLE: 21,
  AP_MALFUNCTIONING: 22,
  BAD_CRIMP: 23,
  CX_WANTS_EQUIPMENT_INSIDE: 24,
  COAX_ADAPTER_DISCONNECTED_AT_SWITCH: 25,
  COAX_ADAPTER_DISCONNECTED_AT_CX: 26,
  POOR_CABLE_RUN: 27,
  RADIOS_MISALIGNED: 28,
  MESSY_NEMA_BOX: 29,
  CX_TAMPERED_WITH_INDOOR_EQUIPMENT: 30,
  NO_ISSUE: 31,
  MOVE_CHANGE_MOUNT: 32,
  CX_TAMPERED_WITH_OUTDOOR_EQUIPMENT: 33,
  DEAD_SWITCH_OR_ADAPTOR: 34,
  UNABLE_TO_ACCESS_EQUIPMENT: 35,
};
type FlexibleWorkOrder_ClosedReason = $Keys<
  typeof FlexibleWorkOrder_ClosedReasonValues,
>;
export type {FlexibleWorkOrder_ClosedReason};

export function FlexibleWorkOrder_ClosedReasonValue(
  n: number,
): FlexibleWorkOrder_ClosedReason {
  switch (n) {
    case 0:
      return 'INVALID_CLOSED_REASON';

    case 1:
      return 'UNSERVICEABLE_NO_LOS';

    case 2:
      return 'LL_OWNER_PERMISSION_DENIED';

    case 3:
      return 'LL_OWNER_PERMISSION_REQUIRED_IN_PROGRESS';

    case 4:
      return 'AESTHETICS';

    case 5:
      return 'BUILDING_RESTRICTION_TOO_COMPLEX';

    case 6:
      return 'BUILDING_RESTRICTION_ROOF_UNSAFE';

    case 7:
      return 'NO_TECHS_AVAILABLE';

    case 8:
      return 'CUSTOMER_NO_SHOW';

    case 9:
      return 'RAN_OUT_OF_TIME';

    case 10:
      return 'INVENTORY_ISSUE';

    case 11:
      return 'MISSED_INSTALL_WINDOW';

    case 12:
      return 'HIGH_TX';

    case 13:
      return 'HST_UNAVAILABLE';

    case 14:
      return 'CX_REBOOK_LATER';

    case 15:
      return 'RESCHEDULED';

    case 16:
      return 'BOOKED_BY_ACCIDENT';

    case 17:
      return 'STILL_UNDER_CONTRACT';

    case 18:
      return 'OTHER_CLOSED_REASON';

    case 19:
      return 'SECOND_TECH_NEEDED';

    case 20:
      return 'RELATED_WORK_ORDER_CLOSED';

    case 21:
      return 'DEVICE_UNREACHABLE';

    case 22:
      return 'AP_MALFUNCTIONING';

    case 23:
      return 'BAD_CRIMP';

    case 24:
      return 'CX_WANTS_EQUIPMENT_INSIDE';

    case 25:
      return 'COAX_ADAPTER_DISCONNECTED_AT_SWITCH';

    case 26:
      return 'COAX_ADAPTER_DISCONNECTED_AT_CX';

    case 27:
      return 'POOR_CABLE_RUN';

    case 28:
      return 'RADIOS_MISALIGNED';

    case 29:
      return 'MESSY_NEMA_BOX';

    case 30:
      return 'CX_TAMPERED_WITH_INDOOR_EQUIPMENT';

    case 31:
      return 'NO_ISSUE';

    case 32:
      return 'MOVE_CHANGE_MOUNT';

    case 33:
      return 'CX_TAMPERED_WITH_OUTDOOR_EQUIPMENT';

    case 34:
      return 'DEAD_SWITCH_OR_ADAPTOR';

    case 35:
      return 'UNABLE_TO_ACCESS_EQUIPMENT';

    default:
      return 'INVALID_CLOSED_REASON';
  }
}

class FlexibleWorkOrder_NetworkPlan {
  candidate_network_plan_id: string;
  mount_ids: Array<string>;
  site_radio_link_ids: Array<string>;

  constructor(props: $Shape<FlexibleWorkOrder_NetworkPlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.candidate_network_plan_id = '0';
    if (props.hasOwnProperty('candidate_network_plan_id')) {
      const v = props.candidate_network_plan_id;
      this.candidate_network_plan_id = v;
    }

    this.mount_ids = [];
    if (props.hasOwnProperty('mount_ids')) {
      const v = props.mount_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mount_ids should be array');
      }
      this.mount_ids = v.map(function(v) {
        return v;
      });
    }

    this.site_radio_link_ids = [];
    if (props.hasOwnProperty('site_radio_link_ids')) {
      const v = props.site_radio_link_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_link_ids should be array');
      }
      this.site_radio_link_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {FlexibleWorkOrder_NetworkPlan};
class FlexibleWorkOrder {
  id: string;
  site_id: string;
  zen_ticket_id: string;
  type: FlexibleWorkOrder_WorkOrderType;
  other_type: string;
  state: FlexibleWorkOrder_State;
  tasks: Array<Task>;
  created: ?Date;
  started: ?Date;
  updated: ?Date;
  created_by: ?cwn_user.User;
  started_by: ?cwn_user.User;
  updated_by: ?cwn_user.User;
  assigned_to: ?cwn_user.User;
  lead_id: string;
  appointment: ?cwn_acuity.Appointment;
  network_plan: ?FlexibleWorkOrder_NetworkPlan;
  description: string;
  appointment_time: ?Date;
  tags: Array<string>;
  closed_reasons: Array<FlexibleWorkOrder_ClosedReason>;
  closed_reason_other: string;
  closed: ?Date;
  closed_by: ?cwn_user.User;
  site_radio_links: Array<string>;
  build_plan_id: string;
  title: string;
  closing_notes: string;
  work_units: Array<WorkUnit>;
  building_info_task: ?Task;
  health_check_task: ?Task;
  other_photos_task: ?Task;

  constructor(props: $Shape<FlexibleWorkOrder> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.zen_ticket_id = '0';
    if (props.hasOwnProperty('zen_ticket_id')) {
      const v = props.zen_ticket_id;
      this.zen_ticket_id = v;
    }

    this.type = FlexibleWorkOrder_WorkOrderTypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.other_type = '';
    if (props.hasOwnProperty('other_type')) {
      const v = props.other_type;
      this.other_type = v;
    }

    this.state = FlexibleWorkOrder_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.tasks = [];
    if (props.hasOwnProperty('tasks')) {
      const v = props.tasks;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tasks should be array');
      }
      this.tasks = v.map(function(v) {
        return new Task(v);
      });
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.started = null;
    if (props.hasOwnProperty('started')) {
      const v = props.started;
      this.started = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.created_by = null;
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v && new cwn_user.User(v);
    }

    this.started_by = null;
    if (props.hasOwnProperty('started_by')) {
      const v = props.started_by;
      this.started_by = v && new cwn_user.User(v);
    }

    this.updated_by = null;
    if (props.hasOwnProperty('updated_by')) {
      const v = props.updated_by;
      this.updated_by = v && new cwn_user.User(v);
    }

    this.assigned_to = null;
    if (props.hasOwnProperty('assigned_to')) {
      const v = props.assigned_to;
      this.assigned_to = v && new cwn_user.User(v);
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.appointment = null;
    if (props.hasOwnProperty('appointment')) {
      const v = props.appointment;
      this.appointment = v && new cwn_acuity.Appointment(v);
    }

    this.network_plan = null;
    if (props.hasOwnProperty('network_plan')) {
      const v = props.network_plan;
      this.network_plan = v && new FlexibleWorkOrder_NetworkPlan(v);
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.appointment_time = null;
    if (props.hasOwnProperty('appointment_time')) {
      const v = props.appointment_time;
      this.appointment_time = v && new Date(v);
    }

    this.tags = [];
    if (props.hasOwnProperty('tags')) {
      const v = props.tags;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tags should be array');
      }
      this.tags = v.map(function(v) {
        return v;
      });
    }

    this.closed_reasons = [];
    if (props.hasOwnProperty('closed_reasons')) {
      const v = props.closed_reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field closed_reasons should be array');
      }
      this.closed_reasons = v.map(function(v) {
        return v;
      });
    }

    this.closed_reason_other = '';
    if (props.hasOwnProperty('closed_reason_other')) {
      const v = props.closed_reason_other;
      this.closed_reason_other = v;
    }

    this.closed = null;
    if (props.hasOwnProperty('closed')) {
      const v = props.closed;
      this.closed = v && new Date(v);
    }

    this.closed_by = null;
    if (props.hasOwnProperty('closed_by')) {
      const v = props.closed_by;
      this.closed_by = v && new cwn_user.User(v);
    }

    this.site_radio_links = [];
    if (props.hasOwnProperty('site_radio_links')) {
      const v = props.site_radio_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_links should be array');
      }
      this.site_radio_links = v.map(function(v) {
        return v;
      });
    }

    this.build_plan_id = '0';
    if (props.hasOwnProperty('build_plan_id')) {
      const v = props.build_plan_id;
      this.build_plan_id = v;
    }

    this.title = '';
    if (props.hasOwnProperty('title')) {
      const v = props.title;
      this.title = v;
    }

    this.closing_notes = '';
    if (props.hasOwnProperty('closing_notes')) {
      const v = props.closing_notes;
      this.closing_notes = v;
    }

    this.work_units = [];
    if (props.hasOwnProperty('work_units')) {
      const v = props.work_units;
      if (!Array.isArray(v)) {
        throw new Error('repeated field work_units should be array');
      }
      this.work_units = v.map(function(v) {
        return new WorkUnit(v);
      });
    }

    this.building_info_task = null;
    if (props.hasOwnProperty('building_info_task')) {
      const v = props.building_info_task;
      this.building_info_task = v && new Task(v);
    }

    this.health_check_task = null;
    if (props.hasOwnProperty('health_check_task')) {
      const v = props.health_check_task;
      this.health_check_task = v && new Task(v);
    }

    this.other_photos_task = null;
    if (props.hasOwnProperty('other_photos_task')) {
      const v = props.other_photos_task;
      this.other_photos_task = v && new Task(v);
    }
  }

  getCreatedBy(): cwn_user.User {
    if (this.created_by) {
      return this.created_by;
    }
    return new cwn_user.User();
  }

  getStartedBy(): cwn_user.User {
    if (this.started_by) {
      return this.started_by;
    }
    return new cwn_user.User();
  }

  getUpdatedBy(): cwn_user.User {
    if (this.updated_by) {
      return this.updated_by;
    }
    return new cwn_user.User();
  }

  getAssignedTo(): cwn_user.User {
    if (this.assigned_to) {
      return this.assigned_to;
    }
    return new cwn_user.User();
  }

  getAppointment(): cwn_acuity.Appointment {
    if (this.appointment) {
      return this.appointment;
    }
    return new cwn_acuity.Appointment();
  }

  getNetworkPlan(): FlexibleWorkOrder_NetworkPlan {
    if (this.network_plan) {
      return this.network_plan;
    }
    return new FlexibleWorkOrder_NetworkPlan();
  }

  getClosedBy(): cwn_user.User {
    if (this.closed_by) {
      return this.closed_by;
    }
    return new cwn_user.User();
  }

  getBuildingInfoTask(): Task {
    if (this.building_info_task) {
      return this.building_info_task;
    }
    return new Task();
  }

  getHealthCheckTask(): Task {
    if (this.health_check_task) {
      return this.health_check_task;
    }
    return new Task();
  }

  getOtherPhotosTask(): Task {
    if (this.other_photos_task) {
      return this.other_photos_task;
    }
    return new Task();
  }
}
export {FlexibleWorkOrder};

export const Tag_TeamValues = {
  ALL: 0,
  SUPPORT: 1,
  NETOPS: 2,
  FIELD_SUPPORT: 3,
};
type Tag_Team = $Keys<typeof Tag_TeamValues>;
export type {Tag_Team};

export function Tag_TeamValue(n: number): Tag_Team {
  switch (n) {
    case 0:
      return 'ALL';

    case 1:
      return 'SUPPORT';

    case 2:
      return 'NETOPS';

    case 3:
      return 'FIELD_SUPPORT';

    default:
      return 'ALL';
  }
}

class Tag {
  id: string;
  disabled: boolean;
  description: string;
  created: ?Date;
  updated: ?Date;
  created_by: ?cwn_user.User;
  updated_by: ?cwn_user.User;
  created_for: Tag_Team;

  constructor(props: $Shape<Tag> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.disabled = false;
    if (props.hasOwnProperty('disabled')) {
      const v = props.disabled;
      this.disabled = !!v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.created_by = null;
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v && new cwn_user.User(v);
    }

    this.updated_by = null;
    if (props.hasOwnProperty('updated_by')) {
      const v = props.updated_by;
      this.updated_by = v && new cwn_user.User(v);
    }

    this.created_for = Tag_TeamValue(0);
    if (props.hasOwnProperty('created_for')) {
      const v = props.created_for;
      this.created_for = v;
    }
  }

  getCreatedBy(): cwn_user.User {
    if (this.created_by) {
      return this.created_by;
    }
    return new cwn_user.User();
  }

  getUpdatedBy(): cwn_user.User {
    if (this.updated_by) {
      return this.updated_by;
    }
    return new cwn_user.User();
  }
}
export {Tag};

export const Task_TypeValues = {
  UNKNOWN: 0,
  OTHER: 1,
  DESCRIPTION: 2,
  PHOTO: 3,
  DEVICE: 4,
  CHECKLIST: 5,
  UPDATE_SITE: 6,
  UPDATE_SUB: 7,
};
type Task_Type = $Keys<typeof Task_TypeValues>;
export type {Task_Type};

export function Task_TypeValue(n: number): Task_Type {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'OTHER';

    case 2:
      return 'DESCRIPTION';

    case 3:
      return 'PHOTO';

    case 4:
      return 'DEVICE';

    case 5:
      return 'CHECKLIST';

    case 6:
      return 'UPDATE_SITE';

    case 7:
      return 'UPDATE_SUB';

    default:
      return 'UNKNOWN';
  }
}

class Task_LabeledPhoto {
  label: string;
  photo_id: string;

  constructor(props: $Shape<Task_LabeledPhoto> = {}): void {
    if (!props) {
      props = {};
    }

    this.label = '';
    if (props.hasOwnProperty('label')) {
      const v = props.label;
      this.label = v;
    }

    this.photo_id = '';
    if (props.hasOwnProperty('photo_id')) {
      const v = props.photo_id;
      this.photo_id = v;
    }
  }
}
export {Task_LabeledPhoto};
class Task {
  kind: TaskKind;
  other_kind: string;
  type: Task_Type;
  description: string;
  photos: Array<Task_LabeledPhoto>;
  completed_by: ?cwn_user.User;
  completed: ?Date;

  constructor(props: $Shape<Task> = {}): void {
    if (!props) {
      props = {};
    }

    this.kind = TaskKindValue(0);
    if (props.hasOwnProperty('kind')) {
      const v = props.kind;
      this.kind = v;
    }

    this.other_kind = '';
    if (props.hasOwnProperty('other_kind')) {
      const v = props.other_kind;
      this.other_kind = v;
    }

    this.type = Task_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.photos = [];
    if (props.hasOwnProperty('photos')) {
      const v = props.photos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photos should be array');
      }
      this.photos = v.map(function(v) {
        return new Task_LabeledPhoto(v);
      });
    }

    this.completed_by = null;
    if (props.hasOwnProperty('completed_by')) {
      const v = props.completed_by;
      this.completed_by = v && new cwn_user.User(v);
    }

    this.completed = null;
    if (props.hasOwnProperty('completed')) {
      const v = props.completed;
      this.completed = v && new Date(v);
    }
  }

  getCompletedBy(): cwn_user.User {
    if (this.completed_by) {
      return this.completed_by;
    }
    return new cwn_user.User();
  }
}
export {Task};

export const WorkOrder_CancellationOrFailureReasonValues = {
  INVALID_FAILURE_REASON: 0,
  CUSTOMER_NO_LONGER_INTERESTED: 1,
  RESCHEDULE: 2,
  NO_TECHS_AVAILABLE: 3,
  BUILDING_RESTRICTION_TOO_COMPLEX: 4,
  BUILDING_RESTRICTION_ROOF_UNSAFE: 5,
  UNSERVICEABLE_NO_LOS: 6,
  LL_OWNER_PERMISSION_DENIED: 7,
  LL_OWNER_PERMISSION_REQUIRED_IN_PROGRESS: 8,
  MDU_UPGRADE_REQUIRED_FIRST: 9,
  INVENTORY_ISSUE: 10,
  SECOND_TECH_NEEDED: 11,
  MISSED_INSTALL_WINDOW: 12,
  AESTHETICS: 13,
  CUSTOMER_NO_SHOW: 14,
  LAST_MINUTE_CANCEL_THAT_WE_MISSED: 15,
  RAN_OUT_OF_TIME: 16,
  WRONG_LOCATION: 17,
  NO_PRODUCT_FIT: 18,
  OTHER_FAILURE_REASON: 19,
  BOOKED_BY_ACCIDENT: 20,
  STILL_DECIDING: 21,
  STILL_UNDER_CONTRACT: 22,
  NO_PRODUCT_FIT_COMMERCIAL: 23,
  NO_PRODUCT_FIT_CABLE_OR_PHONE: 24,
  LL_OWNER_ISSUE: 25,
  MODIFY_APPOINTMENT: 26,
  DETACHED_LEAD: 27,
  DELETE_SRL: 28,
  HIGH_TX: 29,
  INSTALLATION_POLICY: 30,
  BUILD_PLAN_UPGRADE: 31,
};
type WorkOrder_CancellationOrFailureReason = $Keys<
  typeof WorkOrder_CancellationOrFailureReasonValues,
>;
export type {WorkOrder_CancellationOrFailureReason};

export function WorkOrder_CancellationOrFailureReasonValue(
  n: number,
): WorkOrder_CancellationOrFailureReason {
  switch (n) {
    case 0:
      return 'INVALID_FAILURE_REASON';

    case 1:
      return 'CUSTOMER_NO_LONGER_INTERESTED';

    case 2:
      return 'RESCHEDULE';

    case 3:
      return 'NO_TECHS_AVAILABLE';

    case 4:
      return 'BUILDING_RESTRICTION_TOO_COMPLEX';

    case 5:
      return 'BUILDING_RESTRICTION_ROOF_UNSAFE';

    case 6:
      return 'UNSERVICEABLE_NO_LOS';

    case 7:
      return 'LL_OWNER_PERMISSION_DENIED';

    case 8:
      return 'LL_OWNER_PERMISSION_REQUIRED_IN_PROGRESS';

    case 9:
      return 'MDU_UPGRADE_REQUIRED_FIRST';

    case 10:
      return 'INVENTORY_ISSUE';

    case 11:
      return 'SECOND_TECH_NEEDED';

    case 12:
      return 'MISSED_INSTALL_WINDOW';

    case 13:
      return 'AESTHETICS';

    case 14:
      return 'CUSTOMER_NO_SHOW';

    case 15:
      return 'LAST_MINUTE_CANCEL_THAT_WE_MISSED';

    case 16:
      return 'RAN_OUT_OF_TIME';

    case 17:
      return 'WRONG_LOCATION';

    case 18:
      return 'NO_PRODUCT_FIT';

    case 19:
      return 'OTHER_FAILURE_REASON';

    case 20:
      return 'BOOKED_BY_ACCIDENT';

    case 21:
      return 'STILL_DECIDING';

    case 22:
      return 'STILL_UNDER_CONTRACT';

    case 23:
      return 'NO_PRODUCT_FIT_COMMERCIAL';

    case 24:
      return 'NO_PRODUCT_FIT_CABLE_OR_PHONE';

    case 25:
      return 'LL_OWNER_ISSUE';

    case 26:
      return 'MODIFY_APPOINTMENT';

    case 27:
      return 'DETACHED_LEAD';

    case 28:
      return 'DELETE_SRL';

    case 29:
      return 'HIGH_TX';

    case 30:
      return 'INSTALLATION_POLICY';

    case 31:
      return 'BUILD_PLAN_UPGRADE';

    default:
      return 'INVALID_FAILURE_REASON';
  }
}

export const WorkOrder_CompletionProblemValues = {
  PROBLEM_INVALID: 0,
  PROBLEM_SECOND_TECH_NEEDED: 1,
  PROBLEM_CUSTOMER_LATE: 2,
  PROBLEM_EQUIPMENT_FAILURE: 3,
  PROBLEM_EXTRAORDINARY_CABLE_RUN: 4,
  PROBLEM_INVENTORY_ISSUE: 5,
  PROBLEM_LL_OWNER_PERMISSION_ISSUE: 6,
  PROBLEM_MDU_UPGRADE_REQUIRED_FIRST: 7,
  PROBLEM_ONBOARDING_BILLING_ISSUE: 8,
  PROBLEM_TROUBLESHOOTING: 9,
  PROBLEM_WRONG_LOCATION_IN_NETWORK_PLAN: 10,
  PROBLEM_OTHER: 11,
  PROBLEM_ONLY_ONE_SHOT_UP: 12,
  PROBLEM_HIGH_TX: 13,
};
type WorkOrder_CompletionProblem = $Keys<
  typeof WorkOrder_CompletionProblemValues,
>;
export type {WorkOrder_CompletionProblem};

export function WorkOrder_CompletionProblemValue(
  n: number,
): WorkOrder_CompletionProblem {
  switch (n) {
    case 0:
      return 'PROBLEM_INVALID';

    case 1:
      return 'PROBLEM_SECOND_TECH_NEEDED';

    case 2:
      return 'PROBLEM_CUSTOMER_LATE';

    case 3:
      return 'PROBLEM_EQUIPMENT_FAILURE';

    case 4:
      return 'PROBLEM_EXTRAORDINARY_CABLE_RUN';

    case 5:
      return 'PROBLEM_INVENTORY_ISSUE';

    case 6:
      return 'PROBLEM_LL_OWNER_PERMISSION_ISSUE';

    case 7:
      return 'PROBLEM_MDU_UPGRADE_REQUIRED_FIRST';

    case 8:
      return 'PROBLEM_ONBOARDING_BILLING_ISSUE';

    case 9:
      return 'PROBLEM_TROUBLESHOOTING';

    case 10:
      return 'PROBLEM_WRONG_LOCATION_IN_NETWORK_PLAN';

    case 11:
      return 'PROBLEM_OTHER';

    case 12:
      return 'PROBLEM_ONLY_ONE_SHOT_UP';

    case 13:
      return 'PROBLEM_HIGH_TX';

    default:
      return 'PROBLEM_INVALID';
  }
}

export const WorkOrder_LOSObstructionValues = {
  OBSTRUCTION_INVALID: 0,
  OBSTRUCTION_BUILDING: 1,
  OBSTRUCTION_TREE_OR_BUSH: 2,
  OBSTRUCTION_UTILITY_OR_POLE: 3,
  OBSTRUCTION_WIRES: 4,
  OBSTRUCTION_OTHER: 5,
};
type WorkOrder_LOSObstruction = $Keys<typeof WorkOrder_LOSObstructionValues>;
export type {WorkOrder_LOSObstruction};

export function WorkOrder_LOSObstructionValue(
  n: number,
): WorkOrder_LOSObstruction {
  switch (n) {
    case 0:
      return 'OBSTRUCTION_INVALID';

    case 1:
      return 'OBSTRUCTION_BUILDING';

    case 2:
      return 'OBSTRUCTION_TREE_OR_BUSH';

    case 3:
      return 'OBSTRUCTION_UTILITY_OR_POLE';

    case 4:
      return 'OBSTRUCTION_WIRES';

    case 5:
      return 'OBSTRUCTION_OTHER';

    default:
      return 'OBSTRUCTION_INVALID';
  }
}

class WorkOrder {
  id: string;
  site_id: string;
  closed_by: string;
  deleted: ?Date;
  created: ?Date;
  updated: ?Date;
  started: ?Date;
  completed: ?Date;
  canceled: ?Date;
  failed: ?Date;
  appointment: ?cwn_acuity.Appointment;
  cancellation_reasons: Array<WorkOrder_CancellationOrFailureReason>;
  cancellation_reason_other: string;
  failure_reasons: Array<WorkOrder_CancellationOrFailureReason>;
  failure_reason_other: string;
  _installation: ?InstallationWorkOrder;
  _radioAdd: ?RadioAddWorkOrder;
  _other: ?OtherWorkOrder;
  zen_ticket_id: string;
  completion_problems: Array<WorkOrder_CompletionProblem>;
  completion_problem_other: string;
  los_obstructions: Array<WorkOrder_LOSObstruction>;
  los_obstruction_other: string;
  created_by: ?cwn_user.User;
  updated_by: ?cwn_user.User;
  completed_by: ?cwn_user.User;
  flexible_work_order_id: string;

  constructor(props: $Shape<WorkOrder> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.closed_by = '';
    if (props.hasOwnProperty('closed_by')) {
      const v = props.closed_by;
      this.closed_by = v;
    }

    this.deleted = null;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = v && new Date(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.started = null;
    if (props.hasOwnProperty('started')) {
      const v = props.started;
      this.started = v && new Date(v);
    }

    this.completed = null;
    if (props.hasOwnProperty('completed')) {
      const v = props.completed;
      this.completed = v && new Date(v);
    }

    this.canceled = null;
    if (props.hasOwnProperty('canceled')) {
      const v = props.canceled;
      this.canceled = v && new Date(v);
    }

    this.failed = null;
    if (props.hasOwnProperty('failed')) {
      const v = props.failed;
      this.failed = v && new Date(v);
    }

    this.appointment = null;
    if (props.hasOwnProperty('appointment')) {
      const v = props.appointment;
      this.appointment = v && new cwn_acuity.Appointment(v);
    }

    this.cancellation_reasons = [];
    if (props.hasOwnProperty('cancellation_reasons')) {
      const v = props.cancellation_reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field cancellation_reasons should be array');
      }
      this.cancellation_reasons = v.map(function(v) {
        return v;
      });
    }

    this.cancellation_reason_other = '';
    if (props.hasOwnProperty('cancellation_reason_other')) {
      const v = props.cancellation_reason_other;
      this.cancellation_reason_other = v;
    }

    this.failure_reasons = [];
    if (props.hasOwnProperty('failure_reasons')) {
      const v = props.failure_reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field failure_reasons should be array');
      }
      this.failure_reasons = v.map(function(v) {
        return v;
      });
    }

    this.failure_reason_other = '';
    if (props.hasOwnProperty('failure_reason_other')) {
      const v = props.failure_reason_other;
      this.failure_reason_other = v;
    }

    this._installation = undefined;
    if (props.hasOwnProperty('installation')) {
      const v = props.installation;
      this._installation = v && new InstallationWorkOrder(v);
    }

    this._radioAdd = undefined;
    if (props.hasOwnProperty('radioAdd')) {
      const v = props.radioAdd;
      this._radioAdd = v && new RadioAddWorkOrder(v);
    }

    this._other = undefined;
    if (props.hasOwnProperty('other')) {
      const v = props.other;
      this._other = v && new OtherWorkOrder(v);
    }

    this.zen_ticket_id = '0';
    if (props.hasOwnProperty('zen_ticket_id')) {
      const v = props.zen_ticket_id;
      this.zen_ticket_id = v;
    }

    this.completion_problems = [];
    if (props.hasOwnProperty('completion_problems')) {
      const v = props.completion_problems;
      if (!Array.isArray(v)) {
        throw new Error('repeated field completion_problems should be array');
      }
      this.completion_problems = v.map(function(v) {
        return v;
      });
    }

    this.completion_problem_other = '';
    if (props.hasOwnProperty('completion_problem_other')) {
      const v = props.completion_problem_other;
      this.completion_problem_other = v;
    }

    this.los_obstructions = [];
    if (props.hasOwnProperty('los_obstructions')) {
      const v = props.los_obstructions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field los_obstructions should be array');
      }
      this.los_obstructions = v.map(function(v) {
        return v;
      });
    }

    this.los_obstruction_other = '';
    if (props.hasOwnProperty('los_obstruction_other')) {
      const v = props.los_obstruction_other;
      this.los_obstruction_other = v;
    }

    this.created_by = null;
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v && new cwn_user.User(v);
    }

    this.updated_by = null;
    if (props.hasOwnProperty('updated_by')) {
      const v = props.updated_by;
      this.updated_by = v && new cwn_user.User(v);
    }

    this.completed_by = null;
    if (props.hasOwnProperty('completed_by')) {
      const v = props.completed_by;
      this.completed_by = v && new cwn_user.User(v);
    }

    this.flexible_work_order_id = '0';
    if (props.hasOwnProperty('flexible_work_order_id')) {
      const v = props.flexible_work_order_id;
      this.flexible_work_order_id = v;
    }
  }

  getAppointment(): cwn_acuity.Appointment {
    if (this.appointment) {
      return this.appointment;
    }
    return new cwn_acuity.Appointment();
  }

  getInstallation(): InstallationWorkOrder {
    if (this._installation) {
      return this._installation;
    }
    return new InstallationWorkOrder();
  }

  getRadioAdd(): RadioAddWorkOrder {
    if (this._radioAdd) {
      return this._radioAdd;
    }
    return new RadioAddWorkOrder();
  }

  getOther(): OtherWorkOrder {
    if (this._other) {
      return this._other;
    }
    return new OtherWorkOrder();
  }

  getCreatedBy(): cwn_user.User {
    if (this.created_by) {
      return this.created_by;
    }
    return new cwn_user.User();
  }

  getUpdatedBy(): cwn_user.User {
    if (this.updated_by) {
      return this.updated_by;
    }
    return new cwn_user.User();
  }

  getCompletedBy(): cwn_user.User {
    if (this.completed_by) {
      return this.completed_by;
    }
    return new cwn_user.User();
  }

  get installation(): ?InstallationWorkOrder {
    return this._installation;
  }
  set installation(installation: ?InstallationWorkOrder) {
    this._installation = installation;
    this._radioAdd = undefined;
    this._other = undefined;
  }
  get radioAdd(): ?RadioAddWorkOrder {
    return this._radioAdd;
  }
  set radioAdd(radioAdd: ?RadioAddWorkOrder) {
    this._installation = undefined;
    this._radioAdd = radioAdd;
    this._other = undefined;
  }
  get other(): ?OtherWorkOrder {
    return this._other;
  }
  set other(other: ?OtherWorkOrder) {
    this._installation = undefined;
    this._radioAdd = undefined;
    this._other = other;
  }

  toJSON() {
    return {
      id: this.id,
      site_id: this.site_id,
      closed_by: this.closed_by,
      deleted: this.deleted,
      created: this.created,
      updated: this.updated,
      started: this.started,
      completed: this.completed,
      canceled: this.canceled,
      failed: this.failed,
      appointment: this.appointment,
      cancellation_reasons: this.cancellation_reasons,
      cancellation_reason_other: this.cancellation_reason_other,
      failure_reasons: this.failure_reasons,
      failure_reason_other: this.failure_reason_other,
      installation: this._installation,
      radioAdd: this._radioAdd,
      other: this._other,
      zen_ticket_id: this.zen_ticket_id,
      completion_problems: this.completion_problems,
      completion_problem_other: this.completion_problem_other,
      los_obstructions: this.los_obstructions,
      los_obstruction_other: this.los_obstruction_other,
      created_by: this.created_by,
      updated_by: this.updated_by,
      completed_by: this.completed_by,
      flexible_work_order_id: this.flexible_work_order_id,
    };
  }
}
export {WorkOrder};

class WorkOrderTask {
  completed: boolean;
  warning: boolean;
  updated: ?Date;

  constructor(props: $Shape<WorkOrderTask> = {}): void {
    if (!props) {
      props = {};
    }

    this.completed = false;
    if (props.hasOwnProperty('completed')) {
      const v = props.completed;
      this.completed = !!v;
    }

    this.warning = false;
    if (props.hasOwnProperty('warning')) {
      const v = props.warning;
      this.warning = !!v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }
}
export {WorkOrderTask};

class InstallationWorkOrder {
  lead_id: string;
  cx_walkthrough: ?WorkOrderTask;
  pre_checklist_photo: ?WorkOrderTask;
  collect_billing: ?WorkOrderTask;
  site_inspection_photos: ?WorkOrderTask;
  radio_mac_address: ?WorkOrderTask;
  test_tx_power: ?WorkOrderTask;
  building_info: ?WorkOrderTask;
  assess_los: ?WorkOrderTask;
  add_device: ?WorkOrderTask;
  safety: ?WorkOrderTask;
  verify_mount_location: ?WorkOrderTask;
  point_radio: ?WorkOrderTask;
  full_mount_photos: ?WorkOrderTask;
  exterior_cable_run: ?WorkOrderTask;
  configure_device: ?WorkOrderTask;
  add_ap: ?WorkOrderTask;
  final_checks: ?WorkOrderTask;
  final_cx_walkthrough: ?WorkOrderTask;
  post_checklist_photo: ?WorkOrderTask;
  update_building_notes: ?WorkOrderTask;
  pre_task_photos: ?WorkOrderTask;
  install_task_photos: ?WorkOrderTask;
  post_task_photos: ?WorkOrderTask;
  other_photos: ?WorkOrderTask;
  photo_pre_installation_checklist: boolean;
  photo_front_of_house: boolean;
  photo_ladder: boolean;
  photo_existing_damage: boolean;
  photo_rooftop: boolean;
  photo_radio_mac_address: Array<string>;
  photo_safety: boolean;
  photo_radio_los: Array<string>;
  photo_full_mount: boolean;
  photo_mount_attachment: boolean;
  photo_exterior_cable_run: boolean;
  photo_ap_mac_address: boolean;
  photo_interior_equipment: boolean;
  photo_speedtest: boolean;
  photo_post_installation_checklist: boolean;
  layer_z_scheduled: boolean;
  radio_add_work_order_ids: Array<string>;
  build_plan_id: string;

  constructor(props: $Shape<InstallationWorkOrder> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.cx_walkthrough = null;
    if (props.hasOwnProperty('cx_walkthrough')) {
      const v = props.cx_walkthrough;
      this.cx_walkthrough = v && new WorkOrderTask(v);
    }

    this.pre_checklist_photo = null;
    if (props.hasOwnProperty('pre_checklist_photo')) {
      const v = props.pre_checklist_photo;
      this.pre_checklist_photo = v && new WorkOrderTask(v);
    }

    this.collect_billing = null;
    if (props.hasOwnProperty('collect_billing')) {
      const v = props.collect_billing;
      this.collect_billing = v && new WorkOrderTask(v);
    }

    this.site_inspection_photos = null;
    if (props.hasOwnProperty('site_inspection_photos')) {
      const v = props.site_inspection_photos;
      this.site_inspection_photos = v && new WorkOrderTask(v);
    }

    this.radio_mac_address = null;
    if (props.hasOwnProperty('radio_mac_address')) {
      const v = props.radio_mac_address;
      this.radio_mac_address = v && new WorkOrderTask(v);
    }

    this.test_tx_power = null;
    if (props.hasOwnProperty('test_tx_power')) {
      const v = props.test_tx_power;
      this.test_tx_power = v && new WorkOrderTask(v);
    }

    this.building_info = null;
    if (props.hasOwnProperty('building_info')) {
      const v = props.building_info;
      this.building_info = v && new WorkOrderTask(v);
    }

    this.assess_los = null;
    if (props.hasOwnProperty('assess_los')) {
      const v = props.assess_los;
      this.assess_los = v && new WorkOrderTask(v);
    }

    this.add_device = null;
    if (props.hasOwnProperty('add_device')) {
      const v = props.add_device;
      this.add_device = v && new WorkOrderTask(v);
    }

    this.safety = null;
    if (props.hasOwnProperty('safety')) {
      const v = props.safety;
      this.safety = v && new WorkOrderTask(v);
    }

    this.verify_mount_location = null;
    if (props.hasOwnProperty('verify_mount_location')) {
      const v = props.verify_mount_location;
      this.verify_mount_location = v && new WorkOrderTask(v);
    }

    this.point_radio = null;
    if (props.hasOwnProperty('point_radio')) {
      const v = props.point_radio;
      this.point_radio = v && new WorkOrderTask(v);
    }

    this.full_mount_photos = null;
    if (props.hasOwnProperty('full_mount_photos')) {
      const v = props.full_mount_photos;
      this.full_mount_photos = v && new WorkOrderTask(v);
    }

    this.exterior_cable_run = null;
    if (props.hasOwnProperty('exterior_cable_run')) {
      const v = props.exterior_cable_run;
      this.exterior_cable_run = v && new WorkOrderTask(v);
    }

    this.configure_device = null;
    if (props.hasOwnProperty('configure_device')) {
      const v = props.configure_device;
      this.configure_device = v && new WorkOrderTask(v);
    }

    this.add_ap = null;
    if (props.hasOwnProperty('add_ap')) {
      const v = props.add_ap;
      this.add_ap = v && new WorkOrderTask(v);
    }

    this.final_checks = null;
    if (props.hasOwnProperty('final_checks')) {
      const v = props.final_checks;
      this.final_checks = v && new WorkOrderTask(v);
    }

    this.final_cx_walkthrough = null;
    if (props.hasOwnProperty('final_cx_walkthrough')) {
      const v = props.final_cx_walkthrough;
      this.final_cx_walkthrough = v && new WorkOrderTask(v);
    }

    this.post_checklist_photo = null;
    if (props.hasOwnProperty('post_checklist_photo')) {
      const v = props.post_checklist_photo;
      this.post_checklist_photo = v && new WorkOrderTask(v);
    }

    this.update_building_notes = null;
    if (props.hasOwnProperty('update_building_notes')) {
      const v = props.update_building_notes;
      this.update_building_notes = v && new WorkOrderTask(v);
    }

    this.pre_task_photos = null;
    if (props.hasOwnProperty('pre_task_photos')) {
      const v = props.pre_task_photos;
      this.pre_task_photos = v && new WorkOrderTask(v);
    }

    this.install_task_photos = null;
    if (props.hasOwnProperty('install_task_photos')) {
      const v = props.install_task_photos;
      this.install_task_photos = v && new WorkOrderTask(v);
    }

    this.post_task_photos = null;
    if (props.hasOwnProperty('post_task_photos')) {
      const v = props.post_task_photos;
      this.post_task_photos = v && new WorkOrderTask(v);
    }

    this.other_photos = null;
    if (props.hasOwnProperty('other_photos')) {
      const v = props.other_photos;
      this.other_photos = v && new WorkOrderTask(v);
    }

    this.photo_pre_installation_checklist = false;
    if (props.hasOwnProperty('photo_pre_installation_checklist')) {
      const v = props.photo_pre_installation_checklist;
      this.photo_pre_installation_checklist = !!v;
    }

    this.photo_front_of_house = false;
    if (props.hasOwnProperty('photo_front_of_house')) {
      const v = props.photo_front_of_house;
      this.photo_front_of_house = !!v;
    }

    this.photo_ladder = false;
    if (props.hasOwnProperty('photo_ladder')) {
      const v = props.photo_ladder;
      this.photo_ladder = !!v;
    }

    this.photo_existing_damage = false;
    if (props.hasOwnProperty('photo_existing_damage')) {
      const v = props.photo_existing_damage;
      this.photo_existing_damage = !!v;
    }

    this.photo_rooftop = false;
    if (props.hasOwnProperty('photo_rooftop')) {
      const v = props.photo_rooftop;
      this.photo_rooftop = !!v;
    }

    this.photo_radio_mac_address = [];
    if (props.hasOwnProperty('photo_radio_mac_address')) {
      const v = props.photo_radio_mac_address;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field photo_radio_mac_address should be array',
        );
      }
      this.photo_radio_mac_address = v.map(function(v) {
        return v;
      });
    }

    this.photo_safety = false;
    if (props.hasOwnProperty('photo_safety')) {
      const v = props.photo_safety;
      this.photo_safety = !!v;
    }

    this.photo_radio_los = [];
    if (props.hasOwnProperty('photo_radio_los')) {
      const v = props.photo_radio_los;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photo_radio_los should be array');
      }
      this.photo_radio_los = v.map(function(v) {
        return v;
      });
    }

    this.photo_full_mount = false;
    if (props.hasOwnProperty('photo_full_mount')) {
      const v = props.photo_full_mount;
      this.photo_full_mount = !!v;
    }

    this.photo_mount_attachment = false;
    if (props.hasOwnProperty('photo_mount_attachment')) {
      const v = props.photo_mount_attachment;
      this.photo_mount_attachment = !!v;
    }

    this.photo_exterior_cable_run = false;
    if (props.hasOwnProperty('photo_exterior_cable_run')) {
      const v = props.photo_exterior_cable_run;
      this.photo_exterior_cable_run = !!v;
    }

    this.photo_ap_mac_address = false;
    if (props.hasOwnProperty('photo_ap_mac_address')) {
      const v = props.photo_ap_mac_address;
      this.photo_ap_mac_address = !!v;
    }

    this.photo_interior_equipment = false;
    if (props.hasOwnProperty('photo_interior_equipment')) {
      const v = props.photo_interior_equipment;
      this.photo_interior_equipment = !!v;
    }

    this.photo_speedtest = false;
    if (props.hasOwnProperty('photo_speedtest')) {
      const v = props.photo_speedtest;
      this.photo_speedtest = !!v;
    }

    this.photo_post_installation_checklist = false;
    if (props.hasOwnProperty('photo_post_installation_checklist')) {
      const v = props.photo_post_installation_checklist;
      this.photo_post_installation_checklist = !!v;
    }

    this.layer_z_scheduled = false;
    if (props.hasOwnProperty('layer_z_scheduled')) {
      const v = props.layer_z_scheduled;
      this.layer_z_scheduled = !!v;
    }

    this.radio_add_work_order_ids = [];
    if (props.hasOwnProperty('radio_add_work_order_ids')) {
      const v = props.radio_add_work_order_ids;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field radio_add_work_order_ids should be array',
        );
      }
      this.radio_add_work_order_ids = v.map(function(v) {
        return v;
      });
    }

    this.build_plan_id = '0';
    if (props.hasOwnProperty('build_plan_id')) {
      const v = props.build_plan_id;
      this.build_plan_id = v;
    }
  }

  getCxWalkthrough(): WorkOrderTask {
    if (this.cx_walkthrough) {
      return this.cx_walkthrough;
    }
    return new WorkOrderTask();
  }

  getPreChecklistPhoto(): WorkOrderTask {
    if (this.pre_checklist_photo) {
      return this.pre_checklist_photo;
    }
    return new WorkOrderTask();
  }

  getCollectBilling(): WorkOrderTask {
    if (this.collect_billing) {
      return this.collect_billing;
    }
    return new WorkOrderTask();
  }

  getSiteInspectionPhotos(): WorkOrderTask {
    if (this.site_inspection_photos) {
      return this.site_inspection_photos;
    }
    return new WorkOrderTask();
  }

  getRadioMacAddress(): WorkOrderTask {
    if (this.radio_mac_address) {
      return this.radio_mac_address;
    }
    return new WorkOrderTask();
  }

  getTestTxPower(): WorkOrderTask {
    if (this.test_tx_power) {
      return this.test_tx_power;
    }
    return new WorkOrderTask();
  }

  getBuildingInfo(): WorkOrderTask {
    if (this.building_info) {
      return this.building_info;
    }
    return new WorkOrderTask();
  }

  getAssessLos(): WorkOrderTask {
    if (this.assess_los) {
      return this.assess_los;
    }
    return new WorkOrderTask();
  }

  getAddDevice(): WorkOrderTask {
    if (this.add_device) {
      return this.add_device;
    }
    return new WorkOrderTask();
  }

  getSafety(): WorkOrderTask {
    if (this.safety) {
      return this.safety;
    }
    return new WorkOrderTask();
  }

  getVerifyMountLocation(): WorkOrderTask {
    if (this.verify_mount_location) {
      return this.verify_mount_location;
    }
    return new WorkOrderTask();
  }

  getPointRadio(): WorkOrderTask {
    if (this.point_radio) {
      return this.point_radio;
    }
    return new WorkOrderTask();
  }

  getFullMountPhotos(): WorkOrderTask {
    if (this.full_mount_photos) {
      return this.full_mount_photos;
    }
    return new WorkOrderTask();
  }

  getExteriorCableRun(): WorkOrderTask {
    if (this.exterior_cable_run) {
      return this.exterior_cable_run;
    }
    return new WorkOrderTask();
  }

  getConfigureDevice(): WorkOrderTask {
    if (this.configure_device) {
      return this.configure_device;
    }
    return new WorkOrderTask();
  }

  getAddAp(): WorkOrderTask {
    if (this.add_ap) {
      return this.add_ap;
    }
    return new WorkOrderTask();
  }

  getFinalChecks(): WorkOrderTask {
    if (this.final_checks) {
      return this.final_checks;
    }
    return new WorkOrderTask();
  }

  getFinalCxWalkthrough(): WorkOrderTask {
    if (this.final_cx_walkthrough) {
      return this.final_cx_walkthrough;
    }
    return new WorkOrderTask();
  }

  getPostChecklistPhoto(): WorkOrderTask {
    if (this.post_checklist_photo) {
      return this.post_checklist_photo;
    }
    return new WorkOrderTask();
  }

  getUpdateBuildingNotes(): WorkOrderTask {
    if (this.update_building_notes) {
      return this.update_building_notes;
    }
    return new WorkOrderTask();
  }

  getPreTaskPhotos(): WorkOrderTask {
    if (this.pre_task_photos) {
      return this.pre_task_photos;
    }
    return new WorkOrderTask();
  }

  getInstallTaskPhotos(): WorkOrderTask {
    if (this.install_task_photos) {
      return this.install_task_photos;
    }
    return new WorkOrderTask();
  }

  getPostTaskPhotos(): WorkOrderTask {
    if (this.post_task_photos) {
      return this.post_task_photos;
    }
    return new WorkOrderTask();
  }

  getOtherPhotos(): WorkOrderTask {
    if (this.other_photos) {
      return this.other_photos;
    }
    return new WorkOrderTask();
  }
}
export {InstallationWorkOrder};

export const RadioAction_ActionValues = {
  INVALID_ACTION: 0,
  ADD: 1,
  REPOINT: 2,
  REMOVAL: 3,
};
type RadioAction_Action = $Keys<typeof RadioAction_ActionValues>;
export type {RadioAction_Action};

export function RadioAction_ActionValue(n: number): RadioAction_Action {
  switch (n) {
    case 0:
      return 'INVALID_ACTION';

    case 1:
      return 'ADD';

    case 2:
      return 'REPOINT';

    case 3:
      return 'REMOVAL';

    default:
      return 'INVALID_ACTION';
  }
}

class RadioAction {
  srl_id: string;
  action: RadioAction_Action;

  constructor(props: $Shape<RadioAction> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl_id = '0';
    if (props.hasOwnProperty('srl_id')) {
      const v = props.srl_id;
      this.srl_id = v;
    }

    this.action = RadioAction_ActionValue(0);
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v;
    }
  }
}
export {RadioAction};

class RadioAddWorkOrder {
  lead_id: string;
  radio_actions: Array<RadioAction>;
  cx_walkthrough: ?WorkOrderTask;
  safety: ?WorkOrderTask;
  building_info: ?WorkOrderTask;
  radio_mac_address: ?WorkOrderTask;
  site_inspection_photos: ?WorkOrderTask;
  assess_los: ?WorkOrderTask;
  add_radio: ?WorkOrderTask;
  point_radio: ?WorkOrderTask;
  final_checks: ?WorkOrderTask;
  update_building_notes: ?WorkOrderTask;
  radio_add_photos: ?WorkOrderTask;
  other_photos: ?WorkOrderTask;
  no_peer: Array<string>;
  photo_safety: boolean;
  photo_ladder: boolean;
  photo_existing_damage: boolean;
  add_radio_to_mount: boolean;
  aim_radio: boolean;
  photo_aimed_radio: boolean;
  remove_any_debris: boolean;
  photo_add_radio: Array<string>;
  photo_radio_mac_address: Array<string>;
  photo_los: Array<string>;

  constructor(props: $Shape<RadioAddWorkOrder> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.radio_actions = [];
    if (props.hasOwnProperty('radio_actions')) {
      const v = props.radio_actions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_actions should be array');
      }
      this.radio_actions = v.map(function(v) {
        return new RadioAction(v);
      });
    }

    this.cx_walkthrough = null;
    if (props.hasOwnProperty('cx_walkthrough')) {
      const v = props.cx_walkthrough;
      this.cx_walkthrough = v && new WorkOrderTask(v);
    }

    this.safety = null;
    if (props.hasOwnProperty('safety')) {
      const v = props.safety;
      this.safety = v && new WorkOrderTask(v);
    }

    this.building_info = null;
    if (props.hasOwnProperty('building_info')) {
      const v = props.building_info;
      this.building_info = v && new WorkOrderTask(v);
    }

    this.radio_mac_address = null;
    if (props.hasOwnProperty('radio_mac_address')) {
      const v = props.radio_mac_address;
      this.radio_mac_address = v && new WorkOrderTask(v);
    }

    this.site_inspection_photos = null;
    if (props.hasOwnProperty('site_inspection_photos')) {
      const v = props.site_inspection_photos;
      this.site_inspection_photos = v && new WorkOrderTask(v);
    }

    this.assess_los = null;
    if (props.hasOwnProperty('assess_los')) {
      const v = props.assess_los;
      this.assess_los = v && new WorkOrderTask(v);
    }

    this.add_radio = null;
    if (props.hasOwnProperty('add_radio')) {
      const v = props.add_radio;
      this.add_radio = v && new WorkOrderTask(v);
    }

    this.point_radio = null;
    if (props.hasOwnProperty('point_radio')) {
      const v = props.point_radio;
      this.point_radio = v && new WorkOrderTask(v);
    }

    this.final_checks = null;
    if (props.hasOwnProperty('final_checks')) {
      const v = props.final_checks;
      this.final_checks = v && new WorkOrderTask(v);
    }

    this.update_building_notes = null;
    if (props.hasOwnProperty('update_building_notes')) {
      const v = props.update_building_notes;
      this.update_building_notes = v && new WorkOrderTask(v);
    }

    this.radio_add_photos = null;
    if (props.hasOwnProperty('radio_add_photos')) {
      const v = props.radio_add_photos;
      this.radio_add_photos = v && new WorkOrderTask(v);
    }

    this.other_photos = null;
    if (props.hasOwnProperty('other_photos')) {
      const v = props.other_photos;
      this.other_photos = v && new WorkOrderTask(v);
    }

    this.no_peer = [];
    if (props.hasOwnProperty('no_peer')) {
      const v = props.no_peer;
      if (!Array.isArray(v)) {
        throw new Error('repeated field no_peer should be array');
      }
      this.no_peer = v.map(function(v) {
        return v;
      });
    }

    this.photo_safety = false;
    if (props.hasOwnProperty('photo_safety')) {
      const v = props.photo_safety;
      this.photo_safety = !!v;
    }

    this.photo_ladder = false;
    if (props.hasOwnProperty('photo_ladder')) {
      const v = props.photo_ladder;
      this.photo_ladder = !!v;
    }

    this.photo_existing_damage = false;
    if (props.hasOwnProperty('photo_existing_damage')) {
      const v = props.photo_existing_damage;
      this.photo_existing_damage = !!v;
    }

    this.add_radio_to_mount = false;
    if (props.hasOwnProperty('add_radio_to_mount')) {
      const v = props.add_radio_to_mount;
      this.add_radio_to_mount = !!v;
    }

    this.aim_radio = false;
    if (props.hasOwnProperty('aim_radio')) {
      const v = props.aim_radio;
      this.aim_radio = !!v;
    }

    this.photo_aimed_radio = false;
    if (props.hasOwnProperty('photo_aimed_radio')) {
      const v = props.photo_aimed_radio;
      this.photo_aimed_radio = !!v;
    }

    this.remove_any_debris = false;
    if (props.hasOwnProperty('remove_any_debris')) {
      const v = props.remove_any_debris;
      this.remove_any_debris = !!v;
    }

    this.photo_add_radio = [];
    if (props.hasOwnProperty('photo_add_radio')) {
      const v = props.photo_add_radio;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photo_add_radio should be array');
      }
      this.photo_add_radio = v.map(function(v) {
        return v;
      });
    }

    this.photo_radio_mac_address = [];
    if (props.hasOwnProperty('photo_radio_mac_address')) {
      const v = props.photo_radio_mac_address;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field photo_radio_mac_address should be array',
        );
      }
      this.photo_radio_mac_address = v.map(function(v) {
        return v;
      });
    }

    this.photo_los = [];
    if (props.hasOwnProperty('photo_los')) {
      const v = props.photo_los;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photo_los should be array');
      }
      this.photo_los = v.map(function(v) {
        return v;
      });
    }
  }

  getCxWalkthrough(): WorkOrderTask {
    if (this.cx_walkthrough) {
      return this.cx_walkthrough;
    }
    return new WorkOrderTask();
  }

  getSafety(): WorkOrderTask {
    if (this.safety) {
      return this.safety;
    }
    return new WorkOrderTask();
  }

  getBuildingInfo(): WorkOrderTask {
    if (this.building_info) {
      return this.building_info;
    }
    return new WorkOrderTask();
  }

  getRadioMacAddress(): WorkOrderTask {
    if (this.radio_mac_address) {
      return this.radio_mac_address;
    }
    return new WorkOrderTask();
  }

  getSiteInspectionPhotos(): WorkOrderTask {
    if (this.site_inspection_photos) {
      return this.site_inspection_photos;
    }
    return new WorkOrderTask();
  }

  getAssessLos(): WorkOrderTask {
    if (this.assess_los) {
      return this.assess_los;
    }
    return new WorkOrderTask();
  }

  getAddRadio(): WorkOrderTask {
    if (this.add_radio) {
      return this.add_radio;
    }
    return new WorkOrderTask();
  }

  getPointRadio(): WorkOrderTask {
    if (this.point_radio) {
      return this.point_radio;
    }
    return new WorkOrderTask();
  }

  getFinalChecks(): WorkOrderTask {
    if (this.final_checks) {
      return this.final_checks;
    }
    return new WorkOrderTask();
  }

  getUpdateBuildingNotes(): WorkOrderTask {
    if (this.update_building_notes) {
      return this.update_building_notes;
    }
    return new WorkOrderTask();
  }

  getRadioAddPhotos(): WorkOrderTask {
    if (this.radio_add_photos) {
      return this.radio_add_photos;
    }
    return new WorkOrderTask();
  }

  getOtherPhotos(): WorkOrderTask {
    if (this.other_photos) {
      return this.other_photos;
    }
    return new WorkOrderTask();
  }
}
export {RadioAddWorkOrder};

export const OtherWorkOrder_WorkOrderNameValues = {
  UNKNOWN: 0,
  MDU_INSTALL: 1,
  MDU_ADD: 2,
  INSTALL_ASSIST: 3,
  INSTALL_FOLLOW_UP: 4,
  RADIO_REALIGN: 5,
  RADIO_SWAP: 6,
  SERVICE_CALL: 7,
  LAST_MINUTE_SERVICE_CALL: 8,
  SITE_SURVEY: 9,
  UNINSTALL: 10,
  RELAY: 11,
};
type OtherWorkOrder_WorkOrderName = $Keys<
  typeof OtherWorkOrder_WorkOrderNameValues,
>;
export type {OtherWorkOrder_WorkOrderName};

export function OtherWorkOrder_WorkOrderNameValue(
  n: number,
): OtherWorkOrder_WorkOrderName {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'MDU_INSTALL';

    case 2:
      return 'MDU_ADD';

    case 3:
      return 'INSTALL_ASSIST';

    case 4:
      return 'INSTALL_FOLLOW_UP';

    case 5:
      return 'RADIO_REALIGN';

    case 6:
      return 'RADIO_SWAP';

    case 7:
      return 'SERVICE_CALL';

    case 8:
      return 'LAST_MINUTE_SERVICE_CALL';

    case 9:
      return 'SITE_SURVEY';

    case 10:
      return 'UNINSTALL';

    case 11:
      return 'RELAY';

    default:
      return 'UNKNOWN';
  }
}

class OtherWorkOrder {
  lead_id: string;
  name: OtherWorkOrder_WorkOrderName;
  description: string;
  more_info: string;
  post_info: string;

  constructor(props: $Shape<OtherWorkOrder> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.name = OtherWorkOrder_WorkOrderNameValue(0);
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.more_info = '';
    if (props.hasOwnProperty('more_info')) {
      const v = props.more_info;
      this.more_info = v;
    }

    this.post_info = '';
    if (props.hasOwnProperty('post_info')) {
      const v = props.post_info;
      this.post_info = v;
    }
  }
}
export {OtherWorkOrder};

// END cwn/tech/models.proto
