// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

import * as cwn_asset from '../../cwn/asset';

// START cwn/antenna/models.proto

export const ModelValues = {
  NONE: 0,
  UBNT_AM5AC2160: 1,
  UBNT_AM5AC2245: 2,
  UBNT_AM5AC2090: 3,
  UBNT_RD5G31AC: 4,
  UBNT_RD5G30LW: 5,
  UBNT_AMO_5G10: 6,
  UBNT_AMO_5G13: 7,
  RF_ELEMENTS_SH_TP_5_30: 8,
  RF_ELEMENTS_SH_TP_5_40: 9,
  RF_ELEMENTS_SH_TP_5_50: 10,
  RF_ELEMENTS_SH_TP_5_60: 11,
  RF_ELEMENTS_SH_TP_5_70: 12,
  RF_ELEMENTS_SH_TP_5_80: 13,
  RF_ELEMENTS_SH_TP_5_90: 14,
  PRISMAP_5_60: 15,
  PRISMAP_5_90: 16,
  PRISMAP_5_45: 17,
};
type Model = $Keys<typeof ModelValues>;
export type {Model};

export function ModelValue(n: number): Model {
  switch (n) {
    case 0:
      return 'NONE';

    case 1:
      return 'UBNT_AM5AC2160';

    case 2:
      return 'UBNT_AM5AC2245';

    case 3:
      return 'UBNT_AM5AC2090';

    case 4:
      return 'UBNT_RD5G31AC';

    case 5:
      return 'UBNT_RD5G30LW';

    case 6:
      return 'UBNT_AMO_5G10';

    case 7:
      return 'UBNT_AMO_5G13';

    case 8:
      return 'RF_ELEMENTS_SH_TP_5_30';

    case 9:
      return 'RF_ELEMENTS_SH_TP_5_40';

    case 10:
      return 'RF_ELEMENTS_SH_TP_5_50';

    case 11:
      return 'RF_ELEMENTS_SH_TP_5_60';

    case 12:
      return 'RF_ELEMENTS_SH_TP_5_70';

    case 13:
      return 'RF_ELEMENTS_SH_TP_5_80';

    case 14:
      return 'RF_ELEMENTS_SH_TP_5_90';

    case 15:
      return 'PRISMAP_5_60';

    case 16:
      return 'PRISMAP_5_90';

    case 17:
      return 'PRISMAP_5_45';

    default:
      return 'NONE';
  }
}

class Antenna {
  model: Model;
  model_name: string;
  bw_azimuth: number;
  azi: ?RadiationPattern;
  gain: number;
  asset_model: cwn_asset.Model;

  constructor(props: $Shape<Antenna> = {}): void {
    if (!props) {
      props = {};
    }

    this.model = ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }

    this.model_name = '';
    if (props.hasOwnProperty('model_name')) {
      const v = props.model_name;
      this.model_name = v;
    }

    this.bw_azimuth = 0;
    if (props.hasOwnProperty('bw_azimuth')) {
      const v = props.bw_azimuth;
      this.bw_azimuth = v;
    }

    this.azi = null;
    if (props.hasOwnProperty('azi')) {
      const v = props.azi;
      this.azi = v && new RadiationPattern(v);
    }

    this.gain = 0;
    if (props.hasOwnProperty('gain')) {
      const v = props.gain;
      this.gain = v;
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }
  }

  getAzi(): RadiationPattern {
    if (this.azi) {
      return this.azi;
    }
    return new RadiationPattern();
  }
}
export {Antenna};

class RadiationPattern_Point {
  degrees: number;
  decibels: number;

  constructor(props: $Shape<RadiationPattern_Point> = {}): void {
    if (!props) {
      props = {};
    }

    this.degrees = 0;
    if (props.hasOwnProperty('degrees')) {
      const v = props.degrees;
      this.degrees = v;
    }

    this.decibels = 0;
    if (props.hasOwnProperty('decibels')) {
      const v = props.decibels;
      this.decibels = v;
    }
  }
}
export {RadiationPattern_Point};
class RadiationPattern {
  points: Array<RadiationPattern_Point>;

  constructor(props: $Shape<RadiationPattern> = {}): void {
    if (!props) {
      props = {};
    }

    this.points = [];
    if (props.hasOwnProperty('points')) {
      const v = props.points;
      if (!Array.isArray(v)) {
        throw new Error('repeated field points should be array');
      }
      this.points = v.map(function(v) {
        return new RadiationPattern_Point(v);
      });
    }
  }
}
export {RadiationPattern};

class Range {
  low: number;
  high: number;

  constructor(props: $Shape<Range> = {}): void {
    if (!props) {
      props = {};
    }

    this.low = 0;
    if (props.hasOwnProperty('low')) {
      const v = props.low;
      this.low = v;
    }

    this.high = 0;
    if (props.hasOwnProperty('high')) {
      const v = props.high;
      this.high = v;
    }
  }
}
export {Range};

// END cwn/antenna/models.proto
