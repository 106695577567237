// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_geocoder from '../../cwn/geocoder';

// START cwn/customers/models.proto

export const Customer_StatusValues = {
  UNKNOWN: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  DELETED: 3,
};
type Customer_Status = $Keys<typeof Customer_StatusValues>;
export type {Customer_Status};

export function Customer_StatusValue(n: number): Customer_Status {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'ACTIVE';

    case 2:
      return 'INACTIVE';

    case 3:
      return 'DELETED';

    default:
      return 'UNKNOWN';
  }
}

class Customer_RawSignupFields {
  first_name: string;
  last_name: string;
  email: string;
  address_line1: string;
  address_line2: string;
  zip: string;
  phone: string;
  building_type: string;
  number_of_units: string;
  city: string;
  state: string;

  constructor(props: $Shape<Customer_RawSignupFields> = {}): void {
    if (!props) {
      props = {};
    }

    this.first_name = '';
    if (props.hasOwnProperty('first_name')) {
      const v = props.first_name;
      this.first_name = v;
    }

    this.last_name = '';
    if (props.hasOwnProperty('last_name')) {
      const v = props.last_name;
      this.last_name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.address_line1 = '';
    if (props.hasOwnProperty('address_line1')) {
      const v = props.address_line1;
      this.address_line1 = v;
    }

    this.address_line2 = '';
    if (props.hasOwnProperty('address_line2')) {
      const v = props.address_line2;
      this.address_line2 = v;
    }

    this.zip = '';
    if (props.hasOwnProperty('zip')) {
      const v = props.zip;
      this.zip = v;
    }

    this.phone = '';
    if (props.hasOwnProperty('phone')) {
      const v = props.phone;
      this.phone = v;
    }

    this.building_type = '';
    if (props.hasOwnProperty('building_type')) {
      const v = props.building_type;
      this.building_type = v;
    }

    this.number_of_units = '';
    if (props.hasOwnProperty('number_of_units')) {
      const v = props.number_of_units;
      this.number_of_units = v;
    }

    this.city = '';
    if (props.hasOwnProperty('city')) {
      const v = props.city;
      this.city = v;
    }

    this.state = '';
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }
  }
}
export {Customer_RawSignupFields};
class Customer {
  id: string;
  created: ?Date;
  updated: ?Date;
  name: string;
  email: string;
  old_emails: Array<string>;
  crm_link: string;
  phone_number: string;
  status: Customer_Status;
  raw_signup_fields: ?Customer_RawSignupFields;
  disable_sms: boolean;
  deleted: boolean;
  disable_marketing_email: boolean;
  hubspot_contact_id: string;

  constructor(props: $Shape<Customer> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.old_emails = [];
    if (props.hasOwnProperty('old_emails')) {
      const v = props.old_emails;
      if (!Array.isArray(v)) {
        throw new Error('repeated field old_emails should be array');
      }
      this.old_emails = v.map(function(v) {
        return v;
      });
    }

    this.crm_link = '';
    if (props.hasOwnProperty('crm_link')) {
      const v = props.crm_link;
      this.crm_link = v;
    }

    this.phone_number = '';
    if (props.hasOwnProperty('phone_number')) {
      const v = props.phone_number;
      this.phone_number = v;
    }

    this.status = Customer_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.raw_signup_fields = null;
    if (props.hasOwnProperty('raw_signup_fields')) {
      const v = props.raw_signup_fields;
      this.raw_signup_fields = v && new Customer_RawSignupFields(v);
    }

    this.disable_sms = false;
    if (props.hasOwnProperty('disable_sms')) {
      const v = props.disable_sms;
      this.disable_sms = !!v;
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }

    this.disable_marketing_email = false;
    if (props.hasOwnProperty('disable_marketing_email')) {
      const v = props.disable_marketing_email;
      this.disable_marketing_email = !!v;
    }

    this.hubspot_contact_id = '0';
    if (props.hasOwnProperty('hubspot_contact_id')) {
      const v = props.hubspot_contact_id;
      this.hubspot_contact_id = v;
    }
  }

  getRawSignupFields(): Customer_RawSignupFields {
    if (this.raw_signup_fields) {
      return this.raw_signup_fields;
    }
    return new Customer_RawSignupFields();
  }
}
export {Customer};

export const Subscription_StateValues = {
  INVALID: 0,
  ACTIVE_V2: 1,
  PENDING_CANCELLATION: 2,
  CANCELLED: 3,
  DELETED: 4,
};
type Subscription_State = $Keys<typeof Subscription_StateValues>;
export type {Subscription_State};

export function Subscription_StateValue(n: number): Subscription_State {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'ACTIVE_V2';

    case 2:
      return 'PENDING_CANCELLATION';

    case 3:
      return 'CANCELLED';

    case 4:
      return 'DELETED';

    default:
      return 'INVALID';
  }
}

export const Subscription_PlanValues = {
  INVALID_PLAN: 0,
  GRANDFATHERED: 1,
  TIER_ONE_BASIC: 2,
  TIER_ONE_FASTER: 3,
  TIER_ONE_FASTEST_300: 4,
  TIER_ONE_FASTEST_140: 5,
  TIER_ONE_FASTEST_500: 6,
};
type Subscription_Plan = $Keys<typeof Subscription_PlanValues>;
export type {Subscription_Plan};

export function Subscription_PlanValue(n: number): Subscription_Plan {
  switch (n) {
    case 0:
      return 'INVALID_PLAN';

    case 1:
      return 'GRANDFATHERED';

    case 2:
      return 'TIER_ONE_BASIC';

    case 3:
      return 'TIER_ONE_FASTER';

    case 4:
      return 'TIER_ONE_FASTEST_300';

    case 5:
      return 'TIER_ONE_FASTEST_140';

    case 6:
      return 'TIER_ONE_FASTEST_500';

    default:
      return 'INVALID_PLAN';
  }
}

export const Subscription_ChurnReasonValues = {
  CHURN_INVALID: 0,
  CHURN_MOVING: 1,
  CHURN_PERFORMANCE_SPEED: 2,
  CHURN_PERFORMANCE_RELIABILITY: 3,
  CHURN_LANDLORD: 4,
  CHURN_PRODUCT: 5,
  CHURN_OTHER: 6,
};
type Subscription_ChurnReason = $Keys<typeof Subscription_ChurnReasonValues>;
export type {Subscription_ChurnReason};

export function Subscription_ChurnReasonValue(
  n: number,
): Subscription_ChurnReason {
  switch (n) {
    case 0:
      return 'CHURN_INVALID';

    case 1:
      return 'CHURN_MOVING';

    case 2:
      return 'CHURN_PERFORMANCE_SPEED';

    case 3:
      return 'CHURN_PERFORMANCE_RELIABILITY';

    case 4:
      return 'CHURN_LANDLORD';

    case 5:
      return 'CHURN_PRODUCT';

    case 6:
      return 'CHURN_OTHER';

    default:
      return 'CHURN_INVALID';
  }
}

class Subscription_PlanChange {
  old: Subscription_Plan;
  new: Subscription_Plan;
  change_date: ?Date;

  constructor(props: $Shape<Subscription_PlanChange> = {}): void {
    if (!props) {
      props = {};
    }

    this.old = Subscription_PlanValue(0);
    if (props.hasOwnProperty('old')) {
      const v = props.old;
      this.old = v;
    }

    this.new = Subscription_PlanValue(0);
    if (props.hasOwnProperty('new')) {
      const v = props.new;
      this.new = v;
    }

    this.change_date = null;
    if (props.hasOwnProperty('change_date')) {
      const v = props.change_date;
      this.change_date = v && new Date(v);
    }
  }
}
export {Subscription_PlanChange};
class Subscription {
  id: string;
  customer_id: string;
  start_date: ?Date;
  address: ?Address;
  raw_address: string;
  geocoded_location: ?cwn_geocoder.GeoPoint;
  site_id: string;
  updated: ?Date;
  state: Subscription_State;
  instrument_id: string;
  referral_token: string;
  declined_ap: boolean;
  plan: Subscription_Plan;
  plan_changes: Array<Subscription_PlanChange>;
  churn_date: ?Date;
  churn_reason: Subscription_ChurnReason;
  churn_notes: string;

  constructor(props: $Shape<Subscription> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.start_date = null;
    if (props.hasOwnProperty('start_date')) {
      const v = props.start_date;
      this.start_date = v && new Date(v);
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new Address(v);
    }

    this.raw_address = '';
    if (props.hasOwnProperty('raw_address')) {
      const v = props.raw_address;
      this.raw_address = v;
    }

    this.geocoded_location = null;
    if (props.hasOwnProperty('geocoded_location')) {
      const v = props.geocoded_location;
      this.geocoded_location = v && new cwn_geocoder.GeoPoint(v);
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.state = Subscription_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.instrument_id = '0';
    if (props.hasOwnProperty('instrument_id')) {
      const v = props.instrument_id;
      this.instrument_id = v;
    }

    this.referral_token = '';
    if (props.hasOwnProperty('referral_token')) {
      const v = props.referral_token;
      this.referral_token = v;
    }

    this.declined_ap = false;
    if (props.hasOwnProperty('declined_ap')) {
      const v = props.declined_ap;
      this.declined_ap = !!v;
    }

    this.plan = Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }

    this.plan_changes = [];
    if (props.hasOwnProperty('plan_changes')) {
      const v = props.plan_changes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field plan_changes should be array');
      }
      this.plan_changes = v.map(function(v) {
        return new Subscription_PlanChange(v);
      });
    }

    this.churn_date = null;
    if (props.hasOwnProperty('churn_date')) {
      const v = props.churn_date;
      this.churn_date = v && new Date(v);
    }

    this.churn_reason = Subscription_ChurnReasonValue(0);
    if (props.hasOwnProperty('churn_reason')) {
      const v = props.churn_reason;
      this.churn_reason = v;
    }

    this.churn_notes = '';
    if (props.hasOwnProperty('churn_notes')) {
      const v = props.churn_notes;
      this.churn_notes = v;
    }
  }

  getAddress(): Address {
    if (this.address) {
      return this.address;
    }
    return new Address();
  }

  getGeocodedLocation(): cwn_geocoder.GeoPoint {
    if (this.geocoded_location) {
      return this.geocoded_location;
    }
    return new cwn_geocoder.GeoPoint();
  }
}
export {Subscription};

class SubscriptionHistory {
  id: string;
  subscription: ?Subscription;

  constructor(props: $Shape<SubscriptionHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new Subscription(v);
    }
  }

  getSubscription(): Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new Subscription();
  }
}
export {SubscriptionHistory};

class SubscriptionActivationRequest {
  id: string;
  subscription_id: string;
  cancelled: boolean;

  constructor(props: $Shape<SubscriptionActivationRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.cancelled = false;
    if (props.hasOwnProperty('cancelled')) {
      const v = props.cancelled;
      this.cancelled = !!v;
    }
  }
}
export {SubscriptionActivationRequest};

class CustomerSubscriptionResult {
  customer: ?Customer;
  subscription: ?Subscription;

  constructor(props: $Shape<CustomerSubscriptionResult> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new Customer(v);
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new Subscription(v);
    }
  }

  getCustomer(): Customer {
    if (this.customer) {
      return this.customer;
    }
    return new Customer();
  }

  getSubscription(): Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new Subscription();
  }
}
export {CustomerSubscriptionResult};

class Address {
  street_address: string;
  route: string;
  subpremise: string;
  locality: string;
  administrativeAreaLevel2: string;
  administrativeAreaLevel1: string;
  postalCode: string;
  country: string;

  constructor(props: $Shape<Address> = {}): void {
    if (!props) {
      props = {};
    }

    this.street_address = '';
    if (props.hasOwnProperty('street_address')) {
      const v = props.street_address;
      this.street_address = v;
    }

    this.route = '';
    if (props.hasOwnProperty('route')) {
      const v = props.route;
      this.route = v;
    }

    this.subpremise = '';
    if (props.hasOwnProperty('subpremise')) {
      const v = props.subpremise;
      this.subpremise = v;
    }

    this.locality = '';
    if (props.hasOwnProperty('locality')) {
      const v = props.locality;
      this.locality = v;
    }

    this.administrativeAreaLevel2 = '';
    if (props.hasOwnProperty('administrativeAreaLevel2')) {
      const v = props.administrativeAreaLevel2;
      this.administrativeAreaLevel2 = v;
    }

    this.administrativeAreaLevel1 = '';
    if (props.hasOwnProperty('administrativeAreaLevel1')) {
      const v = props.administrativeAreaLevel1;
      this.administrativeAreaLevel1 = v;
    }

    this.postalCode = '';
    if (props.hasOwnProperty('postalCode')) {
      const v = props.postalCode;
      this.postalCode = v;
    }

    this.country = '';
    if (props.hasOwnProperty('country')) {
      const v = props.country;
      this.country = v;
    }
  }
}
export {Address};

class ProspectiveCustomer {
  id: string;
  name: string;
  email: string;
  zipcode: string;
  cross_streets: string;
  created: ?Date;

  constructor(props: $Shape<ProspectiveCustomer> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.zipcode = '';
    if (props.hasOwnProperty('zipcode')) {
      const v = props.zipcode;
      this.zipcode = v;
    }

    this.cross_streets = '';
    if (props.hasOwnProperty('cross_streets')) {
      const v = props.cross_streets;
      this.cross_streets = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }
}
export {ProspectiveCustomer};

class Comment {
  id: string;
  customer_id: string;
  created: ?Date;
  author: string;
  content: string;
  subscription_id: string;

  constructor(props: $Shape<Comment> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.author = '';
    if (props.hasOwnProperty('author')) {
      const v = props.author;
      this.author = v;
    }

    this.content = '';
    if (props.hasOwnProperty('content')) {
      const v = props.content;
      this.content = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {Comment};

class Area {
  id: string;
  name: string;
  updated: ?Date;
  gm_name: string;

  constructor(props: $Shape<Area> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.gm_name = '';
    if (props.hasOwnProperty('gm_name')) {
      const v = props.gm_name;
      this.gm_name = v;
    }
  }
}
export {Area};

class SubscriptionFeedEntry {
  id: string;
  prev: ?Subscription;
  next: ?Subscription;
  created: ?Date;

  constructor(props: $Shape<SubscriptionFeedEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.prev = null;
    if (props.hasOwnProperty('prev')) {
      const v = props.prev;
      this.prev = v && new Subscription(v);
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new Subscription(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }

  getPrev(): Subscription {
    if (this.prev) {
      return this.prev;
    }
    return new Subscription();
  }

  getNext(): Subscription {
    if (this.next) {
      return this.next;
    }
    return new Subscription();
  }
}
export {SubscriptionFeedEntry};

class PlanEligibilityOverride {
  id: string;
  site_id: string;
  eligible_plans: Array<Subscription_Plan>;
  enabled: boolean;

  constructor(props: $Shape<PlanEligibilityOverride> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.eligible_plans = [];
    if (props.hasOwnProperty('eligible_plans')) {
      const v = props.eligible_plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eligible_plans should be array');
      }
      this.eligible_plans = v.map(function(v) {
        return v;
      });
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }
  }
}
export {PlanEligibilityOverride};

class ServiceLevel {
  speed: string;
  uptime: number;

  constructor(props: $Shape<ServiceLevel> = {}): void {
    if (!props) {
      props = {};
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.uptime = 0;
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }
  }
}
export {ServiceLevel};

// END cwn/customers/models.proto
