// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/geocoder/models.proto

class GeoPoint {
  lat: number;
  lng: number;

  constructor(props: $Shape<GeoPoint> = {}): void {
    if (!props) {
      props = {};
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }
  }
}
export {GeoPoint};

// END cwn/geocoder/models.proto
