// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START google/protobuf/any.proto

class Any {
  constructor(props: any): void {
    if (props === null || typeof props !== 'object') {
      (this: any).value = props;
      return;
    }

    for (const key of Object.keys(props)) {
      (this: any)[key] = props[key];
    }
  }
}
export {Any};

// END google/protobuf/any.proto

// START google/protobuf/api.proto

class Api {
  name: string;
  methods: Array<Method>;
  options: Array<Option>;
  version: string;
  source_context: ?SourceContext;
  mixins: Array<Mixin>;
  syntax: Syntax;

  constructor(props: $Shape<Api> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.methods = [];
    if (props.hasOwnProperty('methods')) {
      const v = props.methods;
      if (!Array.isArray(v)) {
        throw new Error('repeated field methods should be array');
      }
      this.methods = v.map(function(v) {
        return new Method(v);
      });
    }

    this.options = [];
    if (props.hasOwnProperty('options')) {
      const v = props.options;
      if (!Array.isArray(v)) {
        throw new Error('repeated field options should be array');
      }
      this.options = v.map(function(v) {
        return new Option(v);
      });
    }

    this.version = '';
    if (props.hasOwnProperty('version')) {
      const v = props.version;
      this.version = v;
    }

    this.source_context = null;
    if (props.hasOwnProperty('source_context')) {
      const v = props.source_context;
      this.source_context = v && new SourceContext(v);
    }

    this.mixins = [];
    if (props.hasOwnProperty('mixins')) {
      const v = props.mixins;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mixins should be array');
      }
      this.mixins = v.map(function(v) {
        return new Mixin(v);
      });
    }

    this.syntax = SyntaxValue(0);
    if (props.hasOwnProperty('syntax')) {
      const v = props.syntax;
      this.syntax = v;
    }
  }

  getSourceContext(): SourceContext {
    if (this.source_context) {
      return this.source_context;
    }
    return new SourceContext();
  }
}
export {Api};

class Method {
  name: string;
  request_type_url: string;
  request_streaming: boolean;
  response_type_url: string;
  response_streaming: boolean;
  options: Array<Option>;
  syntax: Syntax;

  constructor(props: $Shape<Method> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.request_type_url = '';
    if (props.hasOwnProperty('request_type_url')) {
      const v = props.request_type_url;
      this.request_type_url = v;
    }

    this.request_streaming = false;
    if (props.hasOwnProperty('request_streaming')) {
      const v = props.request_streaming;
      this.request_streaming = !!v;
    }

    this.response_type_url = '';
    if (props.hasOwnProperty('response_type_url')) {
      const v = props.response_type_url;
      this.response_type_url = v;
    }

    this.response_streaming = false;
    if (props.hasOwnProperty('response_streaming')) {
      const v = props.response_streaming;
      this.response_streaming = !!v;
    }

    this.options = [];
    if (props.hasOwnProperty('options')) {
      const v = props.options;
      if (!Array.isArray(v)) {
        throw new Error('repeated field options should be array');
      }
      this.options = v.map(function(v) {
        return new Option(v);
      });
    }

    this.syntax = SyntaxValue(0);
    if (props.hasOwnProperty('syntax')) {
      const v = props.syntax;
      this.syntax = v;
    }
  }
}
export {Method};

class Mixin {
  name: string;
  root: string;

  constructor(props: $Shape<Mixin> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.root = '';
    if (props.hasOwnProperty('root')) {
      const v = props.root;
      this.root = v;
    }
  }
}
export {Mixin};

// END google/protobuf/api.proto

// START google/protobuf/duration.proto

class Duration {
  seconds: string;
  nanos: number;

  constructor(props: $Shape<Duration> = {}): void {
    if (!props) {
      props = {};
    }

    this.seconds = '0';
    if (props.hasOwnProperty('seconds')) {
      const v = props.seconds;
      this.seconds = v;
    }

    this.nanos = 0;
    if (props.hasOwnProperty('nanos')) {
      const v = props.nanos;
      this.nanos = v;
    }
  }
}
export {Duration};

// END google/protobuf/duration.proto

// START google/protobuf/empty.proto

class Empty {
  constructor(props: $Shape<Empty> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {Empty};

// END google/protobuf/empty.proto

// START google/protobuf/field_mask.proto

class FieldMask {
  paths: Array<string>;

  constructor(props: $Shape<FieldMask> = {}): void {
    if (!props) {
      props = {};
    }

    this.paths = [];
    if (props.hasOwnProperty('paths')) {
      const v = props.paths;
      if (!Array.isArray(v)) {
        throw new Error('repeated field paths should be array');
      }
      this.paths = v.map(function(v) {
        return v;
      });
    }
  }
}
export {FieldMask};

// END google/protobuf/field_mask.proto

// START google/protobuf/source_context.proto

class SourceContext {
  file_name: string;

  constructor(props: $Shape<SourceContext> = {}): void {
    if (!props) {
      props = {};
    }

    this.file_name = '';
    if (props.hasOwnProperty('file_name')) {
      const v = props.file_name;
      this.file_name = v;
    }
  }
}
export {SourceContext};

// END google/protobuf/source_context.proto

// START google/protobuf/struct.proto

export const NullValueValues = {
  NULL_VALUE: 0,
};
type NullValue = $Keys<typeof NullValueValues>;
export type {NullValue};

export function NullValueValue(n: number): NullValue {
  switch (n) {
    case 0:
      return 'NULL_VALUE';

    default:
      return 'NULL_VALUE';
  }
}

class Struct {
  fields: {[key: string]: ?Value};

  constructor(props: $Shape<Struct> = {}): void {
    if (!props) {
      props = {};
    }

    this.fields = {};
    if (props.hasOwnProperty('fields')) {
      const v = props.fields;
      for (const key: any of Object.keys(v)) {
        this.fields[key] = new Value((v[key]: any));
      }
    }
  }
}
export {Struct};

class Value {
  _null_value: ?NullValue;
  _number_value: ?number;
  _string_value: ?string;
  _bool_value: ?boolean;
  _struct_value: ?Struct;
  _list_value: ?ListValue;

  constructor(props: $Shape<Value> = {}): void {
    if (!props) {
      props = {};
    }

    this._null_value = undefined;
    if (props.hasOwnProperty('null_value')) {
      const v = props.null_value;
      this._null_value = v;
    }

    this._number_value = undefined;
    if (props.hasOwnProperty('number_value')) {
      const v = props.number_value;
      this._number_value = v;
    }

    this._string_value = undefined;
    if (props.hasOwnProperty('string_value')) {
      const v = props.string_value;
      this._string_value = v;
    }

    this._bool_value = undefined;
    if (props.hasOwnProperty('bool_value')) {
      const v = props.bool_value;
      this._bool_value = !!v;
    }

    this._struct_value = undefined;
    if (props.hasOwnProperty('struct_value')) {
      const v = props.struct_value;
      this._struct_value = v && new Struct(v);
    }

    this._list_value = undefined;
    if (props.hasOwnProperty('list_value')) {
      const v = props.list_value;
      this._list_value = v && new ListValue(v);
    }
  }

  getStructValue(): Struct {
    if (this._struct_value) {
      return this._struct_value;
    }
    return new Struct();
  }

  getListValue(): ListValue {
    if (this._list_value) {
      return this._list_value;
    }
    return new ListValue();
  }

  get null_value(): ?NullValue {
    return this._null_value;
  }
  set null_value(null_value: ?NullValue) {
    this._null_value = null_value;
    this._number_value = undefined;
    this._string_value = undefined;
    this._bool_value = undefined;
    this._struct_value = undefined;
    this._list_value = undefined;
  }
  get number_value(): ?number {
    return this._number_value;
  }
  set number_value(number_value: ?number) {
    this._null_value = undefined;
    this._number_value = number_value;
    this._string_value = undefined;
    this._bool_value = undefined;
    this._struct_value = undefined;
    this._list_value = undefined;
  }
  get string_value(): ?string {
    return this._string_value;
  }
  set string_value(string_value: ?string) {
    this._null_value = undefined;
    this._number_value = undefined;
    this._string_value = string_value;
    this._bool_value = undefined;
    this._struct_value = undefined;
    this._list_value = undefined;
  }
  get bool_value(): ?boolean {
    return this._bool_value;
  }
  set bool_value(bool_value: ?boolean) {
    this._null_value = undefined;
    this._number_value = undefined;
    this._string_value = undefined;
    this._bool_value = bool_value;
    this._struct_value = undefined;
    this._list_value = undefined;
  }
  get struct_value(): ?Struct {
    return this._struct_value;
  }
  set struct_value(struct_value: ?Struct) {
    this._null_value = undefined;
    this._number_value = undefined;
    this._string_value = undefined;
    this._bool_value = undefined;
    this._struct_value = struct_value;
    this._list_value = undefined;
  }
  get list_value(): ?ListValue {
    return this._list_value;
  }
  set list_value(list_value: ?ListValue) {
    this._null_value = undefined;
    this._number_value = undefined;
    this._string_value = undefined;
    this._bool_value = undefined;
    this._struct_value = undefined;
    this._list_value = list_value;
  }

  toJSON() {
    return {
      null_value: this._null_value,
      number_value: this._number_value,
      string_value: this._string_value,
      bool_value: this._bool_value,
      struct_value: this._struct_value,
      list_value: this._list_value,
    };
  }
}
export {Value};

class ListValue {
  values: Array<Value>;

  constructor(props: $Shape<ListValue> = {}): void {
    if (!props) {
      props = {};
    }

    this.values = [];
    if (props.hasOwnProperty('values')) {
      const v = props.values;
      if (!Array.isArray(v)) {
        throw new Error('repeated field values should be array');
      }
      this.values = v.map(function(v) {
        return new Value(v);
      });
    }
  }
}
export {ListValue};

// END google/protobuf/struct.proto

// START google/protobuf/timestamp.proto

class Timestamp {
  seconds: string;
  nanos: number;

  constructor(props: $Shape<Timestamp> = {}): void {
    if (!props) {
      props = {};
    }

    this.seconds = '0';
    if (props.hasOwnProperty('seconds')) {
      const v = props.seconds;
      this.seconds = v;
    }

    this.nanos = 0;
    if (props.hasOwnProperty('nanos')) {
      const v = props.nanos;
      this.nanos = v;
    }
  }
}
export {Timestamp};

// END google/protobuf/timestamp.proto

// START google/protobuf/type.proto

export const SyntaxValues = {
  SYNTAX_PROTO2: 0,
  SYNTAX_PROTO3: 1,
};
type Syntax = $Keys<typeof SyntaxValues>;
export type {Syntax};

export function SyntaxValue(n: number): Syntax {
  switch (n) {
    case 0:
      return 'SYNTAX_PROTO2';

    case 1:
      return 'SYNTAX_PROTO3';

    default:
      return 'SYNTAX_PROTO2';
  }
}

class Type {
  name: string;
  fields: Array<Field>;
  oneofs: Array<string>;
  options: Array<Option>;
  source_context: ?SourceContext;
  syntax: Syntax;

  constructor(props: $Shape<Type> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.fields = [];
    if (props.hasOwnProperty('fields')) {
      const v = props.fields;
      if (!Array.isArray(v)) {
        throw new Error('repeated field fields should be array');
      }
      this.fields = v.map(function(v) {
        return new Field(v);
      });
    }

    this.oneofs = [];
    if (props.hasOwnProperty('oneofs')) {
      const v = props.oneofs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field oneofs should be array');
      }
      this.oneofs = v.map(function(v) {
        return v;
      });
    }

    this.options = [];
    if (props.hasOwnProperty('options')) {
      const v = props.options;
      if (!Array.isArray(v)) {
        throw new Error('repeated field options should be array');
      }
      this.options = v.map(function(v) {
        return new Option(v);
      });
    }

    this.source_context = null;
    if (props.hasOwnProperty('source_context')) {
      const v = props.source_context;
      this.source_context = v && new SourceContext(v);
    }

    this.syntax = SyntaxValue(0);
    if (props.hasOwnProperty('syntax')) {
      const v = props.syntax;
      this.syntax = v;
    }
  }

  getSourceContext(): SourceContext {
    if (this.source_context) {
      return this.source_context;
    }
    return new SourceContext();
  }
}
export {Type};

export const Field_KindValues = {
  TYPE_UNKNOWN: 0,
  TYPE_DOUBLE: 1,
  TYPE_FLOAT: 2,
  TYPE_INT64: 3,
  TYPE_UINT64: 4,
  TYPE_INT32: 5,
  TYPE_FIXED64: 6,
  TYPE_FIXED32: 7,
  TYPE_BOOL: 8,
  TYPE_STRING: 9,
  TYPE_GROUP: 10,
  TYPE_MESSAGE: 11,
  TYPE_BYTES: 12,
  TYPE_UINT32: 13,
  TYPE_ENUM: 14,
  TYPE_SFIXED32: 15,
  TYPE_SFIXED64: 16,
  TYPE_SINT32: 17,
  TYPE_SINT64: 18,
};
type Field_Kind = $Keys<typeof Field_KindValues>;
export type {Field_Kind};

export function Field_KindValue(n: number): Field_Kind {
  switch (n) {
    case 0:
      return 'TYPE_UNKNOWN';

    case 1:
      return 'TYPE_DOUBLE';

    case 2:
      return 'TYPE_FLOAT';

    case 3:
      return 'TYPE_INT64';

    case 4:
      return 'TYPE_UINT64';

    case 5:
      return 'TYPE_INT32';

    case 6:
      return 'TYPE_FIXED64';

    case 7:
      return 'TYPE_FIXED32';

    case 8:
      return 'TYPE_BOOL';

    case 9:
      return 'TYPE_STRING';

    case 10:
      return 'TYPE_GROUP';

    case 11:
      return 'TYPE_MESSAGE';

    case 12:
      return 'TYPE_BYTES';

    case 13:
      return 'TYPE_UINT32';

    case 14:
      return 'TYPE_ENUM';

    case 15:
      return 'TYPE_SFIXED32';

    case 16:
      return 'TYPE_SFIXED64';

    case 17:
      return 'TYPE_SINT32';

    case 18:
      return 'TYPE_SINT64';

    default:
      return 'TYPE_UNKNOWN';
  }
}

export const Field_CardinalityValues = {
  CARDINALITY_UNKNOWN: 0,
  CARDINALITY_OPTIONAL: 1,
  CARDINALITY_REQUIRED: 2,
  CARDINALITY_REPEATED: 3,
};
type Field_Cardinality = $Keys<typeof Field_CardinalityValues>;
export type {Field_Cardinality};

export function Field_CardinalityValue(n: number): Field_Cardinality {
  switch (n) {
    case 0:
      return 'CARDINALITY_UNKNOWN';

    case 1:
      return 'CARDINALITY_OPTIONAL';

    case 2:
      return 'CARDINALITY_REQUIRED';

    case 3:
      return 'CARDINALITY_REPEATED';

    default:
      return 'CARDINALITY_UNKNOWN';
  }
}

class Field {
  kind: Field_Kind;
  cardinality: Field_Cardinality;
  number: number;
  name: string;
  type_url: string;
  oneof_index: number;
  packed: boolean;
  options: Array<Option>;
  json_name: string;
  default_value: string;

  constructor(props: $Shape<Field> = {}): void {
    if (!props) {
      props = {};
    }

    this.kind = Field_KindValue(0);
    if (props.hasOwnProperty('kind')) {
      const v = props.kind;
      this.kind = v;
    }

    this.cardinality = Field_CardinalityValue(0);
    if (props.hasOwnProperty('cardinality')) {
      const v = props.cardinality;
      this.cardinality = v;
    }

    this.number = 0;
    if (props.hasOwnProperty('number')) {
      const v = props.number;
      this.number = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.type_url = '';
    if (props.hasOwnProperty('type_url')) {
      const v = props.type_url;
      this.type_url = v;
    }

    this.oneof_index = 0;
    if (props.hasOwnProperty('oneof_index')) {
      const v = props.oneof_index;
      this.oneof_index = v;
    }

    this.packed = false;
    if (props.hasOwnProperty('packed')) {
      const v = props.packed;
      this.packed = !!v;
    }

    this.options = [];
    if (props.hasOwnProperty('options')) {
      const v = props.options;
      if (!Array.isArray(v)) {
        throw new Error('repeated field options should be array');
      }
      this.options = v.map(function(v) {
        return new Option(v);
      });
    }

    this.json_name = '';
    if (props.hasOwnProperty('json_name')) {
      const v = props.json_name;
      this.json_name = v;
    }

    this.default_value = '';
    if (props.hasOwnProperty('default_value')) {
      const v = props.default_value;
      this.default_value = v;
    }
  }
}
export {Field};

class Enum {
  name: string;
  enumvalue: Array<EnumValue>;
  options: Array<Option>;
  source_context: ?SourceContext;
  syntax: Syntax;

  constructor(props: $Shape<Enum> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.enumvalue = [];
    if (props.hasOwnProperty('enumvalue')) {
      const v = props.enumvalue;
      if (!Array.isArray(v)) {
        throw new Error('repeated field enumvalue should be array');
      }
      this.enumvalue = v.map(function(v) {
        return new EnumValue(v);
      });
    }

    this.options = [];
    if (props.hasOwnProperty('options')) {
      const v = props.options;
      if (!Array.isArray(v)) {
        throw new Error('repeated field options should be array');
      }
      this.options = v.map(function(v) {
        return new Option(v);
      });
    }

    this.source_context = null;
    if (props.hasOwnProperty('source_context')) {
      const v = props.source_context;
      this.source_context = v && new SourceContext(v);
    }

    this.syntax = SyntaxValue(0);
    if (props.hasOwnProperty('syntax')) {
      const v = props.syntax;
      this.syntax = v;
    }
  }

  getSourceContext(): SourceContext {
    if (this.source_context) {
      return this.source_context;
    }
    return new SourceContext();
  }
}
export {Enum};

class EnumValue {
  name: string;
  number: number;
  options: Array<Option>;

  constructor(props: $Shape<EnumValue> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.number = 0;
    if (props.hasOwnProperty('number')) {
      const v = props.number;
      this.number = v;
    }

    this.options = [];
    if (props.hasOwnProperty('options')) {
      const v = props.options;
      if (!Array.isArray(v)) {
        throw new Error('repeated field options should be array');
      }
      this.options = v.map(function(v) {
        return new Option(v);
      });
    }
  }
}
export {EnumValue};

class Option {
  name: string;
  value: ?Any;

  constructor(props: $Shape<Option> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.value = null;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v && new Any(v);
    }
  }

  getValue(): Any {
    if (this.value) {
      return this.value;
    }
    return new Any();
  }
}
export {Option};

// END google/protobuf/type.proto

// START google/protobuf/wrappers.proto

class DoubleValue {
  value: number;

  constructor(props: $Shape<DoubleValue> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = 0;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {DoubleValue};

class FloatValue {
  value: number;

  constructor(props: $Shape<FloatValue> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = 0;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {FloatValue};

class Int64Value {
  value: string;

  constructor(props: $Shape<Int64Value> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = '0';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {Int64Value};

class UInt64Value {
  value: string;

  constructor(props: $Shape<UInt64Value> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = '0';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {UInt64Value};

class Int32Value {
  value: number;

  constructor(props: $Shape<Int32Value> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = 0;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {Int32Value};

class UInt32Value {
  value: number;

  constructor(props: $Shape<UInt32Value> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = 0;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {UInt32Value};

class BoolValue {
  value: boolean;

  constructor(props: $Shape<BoolValue> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = false;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = !!v;
    }
  }
}
export {BoolValue};

class StringValue {
  value: string;

  constructor(props: $Shape<StringValue> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = '';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {StringValue};

class BytesValue {
  value: string;

  constructor(props: $Shape<BytesValue> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = '';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {BytesValue};

// END google/protobuf/wrappers.proto
