// @flow

// https://developers.google.com/maps/documentation/javascript/geocoding
export type GeocoderStatus =
  | 'OK'
  | 'ZERO_RESULTS'
  | 'OVER_QUERY_LIMIT'
  | 'REQUEST_DENIED'
  | 'INVALID_REQUEST'
  | 'UNKNOWN_ERROR'
  | 'ERROR';

export type GeocoderLocationType =
  | 'ROOFTOP'
  | 'RANGE_INTERPOLATED'
  | 'GEOMETRIC_CENTER'
  | 'APPROXIMATE';

export type AddressType =
  | 'street_address'
  | 'route'
  | 'intersection'
  | 'political'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'colloquial_area'
  | 'locality'
  | 'ward'
  | 'sublocality'
  | 'neighborhood'
  | 'premise'
  | 'subpremise'
  | 'postal_code'
  | 'natural_feature'
  | 'airport'
  | 'park'
  | 'point_of_interest';

export type AddressComponentType =
  | AddressType
  | 'floor'
  | 'establishment'
  | 'point_of_interest'
  | 'parking'
  | 'post_box'
  | 'postal_town'
  | 'room'
  | 'street_number'
  | 'bus_station';

// https://developers.google.com/maps/documentation/javascript/reference/3/geocoder#GeocoderComponentRestrictions
export type FilterableComponents =
  | 'postalCode'
  | 'country'
  | 'route'
  | 'locality'
  | 'administrativeArea';

export type LatLng = {|lat: () => number, lng: () => number|};

export type Bounds = {|
  south: number,
  west: number,
  north: number,
  east: number,
|};

export type AddressComponentFilters = {[FilterableComponents]: string};

export type GeocodeParams = {|
  address?: string,
  componentRestrictions?: AddressComponentFilters,
  bounds?: Bounds,
  region?: string,
  language?: string,
|};

export type AddressComponent = {|
  short_name: string,
  long_name: string,
  postcode_localities: string[],
  types: AddressComponentType[],
|};

export type GeocoderResult = {|
  types: AddressType[],
  formatted_address: string,
  address_components: AddressComponent[],
  partial_match: boolean,
  place_id: string,
  postcode_localities: string[],
  geometry: {
    location: LatLng,
    location_type: GeocoderLocationType,
    viewport: Bounds,
    bounds: Bounds,
  },
|};

export type GeocoderResponse = {|
  status: GeocoderStatus,
  results: GeocoderResult[],
|};

// https://googlemaps.github.io/google-maps-services-js/docs/index.html
export type ClientResponse<Payload> = {|
  status: number,
  headers: Object,
  json: Payload,
|};

export type ResponseCallback<Payload> = (
  'timeout' | Error | void,
  ClientResponse<Payload>,
) => void;

export type RequestHandle<Payload> = {|
  asPromise(): Promise<ClientResponse<Payload>>,
  cancel(): void,
  finally(ResponseCallback<Payload>): RequestHandle<Payload>,
|};

export type Client = {
  geocode(
    GeocodeParams,
    ?ResponseCallback<GeocoderResponse>,
  ): RequestHandle<GeocoderResponse>,
};

export default class GoogleMapsAPI {
  async geocode(params: GeocodeParams): Promise<GeocoderResult[]> {
    const geocoder = new (window.google: any).maps.Geocoder();
    const {status, results} = await new Promise(resolve =>
      geocoder.geocode(params, (results, status, ...args) =>
        resolve({status, results}),
      ),
    );

    switch (status) {
      case 'OK':
      case 'ZERO_RESULTS':
        return results;
      case 'OVER_QUERY_LIMIT':
      case 'REQUEST_DENIED':
      case 'INVALID_REQUEST':
      case 'UNKNOWN_ERROR':
      case 'ERROR':
      default:
        throw new Error(`Google geocode failed with status: ${status}`);
    }
  }
}
