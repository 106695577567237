// @flow
import React, {type Node} from 'react';
import {useLoadScript} from '@react-google-maps/api';
import {CircularProgress} from 'ui';

export const MAPS_API_URL = 'https://maps.googleapis.com/maps/api/js';
export const MAPS_API_KEY = 'AIzaSyCJ_FYnnsw_HHX3CtM8XiefmXg0LzQe3Lg';
export const MAPS_API_VERSION = 'weekly';
export const GOOGLE_MAP_URL = `${MAPS_API_URL}?v=${MAPS_API_VERSION}&key=${MAPS_API_KEY}`;

function GoogleMapsProvider({children}: {children: Node}) {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: MAPS_API_KEY,
    version: MAPS_API_VERSION,
    libraries: ['geometry'],
  });

  if (loadError) {
    console.error(loadError);
  }

  if (!isLoaded) {
    return <CircularProgress />;
  }

  return React.Children.only(children);
}

export default GoogleMapsProvider;
