// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/devices/detect/models.proto

export const PlatformValues = {
  UNKNOWN: 0,
  NUC: 1,
  ZBOX: 2,
  LIVA_Z: 3,
  EP_R6: 4,
  SUPER_SERVER: 5,
  COR: 6,
  COR2: 7,
  PUMA: 8,
  E300: 9,
  NBE5ACGEN2: 10,
};
type Platform = $Keys<typeof PlatformValues>;
export type {Platform};

export function PlatformValue(n: number): Platform {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'NUC';

    case 2:
      return 'ZBOX';

    case 3:
      return 'LIVA_Z';

    case 4:
      return 'EP_R6';

    case 5:
      return 'SUPER_SERVER';

    case 6:
      return 'COR';

    case 7:
      return 'COR2';

    case 8:
      return 'PUMA';

    case 9:
      return 'E300';

    case 10:
      return 'NBE5ACGEN2';

    default:
      return 'UNKNOWN';
  }
}

// END cwn/devices/detect/models.proto
