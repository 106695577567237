// @flow
import {type LatLngLiteral, type PointLiteral} from 'google/maps/GoogleMap';

// projects a lat/lng to an x/y
export function mercatorProject(
  latLng: LatLngLiteral,
  // viewport
  height: number,
  width: number,
  northeast: LatLngLiteral,
  southwest: LatLngLiteral,
): PointLiteral {
  const viewportMinLatRad = (southwest.lat * Math.PI) / 180;
  const latRad = (latLng.lat * Math.PI) / 180;
  const mapLngDelta = northeast.lng - southwest.lng;
  const worldMapWidth = ((width / mapLngDelta) * 360) / (2 * Math.PI);
  const mapOffsetY =
    (worldMapWidth / 2) *
    Math.log(
      (1 + Math.sin(viewportMinLatRad)) / (1 - Math.sin(viewportMinLatRad)),
    );
  const x = (latLng.lng - southwest.lng) * (width / mapLngDelta);
  const y =
    height -
    ((worldMapWidth / 2) *
      Math.log((1 + Math.sin(latRad)) / (1 - Math.sin(latRad))) -
      mapOffsetY);

  return {x, y};
}
