// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */

// START cwn/site/hw/models.proto

export const Equipment_DeviceValues = {
  UNKNOWN_DEVICE: 0,
  LIVA: 1,
  NUC: 2,
  ZBOX: 3,
  COR_1A: 4,
  ONE_U: 5,
  E300_8D: 6,
  EPR6: 7,
  NBE5ACGEN2: 8,
  PEREGRINE: 9,
};
type Equipment_Device = $Keys<typeof Equipment_DeviceValues>;
export type {Equipment_Device};

export function Equipment_DeviceValue(n: number): Equipment_Device {
  switch (n) {
    case 0:
      return 'UNKNOWN_DEVICE';

    case 1:
      return 'LIVA';

    case 2:
      return 'NUC';

    case 3:
      return 'ZBOX';

    case 4:
      return 'COR_1A';

    case 5:
      return 'ONE_U';

    case 6:
      return 'E300_8D';

    case 7:
      return 'EPR6';

    case 8:
      return 'NBE5ACGEN2';

    case 9:
      return 'PEREGRINE';

    default:
      return 'UNKNOWN_DEVICE';
  }
}

export const Equipment_SwitchValues = {
  UNKNOWN_SWITCH: 0,
  EP_R6: 1,
  EP_S16: 2,
  TS5POE: 3,
  JGS516PE: 4,
  JGS524PE: 5,
};
type Equipment_Switch = $Keys<typeof Equipment_SwitchValues>;
export type {Equipment_Switch};

export function Equipment_SwitchValue(n: number): Equipment_Switch {
  switch (n) {
    case 0:
      return 'UNKNOWN_SWITCH';

    case 1:
      return 'EP_R6';

    case 2:
      return 'EP_S16';

    case 3:
      return 'TS5POE';

    case 4:
      return 'JGS516PE';

    case 5:
      return 'JGS524PE';

    default:
      return 'UNKNOWN_SWITCH';
  }
}

export const Equipment_BuildingPowerValues = {
  UNKNOWN_BUILDINGPOWER: 0,
  INDOOR_AC: 1,
  OUTDOOR_AC: 2,
  INDOOR_AC_TO_OUTDOOR_DC: 3,
};
type Equipment_BuildingPower = $Keys<typeof Equipment_BuildingPowerValues>;
export type {Equipment_BuildingPower};

export function Equipment_BuildingPowerValue(
  n: number,
): Equipment_BuildingPower {
  switch (n) {
    case 0:
      return 'UNKNOWN_BUILDINGPOWER';

    case 1:
      return 'INDOOR_AC';

    case 2:
      return 'OUTDOOR_AC';

    case 3:
      return 'INDOOR_AC_TO_OUTDOOR_DC';

    default:
      return 'UNKNOWN_BUILDINGPOWER';
  }
}

export const Equipment_EquipmentBoxLocationValues = {
  UNKNOWN_LOCATION: 0,
  IN_UNIT: 1,
  INDOOR_SHARED_SPACE: 2,
  OUTDOOR_NEMA: 3,
  NO_BOX: 4,
};
type Equipment_EquipmentBoxLocation = $Keys<
  typeof Equipment_EquipmentBoxLocationValues,
>;
export type {Equipment_EquipmentBoxLocation};

export function Equipment_EquipmentBoxLocationValue(
  n: number,
): Equipment_EquipmentBoxLocation {
  switch (n) {
    case 0:
      return 'UNKNOWN_LOCATION';

    case 1:
      return 'IN_UNIT';

    case 2:
      return 'INDOOR_SHARED_SPACE';

    case 3:
      return 'OUTDOOR_NEMA';

    case 4:
      return 'NO_BOX';

    default:
      return 'UNKNOWN_LOCATION';
  }
}

class Equipment {
  site_id: string;
  created: ?Date;
  updated: ?Date;
  device: Equipment_Device;
  switches: Array<Equipment_Switch>;
  building_power: Equipment_BuildingPower;
  notes: string;
  equipment_box_location: Equipment_EquipmentBoxLocation;

  constructor(props: $Shape<Equipment> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.device = Equipment_DeviceValue(0);
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v;
    }

    this.switches = [];
    if (props.hasOwnProperty('switches')) {
      const v = props.switches;
      if (!Array.isArray(v)) {
        throw new Error('repeated field switches should be array');
      }
      this.switches = v.map(function(v) {
        return v;
      });
    }

    this.building_power = Equipment_BuildingPowerValue(0);
    if (props.hasOwnProperty('building_power')) {
      const v = props.building_power;
      this.building_power = v;
    }

    this.notes = '';
    if (props.hasOwnProperty('notes')) {
      const v = props.notes;
      this.notes = v;
    }

    this.equipment_box_location = Equipment_EquipmentBoxLocationValue(0);
    if (props.hasOwnProperty('equipment_box_location')) {
      const v = props.equipment_box_location;
      this.equipment_box_location = v;
    }
  }
}
export {Equipment};

// END cwn/site/hw/models.proto
