// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/rpc/error.proto

export const RPCError_CodeValues = {
  UNKNOWN: 0,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  UNSUPPORTED_MEDIA_TYPE: 415,
  INTERNAL_SERVER_ERROR: 500,
};
type RPCError_Code = $Keys<typeof RPCError_CodeValues>;
export type {RPCError_Code};

export function RPCError_CodeValue(n: number): RPCError_Code {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 400:
      return 'BAD_REQUEST';

    case 401:
      return 'UNAUTHORIZED';

    case 404:
      return 'NOT_FOUND';

    case 405:
      return 'METHOD_NOT_ALLOWED';

    case 406:
      return 'NOT_ACCEPTABLE';

    case 415:
      return 'UNSUPPORTED_MEDIA_TYPE';

    case 500:
      return 'INTERNAL_SERVER_ERROR';

    default:
      return 'UNKNOWN';
  }
}

class RPCError {
  code: RPCError_Code;
  message: string;
  server_trace_id: string;
  client_trace_id: string;

  constructor(props: $Shape<RPCError> = {}): void {
    if (!props) {
      props = {};
    }

    this.code = RPCError_CodeValue(0);
    if (props.hasOwnProperty('code')) {
      const v = props.code;
      this.code = v;
    }

    this.message = '';
    if (props.hasOwnProperty('message')) {
      const v = props.message;
      this.message = v;
    }

    this.server_trace_id = '';
    if (props.hasOwnProperty('server_trace_id')) {
      const v = props.server_trace_id;
      this.server_trace_id = v;
    }

    this.client_trace_id = '';
    if (props.hasOwnProperty('client_trace_id')) {
      const v = props.client_trace_id;
      this.client_trace_id = v;
    }
  }
}
export {RPCError};

// END cwn/rpc/error.proto

// START cwn/rpc/handler_test.proto

class CallRequest {
  value: string;

  constructor(props: $Shape<CallRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = '';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {CallRequest};

class CallReply {
  value: string;

  constructor(props: $Shape<CallReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = '';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {CallReply};

class TestService {
  baseURL: string;
  additionalHeaders: Array<Array<string>>;
  headersProvider: ?() => Array<Array<string>>;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.additionalHeaders = [];
  }

  async call<Req, Res>(
    method: string,
    req: Req,
    ResClass: Class<Res>,
  ): Promise<Res> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.additionalHeaders.forEach(function(h) {
      headers.append(h[0], h[1]);
    });

    if (this.headersProvider) {
      this.headersProvider().forEach(h => {
        headers.append(h[0], h[1]);
      });
    }

    const credentials =
      process.env.NODE_ENV === 'production' ? 'same-origin' : 'include';

    let res: Response;
    let resText: string;
    try {
      res = await fetch(this.baseURL + '/cwn.rpc.TestService/' + method, {
        credentials,
        method: 'POST',
        headers: headers,
        body: JSON.stringify(req),
      });
      resText = await res.text();
    } catch (e) {
      if (e instanceof TypeError) {
        throw new Error(
          'Could not connect to server (reason: ' +
            e.message +
            ') Check your Internet connection and try again.',
        );
      } else {
        throw e;
      }
    }

    if (res.ok) {
      try {
        const json = JSON.parse(resText);
        // https://flow.org/en/docs/types/utilities/#toc-class
        // $FlowFixMe this will work in a future version of flow
        return new ResClass(json);
      } catch (e) {
        throw new Error(
          'Could not parse response for ' +
            method +
            ': ' +
            e.message +
            ' payload: ' +
            resText,
        );
      }
    }

    let errMessage: string;
    try {
      const errJSON = JSON.parse(resText);
      if (errJSON.code && errJSON.message) {
        // RPCError
        errMessage = errJSON.code + ': ' + errJSON.message;
      } else {
        errMessage = JSON.stringify(errJSON, null, 2);
      }
    } catch (e) {
      errMessage = resText;
    }
    throw new Error(
      'Request for ' +
        method +
        ' failed: ' +
        res.status +
        ' ' +
        res.statusText +
        ' ' +
        errMessage,
    );
  }

  addHeader(name: string, value: string) {
    this.additionalHeaders.push([name, value]);
  }

  async Call(req: CallRequest): Promise<CallReply> {
    return await this.call('Call', req, CallReply);
  }
}
export {TestService};

// END cwn/rpc/handler_test.proto

// START cwn/rpc/models.proto

class Empty {
  constructor(props: $Shape<Empty> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {Empty};

class Id {
  id: string;

  constructor(props: $Shape<Id> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {Id};

class StringId {
  id: string;

  constructor(props: $Shape<StringId> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {StringId};

class Email {
  email: string;

  constructor(props: $Shape<Email> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {Email};

// END cwn/rpc/models.proto
