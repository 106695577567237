// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_geocoder from '../../cwn/geocoder';

// START cwn/search/models.proto

export const ContentTypeValues = {
  TEXT: 0,
  HTML: 1,
  ATOM: 2,
  DATE: 3,
  NUMBER: 4,
  GEO: 5,
};
type ContentType = $Keys<typeof ContentTypeValues>;
export type {ContentType};

export function ContentTypeValue(n: number): ContentType {
  switch (n) {
    case 0:
      return 'TEXT';

    case 1:
      return 'HTML';

    case 2:
      return 'ATOM';

    case 3:
      return 'DATE';

    case 4:
      return 'NUMBER';

    case 5:
      return 'GEO';

    default:
      return 'TEXT';
  }
}

class Field {
  name: string;
  language: string;
  _text_value: ?string;
  _html_value: ?string;
  _atom_value: ?string;
  _timestamp: ?Date;
  _number_value: ?number;
  _geo: ?cwn_geocoder.GeoPoint;

  constructor(props: $Shape<Field> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.language = '';
    if (props.hasOwnProperty('language')) {
      const v = props.language;
      this.language = v;
    }

    this._text_value = undefined;
    if (props.hasOwnProperty('text_value')) {
      const v = props.text_value;
      this._text_value = v;
    }

    this._html_value = undefined;
    if (props.hasOwnProperty('html_value')) {
      const v = props.html_value;
      this._html_value = v;
    }

    this._atom_value = undefined;
    if (props.hasOwnProperty('atom_value')) {
      const v = props.atom_value;
      this._atom_value = v;
    }

    this._timestamp = undefined;
    if (props.hasOwnProperty('timestamp')) {
      const v = props.timestamp;
      this._timestamp = v && new Date(v);
    }

    this._number_value = undefined;
    if (props.hasOwnProperty('number_value')) {
      const v = props.number_value;
      this._number_value = v;
    }

    this._geo = undefined;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this._geo = v && new cwn_geocoder.GeoPoint(v);
    }
  }

  getGeo(): cwn_geocoder.GeoPoint {
    if (this._geo) {
      return this._geo;
    }
    return new cwn_geocoder.GeoPoint();
  }

  get text_value(): ?string {
    return this._text_value;
  }
  set text_value(text_value: ?string) {
    this._text_value = text_value;
    this._html_value = undefined;
    this._atom_value = undefined;
    this._timestamp = undefined;
    this._number_value = undefined;
    this._geo = undefined;
  }
  get html_value(): ?string {
    return this._html_value;
  }
  set html_value(html_value: ?string) {
    this._text_value = undefined;
    this._html_value = html_value;
    this._atom_value = undefined;
    this._timestamp = undefined;
    this._number_value = undefined;
    this._geo = undefined;
  }
  get atom_value(): ?string {
    return this._atom_value;
  }
  set atom_value(atom_value: ?string) {
    this._text_value = undefined;
    this._html_value = undefined;
    this._atom_value = atom_value;
    this._timestamp = undefined;
    this._number_value = undefined;
    this._geo = undefined;
  }
  get timestamp(): ?Date {
    return this._timestamp;
  }
  set timestamp(timestamp: ?Date) {
    this._text_value = undefined;
    this._html_value = undefined;
    this._atom_value = undefined;
    this._timestamp = timestamp;
    this._number_value = undefined;
    this._geo = undefined;
  }
  get number_value(): ?number {
    return this._number_value;
  }
  set number_value(number_value: ?number) {
    this._text_value = undefined;
    this._html_value = undefined;
    this._atom_value = undefined;
    this._timestamp = undefined;
    this._number_value = number_value;
    this._geo = undefined;
  }
  get geo(): ?cwn_geocoder.GeoPoint {
    return this._geo;
  }
  set geo(geo: ?cwn_geocoder.GeoPoint) {
    this._text_value = undefined;
    this._html_value = undefined;
    this._atom_value = undefined;
    this._timestamp = undefined;
    this._number_value = undefined;
    this._geo = geo;
  }

  toJSON() {
    return {
      name: this.name,
      language: this.language,
      text_value: this._text_value,
      html_value: this._html_value,
      atom_value: this._atom_value,
      timestamp: this._timestamp,
      number_value: this._number_value,
      geo: this._geo,
    };
  }
}
export {Field};

class Facet {
  name: string;
  _atom_value: ?string;
  _number_value: ?number;

  constructor(props: $Shape<Facet> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this._atom_value = undefined;
    if (props.hasOwnProperty('atom_value')) {
      const v = props.atom_value;
      this._atom_value = v;
    }

    this._number_value = undefined;
    if (props.hasOwnProperty('number_value')) {
      const v = props.number_value;
      this._number_value = v;
    }
  }

  get atom_value(): ?string {
    return this._atom_value;
  }
  set atom_value(atom_value: ?string) {
    this._atom_value = atom_value;
    this._number_value = undefined;
  }
  get number_value(): ?number {
    return this._number_value;
  }
  set number_value(number_value: ?number) {
    this._atom_value = undefined;
    this._number_value = number_value;
  }

  toJSON() {
    return {
      name: this.name,
      atom_value: this._atom_value,
      number_value: this._number_value,
    };
  }
}
export {Facet};

class Document {
  id: string;
  fields: Array<Field>;
  rank: number;
  facets: Array<Facet>;
  proto_message: string;

  constructor(props: $Shape<Document> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.fields = [];
    if (props.hasOwnProperty('fields')) {
      const v = props.fields;
      if (!Array.isArray(v)) {
        throw new Error('repeated field fields should be array');
      }
      this.fields = v.map(function(v) {
        return new Field(v);
      });
    }

    this.rank = 0;
    if (props.hasOwnProperty('rank')) {
      const v = props.rank;
      this.rank = v;
    }

    this.facets = [];
    if (props.hasOwnProperty('facets')) {
      const v = props.facets;
      if (!Array.isArray(v)) {
        throw new Error('repeated field facets should be array');
      }
      this.facets = v.map(function(v) {
        return new Facet(v);
      });
    }

    this.proto_message = '';
    if (props.hasOwnProperty('proto_message')) {
      const v = props.proto_message;
      this.proto_message = v;
    }
  }
}
export {Document};

class Query_Refinement_Range {
  start: number;
  end: number;

  constructor(props: $Shape<Query_Refinement_Range> = {}): void {
    if (!props) {
      props = {};
    }

    this.start = 0;
    if (props.hasOwnProperty('start')) {
      const v = props.start;
      this.start = v;
    }

    this.end = 0;
    if (props.hasOwnProperty('end')) {
      const v = props.end;
      this.end = v;
    }
  }
}
export {Query_Refinement_Range};
class Query_Refinement {
  name: string;
  _atom_value: ?string;
  _range: ?Query_Refinement_Range;

  constructor(props: $Shape<Query_Refinement> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this._atom_value = undefined;
    if (props.hasOwnProperty('atom_value')) {
      const v = props.atom_value;
      this._atom_value = v;
    }

    this._range = undefined;
    if (props.hasOwnProperty('range')) {
      const v = props.range;
      this._range = v && new Query_Refinement_Range(v);
    }
  }

  getRange(): Query_Refinement_Range {
    if (this._range) {
      return this._range;
    }
    return new Query_Refinement_Range();
  }

  get atom_value(): ?string {
    return this._atom_value;
  }
  set atom_value(atom_value: ?string) {
    this._atom_value = atom_value;
    this._range = undefined;
  }
  get range(): ?Query_Refinement_Range {
    return this._range;
  }
  set range(range: ?Query_Refinement_Range) {
    this._atom_value = undefined;
    this._range = range;
  }

  toJSON() {
    return {
      name: this.name,
      atom_value: this._atom_value,
      range: this._range,
    };
  }
}
export {Query_Refinement};
export const Query_SortOptions_OrderValues = {
  ASC: 0,
  DESC: 1,
};
type Query_SortOptions_Order = $Keys<typeof Query_SortOptions_OrderValues>;
export type {Query_SortOptions_Order};

export function Query_SortOptions_OrderValue(
  n: number,
): Query_SortOptions_Order {
  switch (n) {
    case 0:
      return 'ASC';

    case 1:
      return 'DESC';

    default:
      return 'ASC';
  }
}

class Query_SortOptions_SortExpression {
  expr: string;
  order: Query_SortOptions_Order;

  constructor(props: $Shape<Query_SortOptions_SortExpression> = {}): void {
    if (!props) {
      props = {};
    }

    this.expr = '';
    if (props.hasOwnProperty('expr')) {
      const v = props.expr;
      this.expr = v;
    }

    this.order = Query_SortOptions_OrderValue(0);
    if (props.hasOwnProperty('order')) {
      const v = props.order;
      this.order = v;
    }
  }
}
export {Query_SortOptions_SortExpression};
class Query_SortOptions {
  expressions: Array<Query_SortOptions_SortExpression>;
  limit: number;

  constructor(props: $Shape<Query_SortOptions> = {}): void {
    if (!props) {
      props = {};
    }

    this.expressions = [];
    if (props.hasOwnProperty('expressions')) {
      const v = props.expressions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field expressions should be array');
      }
      this.expressions = v.map(function(v) {
        return new Query_SortOptions_SortExpression(v);
      });
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {Query_SortOptions};
class Query {
  query: string;
  refinements: Array<Query_Refinement>;
  sort_options: ?Query_SortOptions;
  limit: number;
  cursor: string;

  constructor(props: $Shape<Query> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = '';
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v;
    }

    this.refinements = [];
    if (props.hasOwnProperty('refinements')) {
      const v = props.refinements;
      if (!Array.isArray(v)) {
        throw new Error('repeated field refinements should be array');
      }
      this.refinements = v.map(function(v) {
        return new Query_Refinement(v);
      });
    }

    this.sort_options = null;
    if (props.hasOwnProperty('sort_options')) {
      const v = props.sort_options;
      this.sort_options = v && new Query_SortOptions(v);
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }

  getSortOptions(): Query_SortOptions {
    if (this.sort_options) {
      return this.sort_options;
    }
    return new Query_SortOptions();
  }
}
export {Query};

// END cwn/search/models.proto
