// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

import * as cwn_site from '../../../cwn/site';
import * as cwn_radios from '../../../cwn/radios';
import * as cwn_devices_detect from '../../../cwn/devices/detect';
import * as cwn_bridges from '../../../cwn/bridges';
import * as cwn_asset from '../../../cwn/asset';

// START cwn/site/build/models.proto

class Build {
  site: ?LocalSite;
  device: ?LocalDevice;
  hans: Array<LocalHAN>;
  switches: Array<LocalSwitch>;
  mounts: Array<LocalMount>;

  constructor(props: $Shape<Build> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new LocalSite(v);
    }

    this.device = null;
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v && new LocalDevice(v);
    }

    this.hans = [];
    if (props.hasOwnProperty('hans')) {
      const v = props.hans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field hans should be array');
      }
      this.hans = v.map(function(v) {
        return new LocalHAN(v);
      });
    }

    this.switches = [];
    if (props.hasOwnProperty('switches')) {
      const v = props.switches;
      if (!Array.isArray(v)) {
        throw new Error('repeated field switches should be array');
      }
      this.switches = v.map(function(v) {
        return new LocalSwitch(v);
      });
    }

    this.mounts = [];
    if (props.hasOwnProperty('mounts')) {
      const v = props.mounts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mounts should be array');
      }
      this.mounts = v.map(function(v) {
        return new LocalMount(v);
      });
    }
  }

  getSite(): LocalSite {
    if (this.site) {
      return this.site;
    }
    return new LocalSite();
  }

  getDevice(): LocalDevice {
    if (this.device) {
      return this.device;
    }
    return new LocalDevice();
  }
}
export {Build};

class LocalSite {
  details: ?SiteDetails;

  constructor(props: $Shape<LocalSite> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new SiteDetails(v);
    }
  }

  getDetails(): SiteDetails {
    if (this.details) {
      return this.details;
    }
    return new SiteDetails();
  }
}
export {LocalSite};

class TargetSite {
  details: ?SiteDetails;

  constructor(props: $Shape<TargetSite> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new SiteDetails(v);
    }
  }

  getDetails(): SiteDetails {
    if (this.details) {
      return this.details;
    }
    return new SiteDetails();
  }
}
export {TargetSite};

class SiteDetails {
  id: string;
  node_type: cwn_site.Site_NodeType;
  status: ?cwn_site.StatusV2;
  device_id: string;

  constructor(props: $Shape<SiteDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.node_type = cwn_site.Site_NodeTypeValue(0);
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new cwn_site.StatusV2(v);
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }

  getStatus(): cwn_site.StatusV2 {
    if (this.status) {
      return this.status;
    }
    return new cwn_site.StatusV2();
  }
}
export {SiteDetails};

class LocalDevice {
  details: ?DeviceDetails;

  constructor(props: $Shape<LocalDevice> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new DeviceDetails(v);
    }
  }

  getDetails(): DeviceDetails {
    if (this.details) {
      return this.details;
    }
    return new DeviceDetails();
  }
}
export {LocalDevice};

class TargetDevice {
  details: ?DeviceDetails;

  constructor(props: $Shape<TargetDevice> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new DeviceDetails(v);
    }
  }

  getDetails(): DeviceDetails {
    if (this.details) {
      return this.details;
    }
    return new DeviceDetails();
  }
}
export {TargetDevice};

class DeviceDetails {
  id: string;
  profile: ?DeviceProfileDetails;
  bridge_statuses: Array<BridgeStatusDetails>;
  speed: number;
  uptime: number;

  constructor(props: $Shape<DeviceDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.profile = null;
    if (props.hasOwnProperty('profile')) {
      const v = props.profile;
      this.profile = v && new DeviceProfileDetails(v);
    }

    this.bridge_statuses = [];
    if (props.hasOwnProperty('bridge_statuses')) {
      const v = props.bridge_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field bridge_statuses should be array');
      }
      this.bridge_statuses = v.map(function(v) {
        return new BridgeStatusDetails(v);
      });
    }

    this.speed = 0;
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.uptime = 0;
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }
  }

  getProfile(): DeviceProfileDetails {
    if (this.profile) {
      return this.profile;
    }
    return new DeviceProfileDetails();
  }
}
export {DeviceDetails};

class DeviceProfileDetails {
  platform: cwn_devices_detect.Platform;

  constructor(props: $Shape<DeviceProfileDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.platform = cwn_devices_detect.PlatformValue(0);
    if (props.hasOwnProperty('platform')) {
      const v = props.platform;
      this.platform = v;
    }
  }
}
export {DeviceProfileDetails};

class BridgeStatusDetails {
  port_statuses: Array<PortStatusDetails>;

  constructor(props: $Shape<BridgeStatusDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.port_statuses = [];
    if (props.hasOwnProperty('port_statuses')) {
      const v = props.port_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field port_statuses should be array');
      }
      this.port_statuses = v.map(function(v) {
        return new PortStatusDetails(v);
      });
    }
  }
}
export {BridgeStatusDetails};

class PortStatusDetails {
  interface_name: string;
  link_state: boolean;
  poe_op_mode: cwn_bridges.PortStatus_PoeOpMode;

  constructor(props: $Shape<PortStatusDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.link_state = false;
    if (props.hasOwnProperty('link_state')) {
      const v = props.link_state;
      this.link_state = !!v;
    }

    this.poe_op_mode = cwn_bridges.PortStatus_PoeOpModeValue(0);
    if (props.hasOwnProperty('poe_op_mode')) {
      const v = props.poe_op_mode;
      this.poe_op_mode = v;
    }
  }
}
export {PortStatusDetails};

class LocalHAN {
  details: ?HANDetails;

  constructor(props: $Shape<LocalHAN> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new HANDetails(v);
    }
  }

  getDetails(): HANDetails {
    if (this.details) {
      return this.details;
    }
    return new HANDetails();
  }
}
export {LocalHAN};

class HANDetails {
  constructor(props: $Shape<HANDetails> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {HANDetails};

class LocalSwitch {
  details: ?SwitchDetails;

  constructor(props: $Shape<LocalSwitch> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new SwitchDetails(v);
    }
  }

  getDetails(): SwitchDetails {
    if (this.details) {
      return this.details;
    }
    return new SwitchDetails();
  }
}
export {LocalSwitch};

class SwitchDetails {
  constructor(props: $Shape<SwitchDetails> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {SwitchDetails};

class LocalMount {
  details: ?MountDetails;
  radios: Array<LocalRadio>;
  shots: Array<LocalMountShot>;

  constructor(props: $Shape<LocalMount> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new MountDetails(v);
    }

    this.radios = [];
    if (props.hasOwnProperty('radios')) {
      const v = props.radios;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radios should be array');
      }
      this.radios = v.map(function(v) {
        return new LocalRadio(v);
      });
    }

    this.shots = [];
    if (props.hasOwnProperty('shots')) {
      const v = props.shots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field shots should be array');
      }
      this.shots = v.map(function(v) {
        return new LocalMountShot(v);
      });
    }
  }

  getDetails(): MountDetails {
    if (this.details) {
      return this.details;
    }
    return new MountDetails();
  }
}
export {LocalMount};

class TargetMount {
  details: ?MountDetails;
  radios: Array<TargetMountRadio>;
  site: ?TargetSite;
  device: ?TargetDevice;

  constructor(props: $Shape<TargetMount> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new MountDetails(v);
    }

    this.radios = [];
    if (props.hasOwnProperty('radios')) {
      const v = props.radios;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radios should be array');
      }
      this.radios = v.map(function(v) {
        return new TargetMountRadio(v);
      });
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new TargetSite(v);
    }

    this.device = null;
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v && new TargetDevice(v);
    }
  }

  getDetails(): MountDetails {
    if (this.details) {
      return this.details;
    }
    return new MountDetails();
  }

  getSite(): TargetSite {
    if (this.site) {
      return this.site;
    }
    return new TargetSite();
  }

  getDevice(): TargetDevice {
    if (this.device) {
      return this.device;
    }
    return new TargetDevice();
  }
}
export {TargetMount};

class MountDetails {
  id: string;
  site_id: string;
  lat: number;
  lng: number;
  height_in_meters: number;
  elevation_in_meters: number;

  constructor(props: $Shape<MountDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }

    this.height_in_meters = 0;
    if (props.hasOwnProperty('height_in_meters')) {
      const v = props.height_in_meters;
      this.height_in_meters = v;
    }

    this.elevation_in_meters = 0;
    if (props.hasOwnProperty('elevation_in_meters')) {
      const v = props.elevation_in_meters;
      this.elevation_in_meters = v;
    }
  }
}
export {MountDetails};

class LocalRadio {
  details: ?RadioDetails;
  targets: Array<TargetRadio>;

  constructor(props: $Shape<LocalRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new RadioDetails(v);
    }

    this.targets = [];
    if (props.hasOwnProperty('targets')) {
      const v = props.targets;
      if (!Array.isArray(v)) {
        throw new Error('repeated field targets should be array');
      }
      this.targets = v.map(function(v) {
        return new TargetRadio(v);
      });
    }
  }

  getDetails(): RadioDetails {
    if (this.details) {
      return this.details;
    }
    return new RadioDetails();
  }
}
export {LocalRadio};

class TargetRadio {
  details: ?RadioDetails;
  mount: ?TargetMount;

  constructor(props: $Shape<TargetRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new RadioDetails(v);
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new TargetMount(v);
    }
  }

  getDetails(): RadioDetails {
    if (this.details) {
      return this.details;
    }
    return new RadioDetails();
  }

  getMount(): TargetMount {
    if (this.mount) {
      return this.mount;
    }
    return new TargetMount();
  }
}
export {TargetRadio};

class TargetMountRadio {
  details: ?RadioDetails;
  targets: Array<TargetMountRadioTarget>;

  constructor(props: $Shape<TargetMountRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.details = null;
    if (props.hasOwnProperty('details')) {
      const v = props.details;
      this.details = v && new RadioDetails(v);
    }

    this.targets = [];
    if (props.hasOwnProperty('targets')) {
      const v = props.targets;
      if (!Array.isArray(v)) {
        throw new Error('repeated field targets should be array');
      }
      this.targets = v.map(function(v) {
        return new TargetMountRadioTarget(v);
      });
    }
  }

  getDetails(): RadioDetails {
    if (this.details) {
      return this.details;
    }
    return new RadioDetails();
  }
}
export {TargetMountRadio};

class TargetMountRadioTarget {
  site: ?SiteDetails;

  constructor(props: $Shape<TargetMountRadioTarget> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new SiteDetails(v);
    }
  }

  getSite(): SiteDetails {
    if (this.site) {
      return this.site;
    }
    return new SiteDetails();
  }
}
export {TargetMountRadioTarget};

class RadioDetails {
  srl: ?SRLDetails;

  constructor(props: $Shape<RadioDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl = null;
    if (props.hasOwnProperty('srl')) {
      const v = props.srl;
      this.srl = v && new SRLDetails(v);
    }
  }

  getSrl(): SRLDetails {
    if (this.srl) {
      return this.srl;
    }
    return new SRLDetails();
  }
}
export {RadioDetails};

class SRLDetails {
  id: string;
  mac_address: string;
  site_id: string;
  mount_id: string;
  asset_model: cwn_asset.Model;
  heading: string;
  tx_power_dbm: number;
  mode: cwn_radios.Mode;
  antenna: ?AntennaDetails;
  radio_group: ?RadioGroupDetails;

  constructor(props: $Shape<SRLDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.heading = '0';
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }

    this.tx_power_dbm = 0;
    if (props.hasOwnProperty('tx_power_dbm')) {
      const v = props.tx_power_dbm;
      this.tx_power_dbm = v;
    }

    this.mode = cwn_radios.ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.antenna = null;
    if (props.hasOwnProperty('antenna')) {
      const v = props.antenna;
      this.antenna = v && new AntennaDetails(v);
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new RadioGroupDetails(v);
    }
  }

  getAntenna(): AntennaDetails {
    if (this.antenna) {
      return this.antenna;
    }
    return new AntennaDetails();
  }

  getRadioGroup(): RadioGroupDetails {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new RadioGroupDetails();
  }
}
export {SRLDetails};

class AntennaDetails {
  bw_azimuth: number;
  asset_model: cwn_asset.Model;
  gain: number;

  constructor(props: $Shape<AntennaDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.bw_azimuth = 0;
    if (props.hasOwnProperty('bw_azimuth')) {
      const v = props.bw_azimuth;
      this.bw_azimuth = v;
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.gain = 0;
    if (props.hasOwnProperty('gain')) {
      const v = props.gain;
      this.gain = v;
    }
  }
}
export {AntennaDetails};

class RadioGroupDetails {
  id: string;

  constructor(props: $Shape<RadioGroupDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {RadioGroupDetails};

class LocalMountShot {
  target_mount_id: string;
  has_override: boolean;
  override: ?ShotOverrideDetails;

  constructor(props: $Shape<LocalMountShot> = {}): void {
    if (!props) {
      props = {};
    }

    this.target_mount_id = '0';
    if (props.hasOwnProperty('target_mount_id')) {
      const v = props.target_mount_id;
      this.target_mount_id = v;
    }

    this.has_override = false;
    if (props.hasOwnProperty('has_override')) {
      const v = props.has_override;
      this.has_override = !!v;
    }

    this.override = null;
    if (props.hasOwnProperty('override')) {
      const v = props.override;
      this.override = v && new ShotOverrideDetails(v);
    }
  }

  getOverride(): ShotOverrideDetails {
    if (this.override) {
      return this.override;
    }
    return new ShotOverrideDetails();
  }
}
export {LocalMountShot};

class ShotOverrideDetails {
  has_line_of_sight: boolean;

  constructor(props: $Shape<ShotOverrideDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.has_line_of_sight = false;
    if (props.hasOwnProperty('has_line_of_sight')) {
      const v = props.has_line_of_sight;
      this.has_line_of_sight = !!v;
    }
  }
}
export {ShotOverrideDetails};

class RequiredWork {
  add_device: ?AddDevice;
  add_radio: ?AddRadio;
  remove_radio: ?RemoveRadio;
  repoint_radio: ?RepointRadio;
  move_mount: ?MoveMount;
  add_mount_without_radios: ?AddMountWithoutRadios;
  add_mount_with_radios: ?AddMountWithRadios;

  constructor(props: $Shape<RequiredWork> = {}): void {
    if (!props) {
      props = {};
    }

    this.add_device = null;
    if (props.hasOwnProperty('add_device')) {
      const v = props.add_device;
      this.add_device = v && new AddDevice(v);
    }

    this.add_radio = null;
    if (props.hasOwnProperty('add_radio')) {
      const v = props.add_radio;
      this.add_radio = v && new AddRadio(v);
    }

    this.remove_radio = null;
    if (props.hasOwnProperty('remove_radio')) {
      const v = props.remove_radio;
      this.remove_radio = v && new RemoveRadio(v);
    }

    this.repoint_radio = null;
    if (props.hasOwnProperty('repoint_radio')) {
      const v = props.repoint_radio;
      this.repoint_radio = v && new RepointRadio(v);
    }

    this.move_mount = null;
    if (props.hasOwnProperty('move_mount')) {
      const v = props.move_mount;
      this.move_mount = v && new MoveMount(v);
    }

    this.add_mount_without_radios = null;
    if (props.hasOwnProperty('add_mount_without_radios')) {
      const v = props.add_mount_without_radios;
      this.add_mount_without_radios = v && new AddMountWithoutRadios(v);
    }

    this.add_mount_with_radios = null;
    if (props.hasOwnProperty('add_mount_with_radios')) {
      const v = props.add_mount_with_radios;
      this.add_mount_with_radios = v && new AddMountWithRadios(v);
    }
  }

  getAddDevice(): AddDevice {
    if (this.add_device) {
      return this.add_device;
    }
    return new AddDevice();
  }

  getAddRadio(): AddRadio {
    if (this.add_radio) {
      return this.add_radio;
    }
    return new AddRadio();
  }

  getRemoveRadio(): RemoveRadio {
    if (this.remove_radio) {
      return this.remove_radio;
    }
    return new RemoveRadio();
  }

  getRepointRadio(): RepointRadio {
    if (this.repoint_radio) {
      return this.repoint_radio;
    }
    return new RepointRadio();
  }

  getMoveMount(): MoveMount {
    if (this.move_mount) {
      return this.move_mount;
    }
    return new MoveMount();
  }

  getAddMountWithoutRadios(): AddMountWithoutRadios {
    if (this.add_mount_without_radios) {
      return this.add_mount_without_radios;
    }
    return new AddMountWithoutRadios();
  }

  getAddMountWithRadios(): AddMountWithRadios {
    if (this.add_mount_with_radios) {
      return this.add_mount_with_radios;
    }
    return new AddMountWithRadios();
  }
}
export {RequiredWork};

class AddDevice {
  device: ?LocalDevice;

  constructor(props: $Shape<AddDevice> = {}): void {
    if (!props) {
      props = {};
    }

    this.device = null;
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v && new LocalDevice(v);
    }
  }

  getDevice(): LocalDevice {
    if (this.device) {
      return this.device;
    }
    return new LocalDevice();
  }
}
export {AddDevice};

class AddRadio {
  mount_id: string;
  radio: ?LocalRadio;

  constructor(props: $Shape<AddRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.radio = null;
    if (props.hasOwnProperty('radio')) {
      const v = props.radio;
      this.radio = v && new LocalRadio(v);
    }
  }

  getRadio(): LocalRadio {
    if (this.radio) {
      return this.radio;
    }
    return new LocalRadio();
  }
}
export {AddRadio};

class RemoveRadio {
  radio_srl_id: string;

  constructor(props: $Shape<RemoveRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_srl_id = '0';
    if (props.hasOwnProperty('radio_srl_id')) {
      const v = props.radio_srl_id;
      this.radio_srl_id = v;
    }
  }
}
export {RemoveRadio};

class RepointRadio {
  radio_srl_id: string;
  heading: string;

  constructor(props: $Shape<RepointRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_srl_id = '0';
    if (props.hasOwnProperty('radio_srl_id')) {
      const v = props.radio_srl_id;
      this.radio_srl_id = v;
    }

    this.heading = '0';
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }
  }
}
export {RepointRadio};

class MoveMount {
  mount_id: string;
  lat: number;
  lng: number;
  elevation_in_meters: number;

  constructor(props: $Shape<MoveMount> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }

    this.elevation_in_meters = 0;
    if (props.hasOwnProperty('elevation_in_meters')) {
      const v = props.elevation_in_meters;
      this.elevation_in_meters = v;
    }
  }
}
export {MoveMount};

class AddMountWithoutRadios {
  mount: ?LocalMount;

  constructor(props: $Shape<AddMountWithoutRadios> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new LocalMount(v);
    }
  }

  getMount(): LocalMount {
    if (this.mount) {
      return this.mount;
    }
    return new LocalMount();
  }
}
export {AddMountWithoutRadios};

class AddMountWithRadios {
  mount: ?LocalMount;

  constructor(props: $Shape<AddMountWithRadios> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new LocalMount(v);
    }
  }

  getMount(): LocalMount {
    if (this.mount) {
      return this.mount;
    }
    return new LocalMount();
  }
}
export {AddMountWithRadios};

// END cwn/site/build/models.proto
