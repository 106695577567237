// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_tc from '../../cwn/tc';

// START cwn/han/models.proto

export const UnifiControllerValues = {
  DEFAULT: 0,
  OFFICE: 1,
  UNIVERSAL1: 3,
};
type UnifiController = $Keys<typeof UnifiControllerValues>;
export type {UnifiController};

export function UnifiControllerValue(n: number): UnifiController {
  switch (n) {
    case 0:
      return 'DEFAULT';

    case 1:
      return 'OFFICE';

    case 3:
      return 'UNIVERSAL1';

    default:
      return 'DEFAULT';
  }
}

class Han {
  id: string;
  subscription_id: string;
  ap_macs: Array<string>;
  updated: ?Date;
  wlans: Array<Wlan>;
  controller: string;
  prefix: string;
  interface_name: string;
  site_id: string;
  qdisc: ?cwn_tc.Qdisc;
  declined_ap: boolean;
  declined_ap_mac: string;
  interface_is_stale: boolean;
  eero_serial_numbers: Array<string>;
  eero_macs: Array<string>;
  eero_network_url: string;
  eero_wlans: Array<Wlan>;

  constructor(props: $Shape<Han> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.ap_macs = [];
    if (props.hasOwnProperty('ap_macs')) {
      const v = props.ap_macs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ap_macs should be array');
      }
      this.ap_macs = v.map(function(v) {
        return v;
      });
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new Wlan(v);
      });
    }

    this.controller = '';
    if (props.hasOwnProperty('controller')) {
      const v = props.controller;
      this.controller = v;
    }

    this.prefix = '0';
    if (props.hasOwnProperty('prefix')) {
      const v = props.prefix;
      this.prefix = v;
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.qdisc = null;
    if (props.hasOwnProperty('qdisc')) {
      const v = props.qdisc;
      this.qdisc = v && new cwn_tc.Qdisc(v);
    }

    this.declined_ap = false;
    if (props.hasOwnProperty('declined_ap')) {
      const v = props.declined_ap;
      this.declined_ap = !!v;
    }

    this.declined_ap_mac = '';
    if (props.hasOwnProperty('declined_ap_mac')) {
      const v = props.declined_ap_mac;
      this.declined_ap_mac = v;
    }

    this.interface_is_stale = false;
    if (props.hasOwnProperty('interface_is_stale')) {
      const v = props.interface_is_stale;
      this.interface_is_stale = !!v;
    }

    this.eero_serial_numbers = [];
    if (props.hasOwnProperty('eero_serial_numbers')) {
      const v = props.eero_serial_numbers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eero_serial_numbers should be array');
      }
      this.eero_serial_numbers = v.map(function(v) {
        return v;
      });
    }

    this.eero_macs = [];
    if (props.hasOwnProperty('eero_macs')) {
      const v = props.eero_macs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eero_macs should be array');
      }
      this.eero_macs = v.map(function(v) {
        return v;
      });
    }

    this.eero_network_url = '';
    if (props.hasOwnProperty('eero_network_url')) {
      const v = props.eero_network_url;
      this.eero_network_url = v;
    }

    this.eero_wlans = [];
    if (props.hasOwnProperty('eero_wlans')) {
      const v = props.eero_wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eero_wlans should be array');
      }
      this.eero_wlans = v.map(function(v) {
        return new Wlan(v);
      });
    }
  }

  getQdisc(): cwn_tc.Qdisc {
    if (this.qdisc) {
      return this.qdisc;
    }
    return new cwn_tc.Qdisc();
  }
}
export {Han};

class HanHistory {
  created: ?Date;
  han: ?Han;
  is_deleted: boolean;

  constructor(props: $Shape<HanHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.han = null;
    if (props.hasOwnProperty('han')) {
      const v = props.han;
      this.han = v && new Han(v);
    }

    this.is_deleted = false;
    if (props.hasOwnProperty('is_deleted')) {
      const v = props.is_deleted;
      this.is_deleted = !!v;
    }
  }

  getHan(): Han {
    if (this.han) {
      return this.han;
    }
    return new Han();
  }
}
export {HanHistory};

export const Wlan_ModeValues = {
  DEFAULT: 0,
  TWO_G_ONLY: 1,
  FIVE_G_ONLY: 2,
};
type Wlan_Mode = $Keys<typeof Wlan_ModeValues>;
export type {Wlan_Mode};

export function Wlan_ModeValue(n: number): Wlan_Mode {
  switch (n) {
    case 0:
      return 'DEFAULT';

    case 1:
      return 'TWO_G_ONLY';

    case 2:
      return 'FIVE_G_ONLY';

    default:
      return 'DEFAULT';
  }
}

class Wlan {
  essid: string;
  passphrase: string;
  mode: Wlan_Mode;
  enabled: boolean;
  guest: boolean;

  constructor(props: $Shape<Wlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.essid = '';
    if (props.hasOwnProperty('essid')) {
      const v = props.essid;
      this.essid = v;
    }

    this.passphrase = '';
    if (props.hasOwnProperty('passphrase')) {
      const v = props.passphrase;
      this.passphrase = v;
    }

    this.mode = Wlan_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.guest = false;
    if (props.hasOwnProperty('guest')) {
      const v = props.guest;
      this.guest = !!v;
    }
  }
}
export {Wlan};

class SiteHans {
  site_id: string;
  hans: Array<Han>;

  constructor(props: $Shape<SiteHans> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.hans = [];
    if (props.hasOwnProperty('hans')) {
      const v = props.hans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field hans should be array');
      }
      this.hans = v.map(function(v) {
        return new Han(v);
      });
    }
  }
}
export {SiteHans};

class PrefixCounter {
  area_name: string;
  next: string;

  constructor(props: $Shape<PrefixCounter> = {}): void {
    if (!props) {
      props = {};
    }

    this.area_name = '';
    if (props.hasOwnProperty('area_name')) {
      const v = props.area_name;
      this.area_name = v;
    }

    this.next = '0';
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v;
    }
  }
}
export {PrefixCounter};

class SiteUnifiController {
  site_id: string;
  created: ?Date;
  updated: ?Date;
  controller: UnifiController;

  constructor(props: $Shape<SiteUnifiController> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.controller = UnifiControllerValue(0);
    if (props.hasOwnProperty('controller')) {
      const v = props.controller;
      this.controller = v;
    }
  }
}
export {SiteUnifiController};

// END cwn/han/models.proto
