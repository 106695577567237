// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/proc/models.proto

class Info {
  fd_usage: number;
  fd_limit: number;
  bin_name: string;
  pid: number;
  bin_md5_sum: string;

  constructor(props: $Shape<Info> = {}): void {
    if (!props) {
      props = {};
    }

    this.fd_usage = 0;
    if (props.hasOwnProperty('fd_usage')) {
      const v = props.fd_usage;
      this.fd_usage = v;
    }

    this.fd_limit = 0;
    if (props.hasOwnProperty('fd_limit')) {
      const v = props.fd_limit;
      this.fd_limit = v;
    }

    this.bin_name = '';
    if (props.hasOwnProperty('bin_name')) {
      const v = props.bin_name;
      this.bin_name = v;
    }

    this.pid = 0;
    if (props.hasOwnProperty('pid')) {
      const v = props.pid;
      this.pid = v;
    }

    this.bin_md5_sum = '';
    if (props.hasOwnProperty('bin_md5_sum')) {
      const v = props.bin_md5_sum;
      this.bin_md5_sum = v;
    }
  }
}
export {Info};

class NetDevLine_Counter {
  bytes: string;
  packets: string;
  errs: string;
  drop: string;
  fifo: string;
  frame: string;
  compressed: string;
  multicast: string;

  constructor(props: $Shape<NetDevLine_Counter> = {}): void {
    if (!props) {
      props = {};
    }

    this.bytes = '0';
    if (props.hasOwnProperty('bytes')) {
      const v = props.bytes;
      this.bytes = v;
    }

    this.packets = '0';
    if (props.hasOwnProperty('packets')) {
      const v = props.packets;
      this.packets = v;
    }

    this.errs = '0';
    if (props.hasOwnProperty('errs')) {
      const v = props.errs;
      this.errs = v;
    }

    this.drop = '0';
    if (props.hasOwnProperty('drop')) {
      const v = props.drop;
      this.drop = v;
    }

    this.fifo = '0';
    if (props.hasOwnProperty('fifo')) {
      const v = props.fifo;
      this.fifo = v;
    }

    this.frame = '0';
    if (props.hasOwnProperty('frame')) {
      const v = props.frame;
      this.frame = v;
    }

    this.compressed = '0';
    if (props.hasOwnProperty('compressed')) {
      const v = props.compressed;
      this.compressed = v;
    }

    this.multicast = '0';
    if (props.hasOwnProperty('multicast')) {
      const v = props.multicast;
      this.multicast = v;
    }
  }
}
export {NetDevLine_Counter};
class NetDevLine {
  interface_name: string;
  rx_counter: ?NetDevLine_Counter;
  tx_counter: ?NetDevLine_Counter;

  constructor(props: $Shape<NetDevLine> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.rx_counter = null;
    if (props.hasOwnProperty('rx_counter')) {
      const v = props.rx_counter;
      this.rx_counter = v && new NetDevLine_Counter(v);
    }

    this.tx_counter = null;
    if (props.hasOwnProperty('tx_counter')) {
      const v = props.tx_counter;
      this.tx_counter = v && new NetDevLine_Counter(v);
    }
  }

  getRxCounter(): NetDevLine_Counter {
    if (this.rx_counter) {
      return this.rx_counter;
    }
    return new NetDevLine_Counter();
  }

  getTxCounter(): NetDevLine_Counter {
    if (this.tx_counter) {
      return this.tx_counter;
    }
    return new NetDevLine_Counter();
  }
}
export {NetDevLine};

class LoadAvg {
  load_60s: number;
  load_300s: number;
  load_600s: number;
  active_procs: string;
  total_procs: string;

  constructor(props: $Shape<LoadAvg> = {}): void {
    if (!props) {
      props = {};
    }

    this.load_60s = 0;
    if (props.hasOwnProperty('load_60s')) {
      const v = props.load_60s;
      this.load_60s = v;
    }

    this.load_300s = 0;
    if (props.hasOwnProperty('load_300s')) {
      const v = props.load_300s;
      this.load_300s = v;
    }

    this.load_600s = 0;
    if (props.hasOwnProperty('load_600s')) {
      const v = props.load_600s;
      this.load_600s = v;
    }

    this.active_procs = '0';
    if (props.hasOwnProperty('active_procs')) {
      const v = props.active_procs;
      this.active_procs = v;
    }

    this.total_procs = '0';
    if (props.hasOwnProperty('total_procs')) {
      const v = props.total_procs;
      this.total_procs = v;
    }
  }
}
export {LoadAvg};

class MemInfo {
  free_kb: string;
  total_kb: string;
  available_kb: string;
  cached_kb: string;
  buffers_kb: string;

  constructor(props: $Shape<MemInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.free_kb = '0';
    if (props.hasOwnProperty('free_kb')) {
      const v = props.free_kb;
      this.free_kb = v;
    }

    this.total_kb = '0';
    if (props.hasOwnProperty('total_kb')) {
      const v = props.total_kb;
      this.total_kb = v;
    }

    this.available_kb = '0';
    if (props.hasOwnProperty('available_kb')) {
      const v = props.available_kb;
      this.available_kb = v;
    }

    this.cached_kb = '0';
    if (props.hasOwnProperty('cached_kb')) {
      const v = props.cached_kb;
      this.cached_kb = v;
    }

    this.buffers_kb = '0';
    if (props.hasOwnProperty('buffers_kb')) {
      const v = props.buffers_kb;
      this.buffers_kb = v;
    }
  }
}
export {MemInfo};

class MmcInfo {
  FwVersion: string;
  UsedLifeEstimateA: number;
  UsedLifeEstimateB: number;
  CSDRaw: string;
  CIDRaw: string;

  constructor(props: $Shape<MmcInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.FwVersion = '';
    if (props.hasOwnProperty('FwVersion')) {
      const v = props.FwVersion;
      this.FwVersion = v;
    }

    this.UsedLifeEstimateA = 0;
    if (props.hasOwnProperty('UsedLifeEstimateA')) {
      const v = props.UsedLifeEstimateA;
      this.UsedLifeEstimateA = v;
    }

    this.UsedLifeEstimateB = 0;
    if (props.hasOwnProperty('UsedLifeEstimateB')) {
      const v = props.UsedLifeEstimateB;
      this.UsedLifeEstimateB = v;
    }

    this.CSDRaw = '';
    if (props.hasOwnProperty('CSDRaw')) {
      const v = props.CSDRaw;
      this.CSDRaw = v;
    }

    this.CIDRaw = '';
    if (props.hasOwnProperty('CIDRaw')) {
      const v = props.CIDRaw;
      this.CIDRaw = v;
    }
  }
}
export {MmcInfo};

export const Modules_Module_StateValues = {
  INVAILD: 0,
  LIVE: 1,
  LOADING: 2,
  UNLOADING: 3,
};
type Modules_Module_State = $Keys<typeof Modules_Module_StateValues>;
export type {Modules_Module_State};

export function Modules_Module_StateValue(n: number): Modules_Module_State {
  switch (n) {
    case 0:
      return 'INVAILD';

    case 1:
      return 'LIVE';

    case 2:
      return 'LOADING';

    case 3:
      return 'UNLOADING';

    default:
      return 'INVAILD';
  }
}

class Modules_Module {
  name: string;
  size: string;
  instances: string;
  users: Array<string>;
  state: Modules_Module_State;
  offset: string;
  taint: string;

  constructor(props: $Shape<Modules_Module> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.size = '0';
    if (props.hasOwnProperty('size')) {
      const v = props.size;
      this.size = v;
    }

    this.instances = '0';
    if (props.hasOwnProperty('instances')) {
      const v = props.instances;
      this.instances = v;
    }

    this.users = [];
    if (props.hasOwnProperty('users')) {
      const v = props.users;
      if (!Array.isArray(v)) {
        throw new Error('repeated field users should be array');
      }
      this.users = v.map(function(v) {
        return v;
      });
    }

    this.state = Modules_Module_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.offset = '';
    if (props.hasOwnProperty('offset')) {
      const v = props.offset;
      this.offset = v;
    }

    this.taint = '';
    if (props.hasOwnProperty('taint')) {
      const v = props.taint;
      this.taint = v;
    }
  }
}
export {Modules_Module};
class Modules {
  modules: Array<Modules_Module>;

  constructor(props: $Shape<Modules> = {}): void {
    if (!props) {
      props = {};
    }

    this.modules = [];
    if (props.hasOwnProperty('modules')) {
      const v = props.modules;
      if (!Array.isArray(v)) {
        throw new Error('repeated field modules should be array');
      }
      this.modules = v.map(function(v) {
        return new Modules_Module(v);
      });
    }
  }
}
export {Modules};

class Stat_Cpu {
  name: string;
  user: string;
  nice: string;
  system: string;
  idle: string;
  iowait: string;
  irq: string;
  softirq: string;
  steal: string;
  guest: string;
  guest_nice: string;

  constructor(props: $Shape<Stat_Cpu> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.user = '0';
    if (props.hasOwnProperty('user')) {
      const v = props.user;
      this.user = v;
    }

    this.nice = '0';
    if (props.hasOwnProperty('nice')) {
      const v = props.nice;
      this.nice = v;
    }

    this.system = '0';
    if (props.hasOwnProperty('system')) {
      const v = props.system;
      this.system = v;
    }

    this.idle = '0';
    if (props.hasOwnProperty('idle')) {
      const v = props.idle;
      this.idle = v;
    }

    this.iowait = '0';
    if (props.hasOwnProperty('iowait')) {
      const v = props.iowait;
      this.iowait = v;
    }

    this.irq = '0';
    if (props.hasOwnProperty('irq')) {
      const v = props.irq;
      this.irq = v;
    }

    this.softirq = '0';
    if (props.hasOwnProperty('softirq')) {
      const v = props.softirq;
      this.softirq = v;
    }

    this.steal = '0';
    if (props.hasOwnProperty('steal')) {
      const v = props.steal;
      this.steal = v;
    }

    this.guest = '0';
    if (props.hasOwnProperty('guest')) {
      const v = props.guest;
      this.guest = v;
    }

    this.guest_nice = '0';
    if (props.hasOwnProperty('guest_nice')) {
      const v = props.guest_nice;
      this.guest_nice = v;
    }
  }
}
export {Stat_Cpu};
class Stat {
  cpus: Array<Stat_Cpu>;

  constructor(props: $Shape<Stat> = {}): void {
    if (!props) {
      props = {};
    }

    this.cpus = [];
    if (props.hasOwnProperty('cpus')) {
      const v = props.cpus;
      if (!Array.isArray(v)) {
        throw new Error('repeated field cpus should be array');
      }
      this.cpus = v.map(function(v) {
        return new Stat_Cpu(v);
      });
    }
  }
}
export {Stat};

class UptimeInfo {
  uptime: number;
  idle: number;

  constructor(props: $Shape<UptimeInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.uptime = 0;
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.idle = 0;
    if (props.hasOwnProperty('idle')) {
      const v = props.idle;
      this.idle = v;
    }
  }
}
export {UptimeInfo};

// END cwn/proc/models.proto
