// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_log from '../../cwn/log';
import * as cwn_devices_detect from '../../cwn/devices/detect';

// START cwn/devices/models.proto

class Device {
  id: string;
  created: ?Date;
  site_id: string;
  updated: ?Date;

  constructor(props: $Shape<Device> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }
}
export {Device};

class DeviceProfile {
  id: string;
  created: ?Date;
  device_id: string;
  cpu_id: string;
  interfaces: Array<Interface>;
  machine_hardware_name: string;
  platform: cwn_devices_detect.Platform;
  serial_number: string;
  manifest: string;

  constructor(props: $Shape<DeviceProfile> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.cpu_id = '';
    if (props.hasOwnProperty('cpu_id')) {
      const v = props.cpu_id;
      this.cpu_id = v;
    }

    this.interfaces = [];
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      if (!Array.isArray(v)) {
        throw new Error('repeated field interfaces should be array');
      }
      this.interfaces = v.map(function(v) {
        return new Interface(v);
      });
    }

    this.machine_hardware_name = '';
    if (props.hasOwnProperty('machine_hardware_name')) {
      const v = props.machine_hardware_name;
      this.machine_hardware_name = v;
    }

    this.platform = cwn_devices_detect.PlatformValue(0);
    if (props.hasOwnProperty('platform')) {
      const v = props.platform;
      this.platform = v;
    }

    this.serial_number = '';
    if (props.hasOwnProperty('serial_number')) {
      const v = props.serial_number;
      this.serial_number = v;
    }

    this.manifest = '';
    if (props.hasOwnProperty('manifest')) {
      const v = props.manifest;
      this.manifest = v;
    }
  }
}
export {DeviceProfile};

class Interface {
  name: string;
  hardware_address: string;

  constructor(props: $Shape<Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.hardware_address = '';
    if (props.hasOwnProperty('hardware_address')) {
      const v = props.hardware_address;
      this.hardware_address = v;
    }
  }
}
export {Interface};

class AvailabilityHistory_Downtime {
  duration: number;
  start: ?Date;
  end: ?Date;
  device_id: string;

  constructor(props: $Shape<AvailabilityHistory_Downtime> = {}): void {
    if (!props) {
      props = {};
    }

    this.duration = 0;
    if (props.hasOwnProperty('duration')) {
      const v = props.duration;
      this.duration = v;
    }

    this.start = null;
    if (props.hasOwnProperty('start')) {
      const v = props.start;
      this.start = v && new Date(v);
    }

    this.end = null;
    if (props.hasOwnProperty('end')) {
      const v = props.end;
      this.end = v && new Date(v);
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {AvailabilityHistory_Downtime};
class AvailabilityHistory {
  device_id: string;
  downtimes: Array<AvailabilityHistory_Downtime>;
  updated: ?Date;
  restarts: Array<Date>;

  constructor(props: $Shape<AvailabilityHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.downtimes = [];
    if (props.hasOwnProperty('downtimes')) {
      const v = props.downtimes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field downtimes should be array');
      }
      this.downtimes = v.map(function(v) {
        return new AvailabilityHistory_Downtime(v);
      });
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.restarts = [];
    if (props.hasOwnProperty('restarts')) {
      const v = props.restarts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field restarts should be array');
      }
      this.restarts = v.map(function(v) {
        return new Date(v);
      });
    }
  }
}
export {AvailabilityHistory};

class AvailabilitySummary {
  created: ?Date;
  availability_histories: Array<AvailabilityHistory>;

  constructor(props: $Shape<AvailabilitySummary> = {}): void {
    if (!props) {
      props = {};
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.availability_histories = [];
    if (props.hasOwnProperty('availability_histories')) {
      const v = props.availability_histories;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field availability_histories should be array',
        );
      }
      this.availability_histories = v.map(function(v) {
        return new AvailabilityHistory(v);
      });
    }
  }
}
export {AvailabilitySummary};

class DeviceLogLevel {
  device_id: string;
  severity: cwn_log.Severity;

  constructor(props: $Shape<DeviceLogLevel> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.severity = cwn_log.SeverityValue(0);
    if (props.hasOwnProperty('severity')) {
      const v = props.severity;
      this.severity = v;
    }
  }
}
export {DeviceLogLevel};

// END cwn/devices/models.proto
