// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_user from '../../cwn/user';
import * as cwn_kml from '../../cwn/kml';

// START cwn/geo/models.proto

export const Mount_TypeValues = {
  INVALID: 0,
  TRIPOD: 1,
  J_ARM: 2,
  LIGHT_DUTY_SLED_MOUNT: 3,
  HEAVY_DUTY_SLED_MOUNT: 4,
  CHIMNEY_MOUNT_WITH_STRAPS: 5,
  WALL_MOUNT: 6,
  GABLE_MOUNT: 7,
  POLE_TO_POLE: 8,
  COMM_TILE_MOUNT: 9,
  OTHER: 10,
};
type Mount_Type = $Keys<typeof Mount_TypeValues>;
export type {Mount_Type};

export function Mount_TypeValue(n: number): Mount_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'TRIPOD';

    case 2:
      return 'J_ARM';

    case 3:
      return 'LIGHT_DUTY_SLED_MOUNT';

    case 4:
      return 'HEAVY_DUTY_SLED_MOUNT';

    case 5:
      return 'CHIMNEY_MOUNT_WITH_STRAPS';

    case 6:
      return 'WALL_MOUNT';

    case 7:
      return 'GABLE_MOUNT';

    case 8:
      return 'POLE_TO_POLE';

    case 9:
      return 'COMM_TILE_MOUNT';

    case 10:
      return 'OTHER';

    default:
      return 'INVALID';
  }
}

export const Mount_InstallationStateValues = {
  INVALID_INSTALLATION_STATE: 0,
  PLANNED: 1,
  INSTALLED: 2,
};
type Mount_InstallationState = $Keys<typeof Mount_InstallationStateValues>;
export type {Mount_InstallationState};

export function Mount_InstallationStateValue(
  n: number,
): Mount_InstallationState {
  switch (n) {
    case 0:
      return 'INVALID_INSTALLATION_STATE';

    case 1:
      return 'PLANNED';

    case 2:
      return 'INSTALLED';

    default:
      return 'INVALID_INSTALLATION_STATE';
  }
}

class Mount {
  id: string;
  created: ?Date;
  updated: ?Date;
  srl_location_ids: Array<string>;
  placemark: ?cwn_kml.Placemark;
  type: Mount_Type;
  height_above_roof: number;
  extensible: boolean;
  site_id: string;
  deleted: boolean;
  installation_state: Mount_InstallationState;

  constructor(props: $Shape<Mount> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.srl_location_ids = [];
    if (props.hasOwnProperty('srl_location_ids')) {
      const v = props.srl_location_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field srl_location_ids should be array');
      }
      this.srl_location_ids = v.map(function(v) {
        return v;
      });
    }

    this.placemark = null;
    if (props.hasOwnProperty('placemark')) {
      const v = props.placemark;
      this.placemark = v && new cwn_kml.Placemark(v);
    }

    this.type = Mount_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.height_above_roof = 0;
    if (props.hasOwnProperty('height_above_roof')) {
      const v = props.height_above_roof;
      this.height_above_roof = v;
    }

    this.extensible = false;
    if (props.hasOwnProperty('extensible')) {
      const v = props.extensible;
      this.extensible = !!v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }

    this.installation_state = Mount_InstallationStateValue(0);
    if (props.hasOwnProperty('installation_state')) {
      const v = props.installation_state;
      this.installation_state = v;
    }
  }

  getPlacemark(): cwn_kml.Placemark {
    if (this.placemark) {
      return this.placemark;
    }
    return new cwn_kml.Placemark();
  }
}
export {Mount};

class ShotOverride {
  mount_a: string;
  mount_b: string;
  has_line_of_sight: boolean;
  created: ?Date;
  updated: ?Date;
  created_by: ?cwn_user.User;
  updated_by: ?cwn_user.User;

  constructor(props: $Shape<ShotOverride> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_a = '0';
    if (props.hasOwnProperty('mount_a')) {
      const v = props.mount_a;
      this.mount_a = v;
    }

    this.mount_b = '0';
    if (props.hasOwnProperty('mount_b')) {
      const v = props.mount_b;
      this.mount_b = v;
    }

    this.has_line_of_sight = false;
    if (props.hasOwnProperty('has_line_of_sight')) {
      const v = props.has_line_of_sight;
      this.has_line_of_sight = !!v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.created_by = null;
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v && new cwn_user.User(v);
    }

    this.updated_by = null;
    if (props.hasOwnProperty('updated_by')) {
      const v = props.updated_by;
      this.updated_by = v && new cwn_user.User(v);
    }
  }

  getCreatedBy(): cwn_user.User {
    if (this.created_by) {
      return this.created_by;
    }
    return new cwn_user.User();
  }

  getUpdatedBy(): cwn_user.User {
    if (this.updated_by) {
      return this.updated_by;
    }
    return new cwn_user.User();
  }
}
export {ShotOverride};

class IntIdArgs {
  id: string;

  constructor(props: $Shape<IntIdArgs> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {IntIdArgs};

// END cwn/geo/models.proto
