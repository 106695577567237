// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_user from '../../cwn/user';
import * as cwn_proc from '../../cwn/proc';
import * as cwn_network from '../../cwn/network';
import * as cwn_asset from '../../cwn/asset';
import * as cwn_antenna from '../../cwn/antenna';

// START cwn/radios/models.proto

export const ModeValues = {
  UNKNOWN: 0,
  PTP_AP: 1,
  PTP_STA: 2,
  PTMP_AP: 3,
  PTMP_STA: 4,
};
type Mode = $Keys<typeof ModeValues>;
export type {Mode};

export function ModeValue(n: number): Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'PTP_AP';

    case 2:
      return 'PTP_STA';

    case 3:
      return 'PTMP_AP';

    case 4:
      return 'PTMP_STA';

    default:
      return 'UNKNOWN';
  }
}

export const LinkStateValues = {
  LINK_UNKNOWN: 0,
  LINK_INIT: 1,
  LINK_DOWN: 2,
  LINK_UP: 3,
  LINK_PAUSE: 4,
};
type LinkState = $Keys<typeof LinkStateValues>;
export type {LinkState};

export function LinkStateValue(n: number): LinkState {
  switch (n) {
    case 0:
      return 'LINK_UNKNOWN';

    case 1:
      return 'LINK_INIT';

    case 2:
      return 'LINK_DOWN';

    case 3:
      return 'LINK_UP';

    case 4:
      return 'LINK_PAUSE';

    default:
      return 'LINK_UNKNOWN';
  }
}

class Channel {
  center_freq: number;
  control_freq: number;
  dfs_enabled: boolean;
  bandwidth: number;

  constructor(props: $Shape<Channel> = {}): void {
    if (!props) {
      props = {};
    }

    this.center_freq = 0;
    if (props.hasOwnProperty('center_freq')) {
      const v = props.center_freq;
      this.center_freq = v;
    }

    this.control_freq = 0;
    if (props.hasOwnProperty('control_freq')) {
      const v = props.control_freq;
      this.control_freq = v;
    }

    this.dfs_enabled = false;
    if (props.hasOwnProperty('dfs_enabled')) {
      const v = props.dfs_enabled;
      this.dfs_enabled = !!v;
    }

    this.bandwidth = 0;
    if (props.hasOwnProperty('bandwidth')) {
      const v = props.bandwidth;
      this.bandwidth = v;
    }
  }
}
export {Channel};

export const RadioGroup_StatusValues = {
  ACTIVE: 0,
  DELETED: 1,
};
type RadioGroup_Status = $Keys<typeof RadioGroup_StatusValues>;
export type {RadioGroup_Status};

export function RadioGroup_StatusValue(n: number): RadioGroup_Status {
  switch (n) {
    case 0:
      return 'ACTIVE';

    case 1:
      return 'DELETED';

    default:
      return 'ACTIVE';
  }
}

class RadioGroup {
  id: string;
  ssid: string;
  wpa_key: string;
  ip_net: ?cwn_network.IPNet;
  area_id: string;
  created: ?Date;
  updated: ?Date;
  max_ip_offset: string;
  channel: ?Channel;
  notes: string;
  status: RadioGroup_Status;
  site_radio_link_ip_offsets: {[key: string]: number};

  constructor(props: $Shape<RadioGroup> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.ssid = '';
    if (props.hasOwnProperty('ssid')) {
      const v = props.ssid;
      this.ssid = v;
    }

    this.wpa_key = '';
    if (props.hasOwnProperty('wpa_key')) {
      const v = props.wpa_key;
      this.wpa_key = v;
    }

    this.ip_net = null;
    if (props.hasOwnProperty('ip_net')) {
      const v = props.ip_net;
      this.ip_net = v && new cwn_network.IPNet(v);
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.max_ip_offset = '0';
    if (props.hasOwnProperty('max_ip_offset')) {
      const v = props.max_ip_offset;
      this.max_ip_offset = v;
    }

    this.channel = null;
    if (props.hasOwnProperty('channel')) {
      const v = props.channel;
      this.channel = v && new Channel(v);
    }

    this.notes = '';
    if (props.hasOwnProperty('notes')) {
      const v = props.notes;
      this.notes = v;
    }

    this.status = RadioGroup_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.site_radio_link_ip_offsets = {};
    if (props.hasOwnProperty('site_radio_link_ip_offsets')) {
      const v = props.site_radio_link_ip_offsets;
      for (const key: any of Object.keys(v)) {
        this.site_radio_link_ip_offsets[key] = v[key];
      }
    }
  }

  getIpNet(): cwn_network.IPNet {
    if (this.ip_net) {
      return this.ip_net;
    }
    return new cwn_network.IPNet();
  }

  getChannel(): Channel {
    if (this.channel) {
      return this.channel;
    }
    return new Channel();
  }
}
export {RadioGroup};

class RadioGroupHistory {
  id: string;
  created: ?Date;
  radio_group: ?RadioGroup;

  constructor(props: $Shape<RadioGroupHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new RadioGroup(v);
    }
  }

  getRadioGroup(): RadioGroup {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new RadioGroup();
  }
}
export {RadioGroupHistory};

class ChannelLock {
  id: string;
  radio_group_id: string;
  created: ?Date;
  updated: ?Date;
  created_by: ?cwn_user.User;
  updated_by: ?cwn_user.User;
  deleted: boolean;

  constructor(props: $Shape<ChannelLock> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.created_by = null;
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v && new cwn_user.User(v);
    }

    this.updated_by = null;
    if (props.hasOwnProperty('updated_by')) {
      const v = props.updated_by;
      this.updated_by = v && new cwn_user.User(v);
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }
  }

  getCreatedBy(): cwn_user.User {
    if (this.created_by) {
      return this.created_by;
    }
    return new cwn_user.User();
  }

  getUpdatedBy(): cwn_user.User {
    if (this.updated_by) {
      return this.updated_by;
    }
    return new cwn_user.User();
  }
}
export {ChannelLock};

class Radio {
  device_id: string;
  mac_address: string;
  radio_ip: ?cwn_network.IP;
  interface_ip: ?cwn_network.IP;
  ssid: string;
  wpa_key: string;
  mode: Mode;
  created: ?Date;
  updated: ?Date;
  interface_name: string;
  channel: ?Channel;
  neighbors: Array<Neighbor>;
  client_isolation: boolean;
  activate_unii: boolean;
  site_radio_link_id: string;
  antenna: ?cwn_antenna.Antenna;
  scan_list_channels: Array<string>;
  asset_model: cwn_asset.Model;
  tx_channel: ?Channel;
  rx_channel: ?Channel;
  is_half_duplex: boolean;
  tx_power_dbm: number;
  mount_id: string;
  bfgt_config: ?BFGTRadio;
  radio_ip_addr: ?cwn_network.IPAddr;
  radio_hw_addr: ?cwn_network.HwAddr;
  interface_ip_addr: ?cwn_network.IPAddr;
  beam_width: string;
  specific: ?google_protobuf.Any;

  constructor(props: $Shape<Radio> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.radio_ip = null;
    if (props.hasOwnProperty('radio_ip')) {
      const v = props.radio_ip;
      this.radio_ip = v && new cwn_network.IP(v);
    }

    this.interface_ip = null;
    if (props.hasOwnProperty('interface_ip')) {
      const v = props.interface_ip;
      this.interface_ip = v && new cwn_network.IP(v);
    }

    this.ssid = '';
    if (props.hasOwnProperty('ssid')) {
      const v = props.ssid;
      this.ssid = v;
    }

    this.wpa_key = '';
    if (props.hasOwnProperty('wpa_key')) {
      const v = props.wpa_key;
      this.wpa_key = v;
    }

    this.mode = ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.channel = null;
    if (props.hasOwnProperty('channel')) {
      const v = props.channel;
      this.channel = v && new Channel(v);
    }

    this.neighbors = [];
    if (props.hasOwnProperty('neighbors')) {
      const v = props.neighbors;
      if (!Array.isArray(v)) {
        throw new Error('repeated field neighbors should be array');
      }
      this.neighbors = v.map(function(v) {
        return new Neighbor(v);
      });
    }

    this.client_isolation = false;
    if (props.hasOwnProperty('client_isolation')) {
      const v = props.client_isolation;
      this.client_isolation = !!v;
    }

    this.activate_unii = false;
    if (props.hasOwnProperty('activate_unii')) {
      const v = props.activate_unii;
      this.activate_unii = !!v;
    }

    this.site_radio_link_id = '0';
    if (props.hasOwnProperty('site_radio_link_id')) {
      const v = props.site_radio_link_id;
      this.site_radio_link_id = v;
    }

    this.antenna = null;
    if (props.hasOwnProperty('antenna')) {
      const v = props.antenna;
      this.antenna = v && new cwn_antenna.Antenna(v);
    }

    this.scan_list_channels = [];
    if (props.hasOwnProperty('scan_list_channels')) {
      const v = props.scan_list_channels;
      if (!Array.isArray(v)) {
        throw new Error('repeated field scan_list_channels should be array');
      }
      this.scan_list_channels = v.map(function(v) {
        return v;
      });
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.tx_channel = null;
    if (props.hasOwnProperty('tx_channel')) {
      const v = props.tx_channel;
      this.tx_channel = v && new Channel(v);
    }

    this.rx_channel = null;
    if (props.hasOwnProperty('rx_channel')) {
      const v = props.rx_channel;
      this.rx_channel = v && new Channel(v);
    }

    this.is_half_duplex = false;
    if (props.hasOwnProperty('is_half_duplex')) {
      const v = props.is_half_duplex;
      this.is_half_duplex = !!v;
    }

    this.tx_power_dbm = 0;
    if (props.hasOwnProperty('tx_power_dbm')) {
      const v = props.tx_power_dbm;
      this.tx_power_dbm = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.bfgt_config = null;
    if (props.hasOwnProperty('bfgt_config')) {
      const v = props.bfgt_config;
      this.bfgt_config = v && new BFGTRadio(v);
    }

    this.radio_ip_addr = null;
    if (props.hasOwnProperty('radio_ip_addr')) {
      const v = props.radio_ip_addr;
      this.radio_ip_addr = v && new cwn_network.IPAddr(v);
    }

    this.radio_hw_addr = null;
    if (props.hasOwnProperty('radio_hw_addr')) {
      const v = props.radio_hw_addr;
      this.radio_hw_addr = v && new cwn_network.HwAddr(v);
    }

    this.interface_ip_addr = null;
    if (props.hasOwnProperty('interface_ip_addr')) {
      const v = props.interface_ip_addr;
      this.interface_ip_addr = v && new cwn_network.IPAddr(v);
    }

    this.beam_width = '0';
    if (props.hasOwnProperty('beam_width')) {
      const v = props.beam_width;
      this.beam_width = v;
    }

    this.specific = null;
    if (props.hasOwnProperty('specific')) {
      const v = props.specific;
      this.specific = v && new google_protobuf.Any(v);
    }
  }

  getRadioIp(): cwn_network.IP {
    if (this.radio_ip) {
      return this.radio_ip;
    }
    return new cwn_network.IP();
  }

  getInterfaceIp(): cwn_network.IP {
    if (this.interface_ip) {
      return this.interface_ip;
    }
    return new cwn_network.IP();
  }

  getChannel(): Channel {
    if (this.channel) {
      return this.channel;
    }
    return new Channel();
  }

  getAntenna(): cwn_antenna.Antenna {
    if (this.antenna) {
      return this.antenna;
    }
    return new cwn_antenna.Antenna();
  }

  getTxChannel(): Channel {
    if (this.tx_channel) {
      return this.tx_channel;
    }
    return new Channel();
  }

  getRxChannel(): Channel {
    if (this.rx_channel) {
      return this.rx_channel;
    }
    return new Channel();
  }

  getBfgtConfig(): BFGTRadio {
    if (this.bfgt_config) {
      return this.bfgt_config;
    }
    return new BFGTRadio();
  }

  getRadioIpAddr(): cwn_network.IPAddr {
    if (this.radio_ip_addr) {
      return this.radio_ip_addr;
    }
    return new cwn_network.IPAddr();
  }

  getRadioHwAddr(): cwn_network.HwAddr {
    if (this.radio_hw_addr) {
      return this.radio_hw_addr;
    }
    return new cwn_network.HwAddr();
  }

  getInterfaceIpAddr(): cwn_network.IPAddr {
    if (this.interface_ip_addr) {
      return this.interface_ip_addr;
    }
    return new cwn_network.IPAddr();
  }

  getSpecific(): google_protobuf.Any {
    if (this.specific) {
      return this.specific;
    }
    return new google_protobuf.Any();
  }
}
export {Radio};

class BFGTRadio {
  is_controller: boolean;
  controller_ip: string;
  tunnels: Array<string>;
  secondaries: Array<Radio>;
  topology: ?BFGTTopology;

  constructor(props: $Shape<BFGTRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.is_controller = false;
    if (props.hasOwnProperty('is_controller')) {
      const v = props.is_controller;
      this.is_controller = !!v;
    }

    this.controller_ip = '';
    if (props.hasOwnProperty('controller_ip')) {
      const v = props.controller_ip;
      this.controller_ip = v;
    }

    this.tunnels = [];
    if (props.hasOwnProperty('tunnels')) {
      const v = props.tunnels;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tunnels should be array');
      }
      this.tunnels = v.map(function(v) {
        return v;
      });
    }

    this.secondaries = [];
    if (props.hasOwnProperty('secondaries')) {
      const v = props.secondaries;
      if (!Array.isArray(v)) {
        throw new Error('repeated field secondaries should be array');
      }
      this.secondaries = v.map(function(v) {
        return new Radio(v);
      });
    }

    this.topology = null;
    if (props.hasOwnProperty('topology')) {
      const v = props.topology;
      this.topology = v && new BFGTTopology(v);
    }
  }

  getTopology(): BFGTTopology {
    if (this.topology) {
      return this.topology;
    }
    return new BFGTTopology();
  }
}
export {BFGTRadio};

class BFGTTopology_Node {
  mac_addr: string;
  polarity: number;
  node_type: number;
  site_name: string;
  pop_node: boolean;

  constructor(props: $Shape<BFGTTopology_Node> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_addr = '';
    if (props.hasOwnProperty('mac_addr')) {
      const v = props.mac_addr;
      this.mac_addr = v;
    }

    this.polarity = 0;
    if (props.hasOwnProperty('polarity')) {
      const v = props.polarity;
      this.polarity = v;
    }

    this.node_type = 0;
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }

    this.site_name = '';
    if (props.hasOwnProperty('site_name')) {
      const v = props.site_name;
      this.site_name = v;
    }

    this.pop_node = false;
    if (props.hasOwnProperty('pop_node')) {
      const v = props.pop_node;
      this.pop_node = !!v;
    }
  }
}
export {BFGTTopology_Node};
class BFGTTopology_Link {
  a_node_mac: string;
  z_node_mac: string;
  link_type: number;

  constructor(props: $Shape<BFGTTopology_Link> = {}): void {
    if (!props) {
      props = {};
    }

    this.a_node_mac = '';
    if (props.hasOwnProperty('a_node_mac')) {
      const v = props.a_node_mac;
      this.a_node_mac = v;
    }

    this.z_node_mac = '';
    if (props.hasOwnProperty('z_node_mac')) {
      const v = props.z_node_mac;
      this.z_node_mac = v;
    }

    this.link_type = 0;
    if (props.hasOwnProperty('link_type')) {
      const v = props.link_type;
      this.link_type = v;
    }
  }
}
export {BFGTTopology_Link};
class BFGTTopology_Site_Location {
  latitude: number;
  longitude: number;
  altitude: number;
  accuracy: number;

  constructor(props: $Shape<BFGTTopology_Site_Location> = {}): void {
    if (!props) {
      props = {};
    }

    this.latitude = 0;
    if (props.hasOwnProperty('latitude')) {
      const v = props.latitude;
      this.latitude = v;
    }

    this.longitude = 0;
    if (props.hasOwnProperty('longitude')) {
      const v = props.longitude;
      this.longitude = v;
    }

    this.altitude = 0;
    if (props.hasOwnProperty('altitude')) {
      const v = props.altitude;
      this.altitude = v;
    }

    this.accuracy = 0;
    if (props.hasOwnProperty('accuracy')) {
      const v = props.accuracy;
      this.accuracy = v;
    }
  }
}
export {BFGTTopology_Site_Location};
class BFGTTopology_Site {
  name: string;
  location: ?BFGTTopology_Site_Location;

  constructor(props: $Shape<BFGTTopology_Site> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.location = null;
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v && new BFGTTopology_Site_Location(v);
    }
  }

  getLocation(): BFGTTopology_Site_Location {
    if (this.location) {
      return this.location;
    }
    return new BFGTTopology_Site_Location();
  }
}
export {BFGTTopology_Site};
class BFGTTopology {
  nodes: Array<BFGTTopology_Node>;
  links: Array<BFGTTopology_Link>;
  sites: Array<BFGTTopology_Site>;

  constructor(props: $Shape<BFGTTopology> = {}): void {
    if (!props) {
      props = {};
    }

    this.nodes = [];
    if (props.hasOwnProperty('nodes')) {
      const v = props.nodes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field nodes should be array');
      }
      this.nodes = v.map(function(v) {
        return new BFGTTopology_Node(v);
      });
    }

    this.links = [];
    if (props.hasOwnProperty('links')) {
      const v = props.links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field links should be array');
      }
      this.links = v.map(function(v) {
        return new BFGTTopology_Link(v);
      });
    }

    this.sites = [];
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sites should be array');
      }
      this.sites = v.map(function(v) {
        return new BFGTTopology_Site(v);
      });
    }
  }
}
export {BFGTTopology};

class Neighbor {
  device_id: string;
  ip: ?cwn_network.IP;
  mode: Mode;

  constructor(props: $Shape<Neighbor> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.ip = null;
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v && new cwn_network.IP(v);
    }

    this.mode = ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }
  }

  getIp(): cwn_network.IP {
    if (this.ip) {
      return this.ip;
    }
    return new cwn_network.IP();
  }
}
export {Neighbor};

class Antenna_Chain {
  id: number;
  name: string;

  constructor(props: $Shape<Antenna_Chain> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = 0;
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }
  }
}
export {Antenna_Chain};
class Antenna {
  id: number;
  name: string;
  gain: number;
  built_in: boolean;
  chains: Array<Antenna_Chain>;

  constructor(props: $Shape<Antenna> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = 0;
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.gain = 0;
    if (props.hasOwnProperty('gain')) {
      const v = props.gain;
      this.gain = v;
    }

    this.built_in = false;
    if (props.hasOwnProperty('built_in')) {
      const v = props.built_in;
      this.built_in = !!v;
    }

    this.chains = [];
    if (props.hasOwnProperty('chains')) {
      const v = props.chains;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chains should be array');
      }
      this.chains = v.map(function(v) {
        return new Antenna_Chain(v);
      });
    }
  }
}
export {Antenna};

class ConfiguredRadioHistory {
  id: string;
  radios: Array<Radio>;
  device_id: string;
  created: ?Date;

  constructor(props: $Shape<ConfiguredRadioHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.radios = [];
    if (props.hasOwnProperty('radios')) {
      const v = props.radios;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radios should be array');
      }
      this.radios = v.map(function(v) {
        return new Radio(v);
      });
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }
}
export {ConfiguredRadioHistory};

class DiscoveredRadioHistory {
  id: string;
  radios: Array<Radio>;
  device_id: string;
  created: ?Date;
  updated: ?Date;

  constructor(props: $Shape<DiscoveredRadioHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.radios = [];
    if (props.hasOwnProperty('radios')) {
      const v = props.radios;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radios should be array');
      }
      this.radios = v.map(function(v) {
        return new Radio(v);
      });
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }
}
export {DiscoveredRadioHistory};

class LogLine {
  ip: string;
  mac_address: string;
  content: string;
  radio_timestamp: ?Date;
  agent_timestamp: ?Date;
  server_timestamp: ?Date;

  constructor(props: $Shape<LogLine> = {}): void {
    if (!props) {
      props = {};
    }

    this.ip = '';
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.content = '';
    if (props.hasOwnProperty('content')) {
      const v = props.content;
      this.content = v;
    }

    this.radio_timestamp = null;
    if (props.hasOwnProperty('radio_timestamp')) {
      const v = props.radio_timestamp;
      this.radio_timestamp = v && new Date(v);
    }

    this.agent_timestamp = null;
    if (props.hasOwnProperty('agent_timestamp')) {
      const v = props.agent_timestamp;
      this.agent_timestamp = v && new Date(v);
    }

    this.server_timestamp = null;
    if (props.hasOwnProperty('server_timestamp')) {
      const v = props.server_timestamp;
      this.server_timestamp = v && new Date(v);
    }
  }
}
export {LogLine};

class LogCursor {
  cursor_map: {[key: string]: string};

  constructor(props: $Shape<LogCursor> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor_map = {};
    if (props.hasOwnProperty('cursor_map')) {
      const v = props.cursor_map;
      for (const key: any of Object.keys(v)) {
        this.cursor_map[key] = v[key];
      }
    }
  }
}
export {LogCursor};

class RadioReboot {
  id: string;
  created: ?Date;
  mac_address: string;
  model: string;

  constructor(props: $Shape<RadioReboot> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.model = '';
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }
  }
}
export {RadioReboot};

class Status_Wireless_Sta_Detail {
  capacity_bits: string;
  cinr: string;

  constructor(props: $Shape<Status_Wireless_Sta_Detail> = {}): void {
    if (!props) {
      props = {};
    }

    this.capacity_bits = '0';
    if (props.hasOwnProperty('capacity_bits')) {
      const v = props.capacity_bits;
      this.capacity_bits = v;
    }

    this.cinr = '0';
    if (props.hasOwnProperty('cinr')) {
      const v = props.cinr;
      this.cinr = v;
    }
  }
}
export {Status_Wireless_Sta_Detail};
class Status_Wireless_Sta_Remote {
  hostname: string;
  mac_address: string;
  tx_power_dbm: number;
  noise_floor: number;
  device_uptime_s: string;
  link_uptime_s: string;
  link_stats: ?LinkStats;
  rssi: number;
  tx_rate_data: Array<string>;
  chain_rssi: Array<string>;

  constructor(props: $Shape<Status_Wireless_Sta_Remote> = {}): void {
    if (!props) {
      props = {};
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.tx_power_dbm = 0;
    if (props.hasOwnProperty('tx_power_dbm')) {
      const v = props.tx_power_dbm;
      this.tx_power_dbm = v;
    }

    this.noise_floor = 0;
    if (props.hasOwnProperty('noise_floor')) {
      const v = props.noise_floor;
      this.noise_floor = v;
    }

    this.device_uptime_s = '0';
    if (props.hasOwnProperty('device_uptime_s')) {
      const v = props.device_uptime_s;
      this.device_uptime_s = v;
    }

    this.link_uptime_s = '0';
    if (props.hasOwnProperty('link_uptime_s')) {
      const v = props.link_uptime_s;
      this.link_uptime_s = v;
    }

    this.link_stats = null;
    if (props.hasOwnProperty('link_stats')) {
      const v = props.link_stats;
      this.link_stats = v && new LinkStats(v);
    }

    this.rssi = 0;
    if (props.hasOwnProperty('rssi')) {
      const v = props.rssi;
      this.rssi = v;
    }

    this.tx_rate_data = [];
    if (props.hasOwnProperty('tx_rate_data')) {
      const v = props.tx_rate_data;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tx_rate_data should be array');
      }
      this.tx_rate_data = v.map(function(v) {
        return v;
      });
    }

    this.chain_rssi = [];
    if (props.hasOwnProperty('chain_rssi')) {
      const v = props.chain_rssi;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chain_rssi should be array');
      }
      this.chain_rssi = v.map(function(v) {
        return v;
      });
    }
  }

  getLinkStats(): LinkStats {
    if (this.link_stats) {
      return this.link_stats;
    }
    return new LinkStats();
  }
}
export {Status_Wireless_Sta_Remote};
class Status_Wireless_Sta {
  tx: ?Status_Wireless_Sta_Detail;
  rx: ?Status_Wireless_Sta_Detail;
  rssi: number;
  link_uptime_s: string;
  remote: ?Status_Wireless_Sta_Remote;
  tx_rate_data: Array<string>;
  chain_rssi: Array<string>;

  constructor(props: $Shape<Status_Wireless_Sta> = {}): void {
    if (!props) {
      props = {};
    }

    this.tx = null;
    if (props.hasOwnProperty('tx')) {
      const v = props.tx;
      this.tx = v && new Status_Wireless_Sta_Detail(v);
    }

    this.rx = null;
    if (props.hasOwnProperty('rx')) {
      const v = props.rx;
      this.rx = v && new Status_Wireless_Sta_Detail(v);
    }

    this.rssi = 0;
    if (props.hasOwnProperty('rssi')) {
      const v = props.rssi;
      this.rssi = v;
    }

    this.link_uptime_s = '0';
    if (props.hasOwnProperty('link_uptime_s')) {
      const v = props.link_uptime_s;
      this.link_uptime_s = v;
    }

    this.remote = null;
    if (props.hasOwnProperty('remote')) {
      const v = props.remote;
      this.remote = v && new Status_Wireless_Sta_Remote(v);
    }

    this.tx_rate_data = [];
    if (props.hasOwnProperty('tx_rate_data')) {
      const v = props.tx_rate_data;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tx_rate_data should be array');
      }
      this.tx_rate_data = v.map(function(v) {
        return v;
      });
    }

    this.chain_rssi = [];
    if (props.hasOwnProperty('chain_rssi')) {
      const v = props.chain_rssi;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chain_rssi should be array');
      }
      this.chain_rssi = v.map(function(v) {
        return v;
      });
    }
  }

  getTx(): Status_Wireless_Sta_Detail {
    if (this.tx) {
      return this.tx;
    }
    return new Status_Wireless_Sta_Detail();
  }

  getRx(): Status_Wireless_Sta_Detail {
    if (this.rx) {
      return this.rx;
    }
    return new Status_Wireless_Sta_Detail();
  }

  getRemote(): Status_Wireless_Sta_Remote {
    if (this.remote) {
      return this.remote;
    }
    return new Status_Wireless_Sta_Remote();
  }
}
export {Status_Wireless_Sta};
class Status_Wireless {
  mac_address: string;
  channel: ?Channel;
  essid: string;
  mode: Mode;
  tx_power_dbm: number;
  noise_floor: number;
  link_stats: ?LinkStats;
  sta: Array<Status_Wireless_Sta>;
  rx_channel: ?Channel;
  tx_channel: ?Channel;
  baseband_stats: Array<BasebandStats>;

  constructor(props: $Shape<Status_Wireless> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.channel = null;
    if (props.hasOwnProperty('channel')) {
      const v = props.channel;
      this.channel = v && new Channel(v);
    }

    this.essid = '';
    if (props.hasOwnProperty('essid')) {
      const v = props.essid;
      this.essid = v;
    }

    this.mode = ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.tx_power_dbm = 0;
    if (props.hasOwnProperty('tx_power_dbm')) {
      const v = props.tx_power_dbm;
      this.tx_power_dbm = v;
    }

    this.noise_floor = 0;
    if (props.hasOwnProperty('noise_floor')) {
      const v = props.noise_floor;
      this.noise_floor = v;
    }

    this.link_stats = null;
    if (props.hasOwnProperty('link_stats')) {
      const v = props.link_stats;
      this.link_stats = v && new LinkStats(v);
    }

    this.sta = [];
    if (props.hasOwnProperty('sta')) {
      const v = props.sta;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sta should be array');
      }
      this.sta = v.map(function(v) {
        return new Status_Wireless_Sta(v);
      });
    }

    this.rx_channel = null;
    if (props.hasOwnProperty('rx_channel')) {
      const v = props.rx_channel;
      this.rx_channel = v && new Channel(v);
    }

    this.tx_channel = null;
    if (props.hasOwnProperty('tx_channel')) {
      const v = props.tx_channel;
      this.tx_channel = v && new Channel(v);
    }

    this.baseband_stats = [];
    if (props.hasOwnProperty('baseband_stats')) {
      const v = props.baseband_stats;
      if (!Array.isArray(v)) {
        throw new Error('repeated field baseband_stats should be array');
      }
      this.baseband_stats = v.map(function(v) {
        return new BasebandStats(v);
      });
    }
  }

  getChannel(): Channel {
    if (this.channel) {
      return this.channel;
    }
    return new Channel();
  }

  getLinkStats(): LinkStats {
    if (this.link_stats) {
      return this.link_stats;
    }
    return new LinkStats();
  }

  getRxChannel(): Channel {
    if (this.rx_channel) {
      return this.rx_channel;
    }
    return new Channel();
  }

  getTxChannel(): Channel {
    if (this.tx_channel) {
      return this.tx_channel;
    }
    return new Channel();
  }
}
export {Status_Wireless};
class Status_Interface {
  name: string;
  mac_address: string;
  enabled: boolean;
  link_detected: boolean;
  auto_negotiation: boolean;
  full_duplex: boolean;
  speed_mbps: string;
  link_stats: ?LinkStats;

  constructor(props: $Shape<Status_Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.link_detected = false;
    if (props.hasOwnProperty('link_detected')) {
      const v = props.link_detected;
      this.link_detected = !!v;
    }

    this.auto_negotiation = false;
    if (props.hasOwnProperty('auto_negotiation')) {
      const v = props.auto_negotiation;
      this.auto_negotiation = !!v;
    }

    this.full_duplex = false;
    if (props.hasOwnProperty('full_duplex')) {
      const v = props.full_duplex;
      this.full_duplex = !!v;
    }

    this.speed_mbps = '0';
    if (props.hasOwnProperty('speed_mbps')) {
      const v = props.speed_mbps;
      this.speed_mbps = v;
    }

    this.link_stats = null;
    if (props.hasOwnProperty('link_stats')) {
      const v = props.link_stats;
      this.link_stats = v && new LinkStats(v);
    }
  }

  getLinkStats(): LinkStats {
    if (this.link_stats) {
      return this.link_stats;
    }
    return new LinkStats();
  }
}
export {Status_Interface};
class Status {
  id: string;
  created: ?Date;
  mac_address: string;
  ip: ?cwn_network.IP;
  model: cwn_asset.Model;
  status: ?google_protobuf.Any;
  firmware_version: string;
  uptime_s: string;
  mem_info: ?cwn_proc.MemInfo;
  hostname: string;
  temp_c: number;
  wireless: ?Status_Wireless;
  interfaces: Array<Status_Interface>;

  constructor(props: $Shape<Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.ip = null;
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v && new cwn_network.IP(v);
    }

    this.model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new google_protobuf.Any(v);
    }

    this.firmware_version = '';
    if (props.hasOwnProperty('firmware_version')) {
      const v = props.firmware_version;
      this.firmware_version = v;
    }

    this.uptime_s = '0';
    if (props.hasOwnProperty('uptime_s')) {
      const v = props.uptime_s;
      this.uptime_s = v;
    }

    this.mem_info = null;
    if (props.hasOwnProperty('mem_info')) {
      const v = props.mem_info;
      this.mem_info = v && new cwn_proc.MemInfo(v);
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }

    this.temp_c = 0;
    if (props.hasOwnProperty('temp_c')) {
      const v = props.temp_c;
      this.temp_c = v;
    }

    this.wireless = null;
    if (props.hasOwnProperty('wireless')) {
      const v = props.wireless;
      this.wireless = v && new Status_Wireless(v);
    }

    this.interfaces = [];
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      if (!Array.isArray(v)) {
        throw new Error('repeated field interfaces should be array');
      }
      this.interfaces = v.map(function(v) {
        return new Status_Interface(v);
      });
    }
  }

  getIp(): cwn_network.IP {
    if (this.ip) {
      return this.ip;
    }
    return new cwn_network.IP();
  }

  getStatus(): google_protobuf.Any {
    if (this.status) {
      return this.status;
    }
    return new google_protobuf.Any();
  }

  getMemInfo(): cwn_proc.MemInfo {
    if (this.mem_info) {
      return this.mem_info;
    }
    return new cwn_proc.MemInfo();
  }

  getWireless(): Status_Wireless {
    if (this.wireless) {
      return this.wireless;
    }
    return new Status_Wireless();
  }
}
export {Status};

class LinkStats_Stats {
  bytes: string;
  packets: string;
  errors: string;

  constructor(props: $Shape<LinkStats_Stats> = {}): void {
    if (!props) {
      props = {};
    }

    this.bytes = '0';
    if (props.hasOwnProperty('bytes')) {
      const v = props.bytes;
      this.bytes = v;
    }

    this.packets = '0';
    if (props.hasOwnProperty('packets')) {
      const v = props.packets;
      this.packets = v;
    }

    this.errors = '0';
    if (props.hasOwnProperty('errors')) {
      const v = props.errors;
      this.errors = v;
    }
  }
}
export {LinkStats_Stats};
class LinkStats {
  tx: ?LinkStats_Stats;
  rx: ?LinkStats_Stats;

  constructor(props: $Shape<LinkStats> = {}): void {
    if (!props) {
      props = {};
    }

    this.tx = null;
    if (props.hasOwnProperty('tx')) {
      const v = props.tx;
      this.tx = v && new LinkStats_Stats(v);
    }

    this.rx = null;
    if (props.hasOwnProperty('rx')) {
      const v = props.rx;
      this.rx = v && new LinkStats_Stats(v);
    }
  }

  getTx(): LinkStats_Stats {
    if (this.tx) {
      return this.tx;
    }
    return new LinkStats_Stats();
  }

  getRx(): LinkStats_Stats {
    if (this.rx) {
      return this.rx;
    }
    return new LinkStats_Stats();
  }
}
export {LinkStats};

class BasebandStats {
  remote_mac: string;
  interface_name: string;
  link_stats: ?LinkStats;
  state: LinkState;
  link_count: string;

  constructor(props: $Shape<BasebandStats> = {}): void {
    if (!props) {
      props = {};
    }

    this.remote_mac = '';
    if (props.hasOwnProperty('remote_mac')) {
      const v = props.remote_mac;
      this.remote_mac = v;
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.link_stats = null;
    if (props.hasOwnProperty('link_stats')) {
      const v = props.link_stats;
      this.link_stats = v && new LinkStats(v);
    }

    this.state = LinkStateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.link_count = '0';
    if (props.hasOwnProperty('link_count')) {
      const v = props.link_count;
      this.link_count = v;
    }
  }

  getLinkStats(): LinkStats {
    if (this.link_stats) {
      return this.link_stats;
    }
    return new LinkStats();
  }
}
export {BasebandStats};

class ApplyRadioConfigOptions {
  is_unlocked: boolean;
  device_id: string;
  firmware_path: string;
  snmp_location: string;

  constructor(props: $Shape<ApplyRadioConfigOptions> = {}): void {
    if (!props) {
      props = {};
    }

    this.is_unlocked = false;
    if (props.hasOwnProperty('is_unlocked')) {
      const v = props.is_unlocked;
      this.is_unlocked = !!v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.firmware_path = '';
    if (props.hasOwnProperty('firmware_path')) {
      const v = props.firmware_path;
      this.firmware_path = v;
    }

    this.snmp_location = '';
    if (props.hasOwnProperty('snmp_location')) {
      const v = props.snmp_location;
      this.snmp_location = v;
    }
  }
}
export {ApplyRadioConfigOptions};

// END cwn/radios/models.proto
