// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_proc from '../../cwn/proc';
import * as cwn_network from '../../cwn/network';
import * as cwn_asset from '../../cwn/asset';

// START cwn/bridges/models.proto

export const BridgeTypeValues = {
  INVALID_BRIDGE_TYPE: 0,
  EDGEOS: 1,
  EDGESWITCH: 2,
  TOUGHSWITCH: 3,
  CORV1: 4,
  NETONIX: 5,
  PUMA: 6,
  POSITRON: 7,
};
type BridgeType = $Keys<typeof BridgeTypeValues>;
export type {BridgeType};

export function BridgeTypeValue(n: number): BridgeType {
  switch (n) {
    case 0:
      return 'INVALID_BRIDGE_TYPE';

    case 1:
      return 'EDGEOS';

    case 2:
      return 'EDGESWITCH';

    case 3:
      return 'TOUGHSWITCH';

    case 4:
      return 'CORV1';

    case 5:
      return 'NETONIX';

    case 6:
      return 'PUMA';

    case 7:
      return 'POSITRON';

    default:
      return 'INVALID_BRIDGE_TYPE';
  }
}

export const PlatformValues = {
  PLATFORM_UNKNOWN: 0,
  PLATFORM_EDGEOS: 1,
  PLATFORM_EDGESWITCH: 2,
  PLATFORM_TOUGHSWITCH: 3,
  PLATFORM_CORV1: 4,
  PLATFORM_NETONIX: 5,
  PLATFORM_POSITRON: 6,
};
type Platform = $Keys<typeof PlatformValues>;
export type {Platform};

export function PlatformValue(n: number): Platform {
  switch (n) {
    case 0:
      return 'PLATFORM_UNKNOWN';

    case 1:
      return 'PLATFORM_EDGEOS';

    case 2:
      return 'PLATFORM_EDGESWITCH';

    case 3:
      return 'PLATFORM_TOUGHSWITCH';

    case 4:
      return 'PLATFORM_CORV1';

    case 5:
      return 'PLATFORM_NETONIX';

    case 6:
      return 'PLATFORM_POSITRON';

    default:
      return 'PLATFORM_UNKNOWN';
  }
}

class Status_EdgeOS_FwInfo {
  name: string;
  running: boolean;
  boot: boolean;

  constructor(props: $Shape<Status_EdgeOS_FwInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.running = false;
    if (props.hasOwnProperty('running')) {
      const v = props.running;
      this.running = !!v;
    }

    this.boot = false;
    if (props.hasOwnProperty('boot')) {
      const v = props.boot;
      this.boot = !!v;
    }
  }
}
export {Status_EdgeOS_FwInfo};
class Status_EdgeOS {
  fw_info: Array<Status_EdgeOS_FwInfo>;

  constructor(props: $Shape<Status_EdgeOS> = {}): void {
    if (!props) {
      props = {};
    }

    this.fw_info = [];
    if (props.hasOwnProperty('fw_info')) {
      const v = props.fw_info;
      if (!Array.isArray(v)) {
        throw new Error('repeated field fw_info should be array');
      }
      this.fw_info = v.map(function(v) {
        return new Status_EdgeOS_FwInfo(v);
      });
    }
  }
}
export {Status_EdgeOS};
class Status_ToughSwitch {
  num_ports: string;
  has_poe24v: boolean;
  has_poe48v: boolean;

  constructor(props: $Shape<Status_ToughSwitch> = {}): void {
    if (!props) {
      props = {};
    }

    this.num_ports = '0';
    if (props.hasOwnProperty('num_ports')) {
      const v = props.num_ports;
      this.num_ports = v;
    }

    this.has_poe24v = false;
    if (props.hasOwnProperty('has_poe24v')) {
      const v = props.has_poe24v;
      this.has_poe24v = !!v;
    }

    this.has_poe48v = false;
    if (props.hasOwnProperty('has_poe48v')) {
      const v = props.has_poe48v;
      this.has_poe48v = !!v;
    }
  }
}
export {Status_ToughSwitch};
class Status {
  boot_id: string;
  device_id: string;
  created: ?Date;
  ip: string;
  mac_address: string;
  name: string;
  short_name: string;
  version_string: string;
  bridge_type: BridgeType;
  load_avg: ?cwn_proc.LoadAvg;
  mem_info: ?cwn_proc.MemInfo;
  uptime_s: string;
  port_statuses: Array<PortStatus>;
  edge_o_s_info: ?Status_EdgeOS;
  tough_switch_info: ?Status_ToughSwitch;
  id: string;
  ip_addr: ?cwn_network.IPAddr;
  model: cwn_asset.Model;
  mgmt_ip: ?cwn_network.IP;
  snmpCommunity: string;
  snmpLocation: string;

  constructor(props: $Shape<Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.boot_id = '';
    if (props.hasOwnProperty('boot_id')) {
      const v = props.boot_id;
      this.boot_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.ip = '';
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.short_name = '';
    if (props.hasOwnProperty('short_name')) {
      const v = props.short_name;
      this.short_name = v;
    }

    this.version_string = '';
    if (props.hasOwnProperty('version_string')) {
      const v = props.version_string;
      this.version_string = v;
    }

    this.bridge_type = BridgeTypeValue(0);
    if (props.hasOwnProperty('bridge_type')) {
      const v = props.bridge_type;
      this.bridge_type = v;
    }

    this.load_avg = null;
    if (props.hasOwnProperty('load_avg')) {
      const v = props.load_avg;
      this.load_avg = v && new cwn_proc.LoadAvg(v);
    }

    this.mem_info = null;
    if (props.hasOwnProperty('mem_info')) {
      const v = props.mem_info;
      this.mem_info = v && new cwn_proc.MemInfo(v);
    }

    this.uptime_s = '0';
    if (props.hasOwnProperty('uptime_s')) {
      const v = props.uptime_s;
      this.uptime_s = v;
    }

    this.port_statuses = [];
    if (props.hasOwnProperty('port_statuses')) {
      const v = props.port_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field port_statuses should be array');
      }
      this.port_statuses = v.map(function(v) {
        return new PortStatus(v);
      });
    }

    this.edge_o_s_info = null;
    if (props.hasOwnProperty('edge_o_s_info')) {
      const v = props.edge_o_s_info;
      this.edge_o_s_info = v && new Status_EdgeOS(v);
    }

    this.tough_switch_info = null;
    if (props.hasOwnProperty('tough_switch_info')) {
      const v = props.tough_switch_info;
      this.tough_switch_info = v && new Status_ToughSwitch(v);
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.ip_addr = null;
    if (props.hasOwnProperty('ip_addr')) {
      const v = props.ip_addr;
      this.ip_addr = v && new cwn_network.IPAddr(v);
    }

    this.model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }

    this.mgmt_ip = null;
    if (props.hasOwnProperty('mgmt_ip')) {
      const v = props.mgmt_ip;
      this.mgmt_ip = v && new cwn_network.IP(v);
    }

    this.snmpCommunity = '';
    if (props.hasOwnProperty('snmpCommunity')) {
      const v = props.snmpCommunity;
      this.snmpCommunity = v;
    }

    this.snmpLocation = '';
    if (props.hasOwnProperty('snmpLocation')) {
      const v = props.snmpLocation;
      this.snmpLocation = v;
    }
  }

  getLoadAvg(): cwn_proc.LoadAvg {
    if (this.load_avg) {
      return this.load_avg;
    }
    return new cwn_proc.LoadAvg();
  }

  getMemInfo(): cwn_proc.MemInfo {
    if (this.mem_info) {
      return this.mem_info;
    }
    return new cwn_proc.MemInfo();
  }

  getEdgeOSInfo(): Status_EdgeOS {
    if (this.edge_o_s_info) {
      return this.edge_o_s_info;
    }
    return new Status_EdgeOS();
  }

  getToughSwitchInfo(): Status_ToughSwitch {
    if (this.tough_switch_info) {
      return this.tough_switch_info;
    }
    return new Status_ToughSwitch();
  }

  getIpAddr(): cwn_network.IPAddr {
    if (this.ip_addr) {
      return this.ip_addr;
    }
    return new cwn_network.IPAddr();
  }

  getMgmtIp(): cwn_network.IP {
    if (this.mgmt_ip) {
      return this.mgmt_ip;
    }
    return new cwn_network.IP();
  }
}
export {Status};

export const PortStatus_PoeOpModeValues = {
  UNKNOWN_POE_OPMODE: 0,
  POE_SHUTDOWN: 1,
  PASSIVE_24V_2PAIR: 2,
  PASSIVE_48V_2PAIR: 3,
  PASSIVE_24V_4PAIR: 4,
  PASSIVE_54V_4PAIR: 5,
  AUTO_POE_PLUS: 6,
};
type PortStatus_PoeOpMode = $Keys<typeof PortStatus_PoeOpModeValues>;
export type {PortStatus_PoeOpMode};

export function PortStatus_PoeOpModeValue(n: number): PortStatus_PoeOpMode {
  switch (n) {
    case 0:
      return 'UNKNOWN_POE_OPMODE';

    case 1:
      return 'POE_SHUTDOWN';

    case 2:
      return 'PASSIVE_24V_2PAIR';

    case 3:
      return 'PASSIVE_48V_2PAIR';

    case 4:
      return 'PASSIVE_24V_4PAIR';

    case 5:
      return 'PASSIVE_54V_4PAIR';

    case 6:
      return 'AUTO_POE_PLUS';

    default:
      return 'UNKNOWN_POE_OPMODE';
  }
}

class PortStatus_Stats {
  byte_count: string;
  packet_count: string;
  error_count: string;

  constructor(props: $Shape<PortStatus_Stats> = {}): void {
    if (!props) {
      props = {};
    }

    this.byte_count = '0';
    if (props.hasOwnProperty('byte_count')) {
      const v = props.byte_count;
      this.byte_count = v;
    }

    this.packet_count = '0';
    if (props.hasOwnProperty('packet_count')) {
      const v = props.packet_count;
      this.packet_count = v;
    }

    this.error_count = '0';
    if (props.hasOwnProperty('error_count')) {
      const v = props.error_count;
      this.error_count = v;
    }
  }
}
export {PortStatus_Stats};
class PortStatus_Power {
  watts: number;
  volts: number;
  milliamps: number;
  temp_c: string;

  constructor(props: $Shape<PortStatus_Power> = {}): void {
    if (!props) {
      props = {};
    }

    this.watts = 0;
    if (props.hasOwnProperty('watts')) {
      const v = props.watts;
      this.watts = v;
    }

    this.volts = 0;
    if (props.hasOwnProperty('volts')) {
      const v = props.volts;
      this.volts = v;
    }

    this.milliamps = 0;
    if (props.hasOwnProperty('milliamps')) {
      const v = props.milliamps;
      this.milliamps = v;
    }

    this.temp_c = '0';
    if (props.hasOwnProperty('temp_c')) {
      const v = props.temp_c;
      this.temp_c = v;
    }
  }
}
export {PortStatus_Power};
class PortStatus {
  interface_name: string;
  link_state: boolean;
  link_speed: string;
  duplex: boolean;
  auto_negotiation: boolean;
  poe_op_mode: PortStatus_PoeOpMode;
  port_enabled: boolean;
  rx_stats: ?PortStatus_Stats;
  tx_stats: ?PortStatus_Stats;
  power: ?PortStatus_Power;
  vlan_id: string;
  link_uptime_s: string;

  constructor(props: $Shape<PortStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.link_state = false;
    if (props.hasOwnProperty('link_state')) {
      const v = props.link_state;
      this.link_state = !!v;
    }

    this.link_speed = '0';
    if (props.hasOwnProperty('link_speed')) {
      const v = props.link_speed;
      this.link_speed = v;
    }

    this.duplex = false;
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = !!v;
    }

    this.auto_negotiation = false;
    if (props.hasOwnProperty('auto_negotiation')) {
      const v = props.auto_negotiation;
      this.auto_negotiation = !!v;
    }

    this.poe_op_mode = PortStatus_PoeOpModeValue(0);
    if (props.hasOwnProperty('poe_op_mode')) {
      const v = props.poe_op_mode;
      this.poe_op_mode = v;
    }

    this.port_enabled = false;
    if (props.hasOwnProperty('port_enabled')) {
      const v = props.port_enabled;
      this.port_enabled = !!v;
    }

    this.rx_stats = null;
    if (props.hasOwnProperty('rx_stats')) {
      const v = props.rx_stats;
      this.rx_stats = v && new PortStatus_Stats(v);
    }

    this.tx_stats = null;
    if (props.hasOwnProperty('tx_stats')) {
      const v = props.tx_stats;
      this.tx_stats = v && new PortStatus_Stats(v);
    }

    this.power = null;
    if (props.hasOwnProperty('power')) {
      const v = props.power;
      this.power = v && new PortStatus_Power(v);
    }

    this.vlan_id = '0';
    if (props.hasOwnProperty('vlan_id')) {
      const v = props.vlan_id;
      this.vlan_id = v;
    }

    this.link_uptime_s = '0';
    if (props.hasOwnProperty('link_uptime_s')) {
      const v = props.link_uptime_s;
      this.link_uptime_s = v;
    }
  }

  getRxStats(): PortStatus_Stats {
    if (this.rx_stats) {
      return this.rx_stats;
    }
    return new PortStatus_Stats();
  }

  getTxStats(): PortStatus_Stats {
    if (this.tx_stats) {
      return this.tx_stats;
    }
    return new PortStatus_Stats();
  }

  getPower(): PortStatus_Power {
    if (this.power) {
      return this.power;
    }
    return new PortStatus_Power();
  }
}
export {PortStatus};

class PortConfig_Port {
  interface: string;
  vlan_id: string;
  trunk: boolean;

  constructor(props: $Shape<PortConfig_Port> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface = '';
    if (props.hasOwnProperty('interface')) {
      const v = props.interface;
      this.interface = v;
    }

    this.vlan_id = '0';
    if (props.hasOwnProperty('vlan_id')) {
      const v = props.vlan_id;
      this.vlan_id = v;
    }

    this.trunk = false;
    if (props.hasOwnProperty('trunk')) {
      const v = props.trunk;
      this.trunk = !!v;
    }
  }
}
export {PortConfig_Port};
class PortConfig {
  ports: Array<PortConfig_Port>;

  constructor(props: $Shape<PortConfig> = {}): void {
    if (!props) {
      props = {};
    }

    this.ports = [];
    if (props.hasOwnProperty('ports')) {
      const v = props.ports;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ports should be array');
      }
      this.ports = v.map(function(v) {
        return new PortConfig_Port(v);
      });
    }
  }
}
export {PortConfig};

// END cwn/bridges/models.proto
