// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

import * as cwn_kml_style from '../../cwn/kml/style';

// START cwn/kml/models.proto

export const AltitudeModeValues = {
  CLAMP_TO_GROUND: 0,
  RELATIVE_TO_GROUND: 1,
  ABSOLUTE: 2,
  GX_RELATIVE_TO_SEA_FLOOR: 3,
  GX_CLAMP_TO_SEA_FLOOR: 4,
};
type AltitudeMode = $Keys<typeof AltitudeModeValues>;
export type {AltitudeMode};

export function AltitudeModeValue(n: number): AltitudeMode {
  switch (n) {
    case 0:
      return 'CLAMP_TO_GROUND';

    case 1:
      return 'RELATIVE_TO_GROUND';

    case 2:
      return 'ABSOLUTE';

    case 3:
      return 'GX_RELATIVE_TO_SEA_FLOOR';

    case 4:
      return 'GX_CLAMP_TO_SEA_FLOOR';

    default:
      return 'CLAMP_TO_GROUND';
  }
}

class Coord {
  lat: number;
  long: number;
  altitude: number;

  constructor(props: $Shape<Coord> = {}): void {
    if (!props) {
      props = {};
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.long = 0;
    if (props.hasOwnProperty('long')) {
      const v = props.long;
      this.long = v;
    }

    this.altitude = 0;
    if (props.hasOwnProperty('altitude')) {
      const v = props.altitude;
      this.altitude = v;
    }
  }
}
export {Coord};

class Coordinates {
  coordinates: Array<Coord>;

  constructor(props: $Shape<Coordinates> = {}): void {
    if (!props) {
      props = {};
    }

    this.coordinates = [];
    if (props.hasOwnProperty('coordinates')) {
      const v = props.coordinates;
      if (!Array.isArray(v)) {
        throw new Error('repeated field coordinates should be array');
      }
      this.coordinates = v.map(function(v) {
        return new Coord(v);
      });
    }
  }
}
export {Coordinates};

class Point {
  extrude: boolean;
  altitude_mode: AltitudeMode;
  coordinates: ?Coordinates;

  constructor(props: $Shape<Point> = {}): void {
    if (!props) {
      props = {};
    }

    this.extrude = false;
    if (props.hasOwnProperty('extrude')) {
      const v = props.extrude;
      this.extrude = !!v;
    }

    this.altitude_mode = AltitudeModeValue(0);
    if (props.hasOwnProperty('altitude_mode')) {
      const v = props.altitude_mode;
      this.altitude_mode = v;
    }

    this.coordinates = null;
    if (props.hasOwnProperty('coordinates')) {
      const v = props.coordinates;
      this.coordinates = v && new Coordinates(v);
    }
  }

  getCoordinates(): Coordinates {
    if (this.coordinates) {
      return this.coordinates;
    }
    return new Coordinates();
  }
}
export {Point};

class LineString {
  gx_altitude_offset: number;
  extrude: boolean;
  tessellate: boolean;
  altitude_mode: AltitudeMode;
  draw_order: string;
  coordinates: ?Coordinates;

  constructor(props: $Shape<LineString> = {}): void {
    if (!props) {
      props = {};
    }

    this.gx_altitude_offset = 0;
    if (props.hasOwnProperty('gx_altitude_offset')) {
      const v = props.gx_altitude_offset;
      this.gx_altitude_offset = v;
    }

    this.extrude = false;
    if (props.hasOwnProperty('extrude')) {
      const v = props.extrude;
      this.extrude = !!v;
    }

    this.tessellate = false;
    if (props.hasOwnProperty('tessellate')) {
      const v = props.tessellate;
      this.tessellate = !!v;
    }

    this.altitude_mode = AltitudeModeValue(0);
    if (props.hasOwnProperty('altitude_mode')) {
      const v = props.altitude_mode;
      this.altitude_mode = v;
    }

    this.draw_order = '0';
    if (props.hasOwnProperty('draw_order')) {
      const v = props.draw_order;
      this.draw_order = v;
    }

    this.coordinates = null;
    if (props.hasOwnProperty('coordinates')) {
      const v = props.coordinates;
      this.coordinates = v && new Coordinates(v);
    }
  }

  getCoordinates(): Coordinates {
    if (this.coordinates) {
      return this.coordinates;
    }
    return new Coordinates();
  }
}
export {LineString};

class OuterBoundaryIs {
  linear_ring: ?LinearRing;

  constructor(props: $Shape<OuterBoundaryIs> = {}): void {
    if (!props) {
      props = {};
    }

    this.linear_ring = null;
    if (props.hasOwnProperty('linear_ring')) {
      const v = props.linear_ring;
      this.linear_ring = v && new LinearRing(v);
    }
  }

  getLinearRing(): LinearRing {
    if (this.linear_ring) {
      return this.linear_ring;
    }
    return new LinearRing();
  }
}
export {OuterBoundaryIs};

class InnerBoundaryIs {
  linear_ring: ?LinearRing;

  constructor(props: $Shape<InnerBoundaryIs> = {}): void {
    if (!props) {
      props = {};
    }

    this.linear_ring = null;
    if (props.hasOwnProperty('linear_ring')) {
      const v = props.linear_ring;
      this.linear_ring = v && new LinearRing(v);
    }
  }

  getLinearRing(): LinearRing {
    if (this.linear_ring) {
      return this.linear_ring;
    }
    return new LinearRing();
  }
}
export {InnerBoundaryIs};

class Polygon {
  extrude: boolean;
  tessellate: boolean;
  altitude_mode: AltitudeMode;
  outer_boundary_is: ?OuterBoundaryIs;
  inner_boundary_is: Array<InnerBoundaryIs>;

  constructor(props: $Shape<Polygon> = {}): void {
    if (!props) {
      props = {};
    }

    this.extrude = false;
    if (props.hasOwnProperty('extrude')) {
      const v = props.extrude;
      this.extrude = !!v;
    }

    this.tessellate = false;
    if (props.hasOwnProperty('tessellate')) {
      const v = props.tessellate;
      this.tessellate = !!v;
    }

    this.altitude_mode = AltitudeModeValue(0);
    if (props.hasOwnProperty('altitude_mode')) {
      const v = props.altitude_mode;
      this.altitude_mode = v;
    }

    this.outer_boundary_is = null;
    if (props.hasOwnProperty('outer_boundary_is')) {
      const v = props.outer_boundary_is;
      this.outer_boundary_is = v && new OuterBoundaryIs(v);
    }

    this.inner_boundary_is = [];
    if (props.hasOwnProperty('inner_boundary_is')) {
      const v = props.inner_boundary_is;
      if (!Array.isArray(v)) {
        throw new Error('repeated field inner_boundary_is should be array');
      }
      this.inner_boundary_is = v.map(function(v) {
        return new InnerBoundaryIs(v);
      });
    }
  }

  getOuterBoundaryIs(): OuterBoundaryIs {
    if (this.outer_boundary_is) {
      return this.outer_boundary_is;
    }
    return new OuterBoundaryIs();
  }
}
export {Polygon};

class LinearRing {
  gx_altitude_offset: number;
  extrude: boolean;
  tessellate: boolean;
  altitude_mode: AltitudeMode;
  coordinates: ?Coordinates;

  constructor(props: $Shape<LinearRing> = {}): void {
    if (!props) {
      props = {};
    }

    this.gx_altitude_offset = 0;
    if (props.hasOwnProperty('gx_altitude_offset')) {
      const v = props.gx_altitude_offset;
      this.gx_altitude_offset = v;
    }

    this.extrude = false;
    if (props.hasOwnProperty('extrude')) {
      const v = props.extrude;
      this.extrude = !!v;
    }

    this.tessellate = false;
    if (props.hasOwnProperty('tessellate')) {
      const v = props.tessellate;
      this.tessellate = !!v;
    }

    this.altitude_mode = AltitudeModeValue(0);
    if (props.hasOwnProperty('altitude_mode')) {
      const v = props.altitude_mode;
      this.altitude_mode = v;
    }

    this.coordinates = null;
    if (props.hasOwnProperty('coordinates')) {
      const v = props.coordinates;
      this.coordinates = v && new Coordinates(v);
    }
  }

  getCoordinates(): Coordinates {
    if (this.coordinates) {
      return this.coordinates;
    }
    return new Coordinates();
  }
}
export {LinearRing};

class Geometry_MultiGeometry {
  geometries: Array<Geometry>;

  constructor(props: $Shape<Geometry_MultiGeometry> = {}): void {
    if (!props) {
      props = {};
    }

    this.geometries = [];
    if (props.hasOwnProperty('geometries')) {
      const v = props.geometries;
      if (!Array.isArray(v)) {
        throw new Error('repeated field geometries should be array');
      }
      this.geometries = v.map(function(v) {
        return new Geometry(v);
      });
    }
  }
}
export {Geometry_MultiGeometry};
class Geometry {
  _point: ?Point;
  _line_string: ?LineString;
  _multi_geometry: ?Geometry_MultiGeometry;
  _linear_ring: ?LinearRing;
  _polygon: ?Polygon;

  constructor(props: $Shape<Geometry> = {}): void {
    if (!props) {
      props = {};
    }

    this._point = undefined;
    if (props.hasOwnProperty('point')) {
      const v = props.point;
      this._point = v && new Point(v);
    }

    this._line_string = undefined;
    if (props.hasOwnProperty('line_string')) {
      const v = props.line_string;
      this._line_string = v && new LineString(v);
    }

    this._multi_geometry = undefined;
    if (props.hasOwnProperty('multi_geometry')) {
      const v = props.multi_geometry;
      this._multi_geometry = v && new Geometry_MultiGeometry(v);
    }

    this._linear_ring = undefined;
    if (props.hasOwnProperty('linear_ring')) {
      const v = props.linear_ring;
      this._linear_ring = v && new LinearRing(v);
    }

    this._polygon = undefined;
    if (props.hasOwnProperty('polygon')) {
      const v = props.polygon;
      this._polygon = v && new Polygon(v);
    }
  }

  getPoint(): Point {
    if (this._point) {
      return this._point;
    }
    return new Point();
  }

  getLineString(): LineString {
    if (this._line_string) {
      return this._line_string;
    }
    return new LineString();
  }

  getMultiGeometry(): Geometry_MultiGeometry {
    if (this._multi_geometry) {
      return this._multi_geometry;
    }
    return new Geometry_MultiGeometry();
  }

  getLinearRing(): LinearRing {
    if (this._linear_ring) {
      return this._linear_ring;
    }
    return new LinearRing();
  }

  getPolygon(): Polygon {
    if (this._polygon) {
      return this._polygon;
    }
    return new Polygon();
  }

  get point(): ?Point {
    return this._point;
  }
  set point(point: ?Point) {
    this._point = point;
    this._line_string = undefined;
    this._multi_geometry = undefined;
    this._linear_ring = undefined;
    this._polygon = undefined;
  }
  get line_string(): ?LineString {
    return this._line_string;
  }
  set line_string(line_string: ?LineString) {
    this._point = undefined;
    this._line_string = line_string;
    this._multi_geometry = undefined;
    this._linear_ring = undefined;
    this._polygon = undefined;
  }
  get multi_geometry(): ?Geometry_MultiGeometry {
    return this._multi_geometry;
  }
  set multi_geometry(multi_geometry: ?Geometry_MultiGeometry) {
    this._point = undefined;
    this._line_string = undefined;
    this._multi_geometry = multi_geometry;
    this._linear_ring = undefined;
    this._polygon = undefined;
  }
  get linear_ring(): ?LinearRing {
    return this._linear_ring;
  }
  set linear_ring(linear_ring: ?LinearRing) {
    this._point = undefined;
    this._line_string = undefined;
    this._multi_geometry = undefined;
    this._linear_ring = linear_ring;
    this._polygon = undefined;
  }
  get polygon(): ?Polygon {
    return this._polygon;
  }
  set polygon(polygon: ?Polygon) {
    this._point = undefined;
    this._line_string = undefined;
    this._multi_geometry = undefined;
    this._linear_ring = undefined;
    this._polygon = polygon;
  }

  toJSON() {
    return {
      point: this._point,
      line_string: this._line_string,
      multi_geometry: this._multi_geometry,
      linear_ring: this._linear_ring,
      polygon: this._polygon,
    };
  }
}
export {Geometry};

class LookAt {
  lat: number;
  long: number;
  altitude: number;
  heading: number;
  tilt: number;
  range: number;
  altitude_mode: AltitudeMode;

  constructor(props: $Shape<LookAt> = {}): void {
    if (!props) {
      props = {};
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.long = 0;
    if (props.hasOwnProperty('long')) {
      const v = props.long;
      this.long = v;
    }

    this.altitude = 0;
    if (props.hasOwnProperty('altitude')) {
      const v = props.altitude;
      this.altitude = v;
    }

    this.heading = 0;
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }

    this.tilt = 0;
    if (props.hasOwnProperty('tilt')) {
      const v = props.tilt;
      this.tilt = v;
    }

    this.range = 0;
    if (props.hasOwnProperty('range')) {
      const v = props.range;
      this.range = v;
    }

    this.altitude_mode = AltitudeModeValue(0);
    if (props.hasOwnProperty('altitude_mode')) {
      const v = props.altitude_mode;
      this.altitude_mode = v;
    }
  }
}
export {LookAt};

class Placemark {
  name: string;
  visibility: boolean;
  description: string;
  look_at: ?LookAt;
  style_url: string;
  geometry: ?Geometry;
  open: boolean;
  extended_data: ?ExtendedData;

  constructor(props: $Shape<Placemark> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.visibility = false;
    if (props.hasOwnProperty('visibility')) {
      const v = props.visibility;
      this.visibility = !!v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.look_at = null;
    if (props.hasOwnProperty('look_at')) {
      const v = props.look_at;
      this.look_at = v && new LookAt(v);
    }

    this.style_url = '';
    if (props.hasOwnProperty('style_url')) {
      const v = props.style_url;
      this.style_url = v;
    }

    this.geometry = null;
    if (props.hasOwnProperty('geometry')) {
      const v = props.geometry;
      this.geometry = v && new Geometry(v);
    }

    this.open = false;
    if (props.hasOwnProperty('open')) {
      const v = props.open;
      this.open = !!v;
    }

    this.extended_data = null;
    if (props.hasOwnProperty('extended_data')) {
      const v = props.extended_data;
      this.extended_data = v && new ExtendedData(v);
    }
  }

  getLookAt(): LookAt {
    if (this.look_at) {
      return this.look_at;
    }
    return new LookAt();
  }

  getGeometry(): Geometry {
    if (this.geometry) {
      return this.geometry;
    }
    return new Geometry();
  }

  getExtendedData(): ExtendedData {
    if (this.extended_data) {
      return this.extended_data;
    }
    return new ExtendedData();
  }
}
export {Placemark};

class Folder {
  name: string;
  visibility: boolean;
  open: boolean;
  placemarks: Array<Placemark>;

  constructor(props: $Shape<Folder> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.visibility = false;
    if (props.hasOwnProperty('visibility')) {
      const v = props.visibility;
      this.visibility = !!v;
    }

    this.open = false;
    if (props.hasOwnProperty('open')) {
      const v = props.open;
      this.open = !!v;
    }

    this.placemarks = [];
    if (props.hasOwnProperty('placemarks')) {
      const v = props.placemarks;
      if (!Array.isArray(v)) {
        throw new Error('repeated field placemarks should be array');
      }
      this.placemarks = v.map(function(v) {
        return new Placemark(v);
      });
    }
  }
}
export {Folder};

class Document {
  folders: Array<Folder>;
  styles: Array<cwn_kml_style.StyleSelector>;

  constructor(props: $Shape<Document> = {}): void {
    if (!props) {
      props = {};
    }

    this.folders = [];
    if (props.hasOwnProperty('folders')) {
      const v = props.folders;
      if (!Array.isArray(v)) {
        throw new Error('repeated field folders should be array');
      }
      this.folders = v.map(function(v) {
        return new Folder(v);
      });
    }

    this.styles = [];
    if (props.hasOwnProperty('styles')) {
      const v = props.styles;
      if (!Array.isArray(v)) {
        throw new Error('repeated field styles should be array');
      }
      this.styles = v.map(function(v) {
        return new cwn_kml_style.StyleSelector(v);
      });
    }
  }
}
export {Document};

class ExtendedData {
  data: ?Data;

  constructor(props: $Shape<ExtendedData> = {}): void {
    if (!props) {
      props = {};
    }

    this.data = null;
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      this.data = v && new Data(v);
    }
  }

  getData(): Data {
    if (this.data) {
      return this.data;
    }
    return new Data();
  }
}
export {ExtendedData};

class Data {
  display_name: string;
  value: string;

  constructor(props: $Shape<Data> = {}): void {
    if (!props) {
      props = {};
    }

    this.display_name = '';
    if (props.hasOwnProperty('display_name')) {
      const v = props.display_name;
      this.display_name = v;
    }

    this.value = '';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {Data};

// END cwn/kml/models.proto
