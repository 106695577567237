// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/stripe/models.proto

class Card {
  id: string;
  name: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  customer_id: string;
  fingerprint: string;

  constructor(props: $Shape<Card> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.brand = '';
    if (props.hasOwnProperty('brand')) {
      const v = props.brand;
      this.brand = v;
    }

    this.last4 = '';
    if (props.hasOwnProperty('last4')) {
      const v = props.last4;
      this.last4 = v;
    }

    this.exp_month = 0;
    if (props.hasOwnProperty('exp_month')) {
      const v = props.exp_month;
      this.exp_month = v;
    }

    this.exp_year = 0;
    if (props.hasOwnProperty('exp_year')) {
      const v = props.exp_year;
      this.exp_year = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.fingerprint = '';
    if (props.hasOwnProperty('fingerprint')) {
      const v = props.fingerprint;
      this.fingerprint = v;
    }
  }
}
export {Card};

class Charge {
  id: string;
  amount: string;
  captured: boolean;
  created: ?Date;
  currency: string;
  description: string;
  failure_code: string;
  failure_message: string;

  constructor(props: $Shape<Charge> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.amount = '0';
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v;
    }

    this.captured = false;
    if (props.hasOwnProperty('captured')) {
      const v = props.captured;
      this.captured = !!v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.currency = '';
    if (props.hasOwnProperty('currency')) {
      const v = props.currency;
      this.currency = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.failure_code = '';
    if (props.hasOwnProperty('failure_code')) {
      const v = props.failure_code;
      this.failure_code = v;
    }

    this.failure_message = '';
    if (props.hasOwnProperty('failure_message')) {
      const v = props.failure_message;
      this.failure_message = v;
    }
  }
}
export {Charge};

class Error {
  type: string;
  charge: string;
  message: string;
  code: string;
  decline_code: string;
  param: string;

  constructor(props: $Shape<Error> = {}): void {
    if (!props) {
      props = {};
    }

    this.type = '';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.charge = '';
    if (props.hasOwnProperty('charge')) {
      const v = props.charge;
      this.charge = v;
    }

    this.message = '';
    if (props.hasOwnProperty('message')) {
      const v = props.message;
      this.message = v;
    }

    this.code = '';
    if (props.hasOwnProperty('code')) {
      const v = props.code;
      this.code = v;
    }

    this.decline_code = '';
    if (props.hasOwnProperty('decline_code')) {
      const v = props.decline_code;
      this.decline_code = v;
    }

    this.param = '';
    if (props.hasOwnProperty('param')) {
      const v = props.param;
      this.param = v;
    }
  }
}
export {Error};

class Customer {
  id: string;

  constructor(props: $Shape<Customer> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {Customer};

// END cwn/stripe/models.proto
