// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_log from '../../cwn/log';
import * as cwn_asset from '../../cwn/asset';

// START cwn/health/models.proto

export const ConditionValues = {
  INVALID: 0,
  OK: 1,
  STALE_HEARTBEAT: 2,
  MISSING_SITE: 3,
  SITE_MISSING_DEVICE: 4,
  MISSING_DEVICE: 5,
  STALE_MODULE_RUN_STATUS: 6,
  STALE_MODULE_RUN: 7,
  RADIO_ON_WRONG_FREQUENCY: 11,
  CONFIGURED_RADIO_NOT_CONNECTED: 12,
  CONFIG_JSON_OVERRIDE: 13,
  RO_ROOT_MOUNT: 14,
  NET_DEV_ERRORS: 15,
  LOW_MEMORY: 16,
  HIGH_TEMP: 17,
  LOW_LINK_SPEED_ON_DEVICE: 18,
  LOW_LINK_SPEED_ON_SWITCH: 19,
  LOW_LINK_SPEED_ON_RADIO: 20,
  MISSING_RADIO: 21,
  RADIO_NOT_CONFIGURED: 22,
  UNEXPECTED_CONFIGURED_RADIO: 23,
  LONG_RADIO_UPTIME: 24,
  SOURCE_RELAY_OVERRIDE: 25,
  HIGH_POWER_ON_SWITCH: 26,
  STALE_HEARTBEAT_UPSTREAM: 27,
  STALE_HEARTBEAT_POWER: 28,
  MISSING_EXPECTED_PEER: 29,
  UNEXPECTED_PEERING: 30,
  NO_RECENT_STATUS_FOR_RADIO: 31,
  DEVICE_HAS_CWN_MAN_ROUTE: 32,
  LOW_LINK_SPEED_ON_AP: 33,
  OVERLAPPING_FREQUENCY: 34,
  NEED_MORE_SWITCH_PORTS: 35,
  DUPLICATE_RADIO_SRLS: 36,
  DNSMASQ_UNREACHABLE: 37,
  MISSING_AGENT_LOGS: 38,
};
type Condition = $Keys<typeof ConditionValues>;
export type {Condition};

export function ConditionValue(n: number): Condition {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'OK';

    case 2:
      return 'STALE_HEARTBEAT';

    case 3:
      return 'MISSING_SITE';

    case 4:
      return 'SITE_MISSING_DEVICE';

    case 5:
      return 'MISSING_DEVICE';

    case 6:
      return 'STALE_MODULE_RUN_STATUS';

    case 7:
      return 'STALE_MODULE_RUN';

    case 11:
      return 'RADIO_ON_WRONG_FREQUENCY';

    case 12:
      return 'CONFIGURED_RADIO_NOT_CONNECTED';

    case 13:
      return 'CONFIG_JSON_OVERRIDE';

    case 14:
      return 'RO_ROOT_MOUNT';

    case 15:
      return 'NET_DEV_ERRORS';

    case 16:
      return 'LOW_MEMORY';

    case 17:
      return 'HIGH_TEMP';

    case 18:
      return 'LOW_LINK_SPEED_ON_DEVICE';

    case 19:
      return 'LOW_LINK_SPEED_ON_SWITCH';

    case 20:
      return 'LOW_LINK_SPEED_ON_RADIO';

    case 21:
      return 'MISSING_RADIO';

    case 22:
      return 'RADIO_NOT_CONFIGURED';

    case 23:
      return 'UNEXPECTED_CONFIGURED_RADIO';

    case 24:
      return 'LONG_RADIO_UPTIME';

    case 25:
      return 'SOURCE_RELAY_OVERRIDE';

    case 26:
      return 'HIGH_POWER_ON_SWITCH';

    case 27:
      return 'STALE_HEARTBEAT_UPSTREAM';

    case 28:
      return 'STALE_HEARTBEAT_POWER';

    case 29:
      return 'MISSING_EXPECTED_PEER';

    case 30:
      return 'UNEXPECTED_PEERING';

    case 31:
      return 'NO_RECENT_STATUS_FOR_RADIO';

    case 32:
      return 'DEVICE_HAS_CWN_MAN_ROUTE';

    case 33:
      return 'LOW_LINK_SPEED_ON_AP';

    case 34:
      return 'OVERLAPPING_FREQUENCY';

    case 35:
      return 'NEED_MORE_SWITCH_PORTS';

    case 36:
      return 'DUPLICATE_RADIO_SRLS';

    case 37:
      return 'DNSMASQ_UNREACHABLE';

    case 38:
      return 'MISSING_AGENT_LOGS';

    default:
      return 'INVALID';
  }
}

export const SiteRadioLinkStatusValues = {
  UNKNOWN: 0,
  UP: 1,
  RADIO_DOWN: 2,
  SITE_DOWN: 3,
};
type SiteRadioLinkStatus = $Keys<typeof SiteRadioLinkStatusValues>;
export type {SiteRadioLinkStatus};

export function SiteRadioLinkStatusValue(n: number): SiteRadioLinkStatus {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'UP';

    case 2:
      return 'RADIO_DOWN';

    case 3:
      return 'SITE_DOWN';

    default:
      return 'UNKNOWN';
  }
}

class MissingExpectedPeerData {
  remote_device_id: string;
  interface_name: string;

  constructor(props: $Shape<MissingExpectedPeerData> = {}): void {
    if (!props) {
      props = {};
    }

    this.remote_device_id = '';
    if (props.hasOwnProperty('remote_device_id')) {
      const v = props.remote_device_id;
      this.remote_device_id = v;
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }
  }
}
export {MissingExpectedPeerData};

class UnexpectedPeeringData {
  remote_device_id: string;
  interface_name: string;

  constructor(props: $Shape<UnexpectedPeeringData> = {}): void {
    if (!props) {
      props = {};
    }

    this.remote_device_id = '';
    if (props.hasOwnProperty('remote_device_id')) {
      const v = props.remote_device_id;
      this.remote_device_id = v;
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }
  }
}
export {UnexpectedPeeringData};

export const SLA_StatusValues = {
  UNKNOWN_SLA: 0,
  GOOD: 1,
  SIGNIFICANT_OUTAGE: 2,
  FREQUENT_OUTAGES: 3,
  LOW_SPEEDS: 4,
};
type SLA_Status = $Keys<typeof SLA_StatusValues>;
export type {SLA_Status};

export function SLA_StatusValue(n: number): SLA_Status {
  switch (n) {
    case 0:
      return 'UNKNOWN_SLA';

    case 1:
      return 'GOOD';

    case 2:
      return 'SIGNIFICANT_OUTAGE';

    case 3:
      return 'FREQUENT_OUTAGES';

    case 4:
      return 'LOW_SPEEDS';

    default:
      return 'UNKNOWN_SLA';
  }
}

class SLA {
  status: SLA_Status;
  description: string;

  constructor(props: $Shape<SLA> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = SLA_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }
  }
}
export {SLA};

class SiteArgs {
  site_id: string;

  constructor(props: $Shape<SiteArgs> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {SiteArgs};

class Diagnosis_Metadata {
  site_id: string;
  boot_id: string;
  device_id: string;
  target_mac: string;
  subscription_ids: Array<string>;
  area_id: string;
  model: cwn_asset.Model;
  target_interface: string;

  constructor(props: $Shape<Diagnosis_Metadata> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.boot_id = '';
    if (props.hasOwnProperty('boot_id')) {
      const v = props.boot_id;
      this.boot_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.target_mac = '';
    if (props.hasOwnProperty('target_mac')) {
      const v = props.target_mac;
      this.target_mac = v;
    }

    this.subscription_ids = [];
    if (props.hasOwnProperty('subscription_ids')) {
      const v = props.subscription_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field subscription_ids should be array');
      }
      this.subscription_ids = v.map(function(v) {
        return v;
      });
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }

    this.model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }

    this.target_interface = '';
    if (props.hasOwnProperty('target_interface')) {
      const v = props.target_interface;
      this.target_interface = v;
    }
  }
}
export {Diagnosis_Metadata};
class Diagnosis_HighTemp {
  hwMonName: string;
  tempName: string;
  tempLabel: string;
  tempInput: string;
  baseline: string;

  constructor(props: $Shape<Diagnosis_HighTemp> = {}): void {
    if (!props) {
      props = {};
    }

    this.hwMonName = '';
    if (props.hasOwnProperty('hwMonName')) {
      const v = props.hwMonName;
      this.hwMonName = v;
    }

    this.tempName = '';
    if (props.hasOwnProperty('tempName')) {
      const v = props.tempName;
      this.tempName = v;
    }

    this.tempLabel = '';
    if (props.hasOwnProperty('tempLabel')) {
      const v = props.tempLabel;
      this.tempLabel = v;
    }

    this.tempInput = '0';
    if (props.hasOwnProperty('tempInput')) {
      const v = props.tempInput;
      this.tempInput = v;
    }

    this.baseline = '0';
    if (props.hasOwnProperty('baseline')) {
      const v = props.baseline;
      this.baseline = v;
    }
  }
}
export {Diagnosis_HighTemp};
class Diagnosis_Symptoms {
  last_heartbeat: ?Date;
  high_temps: Array<Diagnosis_HighTemp>;

  constructor(props: $Shape<Diagnosis_Symptoms> = {}): void {
    if (!props) {
      props = {};
    }

    this.last_heartbeat = null;
    if (props.hasOwnProperty('last_heartbeat')) {
      const v = props.last_heartbeat;
      this.last_heartbeat = v && new Date(v);
    }

    this.high_temps = [];
    if (props.hasOwnProperty('high_temps')) {
      const v = props.high_temps;
      if (!Array.isArray(v)) {
        throw new Error('repeated field high_temps should be array');
      }
      this.high_temps = v.map(function(v) {
        return new Diagnosis_HighTemp(v);
      });
    }
  }
}
export {Diagnosis_Symptoms};
class Diagnosis {
  created: ?Date;
  condition: Condition;
  description: string;
  metadata: ?Diagnosis_Metadata;
  symptoms: ?Diagnosis_Symptoms;
  log_lines: Array<cwn_log.Line>;
  id: string;
  end: ?Date;
  precluded_by: Condition;
  _missing_expected_peer_data: ?MissingExpectedPeerData;
  _unexpected_peering_data: ?UnexpectedPeeringData;

  constructor(props: $Shape<Diagnosis> = {}): void {
    if (!props) {
      props = {};
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.condition = ConditionValue(0);
    if (props.hasOwnProperty('condition')) {
      const v = props.condition;
      this.condition = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.metadata = null;
    if (props.hasOwnProperty('metadata')) {
      const v = props.metadata;
      this.metadata = v && new Diagnosis_Metadata(v);
    }

    this.symptoms = null;
    if (props.hasOwnProperty('symptoms')) {
      const v = props.symptoms;
      this.symptoms = v && new Diagnosis_Symptoms(v);
    }

    this.log_lines = [];
    if (props.hasOwnProperty('log_lines')) {
      const v = props.log_lines;
      if (!Array.isArray(v)) {
        throw new Error('repeated field log_lines should be array');
      }
      this.log_lines = v.map(function(v) {
        return new cwn_log.Line(v);
      });
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.end = null;
    if (props.hasOwnProperty('end')) {
      const v = props.end;
      this.end = v && new Date(v);
    }

    this.precluded_by = ConditionValue(0);
    if (props.hasOwnProperty('precluded_by')) {
      const v = props.precluded_by;
      this.precluded_by = v;
    }

    this._missing_expected_peer_data = undefined;
    if (props.hasOwnProperty('missing_expected_peer_data')) {
      const v = props.missing_expected_peer_data;
      this._missing_expected_peer_data = v && new MissingExpectedPeerData(v);
    }

    this._unexpected_peering_data = undefined;
    if (props.hasOwnProperty('unexpected_peering_data')) {
      const v = props.unexpected_peering_data;
      this._unexpected_peering_data = v && new UnexpectedPeeringData(v);
    }
  }

  getMetadata(): Diagnosis_Metadata {
    if (this.metadata) {
      return this.metadata;
    }
    return new Diagnosis_Metadata();
  }

  getSymptoms(): Diagnosis_Symptoms {
    if (this.symptoms) {
      return this.symptoms;
    }
    return new Diagnosis_Symptoms();
  }

  getMissingExpectedPeerData(): MissingExpectedPeerData {
    if (this._missing_expected_peer_data) {
      return this._missing_expected_peer_data;
    }
    return new MissingExpectedPeerData();
  }

  getUnexpectedPeeringData(): UnexpectedPeeringData {
    if (this._unexpected_peering_data) {
      return this._unexpected_peering_data;
    }
    return new UnexpectedPeeringData();
  }

  get missing_expected_peer_data(): ?MissingExpectedPeerData {
    return this._missing_expected_peer_data;
  }
  set missing_expected_peer_data(
    missing_expected_peer_data: ?MissingExpectedPeerData,
  ) {
    this._missing_expected_peer_data = missing_expected_peer_data;
    this._unexpected_peering_data = undefined;
  }
  get unexpected_peering_data(): ?UnexpectedPeeringData {
    return this._unexpected_peering_data;
  }
  set unexpected_peering_data(unexpected_peering_data: ?UnexpectedPeeringData) {
    this._missing_expected_peer_data = undefined;
    this._unexpected_peering_data = unexpected_peering_data;
  }

  toJSON() {
    return {
      created: this.created,
      condition: this.condition,
      description: this.description,
      metadata: this.metadata,
      symptoms: this.symptoms,
      log_lines: this.log_lines,
      id: this.id,
      end: this.end,
      precluded_by: this.precluded_by,
      missing_expected_peer_data: this._missing_expected_peer_data,
      unexpected_peering_data: this._unexpected_peering_data,
    };
  }
}
export {Diagnosis};

class SiteHealth {
  site_id: string;
  updated: ?Date;
  diagnosis: ?Diagnosis;
  created: ?Date;
  sla: ?SLA;

  constructor(props: $Shape<SiteHealth> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.diagnosis = null;
    if (props.hasOwnProperty('diagnosis')) {
      const v = props.diagnosis;
      this.diagnosis = v && new Diagnosis(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.sla = null;
    if (props.hasOwnProperty('sla')) {
      const v = props.sla;
      this.sla = v && new SLA(v);
    }
  }

  getDiagnosis(): Diagnosis {
    if (this.diagnosis) {
      return this.diagnosis;
    }
    return new Diagnosis();
  }

  getSla(): SLA {
    if (this.sla) {
      return this.sla;
    }
    return new SLA();
  }
}
export {SiteHealth};

class SiteHealthHistory {
  id: string;
  created: ?Date;
  site_health: ?SiteHealth;
  description: string;

  constructor(props: $Shape<SiteHealthHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.site_health = null;
    if (props.hasOwnProperty('site_health')) {
      const v = props.site_health;
      this.site_health = v && new SiteHealth(v);
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }
  }

  getSiteHealth(): SiteHealth {
    if (this.site_health) {
      return this.site_health;
    }
    return new SiteHealth();
  }
}
export {SiteHealthHistory};

class Prescription {
  id: string;
  created: ?Date;
  action_id: string;
  site_health: ?SiteHealth;
  next_seq: string;
  diagnoses: Array<Diagnosis>;

  constructor(props: $Shape<Prescription> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.action_id = '0';
    if (props.hasOwnProperty('action_id')) {
      const v = props.action_id;
      this.action_id = v;
    }

    this.site_health = null;
    if (props.hasOwnProperty('site_health')) {
      const v = props.site_health;
      this.site_health = v && new SiteHealth(v);
    }

    this.next_seq = '0';
    if (props.hasOwnProperty('next_seq')) {
      const v = props.next_seq;
      this.next_seq = v;
    }

    this.diagnoses = [];
    if (props.hasOwnProperty('diagnoses')) {
      const v = props.diagnoses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field diagnoses should be array');
      }
      this.diagnoses = v.map(function(v) {
        return new Diagnosis(v);
      });
    }
  }

  getSiteHealth(): SiteHealth {
    if (this.site_health) {
      return this.site_health;
    }
    return new SiteHealth();
  }
}
export {Prescription};

// END cwn/health/models.proto
