// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/tc/models.proto

class Qdisc_Tbf {
  rate: string;
  limit: string;
  buffer: string;
  peakrate: string;
  minburst: string;

  constructor(props: $Shape<Qdisc_Tbf> = {}): void {
    if (!props) {
      props = {};
    }

    this.rate = '0';
    if (props.hasOwnProperty('rate')) {
      const v = props.rate;
      this.rate = v;
    }

    this.limit = '0';
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }

    this.buffer = '0';
    if (props.hasOwnProperty('buffer')) {
      const v = props.buffer;
      this.buffer = v;
    }

    this.peakrate = '0';
    if (props.hasOwnProperty('peakrate')) {
      const v = props.peakrate;
      this.peakrate = v;
    }

    this.minburst = '0';
    if (props.hasOwnProperty('minburst')) {
      const v = props.minburst;
      this.minburst = v;
    }
  }
}
export {Qdisc_Tbf};
class Qdisc {
  link_index: string;
  handle: string;
  parent: string;
  refcnt: string;
  _tbf: ?Qdisc_Tbf;

  constructor(props: $Shape<Qdisc> = {}): void {
    if (!props) {
      props = {};
    }

    this.link_index = '0';
    if (props.hasOwnProperty('link_index')) {
      const v = props.link_index;
      this.link_index = v;
    }

    this.handle = '0';
    if (props.hasOwnProperty('handle')) {
      const v = props.handle;
      this.handle = v;
    }

    this.parent = '0';
    if (props.hasOwnProperty('parent')) {
      const v = props.parent;
      this.parent = v;
    }

    this.refcnt = '0';
    if (props.hasOwnProperty('refcnt')) {
      const v = props.refcnt;
      this.refcnt = v;
    }

    this._tbf = undefined;
    if (props.hasOwnProperty('tbf')) {
      const v = props.tbf;
      this._tbf = v && new Qdisc_Tbf(v);
    }
  }

  getTbf(): Qdisc_Tbf {
    if (this._tbf) {
      return this._tbf;
    }
    return new Qdisc_Tbf();
  }

  get tbf(): ?Qdisc_Tbf {
    return this._tbf;
  }
  set tbf(tbf: ?Qdisc_Tbf) {
    this._tbf = tbf;
  }

  toJSON() {
    return {
      link_index: this.link_index,
      handle: this.handle,
      parent: this.parent,
      refcnt: this.refcnt,
      tbf: this._tbf,
    };
  }
}
export {Qdisc};

// END cwn/tc/models.proto
