// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_devices from '../../cwn/devices';

// START cwn/network/models.proto

export const LayerValues = {
  INVALID: 0,
  LAYER_A: 1,
  LAYER_B: 2,
  LAYER_C: 3,
  LAYER_D: 4,
  LAYER_Y: 25,
  LAYER_Z: 26,
};
type Layer = $Keys<typeof LayerValues>;
export type {Layer};

export function LayerValue(n: number): Layer {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'LAYER_A';

    case 2:
      return 'LAYER_B';

    case 3:
      return 'LAYER_C';

    case 4:
      return 'LAYER_D';

    case 25:
      return 'LAYER_Y';

    case 26:
      return 'LAYER_Z';

    default:
      return 'INVALID';
  }
}

class IP {
  address: string;

  constructor(props: $Shape<IP> = {}): void {
    if (!props) {
      props = {};
    }

    this.address = '';
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v;
    }
  }
}
export {IP};

class IPAddr {
  ip: ?IP;
  zone: string;

  constructor(props: $Shape<IPAddr> = {}): void {
    if (!props) {
      props = {};
    }

    this.ip = null;
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v && new IP(v);
    }

    this.zone = '';
    if (props.hasOwnProperty('zone')) {
      const v = props.zone;
      this.zone = v;
    }
  }

  getIp(): IP {
    if (this.ip) {
      return this.ip;
    }
    return new IP();
  }
}
export {IPAddr};

class IPMask {
  address: string;

  constructor(props: $Shape<IPMask> = {}): void {
    if (!props) {
      props = {};
    }

    this.address = '';
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v;
    }
  }
}
export {IPMask};

class IPNet {
  ip: ?IP;
  mask: ?IPMask;

  constructor(props: $Shape<IPNet> = {}): void {
    if (!props) {
      props = {};
    }

    this.ip = null;
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v && new IP(v);
    }

    this.mask = null;
    if (props.hasOwnProperty('mask')) {
      const v = props.mask;
      this.mask = v && new IPMask(v);
    }
  }

  getIp(): IP {
    if (this.ip) {
      return this.ip;
    }
    return new IP();
  }

  getMask(): IPMask {
    if (this.mask) {
      return this.mask;
    }
    return new IPMask();
  }
}
export {IPNet};

export const Interface_DuplexValues = {
  UNKNOWN: 0,
  HALF: 1,
  FULL: 2,
};
type Interface_Duplex = $Keys<typeof Interface_DuplexValues>;
export type {Interface_Duplex};

export function Interface_DuplexValue(n: number): Interface_Duplex {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'HALF';

    case 2:
      return 'FULL';

    default:
      return 'UNKNOWN';
  }
}

class Interface_Parsed_Flags {
  up: boolean;
  broadcast: boolean;
  loopback: boolean;
  point_to_point: boolean;
  multicast: boolean;

  constructor(props: $Shape<Interface_Parsed_Flags> = {}): void {
    if (!props) {
      props = {};
    }

    this.up = false;
    if (props.hasOwnProperty('up')) {
      const v = props.up;
      this.up = !!v;
    }

    this.broadcast = false;
    if (props.hasOwnProperty('broadcast')) {
      const v = props.broadcast;
      this.broadcast = !!v;
    }

    this.loopback = false;
    if (props.hasOwnProperty('loopback')) {
      const v = props.loopback;
      this.loopback = !!v;
    }

    this.point_to_point = false;
    if (props.hasOwnProperty('point_to_point')) {
      const v = props.point_to_point;
      this.point_to_point = !!v;
    }

    this.multicast = false;
    if (props.hasOwnProperty('multicast')) {
      const v = props.multicast;
      this.multicast = !!v;
    }
  }
}
export {Interface_Parsed_Flags};
class Interface_Parsed {
  interface: ?cwn_devices.Interface;
  flags: ?Interface_Parsed_Flags;
  addresses: Array<IPNet>;

  constructor(props: $Shape<Interface_Parsed> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface = null;
    if (props.hasOwnProperty('interface')) {
      const v = props.interface;
      this.interface = v && new cwn_devices.Interface(v);
    }

    this.flags = null;
    if (props.hasOwnProperty('flags')) {
      const v = props.flags;
      this.flags = v && new Interface_Parsed_Flags(v);
    }

    this.addresses = [];
    if (props.hasOwnProperty('addresses')) {
      const v = props.addresses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field addresses should be array');
      }
      this.addresses = v.map(function(v) {
        return new IPNet(v);
      });
    }
  }

  getInterface(): cwn_devices.Interface {
    if (this.interface) {
      return this.interface;
    }
    return new cwn_devices.Interface();
  }

  getFlags(): Interface_Parsed_Flags {
    if (this.flags) {
      return this.flags;
    }
    return new Interface_Parsed_Flags();
  }
}
export {Interface_Parsed};
class Interface {
  raw: string;
  parsed: ?Interface_Parsed;
  link_speed: number;
  index: string;
  virtual: boolean;
  type: string;
  duplex: Interface_Duplex;
  phy_up: boolean;

  constructor(props: $Shape<Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.raw = '';
    if (props.hasOwnProperty('raw')) {
      const v = props.raw;
      this.raw = v;
    }

    this.parsed = null;
    if (props.hasOwnProperty('parsed')) {
      const v = props.parsed;
      this.parsed = v && new Interface_Parsed(v);
    }

    this.link_speed = 0;
    if (props.hasOwnProperty('link_speed')) {
      const v = props.link_speed;
      this.link_speed = v;
    }

    this.index = '0';
    if (props.hasOwnProperty('index')) {
      const v = props.index;
      this.index = v;
    }

    this.virtual = false;
    if (props.hasOwnProperty('virtual')) {
      const v = props.virtual;
      this.virtual = !!v;
    }

    this.type = '0';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.duplex = Interface_DuplexValue(0);
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = v;
    }

    this.phy_up = false;
    if (props.hasOwnProperty('phy_up')) {
      const v = props.phy_up;
      this.phy_up = !!v;
    }
  }

  getParsed(): Interface_Parsed {
    if (this.parsed) {
      return this.parsed;
    }
    return new Interface_Parsed();
  }
}
export {Interface};

export const Route_Parsed_TypeValues = {
  UNICAST: 0,
  THROW: 1,
  BROADCAST: 2,
  LOCAL: 3,
  UNREACHABLE: 4,
  ANYCAST: 5,
  BLACKHOLE: 6,
};
type Route_Parsed_Type = $Keys<typeof Route_Parsed_TypeValues>;
export type {Route_Parsed_Type};

export function Route_Parsed_TypeValue(n: number): Route_Parsed_Type {
  switch (n) {
    case 0:
      return 'UNICAST';

    case 1:
      return 'THROW';

    case 2:
      return 'BROADCAST';

    case 3:
      return 'LOCAL';

    case 4:
      return 'UNREACHABLE';

    case 5:
      return 'ANYCAST';

    case 6:
      return 'BLACKHOLE';

    default:
      return 'UNICAST';
  }
}

export const Route_Parsed_ScopeValues = {
  GLOBAL: 0,
  SITE: 1,
  LINK: 2,
  HOST: 3,
  NOWHERE: 4,
};
type Route_Parsed_Scope = $Keys<typeof Route_Parsed_ScopeValues>;
export type {Route_Parsed_Scope};

export function Route_Parsed_ScopeValue(n: number): Route_Parsed_Scope {
  switch (n) {
    case 0:
      return 'GLOBAL';

    case 1:
      return 'SITE';

    case 2:
      return 'LINK';

    case 3:
      return 'HOST';

    case 4:
      return 'NOWHERE';

    default:
      return 'GLOBAL';
  }
}

class Route_Parsed_NextHop {
  via: ?IP;
  device: string;
  source: ?IP;

  constructor(props: $Shape<Route_Parsed_NextHop> = {}): void {
    if (!props) {
      props = {};
    }

    this.via = null;
    if (props.hasOwnProperty('via')) {
      const v = props.via;
      this.via = v && new IP(v);
    }

    this.device = '';
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v;
    }

    this.source = null;
    if (props.hasOwnProperty('source')) {
      const v = props.source;
      this.source = v && new IP(v);
    }
  }

  getVia(): IP {
    if (this.via) {
      return this.via;
    }
    return new IP();
  }

  getSource(): IP {
    if (this.source) {
      return this.source;
    }
    return new IP();
  }
}
export {Route_Parsed_NextHop};
class Route_Parsed {
  type: Route_Parsed_Type;
  prefix: ?IPNet;
  table: string;
  protocol: string;
  next_hop: ?Route_Parsed_NextHop;
  scope: Route_Parsed_Scope;
  metric: number;
  linkdown: boolean;

  constructor(props: $Shape<Route_Parsed> = {}): void {
    if (!props) {
      props = {};
    }

    this.type = Route_Parsed_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.prefix = null;
    if (props.hasOwnProperty('prefix')) {
      const v = props.prefix;
      this.prefix = v && new IPNet(v);
    }

    this.table = '';
    if (props.hasOwnProperty('table')) {
      const v = props.table;
      this.table = v;
    }

    this.protocol = '';
    if (props.hasOwnProperty('protocol')) {
      const v = props.protocol;
      this.protocol = v;
    }

    this.next_hop = null;
    if (props.hasOwnProperty('next_hop')) {
      const v = props.next_hop;
      this.next_hop = v && new Route_Parsed_NextHop(v);
    }

    this.scope = Route_Parsed_ScopeValue(0);
    if (props.hasOwnProperty('scope')) {
      const v = props.scope;
      this.scope = v;
    }

    this.metric = 0;
    if (props.hasOwnProperty('metric')) {
      const v = props.metric;
      this.metric = v;
    }

    this.linkdown = false;
    if (props.hasOwnProperty('linkdown')) {
      const v = props.linkdown;
      this.linkdown = !!v;
    }
  }

  getPrefix(): IPNet {
    if (this.prefix) {
      return this.prefix;
    }
    return new IPNet();
  }

  getNextHop(): Route_Parsed_NextHop {
    if (this.next_hop) {
      return this.next_hop;
    }
    return new Route_Parsed_NextHop();
  }
}
export {Route_Parsed};
class Route {
  raw: string;
  parsed: ?Route_Parsed;

  constructor(props: $Shape<Route> = {}): void {
    if (!props) {
      props = {};
    }

    this.raw = '';
    if (props.hasOwnProperty('raw')) {
      const v = props.raw;
      this.raw = v;
    }

    this.parsed = null;
    if (props.hasOwnProperty('parsed')) {
      const v = props.parsed;
      this.parsed = v && new Route_Parsed(v);
    }
  }

  getParsed(): Route_Parsed {
    if (this.parsed) {
      return this.parsed;
    }
    return new Route_Parsed();
  }
}
export {Route};

class DeviceNetworkStatus {
  device_id: string;
  created: ?Date;
  interfaces: Array<Interface>;
  routes: Array<Route>;
  updated: ?Date;
  all_tables_routes: Array<Route>;
  vlans: Array<Vlan>;

  constructor(props: $Shape<DeviceNetworkStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.interfaces = [];
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      if (!Array.isArray(v)) {
        throw new Error('repeated field interfaces should be array');
      }
      this.interfaces = v.map(function(v) {
        return new Interface(v);
      });
    }

    this.routes = [];
    if (props.hasOwnProperty('routes')) {
      const v = props.routes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field routes should be array');
      }
      this.routes = v.map(function(v) {
        return new Route(v);
      });
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.all_tables_routes = [];
    if (props.hasOwnProperty('all_tables_routes')) {
      const v = props.all_tables_routes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field all_tables_routes should be array');
      }
      this.all_tables_routes = v.map(function(v) {
        return new Route(v);
      });
    }

    this.vlans = [];
    if (props.hasOwnProperty('vlans')) {
      const v = props.vlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field vlans should be array');
      }
      this.vlans = v.map(function(v) {
        return new Vlan(v);
      });
    }
  }
}
export {DeviceNetworkStatus};

class HwAddr {
  address: string;

  constructor(props: $Shape<HwAddr> = {}): void {
    if (!props) {
      props = {};
    }

    this.address = '';
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v;
    }
  }
}
export {HwAddr};

export const Neighbor_StateFlagValues = {
  NONE: 0,
  INCOMPLETE: 1,
  REACHABLE: 2,
  STALE: 4,
  DELAY: 8,
  PROBE: 16,
  FAILED: 32,
  NOARP: 64,
  PERMANENT: 128,
};
type Neighbor_StateFlag = $Keys<typeof Neighbor_StateFlagValues>;
export type {Neighbor_StateFlag};

export function Neighbor_StateFlagValue(n: number): Neighbor_StateFlag {
  switch (n) {
    case 0:
      return 'NONE';

    case 1:
      return 'INCOMPLETE';

    case 2:
      return 'REACHABLE';

    case 4:
      return 'STALE';

    case 8:
      return 'DELAY';

    case 16:
      return 'PROBE';

    case 32:
      return 'FAILED';

    case 64:
      return 'NOARP';

    case 128:
      return 'PERMANENT';

    default:
      return 'NONE';
  }
}

class Neighbor {
  ip_addr: ?IPAddr;
  hw_addr: ?HwAddr;
  state: Neighbor_StateFlag;
  interface_name: string;

  constructor(props: $Shape<Neighbor> = {}): void {
    if (!props) {
      props = {};
    }

    this.ip_addr = null;
    if (props.hasOwnProperty('ip_addr')) {
      const v = props.ip_addr;
      this.ip_addr = v && new IPAddr(v);
    }

    this.hw_addr = null;
    if (props.hasOwnProperty('hw_addr')) {
      const v = props.hw_addr;
      this.hw_addr = v && new HwAddr(v);
    }

    this.state = Neighbor_StateFlagValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }
  }

  getIpAddr(): IPAddr {
    if (this.ip_addr) {
      return this.ip_addr;
    }
    return new IPAddr();
  }

  getHwAddr(): HwAddr {
    if (this.hw_addr) {
      return this.hw_addr;
    }
    return new HwAddr();
  }
}
export {Neighbor};

export const LinkAttrs_StateValues = {
  STATE_UNKNOWN: 0,
  STATE_NOT_PRESENT: 1,
  STATE_DOWN: 2,
  STATE_LOWER_LAYER_DOWN: 3,
  STATE_TESTING: 4,
  STATE_DORMANT: 5,
  STATE_UP: 6,
};
type LinkAttrs_State = $Keys<typeof LinkAttrs_StateValues>;
export type {LinkAttrs_State};

export function LinkAttrs_StateValue(n: number): LinkAttrs_State {
  switch (n) {
    case 0:
      return 'STATE_UNKNOWN';

    case 1:
      return 'STATE_NOT_PRESENT';

    case 2:
      return 'STATE_DOWN';

    case 3:
      return 'STATE_LOWER_LAYER_DOWN';

    case 4:
      return 'STATE_TESTING';

    case 5:
      return 'STATE_DORMANT';

    case 6:
      return 'STATE_UP';

    default:
      return 'STATE_UNKNOWN';
  }
}

export const LinkAttrs_TypeValues = {
  TYPE_UNKNOWN: 0,
  TYPE_DEVICE: 1,
  TYPE_VLAN: 2,
  TYPE_IP6GRETAP: 3,
};
type LinkAttrs_Type = $Keys<typeof LinkAttrs_TypeValues>;
export type {LinkAttrs_Type};

export function LinkAttrs_TypeValue(n: number): LinkAttrs_Type {
  switch (n) {
    case 0:
      return 'TYPE_UNKNOWN';

    case 1:
      return 'TYPE_DEVICE';

    case 2:
      return 'TYPE_VLAN';

    case 3:
      return 'TYPE_IP6GRETAP';

    default:
      return 'TYPE_UNKNOWN';
  }
}

class LinkAttrs {
  index: string;
  name: string;
  type: LinkAttrs_Type;
  hw_addr: ?HwAddr;
  state: LinkAttrs_State;
  parent_index: string;
  flags: string;
  raw_flags: string;
  mtu: string;
  txqlen: string;
  promisc: string;
  num_tx_queues: string;
  num_rx_queues: string;
  alias: string;
  encap_type: string;
  master_index: string;

  constructor(props: $Shape<LinkAttrs> = {}): void {
    if (!props) {
      props = {};
    }

    this.index = '0';
    if (props.hasOwnProperty('index')) {
      const v = props.index;
      this.index = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.type = LinkAttrs_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.hw_addr = null;
    if (props.hasOwnProperty('hw_addr')) {
      const v = props.hw_addr;
      this.hw_addr = v && new HwAddr(v);
    }

    this.state = LinkAttrs_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.parent_index = '0';
    if (props.hasOwnProperty('parent_index')) {
      const v = props.parent_index;
      this.parent_index = v;
    }

    this.flags = '0';
    if (props.hasOwnProperty('flags')) {
      const v = props.flags;
      this.flags = v;
    }

    this.raw_flags = '0';
    if (props.hasOwnProperty('raw_flags')) {
      const v = props.raw_flags;
      this.raw_flags = v;
    }

    this.mtu = '0';
    if (props.hasOwnProperty('mtu')) {
      const v = props.mtu;
      this.mtu = v;
    }

    this.txqlen = '0';
    if (props.hasOwnProperty('txqlen')) {
      const v = props.txqlen;
      this.txqlen = v;
    }

    this.promisc = '0';
    if (props.hasOwnProperty('promisc')) {
      const v = props.promisc;
      this.promisc = v;
    }

    this.num_tx_queues = '0';
    if (props.hasOwnProperty('num_tx_queues')) {
      const v = props.num_tx_queues;
      this.num_tx_queues = v;
    }

    this.num_rx_queues = '0';
    if (props.hasOwnProperty('num_rx_queues')) {
      const v = props.num_rx_queues;
      this.num_rx_queues = v;
    }

    this.alias = '';
    if (props.hasOwnProperty('alias')) {
      const v = props.alias;
      this.alias = v;
    }

    this.encap_type = '';
    if (props.hasOwnProperty('encap_type')) {
      const v = props.encap_type;
      this.encap_type = v;
    }

    this.master_index = '0';
    if (props.hasOwnProperty('master_index')) {
      const v = props.master_index;
      this.master_index = v;
    }
  }

  getHwAddr(): HwAddr {
    if (this.hw_addr) {
      return this.hw_addr;
    }
    return new HwAddr();
  }
}
export {LinkAttrs};

class LinkDevice {
  attrs: ?LinkAttrs;

  constructor(props: $Shape<LinkDevice> = {}): void {
    if (!props) {
      props = {};
    }

    this.attrs = null;
    if (props.hasOwnProperty('attrs')) {
      const v = props.attrs;
      this.attrs = v && new LinkAttrs(v);
    }
  }

  getAttrs(): LinkAttrs {
    if (this.attrs) {
      return this.attrs;
    }
    return new LinkAttrs();
  }
}
export {LinkDevice};

class Vlan {
  attrs: ?LinkAttrs;
  VlanId: string;

  constructor(props: $Shape<Vlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.attrs = null;
    if (props.hasOwnProperty('attrs')) {
      const v = props.attrs;
      this.attrs = v && new LinkAttrs(v);
    }

    this.VlanId = '0';
    if (props.hasOwnProperty('VlanId')) {
      const v = props.VlanId;
      this.VlanId = v;
    }
  }

  getAttrs(): LinkAttrs {
    if (this.attrs) {
      return this.attrs;
    }
    return new LinkAttrs();
  }
}
export {Vlan};

class IP6Gretap {
  local: ?IPAddr;
  remote: ?IPAddr;
  attrs: ?LinkAttrs;

  constructor(props: $Shape<IP6Gretap> = {}): void {
    if (!props) {
      props = {};
    }

    this.local = null;
    if (props.hasOwnProperty('local')) {
      const v = props.local;
      this.local = v && new IPAddr(v);
    }

    this.remote = null;
    if (props.hasOwnProperty('remote')) {
      const v = props.remote;
      this.remote = v && new IPAddr(v);
    }

    this.attrs = null;
    if (props.hasOwnProperty('attrs')) {
      const v = props.attrs;
      this.attrs = v && new LinkAttrs(v);
    }
  }

  getLocal(): IPAddr {
    if (this.local) {
      return this.local;
    }
    return new IPAddr();
  }

  getRemote(): IPAddr {
    if (this.remote) {
      return this.remote;
    }
    return new IPAddr();
  }

  getAttrs(): LinkAttrs {
    if (this.attrs) {
      return this.attrs;
    }
    return new LinkAttrs();
  }
}
export {IP6Gretap};

class LinkUnknown {
  attrs: ?LinkAttrs;

  constructor(props: $Shape<LinkUnknown> = {}): void {
    if (!props) {
      props = {};
    }

    this.attrs = null;
    if (props.hasOwnProperty('attrs')) {
      const v = props.attrs;
      this.attrs = v && new LinkAttrs(v);
    }
  }

  getAttrs(): LinkAttrs {
    if (this.attrs) {
      return this.attrs;
    }
    return new LinkAttrs();
  }
}
export {LinkUnknown};

class Geo {
  geofence_id: string;
  created: ?Date;
  updated: ?Date;
  inactive: boolean;
  area_id: string;

  constructor(props: $Shape<Geo> = {}): void {
    if (!props) {
      props = {};
    }

    this.geofence_id = '0';
    if (props.hasOwnProperty('geofence_id')) {
      const v = props.geofence_id;
      this.geofence_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.inactive = false;
    if (props.hasOwnProperty('inactive')) {
      const v = props.inactive;
      this.inactive = !!v;
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }
  }
}
export {Geo};

// END cwn/network/models.proto
