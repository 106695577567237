// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_radios from '../../cwn/radios';
import * as cwn_geocoder from '../../cwn/geocoder';
import * as cwn_customers from '../../cwn/customers';
import * as cwn_asset from '../../cwn/asset';
import * as cwn_antenna from '../../cwn/antenna';

// START cwn/site/models.proto

export const SiteRadioLink_CreatedByValues = {
  UNKNOWN: 0,
  ADMIN: 1,
  AUTOMATED_NETWORK_PLANNER: 2,
  BUILD_PLAN_NETWORK_PLANNER: 3,
};
type SiteRadioLink_CreatedBy = $Keys<typeof SiteRadioLink_CreatedByValues>;
export type {SiteRadioLink_CreatedBy};

export function SiteRadioLink_CreatedByValue(
  n: number,
): SiteRadioLink_CreatedBy {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'ADMIN';

    case 2:
      return 'AUTOMATED_NETWORK_PLANNER';

    case 3:
      return 'BUILD_PLAN_NETWORK_PLANNER';

    default:
      return 'UNKNOWN';
  }
}

class SiteRadioLink {
  id: string;
  created: ?Date;
  updated: ?Date;
  site_id: string;
  radio_group_id: string;
  radio_mac_address: string;
  heading: string;
  mode: cwn_radios.Mode;
  antenna: ?cwn_antenna.Antenna;
  asset_model: cwn_asset.Model;
  tx_power_dbm: number;
  mount_id: string;
  beam_width: string;
  traffic_interface: string;
  created_by: SiteRadioLink_CreatedBy;
  specific: ?google_protobuf.Any;

  constructor(props: $Shape<SiteRadioLink> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }

    this.radio_mac_address = '';
    if (props.hasOwnProperty('radio_mac_address')) {
      const v = props.radio_mac_address;
      this.radio_mac_address = v;
    }

    this.heading = '0';
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }

    this.mode = cwn_radios.ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.antenna = null;
    if (props.hasOwnProperty('antenna')) {
      const v = props.antenna;
      this.antenna = v && new cwn_antenna.Antenna(v);
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.tx_power_dbm = 0;
    if (props.hasOwnProperty('tx_power_dbm')) {
      const v = props.tx_power_dbm;
      this.tx_power_dbm = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.beam_width = '0';
    if (props.hasOwnProperty('beam_width')) {
      const v = props.beam_width;
      this.beam_width = v;
    }

    this.traffic_interface = '';
    if (props.hasOwnProperty('traffic_interface')) {
      const v = props.traffic_interface;
      this.traffic_interface = v;
    }

    this.created_by = SiteRadioLink_CreatedByValue(0);
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v;
    }

    this.specific = null;
    if (props.hasOwnProperty('specific')) {
      const v = props.specific;
      this.specific = v && new google_protobuf.Any(v);
    }
  }

  getAntenna(): cwn_antenna.Antenna {
    if (this.antenna) {
      return this.antenna;
    }
    return new cwn_antenna.Antenna();
  }

  getSpecific(): google_protobuf.Any {
    if (this.specific) {
      return this.specific;
    }
    return new google_protobuf.Any();
  }
}
export {SiteRadioLink};

class SiteRadioLinkHistory {
  id: string;
  site_radio_link: ?SiteRadioLink;
  deleted: boolean;

  constructor(props: $Shape<SiteRadioLinkHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_radio_link = null;
    if (props.hasOwnProperty('site_radio_link')) {
      const v = props.site_radio_link;
      this.site_radio_link = v && new SiteRadioLink(v);
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }
  }

  getSiteRadioLink(): SiteRadioLink {
    if (this.site_radio_link) {
      return this.site_radio_link;
    }
    return new SiteRadioLink();
  }
}
export {SiteRadioLinkHistory};

class SiteRadioLinks {
  site_radio_links: Array<SiteRadioLink>;

  constructor(props: $Shape<SiteRadioLinks> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_links = [];
    if (props.hasOwnProperty('site_radio_links')) {
      const v = props.site_radio_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_links should be array');
      }
      this.site_radio_links = v.map(function(v) {
        return new SiteRadioLink(v);
      });
    }
  }
}
export {SiteRadioLinks};

export const StatusV2_ServiceabilityValues = {
  UNKNOWN: 0,
  UNSERVICEABLE: 1,
  SERVICEABLE: 2,
  CANNOT_ASSESS: 4,
  RESURFACED: 5,
};
type StatusV2_Serviceability = $Keys<typeof StatusV2_ServiceabilityValues>;
export type {StatusV2_Serviceability};

export function StatusV2_ServiceabilityValue(
  n: number,
): StatusV2_Serviceability {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'UNSERVICEABLE';

    case 2:
      return 'SERVICEABLE';

    case 4:
      return 'CANNOT_ASSESS';

    case 5:
      return 'RESURFACED';

    default:
      return 'UNKNOWN';
  }
}

export const StatusV2_InstallationValues = {
  NOT_INSTALLED: 0,
  INSTALLED: 1,
  BOOKED_FOR_INSTALL: 2,
};
type StatusV2_Installation = $Keys<typeof StatusV2_InstallationValues>;
export type {StatusV2_Installation};

export function StatusV2_InstallationValue(n: number): StatusV2_Installation {
  switch (n) {
    case 0:
      return 'NOT_INSTALLED';

    case 1:
      return 'INSTALLED';

    case 2:
      return 'BOOKED_FOR_INSTALL';

    default:
      return 'NOT_INSTALLED';
  }
}

export const StatusV2_ActivationValues = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
  DELETED: 2,
};
type StatusV2_Activation = $Keys<typeof StatusV2_ActivationValues>;
export type {StatusV2_Activation};

export function StatusV2_ActivationValue(n: number): StatusV2_Activation {
  switch (n) {
    case 0:
      return 'NOT_ACTIVE';

    case 1:
      return 'ACTIVE';

    case 2:
      return 'DELETED';

    default:
      return 'NOT_ACTIVE';
  }
}

export const StatusV2_PermissionValues = {
  UNKNOWN_PERMISSION: 0,
  REQUIRED_IN_PROGRESS: 1,
  REQUIRED_APPROVED: 2,
  REQUIRED_DENIED: 3,
};
type StatusV2_Permission = $Keys<typeof StatusV2_PermissionValues>;
export type {StatusV2_Permission};

export function StatusV2_PermissionValue(n: number): StatusV2_Permission {
  switch (n) {
    case 0:
      return 'UNKNOWN_PERMISSION';

    case 1:
      return 'REQUIRED_IN_PROGRESS';

    case 2:
      return 'REQUIRED_APPROVED';

    case 3:
      return 'REQUIRED_DENIED';

    default:
      return 'UNKNOWN_PERMISSION';
  }
}

export const StatusV2_PredictedServiceabilityValues = {
  NOT_PREDICTED: 0,
  PREDICTED_UNSERVICEABLE: 1,
  PREDICTED_SERVICEABLE: 2,
};
type StatusV2_PredictedServiceability = $Keys<
  typeof StatusV2_PredictedServiceabilityValues,
>;
export type {StatusV2_PredictedServiceability};

export function StatusV2_PredictedServiceabilityValue(
  n: number,
): StatusV2_PredictedServiceability {
  switch (n) {
    case 0:
      return 'NOT_PREDICTED';

    case 1:
      return 'PREDICTED_UNSERVICEABLE';

    case 2:
      return 'PREDICTED_SERVICEABLE';

    default:
      return 'NOT_PREDICTED';
  }
}

export const StatusV2_ReasonForServiceabilityValues = {
  UNKNOWN_SERVICEABILITY_REASON: 0,
  MANUALLY_REVIEWED: 1,
  LIDAR_PREDICTED: 2,
  LIDAR_PREDICTED_RESURFACED: 3,
  COMMERCIAL_BUILDING: 4,
  PLAN_OVERRIDE: 5,
};
type StatusV2_ReasonForServiceability = $Keys<
  typeof StatusV2_ReasonForServiceabilityValues,
>;
export type {StatusV2_ReasonForServiceability};

export function StatusV2_ReasonForServiceabilityValue(
  n: number,
): StatusV2_ReasonForServiceability {
  switch (n) {
    case 0:
      return 'UNKNOWN_SERVICEABILITY_REASON';

    case 1:
      return 'MANUALLY_REVIEWED';

    case 2:
      return 'LIDAR_PREDICTED';

    case 3:
      return 'LIDAR_PREDICTED_RESURFACED';

    case 4:
      return 'COMMERCIAL_BUILDING';

    case 5:
      return 'PLAN_OVERRIDE';

    default:
      return 'UNKNOWN_SERVICEABILITY_REASON';
  }
}

export const StatusV2_BuildingRestrictionValues = {
  UNKNOWN_BUILDING_RESTRICTION: 0,
  INSTALL_TOO_COMPLEX: 1,
  ROOF_UNSAFE: 2,
};
type StatusV2_BuildingRestriction = $Keys<
  typeof StatusV2_BuildingRestrictionValues,
>;
export type {StatusV2_BuildingRestriction};

export function StatusV2_BuildingRestrictionValue(
  n: number,
): StatusV2_BuildingRestriction {
  switch (n) {
    case 0:
      return 'UNKNOWN_BUILDING_RESTRICTION';

    case 1:
      return 'INSTALL_TOO_COMPLEX';

    case 2:
      return 'ROOF_UNSAFE';

    default:
      return 'UNKNOWN_BUILDING_RESTRICTION';
  }
}

class StatusV2 {
  serviceability: StatusV2_Serviceability;
  installation: StatusV2_Installation;
  activation: StatusV2_Activation;
  permission: StatusV2_Permission;
  predicted_serviceability: StatusV2_PredictedServiceability;
  reason_for_serviceability: StatusV2_ReasonForServiceability;
  building_restrictions: Array<StatusV2_BuildingRestriction>;

  constructor(props: $Shape<StatusV2> = {}): void {
    if (!props) {
      props = {};
    }

    this.serviceability = StatusV2_ServiceabilityValue(0);
    if (props.hasOwnProperty('serviceability')) {
      const v = props.serviceability;
      this.serviceability = v;
    }

    this.installation = StatusV2_InstallationValue(0);
    if (props.hasOwnProperty('installation')) {
      const v = props.installation;
      this.installation = v;
    }

    this.activation = StatusV2_ActivationValue(0);
    if (props.hasOwnProperty('activation')) {
      const v = props.activation;
      this.activation = v;
    }

    this.permission = StatusV2_PermissionValue(0);
    if (props.hasOwnProperty('permission')) {
      const v = props.permission;
      this.permission = v;
    }

    this.predicted_serviceability = StatusV2_PredictedServiceabilityValue(0);
    if (props.hasOwnProperty('predicted_serviceability')) {
      const v = props.predicted_serviceability;
      this.predicted_serviceability = v;
    }

    this.reason_for_serviceability = StatusV2_ReasonForServiceabilityValue(0);
    if (props.hasOwnProperty('reason_for_serviceability')) {
      const v = props.reason_for_serviceability;
      this.reason_for_serviceability = v;
    }

    this.building_restrictions = [];
    if (props.hasOwnProperty('building_restrictions')) {
      const v = props.building_restrictions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field building_restrictions should be array');
      }
      this.building_restrictions = v.map(function(v) {
        return v;
      });
    }
  }
}
export {StatusV2};

export const Site_NodeTypeValues = {
  LEAF: 0,
  RELAY: 1,
  SOURCE: 2,
};
type Site_NodeType = $Keys<typeof Site_NodeTypeValues>;
export type {Site_NodeType};

export function Site_NodeTypeValue(n: number): Site_NodeType {
  switch (n) {
    case 0:
      return 'LEAF';

    case 1:
      return 'RELAY';

    case 2:
      return 'SOURCE';

    default:
      return 'LEAF';
  }
}

export const Site_StatusValues = {
  NEW: 0,
  DEACTIVATED: 1,
  ACTIVE: 2,
  ACTIVE_BUT_KNOWN_BAD: 3,
  READY_TO_INSTALL: 4,
  INSTALL_CONFIRMED: 5,
  UNSERVICEABLE: 6,
};
type Site_Status = $Keys<typeof Site_StatusValues>;
export type {Site_Status};

export function Site_StatusValue(n: number): Site_Status {
  switch (n) {
    case 0:
      return 'NEW';

    case 1:
      return 'DEACTIVATED';

    case 2:
      return 'ACTIVE';

    case 3:
      return 'ACTIVE_BUT_KNOWN_BAD';

    case 4:
      return 'READY_TO_INSTALL';

    case 5:
      return 'INSTALL_CONFIRMED';

    case 6:
      return 'UNSERVICEABLE';

    default:
      return 'NEW';
  }
}

export const Site_BuildingTypeValues = {
  UNKNOWN: 0,
  SINGLE_FAMILY_HOME: 1,
  IN_LAW: 2,
  MDU: 3,
  COMMERCIAL: 4,
};
type Site_BuildingType = $Keys<typeof Site_BuildingTypeValues>;
export type {Site_BuildingType};

export function Site_BuildingTypeValue(n: number): Site_BuildingType {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'SINGLE_FAMILY_HOME';

    case 2:
      return 'IN_LAW';

    case 3:
      return 'MDU';

    case 4:
      return 'COMMERCIAL';

    default:
      return 'UNKNOWN';
  }
}

export const Site_NumberOfUnitsValues = {
  UNKNOWN_NUMBER: 0,
  ONE: 1,
  TWO_TO_FOUR: 2,
  FIVE_TO_ELEVEN: 3,
  TWELVE_TO_TWENTY: 6,
  TWENTY_ONE_PLUS: 5,
};
type Site_NumberOfUnits = $Keys<typeof Site_NumberOfUnitsValues>;
export type {Site_NumberOfUnits};

export function Site_NumberOfUnitsValue(n: number): Site_NumberOfUnits {
  switch (n) {
    case 0:
      return 'UNKNOWN_NUMBER';

    case 1:
      return 'ONE';

    case 2:
      return 'TWO_TO_FOUR';

    case 3:
      return 'FIVE_TO_ELEVEN';

    case 6:
      return 'TWELVE_TO_TWENTY';

    case 5:
      return 'TWENTY_ONE_PLUS';

    default:
      return 'UNKNOWN_NUMBER';
  }
}

class Site_ContactInfo_Contact {
  customer: ?cwn_customers.Customer;
  description: string;

  constructor(props: $Shape<Site_ContactInfo_Contact> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {Site_ContactInfo_Contact};
class Site_ContactInfo {
  contacts: Array<Site_ContactInfo_Contact>;
  address: ?cwn_customers.Address;

  constructor(props: $Shape<Site_ContactInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.contacts = [];
    if (props.hasOwnProperty('contacts')) {
      const v = props.contacts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field contacts should be array');
      }
      this.contacts = v.map(function(v) {
        return new Site_ContactInfo_Contact(v);
      });
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {Site_ContactInfo};
export const Site_PartnerInfo_AgreementTypeValues = {
  INVALID_AGREEMENT: 0,
  PPP: 1,
  RELAY: 2,
  RELAY_AND_PPP: 3,
  RIGHT_OF_ENTRY: 4,
};
type Site_PartnerInfo_AgreementType = $Keys<
  typeof Site_PartnerInfo_AgreementTypeValues,
>;
export type {Site_PartnerInfo_AgreementType};

export function Site_PartnerInfo_AgreementTypeValue(
  n: number,
): Site_PartnerInfo_AgreementType {
  switch (n) {
    case 0:
      return 'INVALID_AGREEMENT';

    case 1:
      return 'PPP';

    case 2:
      return 'RELAY';

    case 3:
      return 'RELAY_AND_PPP';

    case 4:
      return 'RIGHT_OF_ENTRY';

    default:
      return 'INVALID_AGREEMENT';
  }
}

export const Site_PartnerInfo_AutoRenewalLengthValues = {
  INVALID_LENGTH: 0,
  MONTH_TO_MONTH: 1,
  ONE_YEAR: 2,
  TWO_YEARS: 3,
  THREE_YEARS: 4,
  FIVE_YEARS: 5,
};
type Site_PartnerInfo_AutoRenewalLength = $Keys<
  typeof Site_PartnerInfo_AutoRenewalLengthValues,
>;
export type {Site_PartnerInfo_AutoRenewalLength};

export function Site_PartnerInfo_AutoRenewalLengthValue(
  n: number,
): Site_PartnerInfo_AutoRenewalLength {
  switch (n) {
    case 0:
      return 'INVALID_LENGTH';

    case 1:
      return 'MONTH_TO_MONTH';

    case 2:
      return 'ONE_YEAR';

    case 3:
      return 'TWO_YEARS';

    case 4:
      return 'THREE_YEARS';

    case 5:
      return 'FIVE_YEARS';

    default:
      return 'INVALID_LENGTH';
  }
}

export const Site_PartnerInfo_Contact_RoleValues = {
  UNKNOWN: 0,
  MAINTENANCE_OR_BUILDING_ENGINEER: 1,
  OWNER_OR_MANAGER: 2,
  SECURITY: 3,
};
type Site_PartnerInfo_Contact_Role = $Keys<
  typeof Site_PartnerInfo_Contact_RoleValues,
>;
export type {Site_PartnerInfo_Contact_Role};

export function Site_PartnerInfo_Contact_RoleValue(
  n: number,
): Site_PartnerInfo_Contact_Role {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'MAINTENANCE_OR_BUILDING_ENGINEER';

    case 2:
      return 'OWNER_OR_MANAGER';

    case 3:
      return 'SECURITY';

    default:
      return 'UNKNOWN';
  }
}

class Site_PartnerInfo_Contact {
  role: Site_PartnerInfo_Contact_Role;
  name: string;
  email: string;
  office_phone: string;
  cell_phone: string;

  constructor(props: $Shape<Site_PartnerInfo_Contact> = {}): void {
    if (!props) {
      props = {};
    }

    this.role = Site_PartnerInfo_Contact_RoleValue(0);
    if (props.hasOwnProperty('role')) {
      const v = props.role;
      this.role = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.office_phone = '';
    if (props.hasOwnProperty('office_phone')) {
      const v = props.office_phone;
      this.office_phone = v;
    }

    this.cell_phone = '';
    if (props.hasOwnProperty('cell_phone')) {
      const v = props.cell_phone;
      this.cell_phone = v;
    }
  }
}
export {Site_PartnerInfo_Contact};
class Site_PartnerInfo {
  prior_notice_required: boolean;
  roof_access_hours: string;
  key_code: string;
  contacts: Array<Site_PartnerInfo_Contact>;
  contract_link: string;
  install_plan_link: string;
  agreement_type: Site_PartnerInfo_AgreementType;
  max_mounts: string;
  max_radios_per_mount: string;
  max_radios_total: string;
  contract_execution_date: ?Date;
  initial_termination_date: ?Date;
  free_subscription_required: boolean;
  free_subscription_details: string;
  special_instructions: string;
  first_day_of_installation: ?Date;
  auto_renew: boolean;
  auto_renewal_length: Site_PartnerInfo_AutoRenewalLength;
  max_term: string;
  copper_opportunity_id: string;

  constructor(props: $Shape<Site_PartnerInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.prior_notice_required = false;
    if (props.hasOwnProperty('prior_notice_required')) {
      const v = props.prior_notice_required;
      this.prior_notice_required = !!v;
    }

    this.roof_access_hours = '';
    if (props.hasOwnProperty('roof_access_hours')) {
      const v = props.roof_access_hours;
      this.roof_access_hours = v;
    }

    this.key_code = '';
    if (props.hasOwnProperty('key_code')) {
      const v = props.key_code;
      this.key_code = v;
    }

    this.contacts = [];
    if (props.hasOwnProperty('contacts')) {
      const v = props.contacts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field contacts should be array');
      }
      this.contacts = v.map(function(v) {
        return new Site_PartnerInfo_Contact(v);
      });
    }

    this.contract_link = '';
    if (props.hasOwnProperty('contract_link')) {
      const v = props.contract_link;
      this.contract_link = v;
    }

    this.install_plan_link = '';
    if (props.hasOwnProperty('install_plan_link')) {
      const v = props.install_plan_link;
      this.install_plan_link = v;
    }

    this.agreement_type = Site_PartnerInfo_AgreementTypeValue(0);
    if (props.hasOwnProperty('agreement_type')) {
      const v = props.agreement_type;
      this.agreement_type = v;
    }

    this.max_mounts = '0';
    if (props.hasOwnProperty('max_mounts')) {
      const v = props.max_mounts;
      this.max_mounts = v;
    }

    this.max_radios_per_mount = '0';
    if (props.hasOwnProperty('max_radios_per_mount')) {
      const v = props.max_radios_per_mount;
      this.max_radios_per_mount = v;
    }

    this.max_radios_total = '0';
    if (props.hasOwnProperty('max_radios_total')) {
      const v = props.max_radios_total;
      this.max_radios_total = v;
    }

    this.contract_execution_date = null;
    if (props.hasOwnProperty('contract_execution_date')) {
      const v = props.contract_execution_date;
      this.contract_execution_date = v && new Date(v);
    }

    this.initial_termination_date = null;
    if (props.hasOwnProperty('initial_termination_date')) {
      const v = props.initial_termination_date;
      this.initial_termination_date = v && new Date(v);
    }

    this.free_subscription_required = false;
    if (props.hasOwnProperty('free_subscription_required')) {
      const v = props.free_subscription_required;
      this.free_subscription_required = !!v;
    }

    this.free_subscription_details = '';
    if (props.hasOwnProperty('free_subscription_details')) {
      const v = props.free_subscription_details;
      this.free_subscription_details = v;
    }

    this.special_instructions = '';
    if (props.hasOwnProperty('special_instructions')) {
      const v = props.special_instructions;
      this.special_instructions = v;
    }

    this.first_day_of_installation = null;
    if (props.hasOwnProperty('first_day_of_installation')) {
      const v = props.first_day_of_installation;
      this.first_day_of_installation = v && new Date(v);
    }

    this.auto_renew = false;
    if (props.hasOwnProperty('auto_renew')) {
      const v = props.auto_renew;
      this.auto_renew = !!v;
    }

    this.auto_renewal_length = Site_PartnerInfo_AutoRenewalLengthValue(0);
    if (props.hasOwnProperty('auto_renewal_length')) {
      const v = props.auto_renewal_length;
      this.auto_renewal_length = v;
    }

    this.max_term = '0';
    if (props.hasOwnProperty('max_term')) {
      const v = props.max_term;
      this.max_term = v;
    }

    this.copper_opportunity_id = '0';
    if (props.hasOwnProperty('copper_opportunity_id')) {
      const v = props.copper_opportunity_id;
      this.copper_opportunity_id = v;
    }
  }
}
export {Site_PartnerInfo};
class Site {
  id: string;
  created: ?Date;
  updated: ?Date;
  subscription_ids: Array<string>;
  device_id: string;
  mount_ids: Array<string>;
  node_type: Site_NodeType;
  area_id: string;
  deprecated_status_do_not_use: Site_Status;
  building_type: Site_BuildingType;
  number_of_units: Site_NumberOfUnits;
  contact_info: ?Site_ContactInfo;
  status_v2: ?StatusV2;
  activated_at: ?Date;
  last_device_updated: ?Date;
  partner_info: ?Site_PartnerInfo;
  geocoded_location: ?cwn_geocoder.GeoPoint;
  elevation: number;

  constructor(props: $Shape<Site> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.subscription_ids = [];
    if (props.hasOwnProperty('subscription_ids')) {
      const v = props.subscription_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field subscription_ids should be array');
      }
      this.subscription_ids = v.map(function(v) {
        return v;
      });
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.mount_ids = [];
    if (props.hasOwnProperty('mount_ids')) {
      const v = props.mount_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mount_ids should be array');
      }
      this.mount_ids = v.map(function(v) {
        return v;
      });
    }

    this.node_type = Site_NodeTypeValue(0);
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }

    this.deprecated_status_do_not_use = Site_StatusValue(0);
    if (props.hasOwnProperty('deprecated_status_do_not_use')) {
      const v = props.deprecated_status_do_not_use;
      this.deprecated_status_do_not_use = v;
    }

    this.building_type = Site_BuildingTypeValue(0);
    if (props.hasOwnProperty('building_type')) {
      const v = props.building_type;
      this.building_type = v;
    }

    this.number_of_units = Site_NumberOfUnitsValue(0);
    if (props.hasOwnProperty('number_of_units')) {
      const v = props.number_of_units;
      this.number_of_units = v;
    }

    this.contact_info = null;
    if (props.hasOwnProperty('contact_info')) {
      const v = props.contact_info;
      this.contact_info = v && new Site_ContactInfo(v);
    }

    this.status_v2 = null;
    if (props.hasOwnProperty('status_v2')) {
      const v = props.status_v2;
      this.status_v2 = v && new StatusV2(v);
    }

    this.activated_at = null;
    if (props.hasOwnProperty('activated_at')) {
      const v = props.activated_at;
      this.activated_at = v && new Date(v);
    }

    this.last_device_updated = null;
    if (props.hasOwnProperty('last_device_updated')) {
      const v = props.last_device_updated;
      this.last_device_updated = v && new Date(v);
    }

    this.partner_info = null;
    if (props.hasOwnProperty('partner_info')) {
      const v = props.partner_info;
      this.partner_info = v && new Site_PartnerInfo(v);
    }

    this.geocoded_location = null;
    if (props.hasOwnProperty('geocoded_location')) {
      const v = props.geocoded_location;
      this.geocoded_location = v && new cwn_geocoder.GeoPoint(v);
    }

    this.elevation = 0;
    if (props.hasOwnProperty('elevation')) {
      const v = props.elevation;
      this.elevation = v;
    }
  }

  getContactInfo(): Site_ContactInfo {
    if (this.contact_info) {
      return this.contact_info;
    }
    return new Site_ContactInfo();
  }

  getStatusV2(): StatusV2 {
    if (this.status_v2) {
      return this.status_v2;
    }
    return new StatusV2();
  }

  getPartnerInfo(): Site_PartnerInfo {
    if (this.partner_info) {
      return this.partner_info;
    }
    return new Site_PartnerInfo();
  }

  getGeocodedLocation(): cwn_geocoder.GeoPoint {
    if (this.geocoded_location) {
      return this.geocoded_location;
    }
    return new cwn_geocoder.GeoPoint();
  }
}
export {Site};

class SiteHistory {
  id: string;
  site: ?Site;

  constructor(props: $Shape<SiteHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new Site(v);
    }
  }

  getSite(): Site {
    if (this.site) {
      return this.site;
    }
    return new Site();
  }
}
export {SiteHistory};

class FeedEntry_Metadata {
  newly_associated_lead_id: string;

  constructor(props: $Shape<FeedEntry_Metadata> = {}): void {
    if (!props) {
      props = {};
    }

    this.newly_associated_lead_id = '0';
    if (props.hasOwnProperty('newly_associated_lead_id')) {
      const v = props.newly_associated_lead_id;
      this.newly_associated_lead_id = v;
    }
  }
}
export {FeedEntry_Metadata};
class FeedEntry {
  id: string;
  site_id: string;
  prev: ?Site;
  next: ?Site;
  created: ?Date;
  initiator: string;
  metadata: ?FeedEntry_Metadata;

  constructor(props: $Shape<FeedEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.prev = null;
    if (props.hasOwnProperty('prev')) {
      const v = props.prev;
      this.prev = v && new Site(v);
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new Site(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }

    this.metadata = null;
    if (props.hasOwnProperty('metadata')) {
      const v = props.metadata;
      this.metadata = v && new FeedEntry_Metadata(v);
    }
  }

  getPrev(): Site {
    if (this.prev) {
      return this.prev;
    }
    return new Site();
  }

  getNext(): Site {
    if (this.next) {
      return this.next;
    }
    return new Site();
  }

  getMetadata(): FeedEntry_Metadata {
    if (this.metadata) {
      return this.metadata;
    }
    return new FeedEntry_Metadata();
  }
}
export {FeedEntry};

class SubscriptionCount {
  site_id: string;
  device_id: string;
  updated: ?Date;
  num_active_subscriptions: string;
  area_id: string;

  constructor(props: $Shape<SubscriptionCount> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.num_active_subscriptions = '0';
    if (props.hasOwnProperty('num_active_subscriptions')) {
      const v = props.num_active_subscriptions;
      this.num_active_subscriptions = v;
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }
  }
}
export {SubscriptionCount};

class AuditAdminSiteRadioLinkChange {
  old: ?SiteRadioLink;
  new: ?SiteRadioLink;

  constructor(props: $Shape<AuditAdminSiteRadioLinkChange> = {}): void {
    if (!props) {
      props = {};
    }

    this.old = null;
    if (props.hasOwnProperty('old')) {
      const v = props.old;
      this.old = v && new SiteRadioLink(v);
    }

    this.new = null;
    if (props.hasOwnProperty('new')) {
      const v = props.new;
      this.new = v && new SiteRadioLink(v);
    }
  }

  getOld(): SiteRadioLink {
    if (this.old) {
      return this.old;
    }
    return new SiteRadioLink();
  }

  getNew(): SiteRadioLink {
    if (this.new) {
      return this.new;
    }
    return new SiteRadioLink();
  }
}
export {AuditAdminSiteRadioLinkChange};

class SiteBridgeConfig {
  id: string;
  created: ?Date;
  updated: ?Date;
  site_id: string;
  device_interface: string;
  asset_model: cwn_asset.Model;
  switch_mac_address: string;

  constructor(props: $Shape<SiteBridgeConfig> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.device_interface = '';
    if (props.hasOwnProperty('device_interface')) {
      const v = props.device_interface;
      this.device_interface = v;
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.switch_mac_address = '';
    if (props.hasOwnProperty('switch_mac_address')) {
      const v = props.switch_mac_address;
      this.switch_mac_address = v;
    }
  }
}
export {SiteBridgeConfig};

// END cwn/site/models.proto
