// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_stripe from '../../cwn/stripe';

// START cwn/billing/models.proto

export const CurrencyValues = {
  UNKNOWN: 0,
  USD: 1,
};
type Currency = $Keys<typeof CurrencyValues>;
export type {Currency};

export function CurrencyValue(n: number): Currency {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'USD';

    default:
      return 'UNKNOWN';
  }
}

class Money {
  amount: string;
  currency: Currency;

  constructor(props: $Shape<Money> = {}): void {
    if (!props) {
      props = {};
    }

    this.amount = '0';
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v;
    }

    this.currency = CurrencyValue(0);
    if (props.hasOwnProperty('currency')) {
      const v = props.currency;
      this.currency = v;
    }
  }
}
export {Money};

export const Invoice_StateValues = {
  INVALID: 0,
  NEW: 1,
  PENDING: 2,
  PAID: 3,
  FAILED_PAYMENT: 4,
  CANCELLED: 5,
};
type Invoice_State = $Keys<typeof Invoice_StateValues>;
export type {Invoice_State};

export function Invoice_StateValue(n: number): Invoice_State {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'NEW';

    case 2:
      return 'PENDING';

    case 3:
      return 'PAID';

    case 4:
      return 'FAILED_PAYMENT';

    case 5:
      return 'CANCELLED';

    default:
      return 'INVALID';
  }
}

class Invoice_Metadata {
  billing: ?Billing;
  instrument: ?Instrument;

  constructor(props: $Shape<Invoice_Metadata> = {}): void {
    if (!props) {
      props = {};
    }

    this.billing = null;
    if (props.hasOwnProperty('billing')) {
      const v = props.billing;
      this.billing = v && new Billing(v);
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new Instrument(v);
    }
  }

  getBilling(): Billing {
    if (this.billing) {
      return this.billing;
    }
    return new Billing();
  }

  getInstrument(): Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new Instrument();
  }
}
export {Invoice_Metadata};
export const Invoice_Receipt_TypeValues = {
  TYPE_INVALID: 0,
  TYPE_PAID: 1,
  TYPE_FAILED_PAYMENT: 2,
};
type Invoice_Receipt_Type = $Keys<typeof Invoice_Receipt_TypeValues>;
export type {Invoice_Receipt_Type};

export function Invoice_Receipt_TypeValue(n: number): Invoice_Receipt_Type {
  switch (n) {
    case 0:
      return 'TYPE_INVALID';

    case 1:
      return 'TYPE_PAID';

    case 2:
      return 'TYPE_FAILED_PAYMENT';

    default:
      return 'TYPE_INVALID';
  }
}

export const Invoice_Receipt_FailureReasonValues = {
  REASON_NONE: 0,
  REASON_INSTRUMENT_INVALID: 1,
  REASON_INTERNAL_ERROR: 2,
  REASON_CARD_DECLINED: 3,
  REASON_MISSING_CUSTOMER_ID: 4,
};
type Invoice_Receipt_FailureReason = $Keys<
  typeof Invoice_Receipt_FailureReasonValues,
>;
export type {Invoice_Receipt_FailureReason};

export function Invoice_Receipt_FailureReasonValue(
  n: number,
): Invoice_Receipt_FailureReason {
  switch (n) {
    case 0:
      return 'REASON_NONE';

    case 1:
      return 'REASON_INSTRUMENT_INVALID';

    case 2:
      return 'REASON_INTERNAL_ERROR';

    case 3:
      return 'REASON_CARD_DECLINED';

    case 4:
      return 'REASON_MISSING_CUSTOMER_ID';

    default:
      return 'REASON_NONE';
  }
}

class Invoice_Receipt {
  instrument: ?Instrument;
  created: ?Date;
  receipt_number: string;
  payment: ?Payment;
  type: Invoice_Receipt_Type;
  failure_reason: Invoice_Receipt_FailureReason;

  constructor(props: $Shape<Invoice_Receipt> = {}): void {
    if (!props) {
      props = {};
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new Instrument(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.receipt_number = '';
    if (props.hasOwnProperty('receipt_number')) {
      const v = props.receipt_number;
      this.receipt_number = v;
    }

    this.payment = null;
    if (props.hasOwnProperty('payment')) {
      const v = props.payment;
      this.payment = v && new Payment(v);
    }

    this.type = Invoice_Receipt_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.failure_reason = Invoice_Receipt_FailureReasonValue(0);
    if (props.hasOwnProperty('failure_reason')) {
      const v = props.failure_reason;
      this.failure_reason = v;
    }
  }

  getInstrument(): Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new Instrument();
  }

  getPayment(): Payment {
    if (this.payment) {
      return this.payment;
    }
    return new Payment();
  }
}
export {Invoice_Receipt};
class Invoice {
  id: string;
  created: ?Date;
  updated: ?Date;
  subscription_id: string;
  line_items: Array<LineItem>;
  state: Invoice_State;
  amount_due: ?Money;
  payment: ?Payment;
  receipt_number: string;
  cycle_start: ?Date;
  cycle_end: ?Date;
  metadata: ?Invoice_Metadata;
  payment_attempts: Array<Invoice_Receipt>;

  constructor(props: $Shape<Invoice> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.line_items = [];
    if (props.hasOwnProperty('line_items')) {
      const v = props.line_items;
      if (!Array.isArray(v)) {
        throw new Error('repeated field line_items should be array');
      }
      this.line_items = v.map(function(v) {
        return new LineItem(v);
      });
    }

    this.state = Invoice_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.amount_due = null;
    if (props.hasOwnProperty('amount_due')) {
      const v = props.amount_due;
      this.amount_due = v && new Money(v);
    }

    this.payment = null;
    if (props.hasOwnProperty('payment')) {
      const v = props.payment;
      this.payment = v && new Payment(v);
    }

    this.receipt_number = '';
    if (props.hasOwnProperty('receipt_number')) {
      const v = props.receipt_number;
      this.receipt_number = v;
    }

    this.cycle_start = null;
    if (props.hasOwnProperty('cycle_start')) {
      const v = props.cycle_start;
      this.cycle_start = v && new Date(v);
    }

    this.cycle_end = null;
    if (props.hasOwnProperty('cycle_end')) {
      const v = props.cycle_end;
      this.cycle_end = v && new Date(v);
    }

    this.metadata = null;
    if (props.hasOwnProperty('metadata')) {
      const v = props.metadata;
      this.metadata = v && new Invoice_Metadata(v);
    }

    this.payment_attempts = [];
    if (props.hasOwnProperty('payment_attempts')) {
      const v = props.payment_attempts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field payment_attempts should be array');
      }
      this.payment_attempts = v.map(function(v) {
        return new Invoice_Receipt(v);
      });
    }
  }

  getAmountDue(): Money {
    if (this.amount_due) {
      return this.amount_due;
    }
    return new Money();
  }

  getPayment(): Payment {
    if (this.payment) {
      return this.payment;
    }
    return new Payment();
  }

  getMetadata(): Invoice_Metadata {
    if (this.metadata) {
      return this.metadata;
    }
    return new Invoice_Metadata();
  }
}
export {Invoice};

export const LineItem_TypeValues = {
  INVALID: 0,
  CHARGE: 1,
  DISCOUNT: 2,
};
type LineItem_Type = $Keys<typeof LineItem_TypeValues>;
export type {LineItem_Type};

export function LineItem_TypeValue(n: number): LineItem_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'CHARGE';

    case 2:
      return 'DISCOUNT';

    default:
      return 'INVALID';
  }
}

class LineItem {
  type: LineItem_Type;
  amount: ?Money;
  description: string;

  constructor(props: $Shape<LineItem> = {}): void {
    if (!props) {
      props = {};
    }

    this.type = LineItem_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.amount = null;
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v && new Money(v);
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }
  }

  getAmount(): Money {
    if (this.amount) {
      return this.amount;
    }
    return new Money();
  }
}
export {LineItem};

class Coupon {
  id: string;
  name: string;
  percent_off: string;
  amount_off: ?Money;
  expires: ?Date;

  constructor(props: $Shape<Coupon> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.percent_off = '0';
    if (props.hasOwnProperty('percent_off')) {
      const v = props.percent_off;
      this.percent_off = v;
    }

    this.amount_off = null;
    if (props.hasOwnProperty('amount_off')) {
      const v = props.amount_off;
      this.amount_off = v && new Money(v);
    }

    this.expires = null;
    if (props.hasOwnProperty('expires')) {
      const v = props.expires;
      this.expires = v && new Date(v);
    }
  }

  getAmountOff(): Money {
    if (this.amount_off) {
      return this.amount_off;
    }
    return new Money();
  }
}
export {Coupon};

class Billing {
  id: string;
  created: ?Date;
  updated: ?Date;
  subscription_id: string;
  start_date: ?Date;
  coupons: Array<Coupon>;
  instrument: ?Instrument;

  constructor(props: $Shape<Billing> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.start_date = null;
    if (props.hasOwnProperty('start_date')) {
      const v = props.start_date;
      this.start_date = v && new Date(v);
    }

    this.coupons = [];
    if (props.hasOwnProperty('coupons')) {
      const v = props.coupons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field coupons should be array');
      }
      this.coupons = v.map(function(v) {
        return new Coupon(v);
      });
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new Instrument(v);
    }
  }

  getInstrument(): Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new Instrument();
  }
}
export {Billing};

export const Instrument_TypeValues = {
  INVALID: 0,
  STRIPE_CARD: 1,
  GRACE_PAY: 2,
  INVOICE: 3,
};
type Instrument_Type = $Keys<typeof Instrument_TypeValues>;
export type {Instrument_Type};

export function Instrument_TypeValue(n: number): Instrument_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'STRIPE_CARD';

    case 2:
      return 'GRACE_PAY';

    case 3:
      return 'INVOICE';

    default:
      return 'INVALID';
  }
}

class Instrument {
  type: Instrument_Type;
  stripe_card: ?cwn_stripe.Card;
  id: string;
  customer_id: string;
  created: ?Date;
  deleted: boolean;

  constructor(props: $Shape<Instrument> = {}): void {
    if (!props) {
      props = {};
    }

    this.type = Instrument_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.stripe_card = null;
    if (props.hasOwnProperty('stripe_card')) {
      const v = props.stripe_card;
      this.stripe_card = v && new cwn_stripe.Card(v);
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }
  }

  getStripeCard(): cwn_stripe.Card {
    if (this.stripe_card) {
      return this.stripe_card;
    }
    return new cwn_stripe.Card();
  }
}
export {Instrument};

class ScheduledPayment {
  time: ?Date;
  amount: ?Money;

  constructor(props: $Shape<ScheduledPayment> = {}): void {
    if (!props) {
      props = {};
    }

    this.time = null;
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v && new Date(v);
    }

    this.amount = null;
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v && new Money(v);
    }
  }

  getAmount(): Money {
    if (this.amount) {
      return this.amount;
    }
    return new Money();
  }
}
export {ScheduledPayment};

export const Payment_TypeValues = {
  INVALID: 0,
  STRIPE_CHARGE: 1,
  GRACE_PAY: 2,
  INVOICE: 3,
};
type Payment_Type = $Keys<typeof Payment_TypeValues>;
export type {Payment_Type};

export function Payment_TypeValue(n: number): Payment_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'STRIPE_CHARGE';

    case 2:
      return 'GRACE_PAY';

    case 3:
      return 'INVOICE';

    default:
      return 'INVALID';
  }
}

export const Payment_Error_TypeValues = {
  INVALID: 0,
  INTERNAL: 1,
  STRIPE: 2,
};
type Payment_Error_Type = $Keys<typeof Payment_Error_TypeValues>;
export type {Payment_Error_Type};

export function Payment_Error_TypeValue(n: number): Payment_Error_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'INTERNAL';

    case 2:
      return 'STRIPE';

    default:
      return 'INVALID';
  }
}

class Payment_Error {
  type: Payment_Error_Type;
  internal_error: string;
  stripe_error: ?cwn_stripe.Error;

  constructor(props: $Shape<Payment_Error> = {}): void {
    if (!props) {
      props = {};
    }

    this.type = Payment_Error_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.internal_error = '';
    if (props.hasOwnProperty('internal_error')) {
      const v = props.internal_error;
      this.internal_error = v;
    }

    this.stripe_error = null;
    if (props.hasOwnProperty('stripe_error')) {
      const v = props.stripe_error;
      this.stripe_error = v && new cwn_stripe.Error(v);
    }
  }

  getStripeError(): cwn_stripe.Error {
    if (this.stripe_error) {
      return this.stripe_error;
    }
    return new cwn_stripe.Error();
  }
}
export {Payment_Error};
class Payment {
  type: Payment_Type;
  stripe_charge: ?cwn_stripe.Charge;
  error: ?Payment_Error;
  instrument: ?Instrument;

  constructor(props: $Shape<Payment> = {}): void {
    if (!props) {
      props = {};
    }

    this.type = Payment_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.stripe_charge = null;
    if (props.hasOwnProperty('stripe_charge')) {
      const v = props.stripe_charge;
      this.stripe_charge = v && new cwn_stripe.Charge(v);
    }

    this.error = null;
    if (props.hasOwnProperty('error')) {
      const v = props.error;
      this.error = v && new Payment_Error(v);
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new Instrument(v);
    }
  }

  getStripeCharge(): cwn_stripe.Charge {
    if (this.stripe_charge) {
      return this.stripe_charge;
    }
    return new cwn_stripe.Charge();
  }

  getError(): Payment_Error {
    if (this.error) {
      return this.error;
    }
    return new Payment_Error();
  }

  getInstrument(): Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new Instrument();
  }
}
export {Payment};

class BillEntry {
  description: string;
  billing: ?Billing;

  constructor(props: $Shape<BillEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.billing = null;
    if (props.hasOwnProperty('billing')) {
      const v = props.billing;
      this.billing = v && new Billing(v);
    }
  }

  getBilling(): Billing {
    if (this.billing) {
      return this.billing;
    }
    return new Billing();
  }
}
export {BillEntry};

export const PaymentCreditEntry_TypeValues = {
  CREDIT_UNKNOWN: 0,
  CREDIT_SERVICE_DISRUPTION: 1,
  CREDIT_SIGN_UP_PROMOTION: 2,
  CREDIT_REFERRAL: 3,
  CREDIT_OTHER: 4,
};
type PaymentCreditEntry_Type = $Keys<typeof PaymentCreditEntry_TypeValues>;
export type {PaymentCreditEntry_Type};

export function PaymentCreditEntry_TypeValue(
  n: number,
): PaymentCreditEntry_Type {
  switch (n) {
    case 0:
      return 'CREDIT_UNKNOWN';

    case 1:
      return 'CREDIT_SERVICE_DISRUPTION';

    case 2:
      return 'CREDIT_SIGN_UP_PROMOTION';

    case 3:
      return 'CREDIT_REFERRAL';

    case 4:
      return 'CREDIT_OTHER';

    default:
      return 'CREDIT_UNKNOWN';
  }
}

class PaymentCreditEntry {
  description: string;
  type: PaymentCreditEntry_Type;

  constructor(props: $Shape<PaymentCreditEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.type = PaymentCreditEntry_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }
  }
}
export {PaymentCreditEntry};

export const PaymentEntry_TypeValues = {
  TYPE_INVALID: 0,
  TYPE_PAID: 1,
  TYPE_FAILED_PAYMENT: 2,
  TYPE_PENDING: 3,
};
type PaymentEntry_Type = $Keys<typeof PaymentEntry_TypeValues>;
export type {PaymentEntry_Type};

export function PaymentEntry_TypeValue(n: number): PaymentEntry_Type {
  switch (n) {
    case 0:
      return 'TYPE_INVALID';

    case 1:
      return 'TYPE_PAID';

    case 2:
      return 'TYPE_FAILED_PAYMENT';

    case 3:
      return 'TYPE_PENDING';

    default:
      return 'TYPE_INVALID';
  }
}

export const PaymentEntry_FailureReasonValues = {
  REASON_NONE: 0,
  REASON_INSTRUMENT_INVALID: 1,
  REASON_INTERNAL_ERROR: 2,
  REASON_CARD_DECLINED: 3,
  REASON_MISSING_CUSTOMER_ID: 4,
};
type PaymentEntry_FailureReason = $Keys<
  typeof PaymentEntry_FailureReasonValues,
>;
export type {PaymentEntry_FailureReason};

export function PaymentEntry_FailureReasonValue(
  n: number,
): PaymentEntry_FailureReason {
  switch (n) {
    case 0:
      return 'REASON_NONE';

    case 1:
      return 'REASON_INSTRUMENT_INVALID';

    case 2:
      return 'REASON_INTERNAL_ERROR';

    case 3:
      return 'REASON_CARD_DECLINED';

    case 4:
      return 'REASON_MISSING_CUSTOMER_ID';

    default:
      return 'REASON_NONE';
  }
}

class PaymentEntry {
  payment: ?Payment;
  instrument: ?Instrument;
  receipt_number: string;
  type: PaymentEntry_Type;
  failure_reason: PaymentEntry_FailureReason;

  constructor(props: $Shape<PaymentEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.payment = null;
    if (props.hasOwnProperty('payment')) {
      const v = props.payment;
      this.payment = v && new Payment(v);
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new Instrument(v);
    }

    this.receipt_number = '';
    if (props.hasOwnProperty('receipt_number')) {
      const v = props.receipt_number;
      this.receipt_number = v;
    }

    this.type = PaymentEntry_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.failure_reason = PaymentEntry_FailureReasonValue(0);
    if (props.hasOwnProperty('failure_reason')) {
      const v = props.failure_reason;
      this.failure_reason = v;
    }
  }

  getPayment(): Payment {
    if (this.payment) {
      return this.payment;
    }
    return new Payment();
  }

  getInstrument(): Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new Instrument();
  }
}
export {PaymentEntry};

export const LedgerEntry_TypeValues = {
  INVALID: 0,
  CREDIT: 1,
  DEBIT: 2,
};
type LedgerEntry_Type = $Keys<typeof LedgerEntry_TypeValues>;
export type {LedgerEntry_Type};

export function LedgerEntry_TypeValue(n: number): LedgerEntry_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'CREDIT';

    case 2:
      return 'DEBIT';

    default:
      return 'INVALID';
  }
}

class LedgerEntry {
  id: string;
  created: ?Date;
  amount: ?Money;
  type: LedgerEntry_Type;
  _bill: ?BillEntry;
  _payment: ?PaymentEntry;
  _payment_credit: ?PaymentCreditEntry;
  subscription_id: string;
  updated: ?Date;

  constructor(props: $Shape<LedgerEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.amount = null;
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v && new Money(v);
    }

    this.type = LedgerEntry_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this._bill = undefined;
    if (props.hasOwnProperty('bill')) {
      const v = props.bill;
      this._bill = v && new BillEntry(v);
    }

    this._payment = undefined;
    if (props.hasOwnProperty('payment')) {
      const v = props.payment;
      this._payment = v && new PaymentEntry(v);
    }

    this._payment_credit = undefined;
    if (props.hasOwnProperty('payment_credit')) {
      const v = props.payment_credit;
      this._payment_credit = v && new PaymentCreditEntry(v);
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }

  getAmount(): Money {
    if (this.amount) {
      return this.amount;
    }
    return new Money();
  }

  getBill(): BillEntry {
    if (this._bill) {
      return this._bill;
    }
    return new BillEntry();
  }

  getPayment(): PaymentEntry {
    if (this._payment) {
      return this._payment;
    }
    return new PaymentEntry();
  }

  getPaymentCredit(): PaymentCreditEntry {
    if (this._payment_credit) {
      return this._payment_credit;
    }
    return new PaymentCreditEntry();
  }

  get bill(): ?BillEntry {
    return this._bill;
  }
  set bill(bill: ?BillEntry) {
    this._bill = bill;
    this._payment = undefined;
    this._payment_credit = undefined;
  }
  get payment(): ?PaymentEntry {
    return this._payment;
  }
  set payment(payment: ?PaymentEntry) {
    this._bill = undefined;
    this._payment = payment;
    this._payment_credit = undefined;
  }
  get payment_credit(): ?PaymentCreditEntry {
    return this._payment_credit;
  }
  set payment_credit(payment_credit: ?PaymentCreditEntry) {
    this._bill = undefined;
    this._payment = undefined;
    this._payment_credit = payment_credit;
  }

  toJSON() {
    return {
      id: this.id,
      created: this.created,
      amount: this.amount,
      type: this.type,
      bill: this._bill,
      payment: this._payment,
      payment_credit: this._payment_credit,
      subscription_id: this.subscription_id,
      updated: this.updated,
    };
  }
}
export {LedgerEntry};

class Ledger {
  id: string;
  created: ?Date;
  updated: ?Date;
  active_ledger_entries: Array<LedgerEntry>;

  constructor(props: $Shape<Ledger> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.active_ledger_entries = [];
    if (props.hasOwnProperty('active_ledger_entries')) {
      const v = props.active_ledger_entries;
      if (!Array.isArray(v)) {
        throw new Error('repeated field active_ledger_entries should be array');
      }
      this.active_ledger_entries = v.map(function(v) {
        return new LedgerEntry(v);
      });
    }
  }
}
export {Ledger};

class LedgerStatement_LedgerStatementEntry {
  created: ?Date;
  description: string;
  amount: ?Money;

  constructor(props: $Shape<LedgerStatement_LedgerStatementEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.amount = null;
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v && new Money(v);
    }
  }

  getAmount(): Money {
    if (this.amount) {
      return this.amount;
    }
    return new Money();
  }
}
export {LedgerStatement_LedgerStatementEntry};
class LedgerStatement {
  display_ledger_entries: Array<LedgerStatement_LedgerStatementEntry>;
  start: ?Date;
  end: ?Date;
  previous_balance: ?Money;
  new_balance: ?Money;
  amount_due: ?Money;
  amount_paid: ?Money;
  amount_billed: ?Money;
  key: string;
  instruments_used: Array<Instrument>;

  constructor(props: $Shape<LedgerStatement> = {}): void {
    if (!props) {
      props = {};
    }

    this.display_ledger_entries = [];
    if (props.hasOwnProperty('display_ledger_entries')) {
      const v = props.display_ledger_entries;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field display_ledger_entries should be array',
        );
      }
      this.display_ledger_entries = v.map(function(v) {
        return new LedgerStatement_LedgerStatementEntry(v);
      });
    }

    this.start = null;
    if (props.hasOwnProperty('start')) {
      const v = props.start;
      this.start = v && new Date(v);
    }

    this.end = null;
    if (props.hasOwnProperty('end')) {
      const v = props.end;
      this.end = v && new Date(v);
    }

    this.previous_balance = null;
    if (props.hasOwnProperty('previous_balance')) {
      const v = props.previous_balance;
      this.previous_balance = v && new Money(v);
    }

    this.new_balance = null;
    if (props.hasOwnProperty('new_balance')) {
      const v = props.new_balance;
      this.new_balance = v && new Money(v);
    }

    this.amount_due = null;
    if (props.hasOwnProperty('amount_due')) {
      const v = props.amount_due;
      this.amount_due = v && new Money(v);
    }

    this.amount_paid = null;
    if (props.hasOwnProperty('amount_paid')) {
      const v = props.amount_paid;
      this.amount_paid = v && new Money(v);
    }

    this.amount_billed = null;
    if (props.hasOwnProperty('amount_billed')) {
      const v = props.amount_billed;
      this.amount_billed = v && new Money(v);
    }

    this.key = '';
    if (props.hasOwnProperty('key')) {
      const v = props.key;
      this.key = v;
    }

    this.instruments_used = [];
    if (props.hasOwnProperty('instruments_used')) {
      const v = props.instruments_used;
      if (!Array.isArray(v)) {
        throw new Error('repeated field instruments_used should be array');
      }
      this.instruments_used = v.map(function(v) {
        return new Instrument(v);
      });
    }
  }

  getPreviousBalance(): Money {
    if (this.previous_balance) {
      return this.previous_balance;
    }
    return new Money();
  }

  getNewBalance(): Money {
    if (this.new_balance) {
      return this.new_balance;
    }
    return new Money();
  }

  getAmountDue(): Money {
    if (this.amount_due) {
      return this.amount_due;
    }
    return new Money();
  }

  getAmountPaid(): Money {
    if (this.amount_paid) {
      return this.amount_paid;
    }
    return new Money();
  }

  getAmountBilled(): Money {
    if (this.amount_billed) {
      return this.amount_billed;
    }
    return new Money();
  }
}
export {LedgerStatement};

class ArchivedLedgerEntry {
  id: string;
  subscription_id: string;
  entry: ?LedgerEntry;

  constructor(props: $Shape<ArchivedLedgerEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.entry = null;
    if (props.hasOwnProperty('entry')) {
      const v = props.entry;
      this.entry = v && new LedgerEntry(v);
    }
  }

  getEntry(): LedgerEntry {
    if (this.entry) {
      return this.entry;
    }
    return new LedgerEntry();
  }
}
export {ArchivedLedgerEntry};

class ReferralCode {
  code: string;
  amount: ?Money;
  referrer_subscription_id: string;

  constructor(props: $Shape<ReferralCode> = {}): void {
    if (!props) {
      props = {};
    }

    this.code = '';
    if (props.hasOwnProperty('code')) {
      const v = props.code;
      this.code = v;
    }

    this.amount = null;
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v && new Money(v);
    }

    this.referrer_subscription_id = '0';
    if (props.hasOwnProperty('referrer_subscription_id')) {
      const v = props.referrer_subscription_id;
      this.referrer_subscription_id = v;
    }
  }

  getAmount(): Money {
    if (this.amount) {
      return this.amount;
    }
    return new Money();
  }
}
export {ReferralCode};

class PromoCode {
  code: string;
  amount: ?Money;
  created: ?Date;
  updated: ?Date;
  description: string;
  expiration: ?Date;
  redirect: string;
  months: string;

  constructor(props: $Shape<PromoCode> = {}): void {
    if (!props) {
      props = {};
    }

    this.code = '';
    if (props.hasOwnProperty('code')) {
      const v = props.code;
      this.code = v;
    }

    this.amount = null;
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v && new Money(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.expiration = null;
    if (props.hasOwnProperty('expiration')) {
      const v = props.expiration;
      this.expiration = v && new Date(v);
    }

    this.redirect = '';
    if (props.hasOwnProperty('redirect')) {
      const v = props.redirect;
      this.redirect = v;
    }

    this.months = '0';
    if (props.hasOwnProperty('months')) {
      const v = props.months;
      this.months = v;
    }
  }

  getAmount(): Money {
    if (this.amount) {
      return this.amount;
    }
    return new Money();
  }
}
export {PromoCode};

class Promo {
  _referral_code: ?ReferralCode;
  _promo_code: ?PromoCode;

  constructor(props: $Shape<Promo> = {}): void {
    if (!props) {
      props = {};
    }

    this._referral_code = undefined;
    if (props.hasOwnProperty('referral_code')) {
      const v = props.referral_code;
      this._referral_code = v && new ReferralCode(v);
    }

    this._promo_code = undefined;
    if (props.hasOwnProperty('promo_code')) {
      const v = props.promo_code;
      this._promo_code = v && new PromoCode(v);
    }
  }

  getReferralCode(): ReferralCode {
    if (this._referral_code) {
      return this._referral_code;
    }
    return new ReferralCode();
  }

  getPromoCode(): PromoCode {
    if (this._promo_code) {
      return this._promo_code;
    }
    return new PromoCode();
  }

  get referral_code(): ?ReferralCode {
    return this._referral_code;
  }
  set referral_code(referral_code: ?ReferralCode) {
    this._referral_code = referral_code;
    this._promo_code = undefined;
  }
  get promo_code(): ?PromoCode {
    return this._promo_code;
  }
  set promo_code(promo_code: ?PromoCode) {
    this._referral_code = undefined;
    this._promo_code = promo_code;
  }

  toJSON() {
    return {
      referral_code: this._referral_code,
      promo_code: this._promo_code,
    };
  }
}
export {Promo};

export const PromoValidation_InvalidReasonValues = {
  REASON_NONE: 0,
  REASON_INVALID_CODE: 1,
  REASON_EXPIRED: 2,
};
type PromoValidation_InvalidReason = $Keys<
  typeof PromoValidation_InvalidReasonValues,
>;
export type {PromoValidation_InvalidReason};

export function PromoValidation_InvalidReasonValue(
  n: number,
): PromoValidation_InvalidReason {
  switch (n) {
    case 0:
      return 'REASON_NONE';

    case 1:
      return 'REASON_INVALID_CODE';

    case 2:
      return 'REASON_EXPIRED';

    default:
      return 'REASON_NONE';
  }
}

class PromoValidation {
  valid: boolean;
  promo: ?Promo;
  invalid_reason: PromoValidation_InvalidReason;

  constructor(props: $Shape<PromoValidation> = {}): void {
    if (!props) {
      props = {};
    }

    this.valid = false;
    if (props.hasOwnProperty('valid')) {
      const v = props.valid;
      this.valid = !!v;
    }

    this.promo = null;
    if (props.hasOwnProperty('promo')) {
      const v = props.promo;
      this.promo = v && new Promo(v);
    }

    this.invalid_reason = PromoValidation_InvalidReasonValue(0);
    if (props.hasOwnProperty('invalid_reason')) {
      const v = props.invalid_reason;
      this.invalid_reason = v;
    }
  }

  getPromo(): Promo {
    if (this.promo) {
      return this.promo;
    }
    return new Promo();
  }
}
export {PromoValidation};

// END cwn/billing/models.proto
