// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */

// START cwn/ubnt/unifi/models.proto

export const ModelValues = {
  INVALID: 0,
  UAP_AC_PRO_GEN2: 1,
  UAP_AC_IW_PRO: 2,
  UAP_AC_HD: 3,
  UAP_AC_MESH: 4,
  UAP_AC_MESH_PRO: 5,
  UAP_AC_LITE: 6,
  USG_3P: 100,
  TPLINK_EC330: 200,
  EERO: 201,
  EERO_BEACON: 202,
  EERO_PRO: 203,
  EERO_GEN1: 204,
};
type Model = $Keys<typeof ModelValues>;
export type {Model};

export function ModelValue(n: number): Model {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'UAP_AC_PRO_GEN2';

    case 2:
      return 'UAP_AC_IW_PRO';

    case 3:
      return 'UAP_AC_HD';

    case 4:
      return 'UAP_AC_MESH';

    case 5:
      return 'UAP_AC_MESH_PRO';

    case 6:
      return 'UAP_AC_LITE';

    case 100:
      return 'USG_3P';

    case 200:
      return 'TPLINK_EC330';

    case 201:
      return 'EERO';

    case 202:
      return 'EERO_BEACON';

    case 203:
      return 'EERO_PRO';

    case 204:
      return 'EERO_GEN1';

    default:
      return 'INVALID';
  }
}

export const Wlan_WifiSecurityModeValues = {
  UNKNOWN: 0,
  NONE: 1,
  WPA2_PSK_AES: 2,
};
type Wlan_WifiSecurityMode = $Keys<typeof Wlan_WifiSecurityModeValues>;
export type {Wlan_WifiSecurityMode};

export function Wlan_WifiSecurityModeValue(n: number): Wlan_WifiSecurityMode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'NONE';

    case 2:
      return 'WPA2_PSK_AES';

    default:
      return 'UNKNOWN';
  }
}

class Wlan {
  id: string;
  wlangroup_id: string;
  essid: string;
  passphrase: string;
  wifi_security_mode: Wlan_WifiSecurityMode;
  guest: boolean;
  enabled: boolean;
  controller: string;
  site_name: string;

  constructor(props: $Shape<Wlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.wlangroup_id = '';
    if (props.hasOwnProperty('wlangroup_id')) {
      const v = props.wlangroup_id;
      this.wlangroup_id = v;
    }

    this.essid = '';
    if (props.hasOwnProperty('essid')) {
      const v = props.essid;
      this.essid = v;
    }

    this.passphrase = '';
    if (props.hasOwnProperty('passphrase')) {
      const v = props.passphrase;
      this.passphrase = v;
    }

    this.wifi_security_mode = Wlan_WifiSecurityModeValue(0);
    if (props.hasOwnProperty('wifi_security_mode')) {
      const v = props.wifi_security_mode;
      this.wifi_security_mode = v;
    }

    this.guest = false;
    if (props.hasOwnProperty('guest')) {
      const v = props.guest;
      this.guest = !!v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.controller = '';
    if (props.hasOwnProperty('controller')) {
      const v = props.controller;
      this.controller = v;
    }

    this.site_name = '';
    if (props.hasOwnProperty('site_name')) {
      const v = props.site_name;
      this.site_name = v;
    }
  }
}
export {Wlan};

class WlanGroup {
  id: string;
  name: string;
  site_name: string;
  controller: string;

  constructor(props: $Shape<WlanGroup> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.site_name = '';
    if (props.hasOwnProperty('site_name')) {
      const v = props.site_name;
      this.site_name = v;
    }

    this.controller = '';
    if (props.hasOwnProperty('controller')) {
      const v = props.controller;
      this.controller = v;
    }
  }
}
export {WlanGroup};

export const UnifiAP_SteeringModeValues = {
  OFF: 0,
  BALANCED: 1,
  PREFER_5G: 2,
};
type UnifiAP_SteeringMode = $Keys<typeof UnifiAP_SteeringModeValues>;
export type {UnifiAP_SteeringMode};

export function UnifiAP_SteeringModeValue(n: number): UnifiAP_SteeringMode {
  switch (n) {
    case 0:
      return 'OFF';

    case 1:
      return 'BALANCED';

    case 2:
      return 'PREFER_5G';

    default:
      return 'OFF';
  }
}

export const UnifiAP_ModeValues = {
  UNKNOWN: 0,
  ROUTER: 1,
  AP: 2,
  REPEATER: 3,
};
type UnifiAP_Mode = $Keys<typeof UnifiAP_ModeValues>;
export type {UnifiAP_Mode};

export function UnifiAP_ModeValue(n: number): UnifiAP_Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'ROUTER';

    case 2:
      return 'AP';

    case 3:
      return 'REPEATER';

    default:
      return 'UNKNOWN';
  }
}

class UnifiAP_RadioConfig {
  chan_width: string;
  chan: string;
  min_rssi: string;
  min_rssi_enabled: boolean;

  constructor(props: $Shape<UnifiAP_RadioConfig> = {}): void {
    if (!props) {
      props = {};
    }

    this.chan_width = '0';
    if (props.hasOwnProperty('chan_width')) {
      const v = props.chan_width;
      this.chan_width = v;
    }

    this.chan = '0';
    if (props.hasOwnProperty('chan')) {
      const v = props.chan;
      this.chan = v;
    }

    this.min_rssi = '0';
    if (props.hasOwnProperty('min_rssi')) {
      const v = props.min_rssi;
      this.min_rssi = v;
    }

    this.min_rssi_enabled = false;
    if (props.hasOwnProperty('min_rssi_enabled')) {
      const v = props.min_rssi_enabled;
      this.min_rssi_enabled = !!v;
    }
  }
}
export {UnifiAP_RadioConfig};
class UnifiAP {
  id: string;
  mac_address: string;
  model: Model;
  name: string;
  site_name: string;
  wlangroup_id_2g: string;
  wlangroup_id_5g: string;
  radio_2g_config: ?UnifiAP_RadioConfig;
  radio_5g_config: ?UnifiAP_RadioConfig;
  band_steering_mode: UnifiAP_SteeringMode;
  controller: string;
  mode: UnifiAP_Mode;

  constructor(props: $Shape<UnifiAP> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.model = ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.site_name = '';
    if (props.hasOwnProperty('site_name')) {
      const v = props.site_name;
      this.site_name = v;
    }

    this.wlangroup_id_2g = '';
    if (props.hasOwnProperty('wlangroup_id_2g')) {
      const v = props.wlangroup_id_2g;
      this.wlangroup_id_2g = v;
    }

    this.wlangroup_id_5g = '';
    if (props.hasOwnProperty('wlangroup_id_5g')) {
      const v = props.wlangroup_id_5g;
      this.wlangroup_id_5g = v;
    }

    this.radio_2g_config = null;
    if (props.hasOwnProperty('radio_2g_config')) {
      const v = props.radio_2g_config;
      this.radio_2g_config = v && new UnifiAP_RadioConfig(v);
    }

    this.radio_5g_config = null;
    if (props.hasOwnProperty('radio_5g_config')) {
      const v = props.radio_5g_config;
      this.radio_5g_config = v && new UnifiAP_RadioConfig(v);
    }

    this.band_steering_mode = UnifiAP_SteeringModeValue(0);
    if (props.hasOwnProperty('band_steering_mode')) {
      const v = props.band_steering_mode;
      this.band_steering_mode = v;
    }

    this.controller = '';
    if (props.hasOwnProperty('controller')) {
      const v = props.controller;
      this.controller = v;
    }

    this.mode = UnifiAP_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }
  }

  getRadio2gConfig(): UnifiAP_RadioConfig {
    if (this.radio_2g_config) {
      return this.radio_2g_config;
    }
    return new UnifiAP_RadioConfig();
  }

  getRadio5gConfig(): UnifiAP_RadioConfig {
    if (this.radio_5g_config) {
      return this.radio_5g_config;
    }
    return new UnifiAP_RadioConfig();
  }
}
export {UnifiAP};

export const Status_StateValues = {
  UNKNOWN: 0,
  DISCONNECTED: 1,
  CONNECTED: 2,
  DISCOVERED: 3,
  ADOPTING: 4,
  UPGRADING: 5,
  PROVISIONING: 6,
  CONFIGURING: 7,
  NOT_ASSIGNED: 8,
};
type Status_State = $Keys<typeof Status_StateValues>;
export type {Status_State};

export function Status_StateValue(n: number): Status_State {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'DISCONNECTED';

    case 2:
      return 'CONNECTED';

    case 3:
      return 'DISCOVERED';

    case 4:
      return 'ADOPTING';

    case 5:
      return 'UPGRADING';

    case 6:
      return 'PROVISIONING';

    case 7:
      return 'CONFIGURING';

    case 8:
      return 'NOT_ASSIGNED';

    default:
      return 'UNKNOWN';
  }
}

class Status_UnifiUplink {
  interface_name: string;
  speed: string;
  full_duplex: boolean;

  constructor(props: $Shape<Status_UnifiUplink> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.full_duplex = false;
    if (props.hasOwnProperty('full_duplex')) {
      const v = props.full_duplex;
      this.full_duplex = !!v;
    }
  }
}
export {Status_UnifiUplink};
class Status_Stations {
  Count2g: string;
  Count5g: string;

  constructor(props: $Shape<Status_Stations> = {}): void {
    if (!props) {
      props = {};
    }

    this.Count2g = '0';
    if (props.hasOwnProperty('Count2g')) {
      const v = props.Count2g;
      this.Count2g = v;
    }

    this.Count5g = '0';
    if (props.hasOwnProperty('Count5g')) {
      const v = props.Count5g;
      this.Count5g = v;
    }
  }
}
export {Status_Stations};
class Status {
  id: string;
  config: ?UnifiAP;
  created: ?Date;
  adopted: boolean;
  uptime: string;
  firmware: string;
  state: Status_State;
  enabled: boolean;
  uplink: ?Status_UnifiUplink;
  stations: ?Status_Stations;
  ip: string;

  constructor(props: $Shape<Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.config = null;
    if (props.hasOwnProperty('config')) {
      const v = props.config;
      this.config = v && new UnifiAP(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.adopted = false;
    if (props.hasOwnProperty('adopted')) {
      const v = props.adopted;
      this.adopted = !!v;
    }

    this.uptime = '0';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.firmware = '';
    if (props.hasOwnProperty('firmware')) {
      const v = props.firmware;
      this.firmware = v;
    }

    this.state = Status_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.uplink = null;
    if (props.hasOwnProperty('uplink')) {
      const v = props.uplink;
      this.uplink = v && new Status_UnifiUplink(v);
    }

    this.stations = null;
    if (props.hasOwnProperty('stations')) {
      const v = props.stations;
      this.stations = v && new Status_Stations(v);
    }

    this.ip = '';
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v;
    }
  }

  getConfig(): UnifiAP {
    if (this.config) {
      return this.config;
    }
    return new UnifiAP();
  }

  getUplink(): Status_UnifiUplink {
    if (this.uplink) {
      return this.uplink;
    }
    return new Status_UnifiUplink();
  }

  getStations(): Status_Stations {
    if (this.stations) {
      return this.stations;
    }
    return new Status_Stations();
  }
}
export {Status};

class UnassignedUnifis {
  id: string;
  statuses: Array<Status>;
  updated: ?Date;

  constructor(props: $Shape<UnassignedUnifis> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.statuses = [];
    if (props.hasOwnProperty('statuses')) {
      const v = props.statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field statuses should be array');
      }
      this.statuses = v.map(function(v) {
        return new Status(v);
      });
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }
}
export {UnassignedUnifis};

// END cwn/ubnt/unifi/models.proto
