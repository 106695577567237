// @flow
import React, {PureComponent, type Element, type Node} from 'react';
import {GoogleMap as GoogleMapBase} from '@react-google-maps/api';

import {withEnv, type Environment} from 'env';
import {colors} from 'theme';

export {Marker} from '@react-google-maps/api';

export const MapTypeIds = {
  HYBRID: 'hybrid',
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  TERRAIN: 'terrain',
};
export type MapTypeId = $Values<typeof MapTypeIds>;

export type LatLngLiteral = {
  lat: number,
  lng: number,
};

export type LatLng = {
  lat(): number,
  lng(): number,
  equals(LatLng): boolean,
  toJSON(): LatLngLiteral,
  toString(): string,
  toUrlValue(precision: ?number): string,
};

export type LatLngBoundsLiteral = {
  east: number,
  north: number,
  south: number,
  west: number,
};

export type LatLngBounds = {
  contains(LatLng | LatLngLiteral): boolean,
  equals(LatLngBounds | LatLngBoundsLiteral): boolean,
  extend(LatLng | LatLngLiteral): LatLngBounds,
  getCenter(): LatLng,
  getNorthEast(): LatLng,
  getSouthWest(): LatLng,
  intersects(LatLngBounds | LatLngBoundsLiteral): boolean,
  isEmpty(): boolean,
  toJSON(): LatLngBoundsLiteral,
  toSpan(): LatLng,
  toString(): string,
  toUrlValue(precision: ?number): string,
  union(LatLngBounds | LatLngBoundsLiteral): LatLngBounds,
};

export type PointLiteral = {
  x: number,
  y: number,
};

export type Point = {
  x: number,
  y: number,
  equals(Point): boolean,
  toString(): string,
};

export type Projection = {
  fromLatLngToPoint(latLng: LatLng, point: ?Point): Point,
  fromPointToLatLng(pixel: Point, nowrap: ?boolean): LatLng,
};

export type MapRef = {
  fitBounds(LatLngBounds | LatLngBoundsLiteral): void,
  getBounds(): LatLngBounds,
  getCenter(): LatLng,
  getClickableIcons(): boolean,
  getDiv(): any,
  getHeading(): number,
  getMapTypeId(): MapTypeId,
  getProjection(): Projection,
  getStreetView(): any,
  getTilt(): number,
  getZoom(): number,
  panBy(x: number, y: number): void,
  panTo(LatLng | LatLngLiteral): void,
  setCenter(LatLng | LatLngLiteral): void,
  setZoom(number): void,
  // setClickableIcons(boolean): void,
  // setHeading(number): void,
  // setMapTypeId(MapTypeId): void,
  // setOptions(any): void,
  // setStreetView(any): void,
  // setTilt(number): void,
};

export type OwnProps = {|
  children?: Node,
  containerElement?: Element<any>,
  mapElement?: Element<any>,
  defaultCenter?: LatLngLiteral,
  defaultZoom?: number,
  defaultExtraMapTypes?: Array<{[string]: MapTypeId | any}>,
  defaultCenter?: LatLng | LatLngLiteral,
  defaultClickableIcons?: boolean,
  defaultHeading?: number,
  defaultMapTypeId?: MapTypeId,
  defaultOptions?: any,
  defaultStreetView?: any,
  defaultTilt?: number,
  defaultZoom?: number,
  center?: LatLng | LatLngLiteral,
  clickableIcons?: boolean,
  heading?: number,
  mapTypeId?: MapTypeId,
  mapContainerStyle?: Object,
  options?: any,
  streetView?: any,
  tilt?: number,
  zoom?: number,
  onMount?: MapRef => any,
  onBoundsChanged?: LatLngBounds => any,
  onCenterChanged?: LatLng => any,
  onHeadingChanged?: number => any,
  onMapTypeIdChanged?: MapTypeId => any,
  onProjectionChanged?: Projection => any,
  onTiltChanged?: number => any,
  onZoomChanged?: number => any,
  onDblClick?: any => any,
  onDragEnd?: any => any,
  onDragStart?: any => any,
  onMouseMove?: any => any,
  onMouseOut?: any => any,
  onMouseOver?: any => any,
  onRightClick?: any => any,
  onTilesLoaded?: any => any,
  onClick?: any => any,
  onDrag?: any => any,
  onIdle?: any => any,
  onResize?: any => any,
|};

type Props = {|
  ...OwnProps,
  env: Environment,
|};

class GoogleMap extends PureComponent<Props> {
  static defaultProps = {
    containerElement: (
      <div style={{display: 'flex', height: '100%', width: '100%'}} />
    ),
    mapElement: <div style={{flex: 1}} />,
    defaultZoom: 14,
    defaultCenter: {lat: 37.7652, lng: -122.2416},
  };

  map: ?MapRef;

  handleMount = (ref: MapRef) => {
    const {onMount} = this.props;
    this.map = ref;
    if (onMount) {
      onMount(ref);
    }
  };

  // default GoogleMap callbacks dont pass anything useful by default...
  handleBoundsChanged = () => {
    const {onBoundsChanged} = this.props;
    if (this.map && onBoundsChanged) {
      onBoundsChanged(this.map.getBounds());
    }
  };

  handleCenterChanged = () => {
    const {onCenterChanged} = this.props;
    if (this.map && onCenterChanged) {
      onCenterChanged(this.map.getCenter());
    }
  };

  handleHeadingChanged = () => {
    const {onHeadingChanged} = this.props;
    if (this.map && onHeadingChanged) {
      onHeadingChanged(this.map.getHeading());
    }
  };

  handleMapTypeIdChanged = () => {
    const {onMapTypeIdChanged} = this.props;
    if (this.map && onMapTypeIdChanged) {
      onMapTypeIdChanged(this.map.getMapTypeId());
    }
  };

  handleProjectionChanged = () => {
    const {onProjectionChanged} = this.props;
    if (this.map && onProjectionChanged) {
      onProjectionChanged(this.map.getProjection());
    }
  };

  handleTiltChanged = () => {
    const {onTiltChanged} = this.props;
    if (this.map && onTiltChanged) {
      onTiltChanged(this.map.getTilt());
    }
  };

  handleZoomChanged = () => {
    const {onZoomChanged} = this.props;
    if (this.map && onZoomChanged) {
      onZoomChanged(this.map.getZoom());
    }
  };

  render() {
    const {children, env} = this.props;

    if (env.testing) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: colors.blue5,
          }}>
          {children}
        </div>
      );
    }

    return (
      <GoogleMapBase
        mapContainerStyle={{width: '100%', height: '100%'}}
        {...this.props}
        onLoad={this.handleMount}
        onBoundsChanged={this.handleBoundsChanged}
        onCenterChanged={this.handleCenterChanged}
        onHeadingChanged={this.handleHeadingChanged}
        onMapTypeIdChanged={this.handleMapTypeIdChanged}
        onProjectionChanged={this.handleProjectionChanged}
        onTiltChanged={this.handleTiltChanged}
        onZoomChanged={this.handleZoomChanged}
      />
    );
  }
}

export default withEnv<OwnProps>(GoogleMap);
