// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/acuity/models.proto

class Appointment {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  date: string;
  datetime: string;
  time: string;
  price: string;
  type: string;
  appointmentTypeID: string;
  duration: string;
  calendar: string;
  calendarID: string;
  confirmationPage: string;
  forms_text: string;
  notes: string;
  timezone: string;
  dateCreated: string;
  canceled: boolean;

  constructor(props: $Shape<Appointment> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.firstName = '';
    if (props.hasOwnProperty('firstName')) {
      const v = props.firstName;
      this.firstName = v;
    }

    this.lastName = '';
    if (props.hasOwnProperty('lastName')) {
      const v = props.lastName;
      this.lastName = v;
    }

    this.phone = '';
    if (props.hasOwnProperty('phone')) {
      const v = props.phone;
      this.phone = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.date = '';
    if (props.hasOwnProperty('date')) {
      const v = props.date;
      this.date = v;
    }

    this.datetime = '';
    if (props.hasOwnProperty('datetime')) {
      const v = props.datetime;
      this.datetime = v;
    }

    this.time = '';
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v;
    }

    this.price = '';
    if (props.hasOwnProperty('price')) {
      const v = props.price;
      this.price = v;
    }

    this.type = '';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.appointmentTypeID = '0';
    if (props.hasOwnProperty('appointmentTypeID')) {
      const v = props.appointmentTypeID;
      this.appointmentTypeID = v;
    }

    this.duration = '';
    if (props.hasOwnProperty('duration')) {
      const v = props.duration;
      this.duration = v;
    }

    this.calendar = '';
    if (props.hasOwnProperty('calendar')) {
      const v = props.calendar;
      this.calendar = v;
    }

    this.calendarID = '0';
    if (props.hasOwnProperty('calendarID')) {
      const v = props.calendarID;
      this.calendarID = v;
    }

    this.confirmationPage = '';
    if (props.hasOwnProperty('confirmationPage')) {
      const v = props.confirmationPage;
      this.confirmationPage = v;
    }

    this.forms_text = '';
    if (props.hasOwnProperty('forms_text')) {
      const v = props.forms_text;
      this.forms_text = v;
    }

    this.notes = '';
    if (props.hasOwnProperty('notes')) {
      const v = props.notes;
      this.notes = v;
    }

    this.timezone = '';
    if (props.hasOwnProperty('timezone')) {
      const v = props.timezone;
      this.timezone = v;
    }

    this.dateCreated = '';
    if (props.hasOwnProperty('dateCreated')) {
      const v = props.dateCreated;
      this.dateCreated = v;
    }

    this.canceled = false;
    if (props.hasOwnProperty('canceled')) {
      const v = props.canceled;
      this.canceled = !!v;
    }
  }
}
export {Appointment};

class Calendar {
  id: string;
  name: string;
  email: string;
  replyTo: string;
  description: string;
  location: string;
  timezone: string;

  constructor(props: $Shape<Calendar> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.replyTo = '';
    if (props.hasOwnProperty('replyTo')) {
      const v = props.replyTo;
      this.replyTo = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.location = '';
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v;
    }

    this.timezone = '';
    if (props.hasOwnProperty('timezone')) {
      const v = props.timezone;
      this.timezone = v;
    }
  }
}
export {Calendar};

class AppointmentType {
  id: number;
  name: string;
  duration: number;
  calendarIDs: Array<number>;

  constructor(props: $Shape<AppointmentType> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = 0;
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.duration = 0;
    if (props.hasOwnProperty('duration')) {
      const v = props.duration;
      this.duration = v;
    }

    this.calendarIDs = [];
    if (props.hasOwnProperty('calendarIDs')) {
      const v = props.calendarIDs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field calendarIDs should be array');
      }
      this.calendarIDs = v.map(function(v) {
        return v;
      });
    }
  }
}
export {AppointmentType};

class AvailableSlot {
  time: string;

  constructor(props: $Shape<AvailableSlot> = {}): void {
    if (!props) {
      props = {};
    }

    this.time = '';
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v;
    }
  }
}
export {AvailableSlot};

class AvailableDay {
  date: string;

  constructor(props: $Shape<AvailableDay> = {}): void {
    if (!props) {
      props = {};
    }

    this.date = '';
    if (props.hasOwnProperty('date')) {
      const v = props.date;
      this.date = v;
    }
  }
}
export {AvailableDay};

class PostAppointmentRequest {
  datetime: string;
  appointmentTypeID: number;
  calendarID: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  timezone: string;
  notes: string;

  constructor(props: $Shape<PostAppointmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.datetime = '';
    if (props.hasOwnProperty('datetime')) {
      const v = props.datetime;
      this.datetime = v;
    }

    this.appointmentTypeID = 0;
    if (props.hasOwnProperty('appointmentTypeID')) {
      const v = props.appointmentTypeID;
      this.appointmentTypeID = v;
    }

    this.calendarID = 0;
    if (props.hasOwnProperty('calendarID')) {
      const v = props.calendarID;
      this.calendarID = v;
    }

    this.firstName = '';
    if (props.hasOwnProperty('firstName')) {
      const v = props.firstName;
      this.firstName = v;
    }

    this.lastName = '';
    if (props.hasOwnProperty('lastName')) {
      const v = props.lastName;
      this.lastName = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.phone = '';
    if (props.hasOwnProperty('phone')) {
      const v = props.phone;
      this.phone = v;
    }

    this.timezone = '';
    if (props.hasOwnProperty('timezone')) {
      const v = props.timezone;
      this.timezone = v;
    }

    this.notes = '';
    if (props.hasOwnProperty('notes')) {
      const v = props.notes;
      this.notes = v;
    }
  }
}
export {PostAppointmentRequest};

class CancelAppointmentRequest {
  cancelNote: string;
  noShow: boolean;

  constructor(props: $Shape<CancelAppointmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cancelNote = '';
    if (props.hasOwnProperty('cancelNote')) {
      const v = props.cancelNote;
      this.cancelNote = v;
    }

    this.noShow = false;
    if (props.hasOwnProperty('noShow')) {
      const v = props.noShow;
      this.noShow = !!v;
    }
  }
}
export {CancelAppointmentRequest};

class RescheduleAppointmentRequest {
  datetime: string;

  constructor(props: $Shape<RescheduleAppointmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.datetime = '';
    if (props.hasOwnProperty('datetime')) {
      const v = props.datetime;
      this.datetime = v;
    }
  }
}
export {RescheduleAppointmentRequest};

class ErrorResponse {
  status_code: number;
  message: string;
  error: string;

  constructor(props: $Shape<ErrorResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.status_code = 0;
    if (props.hasOwnProperty('status_code')) {
      const v = props.status_code;
      this.status_code = v;
    }

    this.message = '';
    if (props.hasOwnProperty('message')) {
      const v = props.message;
      this.message = v;
    }

    this.error = '';
    if (props.hasOwnProperty('error')) {
      const v = props.error;
      this.error = v;
    }
  }
}
export {ErrorResponse};

class AppointmentConfig {
  user_id: string;
  api_key: string;
  appointment_type: number;

  constructor(props: $Shape<AppointmentConfig> = {}): void {
    if (!props) {
      props = {};
    }

    this.user_id = '';
    if (props.hasOwnProperty('user_id')) {
      const v = props.user_id;
      this.user_id = v;
    }

    this.api_key = '';
    if (props.hasOwnProperty('api_key')) {
      const v = props.api_key;
      this.api_key = v;
    }

    this.appointment_type = 0;
    if (props.hasOwnProperty('appointment_type')) {
      const v = props.appointment_type;
      this.appointment_type = v;
    }
  }
}
export {AppointmentConfig};

class BookingSnapshot {
  id: string;
  date: string;
  area_tag: string;
  created: ?Date;
  updated: ?Date;
  sfh_slots: string;
  sfh_booked: string;
  mdu_slots: string;
  mdu_booked: string;
  percent_booked_seven_days: string;
  percent_booked_fourteen_days: string;
  percent_booked_thirty_days: string;

  constructor(props: $Shape<BookingSnapshot> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.date = '';
    if (props.hasOwnProperty('date')) {
      const v = props.date;
      this.date = v;
    }

    this.area_tag = '';
    if (props.hasOwnProperty('area_tag')) {
      const v = props.area_tag;
      this.area_tag = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.sfh_slots = '0';
    if (props.hasOwnProperty('sfh_slots')) {
      const v = props.sfh_slots;
      this.sfh_slots = v;
    }

    this.sfh_booked = '0';
    if (props.hasOwnProperty('sfh_booked')) {
      const v = props.sfh_booked;
      this.sfh_booked = v;
    }

    this.mdu_slots = '0';
    if (props.hasOwnProperty('mdu_slots')) {
      const v = props.mdu_slots;
      this.mdu_slots = v;
    }

    this.mdu_booked = '0';
    if (props.hasOwnProperty('mdu_booked')) {
      const v = props.mdu_booked;
      this.mdu_booked = v;
    }

    this.percent_booked_seven_days = '0';
    if (props.hasOwnProperty('percent_booked_seven_days')) {
      const v = props.percent_booked_seven_days;
      this.percent_booked_seven_days = v;
    }

    this.percent_booked_fourteen_days = '0';
    if (props.hasOwnProperty('percent_booked_fourteen_days')) {
      const v = props.percent_booked_fourteen_days;
      this.percent_booked_fourteen_days = v;
    }

    this.percent_booked_thirty_days = '0';
    if (props.hasOwnProperty('percent_booked_thirty_days')) {
      const v = props.percent_booked_thirty_days;
      this.percent_booked_thirty_days = v;
    }
  }
}
export {BookingSnapshot};

// END cwn/acuity/models.proto
