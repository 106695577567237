// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */

// START cwn/site/details/models.proto

export const BuildingDescription_RoofMaterialValues = {
  UNKNOWN_MATERIAL: 0,
  ASPHALT_SHINGLES: 1,
  CLAY_SLATE_CONCRETE_TILES: 2,
  METAL: 3,
  CONCRETE: 4,
  WOOD: 5,
  OTHER: 6,
  CALPAC: 7,
  RUBBER_MEMBRANE: 8,
  TAR_AND_GRAVEL: 9,
  FOAM: 10,
};
type BuildingDescription_RoofMaterial = $Keys<
  typeof BuildingDescription_RoofMaterialValues,
>;
export type {BuildingDescription_RoofMaterial};

export function BuildingDescription_RoofMaterialValue(
  n: number,
): BuildingDescription_RoofMaterial {
  switch (n) {
    case 0:
      return 'UNKNOWN_MATERIAL';

    case 1:
      return 'ASPHALT_SHINGLES';

    case 2:
      return 'CLAY_SLATE_CONCRETE_TILES';

    case 3:
      return 'METAL';

    case 4:
      return 'CONCRETE';

    case 5:
      return 'WOOD';

    case 6:
      return 'OTHER';

    case 7:
      return 'CALPAC';

    case 8:
      return 'RUBBER_MEMBRANE';

    case 9:
      return 'TAR_AND_GRAVEL';

    case 10:
      return 'FOAM';

    default:
      return 'UNKNOWN_MATERIAL';
  }
}

export const BuildingDescription_RoofPitchValues = {
  UNKNOWN_DEGREE: 0,
  FLAT: 1,
  SLIGHT: 2,
  STANDARD: 3,
  STEEP: 4,
};
type BuildingDescription_RoofPitch = $Keys<
  typeof BuildingDescription_RoofPitchValues,
>;
export type {BuildingDescription_RoofPitch};

export function BuildingDescription_RoofPitchValue(
  n: number,
): BuildingDescription_RoofPitch {
  switch (n) {
    case 0:
      return 'UNKNOWN_DEGREE';

    case 1:
      return 'FLAT';

    case 2:
      return 'SLIGHT';

    case 3:
      return 'STANDARD';

    case 4:
      return 'STEEP';

    default:
      return 'UNKNOWN_DEGREE';
  }
}

class BuildingDescription {
  site_id: string;
  building_height: string;
  roof_material: BuildingDescription_RoofMaterial;
  roof_pitch: BuildingDescription_RoofPitch;
  access: string;
  note: string;
  two_person: boolean;
  forty_foot_ladder: boolean;
  site_survey_complete: boolean;
  created: ?Date;
  updated: ?Date;

  constructor(props: $Shape<BuildingDescription> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.building_height = '0';
    if (props.hasOwnProperty('building_height')) {
      const v = props.building_height;
      this.building_height = v;
    }

    this.roof_material = BuildingDescription_RoofMaterialValue(0);
    if (props.hasOwnProperty('roof_material')) {
      const v = props.roof_material;
      this.roof_material = v;
    }

    this.roof_pitch = BuildingDescription_RoofPitchValue(0);
    if (props.hasOwnProperty('roof_pitch')) {
      const v = props.roof_pitch;
      this.roof_pitch = v;
    }

    this.access = '';
    if (props.hasOwnProperty('access')) {
      const v = props.access;
      this.access = v;
    }

    this.note = '';
    if (props.hasOwnProperty('note')) {
      const v = props.note;
      this.note = v;
    }

    this.two_person = false;
    if (props.hasOwnProperty('two_person')) {
      const v = props.two_person;
      this.two_person = !!v;
    }

    this.forty_foot_ladder = false;
    if (props.hasOwnProperty('forty_foot_ladder')) {
      const v = props.forty_foot_ladder;
      this.forty_foot_ladder = !!v;
    }

    this.site_survey_complete = false;
    if (props.hasOwnProperty('site_survey_complete')) {
      const v = props.site_survey_complete;
      this.site_survey_complete = !!v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }
}
export {BuildingDescription};

// END cwn/site/details/models.proto
